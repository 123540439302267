import { useEffect, useState } from 'react';
import ChatApi from '@/components/ucms/chat/common/ChatApi';
import AuthUtil from '@/components/auth/AuthUtil';
import { refineMessage } from '@/components/ucms/chat/live/useLiveChat';

export default function useChatLog(cid) {
  const [param, setParam] = useState({
    cid,
    page: null,
    size: 10,
    hasNext: true,
    isLoading: false,
    isInitialLoading: true,
    list: [],
  });

  useEffect(() => {
    if (param.page != null) {
      load();
    }
  }, [param.page]);

  const load = async () => {
    const res = await ChatApi.getChatLog({
      cid: cid,
      _pageNo: param.page,
      _pageSize: param.size,
      ...(await AuthUtil.getOneTimeSessionKey()),
    });
    let list = res.data.map((it) => refineMessage(it));
    let total = res.total;
    // console.log('chatLog', list);
    setParam((prevState) => ({
      ...prevState,
      isLoading: false,
      isInitialLoading: false,
      hasNext: prevState.list.length + list.length < total,
      total: total,
      list: [...prevState.list, ...list],
    }));
  };

  const loadMore = async () => {
    if (param.hasNext === false) {
      return;
    }
    if (param.isLoading === true) {
      return;
    }
    setParam((prevState) => ({
      ...prevState,
      isLoading: true,
      page: (prevState.page ?? 0) + 1,
    }));
  };

  useEffect(() => {
    loadMore();
  }, []);

  return {
    loadMore,
    ...param,
  };
}
