import { useMemo } from 'react';
import UcmsVideoGridContainer from '@/components/ucms/video/UcmsVideoGridContainer';
import UcmsPostGridContainer from '@/components/ucms/post/UcmsPostGridContainer';
import BoardCategoryChipTab from '@/components/ucms/common/category/BoardCategoryChipTab';
import useMenu from '@/components/menu/useMenu';
import { useParams } from 'react-router-dom';
import { isVideoType } from '@/app/config/ContentType';
import AutoFailLoading from '@/components/common/AutoFailLoading';
import { openSideBar } from '@/components/sidebar/sidebarReducer';
import { useDispatch } from 'react-redux';

/**
 * @return {JSX.Element}
 * @constructor
 */
export default function UcmsBoard({}) {
  const { findVisibleMenu } = useMenu();

  const { menuId, menuId2 } = useParams();

  const selectedMenuId = useMemo(() => {
    return menuId2;
  }, [menuId2]);

  const selectedMenu = useMemo(() => {
    return findVisibleMenu(selectedMenuId);
  }, [selectedMenuId]);

  const isVideoBoard = useMemo(() => {
    return selectedMenu == null || isVideoType(selectedMenu?.contentType);
  }, [selectedMenu]);

  const dispatch = useDispatch();

  return (
    <div id="contents" className="mb-32">
      <BoardCategoryChipTab />

      <div className="inner">
        {selectedMenu == null && (
          <div className="h-full min-h-[300px] flex flex-col items-center justify-center">
            {/* <AutoFailLoading message="접근 권한이 없는 메뉴입니다." /> */}
            <h2>접근 권한이 없는 메뉴입니다.</h2>
            <button
              style={{ color: '#008cdc' }}
              onClick={() => dispatch(openSideBar())}
            >
              로그인하기
            </button>
          </div>
        )}
        {selectedMenu != null && isVideoBoard && (
          <UcmsVideoGridContainer menuId={selectedMenu.menuId} />
        )}
        {selectedMenu != null && !isVideoBoard && (
          <UcmsPostGridContainer menuId={selectedMenu.menuId} />
        )}
      </div>
    </div>
  );
}
