import { useEffect, useMemo, useState } from 'react';
import MyPageHeader from '@/page/my/MypageHeader';
import { CircularProgress } from '@mui/material';
import PageIndicator from '@/components/content/paging/PageIndicator';
import CommonTable from '@/components/table/CommonTable';
import useCommentList from '@/components/user/my/useCommentList';
import CommentTableRow from '@/components/table/comment/CommentTableRow';

export default function UserCommentList() {
  const [isSelectMode, setSelectMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { hasError, isLoading, list, load, remove, total, page } =
    useCommentList();
  const setPage = (page) => {
    load({ page: page + 1 });
  };

  const dataList = useMemo(() => {
    return list?.map((it) => ({
      ...it,
      type: it.rType?.toLowerCase() === 'reply' ? '댓글' : '답글',
      link: `/ucms/view/${it.menuId}/${it.cid}`,
      date: it.regDt?.replace('T', ' '),
    }));
  }, [list]);

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <MyPageHeader
        option={{
          title: '내가 쓴 댓글/답글',
          count: total,
          selectCount: selectedItems.length,
          isDeleteMode: isSelectMode,
          onEnterDeleteMode: async () => {
            if (selectedItems.length === 0) {
              return;
            }
            await remove(
              selectedItems.map((it) => dataList.find((d) => d._itemNo === it))
            );
            setSelectMode(false);
            setSelectedItems([]);
            load();
          },
        }}
      />

      {hasError && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <span>오류가 발생하였습니다.</span>
        </div>
      )}
      {!hasError && isLoading && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <CircularProgress className="loading" />
        </div>
      )}

      {!hasError &&
        !isLoading &&
        (dataList == null || dataList?.length === 0) && (
          <div className="w-full flex items-center justify-center min-h-[400px]">
            <span>작성한 댓글/답글이 없습니다.</span>
          </div>
        )}

      {!hasError && !isLoading && dataList?.length > 0 && (
        <>
          <div className="box_wrap grid sm:gap-3 sm:gap-y-2">
            <CommonTable
              colRatios={[5, 15, 10, 30, 10]}
              ths={['', '카테고리', '댓글/답글', '내용', '등록일']}
            >
              {dataList.map((item, index) => (
                <CommentTableRow
                  id={item._itemNo}
                  isSelected={selectedItems.includes(item._itemNo)}
                  onSelectChange={(_) => {
                    if (selectedItems.includes(item._itemNo)) {
                      setSelectedItems(
                        selectedItems.filter((it) => it !== item._itemNo)
                      );
                    } else {
                      setSelectedItems([...selectedItems, item._itemNo]);
                    }
                  }}
                  category={item.menuNm}
                  link={item.link}
                  type={item.type}
                  comment={item.rData}
                  date={item.date}
                />
              ))}
            </CommonTable>
          </div>

          <PageIndicator
            option={{
              page: page - 1,
              totalCount: total,
              pageSize: 10,
              showingPageCount: 5,
              onPageChange: (p) => setPage(p),
            }}
          />
        </>
      )}
    </>
  );
}
