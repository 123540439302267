export default function useMobile() {
  return {
    get isMobile() {
      // return true; // 강제 모바일 웹 테스트
      let ua = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
      if (ua.indexOf('android') > -1) {
        return true;
      } else if (
        ua.indexOf('iphone') > -1 ||
        ua.indexOf('ipad') > -1 ||
        ua.indexOf('ipod') > -1
      ) {
        return true;
      } else {
        return false;
      }
    },
  };
}
