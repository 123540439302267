import { useSearchParams } from 'react-router-dom';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { useMemo } from 'react';

export default function usePage(defaultPage = 0) {
  const [_, setSearchParams] = useSearchParams();
  const { pathname, query } = useLocation2();
  const page = useMemo(() => {
    const n = Number(query.page);
    if (isNaN(n)) {
      setSearchParams(
        (prev) => ({ ...Object.fromEntries(prev), page: `${defaultPage}` }),
        { replace: true }
      );
      return defaultPage;
    }
    return n;
  }, [query, pathname]);
  const setPage = (p, options = { replace: true }) => {
    setSearchParams(
      (prev) => ({
        ...Object.fromEntries(prev),
        page: typeof p == 'function' ? p(page) : p,
      }),
      options
    );
  };

  return [page, setPage];
}
