import { useDispatch } from 'react-redux';
import { openSideBar } from '../sidebar/sidebarReducer';
import HeaderSearchIcon from './HeaderSearcIcon';
import useLogin from '../user/login/useLogin';
import Link2 from '@/components/common/transition/Link2';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import useMenu from '@/components/menu/useMenu';
import classNames from 'classnames';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import MyProfileImage from '@/components/user/MyProfileImage';
import AlertFetch from '@/components/user/my/AlertFetch';
import useAlertCount from '@/components/user/my/useAlertCount';
import LoginPopup from '@/components/popup/LoginPopup';
import { useEffect, useState } from 'react';
import useWindowDimensions from '@/lib/useWindowDimensions';
import MobileHeaderMenu from '@/components/header/MobileHeaderMenu';
import PrivacyPopup from '@/components/popup/PrivacyPopup';
import { apiInstance } from '@/app/config/network';
import { pop } from '../ucms/detailHistoryReducer';
import MyPageApi from '@/components/user/my/MyPageApi';

export function AlertIcon({ unreadCount, onClick }) {
  return (
    <div onClick={onClick} className="cursor-pointer">
      {unreadCount > 0 && <img src="/images/bell_ico_noti.svg" alt="알림" />}
      {unreadCount === 0 && <img src="/images/bell_ico.svg" alt="알림" />}
    </div>
  );
}

export default function Header({}) {
  const { pathname } = useLocation2();
  const dispatch = useDispatch();
  const navigator = useNavigate2();
  const { isLoggedIn, data: loginData, getData } = useLogin();
  const { videoRootMenuList, boardRootMenuList } = useMenu();
  const { unreadCount } = useAlertCount();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showPopup, setShowPopup] = useState('Y');
  const openLogin = () => {
    dispatch(openSideBar());
  };
  /** 240130 최초 로그인 시, 웰컴 문구 추가 */
  useEffect(() => {
    if (!isLoggedIn) {
      console.log('응답 결과 : 1');
      window.firstLogin = true;
    }
    window.firstLoginPopup = true;

    getPrivacyPopupValue();
  });

  const firstLoginChg = () => {
    setTimeout(() => {
      const _div = document.querySelector('.first-login-pop');
      _div.classList.add('hidden');
      window.firstLogin = false;
    }, 5000);
  };

  const getPrivacyPopupValue = async () => {
    console.log('1111111111111111111111111111');
    if (!isLoggedIn) return null;
    const _popup = await MyPageApi.getPrivacyPopup(loginData?.userNo);
    console.log('22222222222222222222222222222222' + window.firstLoginPopup);
    if (_popup?.code === '00') setShowPopup(_popup?.id);
  };

  const setFirstLogin = () => {
    window.firstLoginPopup = false;
  };

  const goToMyPage = () => {
    navigator('/mypage');
  };

  return (
    <div>
      <AlertFetch />
      <header id="header_home">
        <div className="header_inner border-b">
          <MobileHeaderMenu />
          <h1 className="logo">
            <Link2 to="/">
              <img src="/images/logo.png" alt="우리방송 로고" />
            </Link2>
          </h1>
          <nav className="nav_wrap">
            <ul className="nav">
              {videoRootMenuList.map((menu, index) => (
                <li key={`video_root_${menu.menuId}`}>
                  <Link2
                    to={`/ucms/${menu.menuId}`}
                    className={classNames({
                      active: pathname.startsWith(`/ucms/${menu.menuId}`),
                    })}
                  >
                    {menu.menuNm}
                  </Link2>
                </li>
              ))}
              <span className="divide"></span>
              {boardRootMenuList.map((menu, index) => (
                <li key={`board_root_${menu.menuId}`}>
                  <Link2
                    to={`/ucms/${menu.menuId}`}
                    className={classNames({
                      active: pathname.startsWith(`/ucms/${menu.menuId}`),
                    })}
                  >
                    {menu.menuNm}
                  </Link2>
                </li>
              ))}
            </ul>

            <ul className="global">
              <li>
                <button className="join">
                  {!isLoggedIn && (
                    <div onClick={openLogin}>
                      <span className="hidden_text">로그인/회원가입</span>
                      <img className="mypage" src="/images/mypage_ico.png" />
                    </div>
                  )}
                  {isLoggedIn && (
                    <div
                      className="flex items-center login_after"
                      onClick={goToMyPage}
                    >
                      <MyProfileImage useUserContentLink={false} />
                      <p className="f14 ml-3 nav_nick">{loginData?.nickNm}</p>
                      {
                        /** 2024.01.30 추가 */ window.firstLogin && (
                          <div className="first-login-pop absolute flex">
                            <div className="triangle"></div>
                            <p>{loginData?.nickNm}님 반갑습니다.</p>
                            {firstLoginChg()}
                          </div>
                        )
                      }
                    </div>
                  )}
                </button>
              </li>
              <li>
                <AlertIcon
                  unreadCount={unreadCount}
                  onClick={() => {
                    if (isLoggedIn) navigator('/my/alert');
                    else setShowLoginPopup(true);
                  }}
                />
              </li>

              <LoginPopup
                show={showLoginPopup}
                setShow={() => setShowLoginPopup(false)}
              />
              <li className="flex search_ico">
                <HeaderSearchIcon />
              </li>
              {window.firstLoginPopup && showPopup === 'X' && (
                <PrivacyPopup
                  setFirstLogin={setFirstLogin}
                  userNo={loginData?.userNo}
                />
              )}
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
}
