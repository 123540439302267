import axios from 'axios';
import { parse, stringify } from 'qs';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useQuery from '@/components/sidebar/hook/useQuery';
import { useDispatch } from 'react-redux';
import { setSessionLoginFail } from '@/components/user/login/LoginReducer';
import LoginUtil from '@/components/user/login/LoginUtil';

export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const apiInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  paramsSerializer: {
    encode: parse,
    serialize: stringify,
  },
});

let clientGuid = null;
apiInstance.interceptors.request.use(async (config) => {
  try {
    if (config.method.toLowerCase() === 'post') {
      if (clientGuid == null) {
        clientGuid = await LoginUtil.getClientGuid();
      }
      config.data = {
        ...config.data,
        clientGuid: clientGuid, // 익명 사용자 식별
      };
    }
  } catch (e) {
    console.warn(e);
  }
  return config;
});

/**
 * React Dom Component형 interceptor
 * <AxiosWrapper></AxiosWrapper> 형태로 사용하여, navigator 등에 접근 가능하다.
 * 경로가 바뀔때마다 재 설정 해야하므로, useEffect의 natigator 구독을 통해 적용 된다.
 * @param children
 * @returns {*}
 * @constructor
 */
export function AxiosWrapper({ children }) {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const [_, setSearchParam] = useSearchParams();

  useEffect(() => {
    const resInterceptor = (response) => {
      // console.log(response);
      // 로그인 필요
      let url = response.config.url;
      if (response?.data?.code === '17') {
        if (url.startsWith('/api/cms/user/followingList')) {
          return response;
        } else if (url.startsWith('/api/cms/user/userViewTimeUpdate')) {
          // 로그인 상태 코드 무시
          return response;
        } else if (url.startsWith('/api/cms/content/shareRegist')) {
          return response;
        } else if (url.startsWith('/api/cms/user/getSessionData')) {
          return response;
        } else {
          setSearchParam({ ...query, reason: 'expired' }, { replace: true });
        }
        dispatch(setSessionLoginFail());
      }
      return response;
    };
    const interceptor = apiInstance.interceptors.response.use(resInterceptor);
    return () => apiInstance.interceptors.response.eject(interceptor);
  }, [navigator]);

  return children;
}
