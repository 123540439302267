import useBio from './useBio';
import usePopup from '@/components/popup/usePopup';
import Popup from '@/components/popup/Popup';
import useDebugLog from '@/components/debug/useDebugLog';
import { useEffect } from 'react';
import usePin from '@/components/easyLogin/pin/usePin';
import { setPopupShowed } from '@/components/easyLogin/EasyLoginReducer';
import { useDispatch } from 'react-redux';

export default function BioConfig() {
  const dispatch = useDispatch();
  const { pinRegistered, removePin } = usePin();

  const {
    deviceSupport,
    deviceBioRegistered,
    bioRegistered,
    // useBioLogin,
    // setUseBioLogin,
    removeBio,
    registerBio,
  } = useBio();

  const { sendLog } = useDebugLog();

  const { popup, show, hide } = usePopup();

  useEffect(() => {
    sendLog(
      `myinfo render deviceSupport ${deviceSupport}, deviceBioRegistered ${deviceBioRegistered}, bioRegistered ${bioRegistered}`
    );
  }, [deviceSupport, deviceBioRegistered, bioRegistered]);

  const _registerBio = async () => {
    try {
      await registerBio();
      removePin();
      sendLog('myinfo register easyLogin login success');
      show({
        title: '생체인증 로그인',
        message: '생체인증이 등록되었습니다.',
        okCallback: hide,
      });
    } catch (e) {
      console.error(e);
      sendLog(`myinfo register bio login fail ${e.message}`);
      show({
        title: '생체인증 등록 실패',
        message: e.message,
        okCallback: hide,
      });
    }
  };

  const _setUseBioLogin = async (value) => {
    if (!deviceBioRegistered) {
      sendLog(
        `myinfo _setUseBioLogin deviceBioRegistered ${deviceBioRegistered}`
      );
      show({
        title: '등록된 생체인증 없음',
        message: '기기에 등록된 생체 인증이 없습니다.',
        okCallback: hide,
      });
      return;
    }
    if (value) {
      if (pinRegistered) {
        show({
          title: '생체인증',
          message: '생체인증 등록시 간편비밀번호 로그인은 해제됩니다.',
          okCallback: async () => {
            hide();
            dispatch(setPopupShowed(true));
            await _registerBio();
          },
          cancelCallback: () => {
            hide();
          },
        });
      } else {
        await _registerBio();
      }
    } else {
      sendLog('myinfo _setUseBioLogin removeBio');
      await removeBio();
    }
  };

  if (!deviceSupport) {
    return '';
  }

  return (
    <>
      <div className="row flex items-center justify-between mb-10">
        <h5>생체인증 사용</h5>
        <div>
          <input
            className="toggle"
            type="checkbox"
            id="toggle"
            hidden=""
            checked={bioRegistered && deviceBioRegistered}
            onChange={(e) => _setUseBioLogin(e.target.checked)}
          />
          <label htmlFor="toggle" className="toggleSwitch">
            <span className="toggleButton"></span>
          </label>
        </div>
      </div>
      {popup != null && <Popup {...popup} />}
    </>
  );
}
