import { useMemo } from 'react';
import ThankYouTokenIframe from '@/page/ucms/ThankyouTokenIframe';
import useMenu from '@/components/menu/useMenu';
import WonderingIframe from '@/page/ucms/WonderingIframe';

/**
 *
 * @param {string} className
 * @param {MenuModel} menu
 * @returns {JSX.Element}
 * @constructor
 */
export default function MenuIframe({ className, menu }) {
  const { isThankYouTokenMenu } = useMenu();
  const isThankYouToken = useMemo(() => {
    return isThankYouTokenMenu(menu);
  }, [menu]);
  const { isWonderingMenu } = useMenu();
  const isWondering = useMemo(() => {
    return isWonderingMenu(menu);
  }, [menu]);
  if (isThankYouToken) {
    return <ThankYouTokenIframe className={className} src={menu.menuUrl} />;
  } else if (isWondering) {
    return <WonderingIframe className={className} src={menu.menuUrl} />;
  } else {
    return <iframe className={className} src={menu.menuUrl} />;
  }
}
