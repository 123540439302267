import useLogin from '../../../components/user/login/useLogin';
import { useEffect, useMemo, useRef, useState } from 'react';
import Link2 from '@/components/common/transition/Link2';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import {
  EmpNo8Validator,
  EmpNoValidator,
  RankNmValidator,
  useRegexArrayValidator,
} from '@/components/form/useRegexValidate';
import AffltEmailAuthField from '@/components/form/AffltEmailAuthField';
import usePopup from '@/components/popup/usePopup';
import classNames from 'classnames';
import MobileAuthField from '@/components/form/MobileAuthField';
import SearchableDropdown from '@/components/common/SearchableDropdown';
import useDeptList from '@/components/user/company/useDeptList';
import useRankList from '@/components/user/company/useRankList';
import useCompanyList from '@/components/user/company/useCompanyList';
import usePositionList from '@/components/user/company/usePositionList';
import useQuery from '@/components/sidebar/hook/useQuery';
import { Collapse } from '@mui/material';
import { ValidatorInput } from '@/page/my/tabs/MyInfoEdit';
import MyAffltApi from '@/components/user/my/MyAffltApi';
import useDebugWeb from '@/components/debug/useDebugWeb';
import useStartViewTransitionCompat from '@/components/common/transition/useStartViewTransitionCompat';
import { userLogout } from '@/components/user/login/LoginReducer';
import LoginUtil from '@/components/user/login/LoginUtil';
import { useDispatch } from 'react-redux';
import Popup from '@/components/popup/Popup';

export default function MyAffltEdit({}) {
  const { data: _data, isLoading } = useLogin();
  const data = {
    ..._data,
  };
  const { sendLog } = useDebugWeb();
  const companyList = useCompanyList({ excludeNormal: true });
  const [formData, setFormData] = useState({ ...data });
  const [email, setEmail] = useState('');
  const getCompany = (email) => {
    return companyList.find((it) => email.includes(`@${it.compDomain}`));
  };
  const compCd = useMemo(() => getCompany(email)?.wbnCompCd, [email]);
  const updateData = (key, value) => {
    setFormData((s) => ({
      ...s,
      [key]: value,
    }));
  };

  const navigator = useNavigate2();
  const deptQuery = useDeptList(compCd);
  const rankQuery = useRankList(compCd);
  const { positionQuery, useRankTypeCd } = usePositionList(compCd);

  const [deptCd, setDeptCd] = useState();
  const [positionCd, setPositionCd] = useState();
  const [rankTypeCd, setRankTypeCd] = useState();

  const rankNmValidator = useRegexArrayValidator(RankNmValidator, '', null);
  const empNoValidator = useRegexArrayValidator(
    useRankTypeCd ? EmpNo8Validator : EmpNoValidator,
    '',
    null
  );

  const { popup, show, hide } = usePopup();

  const { showPrivacy } = useQuery();
  const mobileAuthRef = useRef();
  const emailAuthRef = useRef();

  const [setShowPrivacyOptionalPopup] = useState(false);
  const [setPopup] = useState(null);
  const [isSignupLoading, setIsSignupLoading] = useState(false);
  const onMobileRefId = (refId) => {
    if (emailAuthRef.current?.refId !== refId) {
      if (emailAuthRef.current != null) {
        emailAuthRef.current.refId = refId;
      }
    }
    updateData('refId', refId);
  };

  const handleSignupWithLoading = async () => {
    if (isSignupLoading) {
      return;
    }
    setIsSignupLoading(true);
    try {
      await handleSignup();
    } catch (e) {}
    setIsSignupLoading(false);
  };

  const handleSignup = async (e) => {
    let _isValidEmail = emailAuthRef.current?.validate() === true;
    //let _isValidMobile = mobileAuthRef.current?.validate() === true;
    let _isValidRankNm = true; //await rankNmValidator.validate();
    let _isValidEmpNo = await empNoValidator.validate();
    let _isValidDeptCd = deptCd?.value != null && deptCd?.value !== '';
    let _isValidRankTypeCd = useRankTypeCd
      ? rankTypeCd?.value != null && rankTypeCd?.value !== ''
      : true;

    let _isValidPositionCd =
      positionCd?.value != null && positionCd?.value !== '';
    if (!_isValidDeptCd) {
      updateData('deptCdMessage', '부서를 선택해 주세요');
    } else {
      updateData('deptCdMessage', null);
    }

    if (!_isValidRankTypeCd) {
      updateData('rankTypeCdMessage', '직급분류를 선택해 주세요');
    } else {
      updateData('rankTypeCdMessage', null);
    }

    if (!_isValidPositionCd) {
      updateData('positionCdMessage', '직위를 선택해 주세요');
    } else {
      updateData('positionCdMessage', null);
    }
    if (
      !_isValidEmail ||
      !_isValidRankNm ||
      !_isValidEmpNo ||
      !_isValidDeptCd ||
      !_isValidRankTypeCd ||
      !_isValidPositionCd
    ) {
      return;
    }
    let userComp = null;
    let compId = 0;
    let company = getCompany(emailAuthRef.current.email);
    if (company == null) {
      setPopup({
        title: '오류',
        message: '알 수 없는 임직원 메일 입니다.',
        okCallback: () => {
          setPopup(null);
        },
      });
      return;
    }
    compId = company.compId;
    userComp = company.compNm;
    submit(compId, userComp);
  };

  useEffect(() => {
    if (showPrivacy === 'true') {
      setShowPrivacyOptionalPopup(true);
    }
  }, [showPrivacy]);

  const onEmailRefId = (refId) => {
    if (mobileAuthRef.current?.refId !== refId) {
      if (mobileAuthRef.current != null) {
        mobileAuthRef.current.refId = refId;
      }
    }
    updateData('refId', refId);
  };

  const submit = async (compId) => {
    let param = {
      wbnCompCd: compCd,
      wbnDeptCd: deptCd.value,
      wbnRankTypeCd: useRankTypeCd ? rankTypeCd.value : positionCd.value,
      wbnRankNm: rankNmValidator.value,
      wbnEmpNo: empNoValidator.value,
      wbnPositionCd: positionCd.value,
      compId: compId,
    };
    let res;
    let error;
    try {
      res = await MyAffltApi.changeEmailApply(param);

      const data = res.data;
      res = data;
      const code = data.code;
      const message =
        data.message != null && data.message?.trim() !== ''
          ? data.message
          : '전환가입에 오류가 발생하였습니다.';
      if (code === '00') {
        show({
          title: '변경 완료',
          message:
            '소속 변경이 완료되었습니다.\n변경된 아이디로 다시 로그인 해주세요.',
          okCallback: () => {
            logout();
          },
        });
      } else if (code === '51') {
        show({
          title: '전환가입 오류',
          message: '기존 정보와 동일하여 업데이트를 진행할 수 없습니다.',
          okCallback: hide,
        });
      } else if (code === '52') {
        show({
          title: '전환가입 오류',
          message: '회원 정보 조회에 실패하였습니다.',
          okCallback: hide,
        });
      } else if (code === '71') {
        show({
          title: '전환가입 오류',
          message: '해당 사번(행번)으로 가입되어 있는 계정이 존재합니다.',
          okCallback: hide,
        });
      } else {
        show({
          title: '전환가입 오류',
          message: message,
          okCallback: hide,
        });
      }
    } catch (e) {
      res = e.response?.data;
      error = e.message;
    }
    sendLog(
      {
        type: 'signup',
        req: {
          ...param,
          userPwd: param?.userPwd?.replace(/./g, '*'),
        },
        res: res,
        error: error,
      },
      formData.refId
    );
  };

  useEffect(() => {
    if (!data) {
      show({
        title: '오류',
        message: '로그인이 필요합니다.',
        okCallback: () => {
          navigator('/');
        },
      });
    }
  }, [data]);

  const startViewTransition = useStartViewTransitionCompat();
  const dispatch = useDispatch();

  const logout = () => {
    startViewTransition(() => {
      dispatch(userLogout());
      navigator('/');
    });
  };

  const isExecutive = data?.userType === 'UT01';

  return (
    <div id="contents">
      <div className="join_cont flex justify-center items-center">
        <div className="inner_box">
          <div className="join_box">
            <h5 className="head_tit mb-4 justify-between">
              <Link2 to={-1}>
                <img
                  className="arrow_left"
                  src="/images/arrow_left.png"
                  alt="뒤로가기"
                />
              </Link2>
              <span>소속계정변경</span>
              <span></span>
            </h5>
            {data?.compNm != null && (
              <span className="f12 p-2 co_pri brand_tag ">{data?.compNm}</span>
            )}
            <h4 className="join_tit f28_w700 mt-4">
              {isExecutive ? '임직원' : '일반사용자'} 회원정보
            </h4>

            <div className="input_box flex flex-col mb-1">
              <AffltEmailAuthField
                onChangeValue={setEmail}
                ref={emailAuthRef}
                onAuthorized={() => {
                  updateData('emailAuthorized', true);
                }}
                isCo="true"
                onRefId={onEmailRefId}
                onOraginId={data.userId}
              />
            </div>

            <div className="row flex mb-4">
              <div className="member_input relative flex-1">
                <SearchableDropdown
                  id={`${compCd}_deptCd`}
                  label="부서명"
                  noOptionsText={'결과가 없습니다.'}
                  searchQuery={{
                    ...deptQuery,
                    data: [
                      ...(deptQuery?.data?.map((item) => ({
                        name: item.deptNm,
                        value: item.deptCd,
                      })) ?? []),
                    ],
                  }}
                  className={
                    formData.deptCdMessage != null &&
                    formData.deptCdMessage !== ''
                      ? 'error'
                      : ''
                  }
                  message={formData.deptCdMessage}
                  selectedItem={deptCd}
                  onSelectItem={setDeptCd}
                />
              </div>
            </div>
            <Collapse in={useRankTypeCd}>
              <div className="row flex  mb-4">
                <div className="member_input relative flex-1">
                  <SearchableDropdown
                    id={`${compCd}_positionCd`}
                    label="직위"
                    noOptionsText={'결과가 없습니다.'}
                    searchQuery={{
                      ...rankQuery,
                      data:
                        rankQuery?.data?.map((item) => ({
                          name: item.rankTypeNm,
                          value: item.rankTypeCd,
                        })) ?? [],
                    }}
                    className={
                      formData.rankTypeCdMessage != null &&
                      formData.rankTypeCdMessage !== ''
                        ? 'error'
                        : ''
                    }
                    message={formData.rankTypeCdMessage}
                    selectedItem={rankTypeCd}
                    onSelectItem={setRankTypeCd}
                  />
                </div>
              </div>
            </Collapse>
            <div className="row flex  mb-4">
              <div className="member_input relative flex-1">
                <SearchableDropdown
                  id={`${compCd}_rankTypeCd`}
                  label={useRankTypeCd ? '직급분류' : '직위'}
                  noOptionsText={'결과가 없습니다.'}
                  openOnlyOnText={!useRankTypeCd}
                  searchQuery={{
                    ...positionQuery,
                    data:
                      positionQuery?.data?.map((item) => ({
                        name: item.positionNm,
                        value: item.positionCd,
                      })) ?? [],
                  }}
                  className={
                    formData.positionCdMessage != null &&
                    formData.positionCdMessage !== ''
                      ? 'error'
                      : ''
                  }
                  message={formData.positionCdMessage}
                  selectedItem={positionCd}
                  onSelectItem={setPositionCd}
                />
              </div>
            </div>
            <div className="row flex  mb-4">
              <div className="member_input relative flex-1">
                <ValidatorInput
                  label="사번(행번)"
                  type="text"
                  validator={empNoValidator}
                  // message={formData.empNoMessage}
                />
              </div>
            </div>
            <button
              type="submit"
              className={classNames({
                'mb-4': true,
                pri_btn: !isSignupLoading,
                pri_btn_dis: isSignupLoading,
                'cursor-default': isSignupLoading,
              })}
              onClick={handleSignupWithLoading}
            >
              소속계정변경
            </button>
          </div>
        </div>

        <div id="term_detail_portal" className="absolute" />
      </div>
      {popup != null && <Popup {...popup} />}
    </div>
  );
}
