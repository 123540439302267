export default function TermCheck({ id, text, checked, onChange, onDetail }) {
  return (
    <div className="flex agree_wrap mb-3 ">
      <input
        id={id}
        className="id_save"
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange?.(e.target.checked)}
      />
      <label htmlFor={id} className="aree_text w-full flex justify-between">
        <span className="co_gray999">{text}</span>
        <span
          className="underline"
          onClick={(e) => {
            e.preventDefault();
            onDetail?.();
          }}
        >
          확인하기
        </span>
      </label>
    </div>
  );
}
