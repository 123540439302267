import { useDispatch, useSelector } from 'react-redux';
import MyPageApi from '@/components/user/my/MyPageApi';
import BioRequest from '@/components/easyLogin/bio/dto/BioRequest';
import LoginUtil from '@/components/user/login/LoginUtil';
import LoginApi from '@/components/user/login/LoginApi';
import useHmacCrypto from '@/components/user/useHmacCrypto';
import BioApi from '@/components/easyLogin/bio/BioApi';
import { setUserLogin } from '@/components/user/login/LoginReducer';
import DeviceApi from '@/components/device/DeviceApi';
import {
  setBioRequestCanceled,
  setBioState,
} from '@/components/easyLogin/bio/BioReducer';
import { useEffect } from 'react';
import { setPinRequestCanceled } from '@/components/easyLogin/pin/PinReducer';

export class BioNotRegisteredException extends Error {
  constructor(message) {
    super(message);
    this.name = 'BioNotRegisteredException';
  }
}

export class BioNotSupported extends Error {
  constructor(message) {
    super(message);
    this.name = 'BioNotSupported';
  }
}

export default function useBio() {
  const dispatch = useDispatch();
  const { getHmacHash } = useHmacCrypto();
  const { bioCanAuth, bioRequest, bioRegistered, bioLoginCanceled } =
    useSelector((state) => state.bioReducer);

  useEffect(() => {
    LoginUtil.getBioKey2().then((bioKey) => {
      if (bioKey != null && bioKey !== '') {
        dispatch(setBioState(true));
      }
    });
  }, []);

  /**
   *
   * @returns {Promise<boolean>}
   */
  const checkBioSupport = async () => {
    // return true;
    return await BioApi.canBioAuth();
  };

  /**
   *
   * @returns {Promise<boolean>}
   */
  const bioReq = async ({ retry = 3 } = {}) => {
    // return {
    // authorized: true,
    // };
    let retryCount = 1;
    let authorized = false;

    let bioRequest = await BioApi.requestBioAuth(
      new BioRequest({
        localizedReason: '생체 인증 요청',
        androidTitle: '생체인증',
      })
    );
    let bioResult = JSON.parse(bioRequest);
    authorized = bioResult.authorized;

    while (!authorized && retryCount < retry) {
      retryCount++;
      bioRequest = await BioApi.requestBioAuth(
        new BioRequest({
          localizedReason: `생체 인증 요청 재시도 ${retryCount - 1}`,
          androidTitle: '생체인증',
        })
      );
      bioResult = JSON.parse(bioRequest);
      authorized = bioResult.authorized;
    }
    if (!authorized) {
      dispatch(setBioRequestCanceled(true));
    }

    return bioResult;
  };

  const registerBio = async () => {
    const bioSupport = await checkBioSupport();
    if (!bioSupport) {
      throw new BioNotSupported('기기가 생체 인증을 지원하지 않습니다');
    }

    const bioResult = await bioReq();
    if (bioResult.authorized !== true) {
      throw new Error('생체 인증에 실패하였습니다.');
    }

    const res = await MyPageApi.registerBio();
    if (res.code === '00') {
      await LoginUtil.setBioKey(res.id);
      dispatch(setBioState(true));
    } else {
      throw new Error(`등록 중 오류가 발생하였습니다.\n오류코드 ${res.code}`);
    }
  };

  const requestBioLogin = async ({ ignoreCancel = false } = {}) => {
    if (!bioRegistered || (bioLoginCanceled && !ignoreCancel)) {
      return false;
    }
    const id = await LoginUtil.getUserId2();
    const bioKey = await LoginUtil.getBioKey2();
    if (id != null && id !== '' && bioKey != null && bioKey !== '') {
      const bioSupport = await checkBioSupport();
      if (!bioSupport) {
        // removeBio(); // 생체인증 지원 안하더라도 삭제는 안함
        throw new BioNotSupported('기기가 생체 인증을 지원하지 않습니다');
      }

      const bioResult = await bioReq();
      if (bioResult.authorized !== true) {
        throw new Error('생체 인증에 실패하였습니다.');
      }

      const now = new Date();
      const timestamp = Math.floor(now.getTime() / 1000);
      const hmacHash = getHmacHash(bioKey, `${bioKey}:${timestamp}`);
      const res = await LoginApi.bioLogin({
        id,
        hmacHash,
        timestamp,
      });
      // console.log("bioLogin", JSON.stringify(res));
      if (res.data?.code === '00') {
        dispatch(setUserLogin(res));
        dispatch(setBioState(true));
        return true;
      } else if (res.data?.code === '19') {
        await removeBio(); // 바이오 키 만료
        throw new Error(
          '잘못된 인증 정보입니다. \n비밀번호 로그인 후, 다시 등록 해주세요'
        );
      } else {
        throw new Error(
          `간편 로그인 중 오류가 발생하였습니다.\n오류코드 ${res.code}`
        );
      }
    } else {
      await removeBio(); // 바이오 인증 불가 상태
      return false;
      // throw new Error("등록된 인증 정보가 없습니다.")
    }
  };

  const removeBio = async () => {
    dispatch(setBioState(false));
    await LoginUtil.setBioKey(null); // 바이오 만료 or 인증 불가 상태
  };

  return {
    get deviceSupport() {
      if (DeviceApi.getAppBuildNumber() >= 111) {
        return DeviceApi.getIsDeviceSupportBio();
      } else {
        // deprecated 2023.07.13, < 2.0.111
        if (DeviceApi.isIOSApp()) {
          return true;
        } else {
          return bioCanAuth?.data === true;
        }
      }
    },
    get deviceBioRegistered() {
      if (DeviceApi.getAppBuildNumber() >= 111) {
        return DeviceApi.getIsDeviceBioRegistered();
      } else {
        // deprecated 2023.07.13, < 2.0.111
        return bioCanAuth?.data === true;
      }
    },
    bioRegistered,
    removeBio,
    registerBio,
    requestBioLogin,
  };
}
