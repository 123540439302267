import { Link } from 'react-router-dom';
import Link2 from '@/components/common/transition/Link2';

export default function OptionalLinkWrapper({ to, children }) {
  return (
    <>
      {(to == null || to === '') && children}
      {to != null && to != '' && <Link2 to={to}>{children}</Link2>}
    </>
  );
}
