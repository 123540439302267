import { apiInstance } from '@/app/config/network';

export default class ChatApi {
  /**
   *
   * @returns {Promise<string>} 채팅 서버 주소
   */
  static async getChatServer() {
    return apiInstance.get('/api/cms/chat/activechat').then((res) => {
      return res.data?.data?.[0]?.url;
    });
  }

  /**
   *
   * @returns {Promise} 채팅 기록
   */
  static async getChatLog(param) {
    return apiInstance.post('/api/cms/chat/chatLog', param).then((res) => {
      return res.data;
    });
  }
}
