import { createPortal } from 'react-dom';

/**
 *
 * @param {UploadPopupOption} options
 * @returns {React.ReactPortal}
 * @constructor
 */
export default function UploadPopup(options) {
  const container = document.querySelector('#upload_popup');
  return createPortal(<UploadPopupContainer {...options} />, container);
}

function UploadPopupContainer({ fileName, progress, cancelCallback } = {}) {
  return (
    <div className="popup_join flex flex-col justify-center items-center">
      <h5 className="mb-1">파일 업로드 중</h5>
      <p className="s_tit f14_w300 mb-8 co_gray999 text-center">{fileName}</p>
      <div className="bar flex items-start">
        <div className="inner" style={{ width: `${progress}%` }} />
      </div>
      <p className="co_pri mt-3">{progress}%</p>
    </div>
  );
}

export class UploadPopupOption {
  fileName;
  progress;
  cancelCallback;
}
