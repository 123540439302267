import { useEffect, useRef } from 'react';

export default function usePlayerZoom() {
  const isTwoFingerRef = useRef(false);
  const pinchDist = useRef(0);
  const zoomRef = useRef(1);

  useEffect(() => {
    let target = document.querySelector('#wooriPlayer-container');
    const detectTwoFinger = (e) => {
      // console.log('detectTwoFinger', e.touches.length);
      if (e.touches.length === 2) {
        isTwoFingerRef.current = true;
      }
    };
    const scale = (e) => {
      if (isTwoFingerRef.current === true) {
        e.preventDefault();
        // console.log(e, e.touches, e.originalEvent?.touches);
        let dist = Math.hypot(
          e.touches[0].pageX - e.touches[1].pageX,
          e.touches[1].pageY - e.touches[1].pageY
        );
        dist = Math.floor(dist / 10);

        if (pinchDist.current === 0) pinchDist.current = dist;
        if (pinchDist.current < dist) {
          zoomRef.current += 0.1;
          zoomRef.current = zoomRef.current < 10 ? zoomRef.current : 10;
          pinchDist.current = dist;
        } else if (pinchDist.current > dist) {
          zoomRef.current -= 0.1;
          zoomRef.current = zoomRef.current < 1 ? 1 : zoomRef.current;
          pinchDist.current = dist;
        }
        window.WooriPlayer?.instance?.wooriPlayer?.player?.zoomrotate({
          zoom: zoomRef.current,
        });
      }
    };

    target?.addEventListener('touchstart', detectTwoFinger);
    target?.addEventListener('touchmove', scale);
    target?.addEventListener('touchend', () => {
      isTwoFingerRef.current = false;
      pinchDist.current = 0;
    });

    return () => {
      target?.removeEventListener('touchstart', detectTwoFinger);
      target?.removeEventListener('touchmove', scale);
      isTwoFingerRef.current = false;
      pinchDist.current = 0;
      zoomRef.current = 1;
    };
  }, []);

  return null;
}
