import { useMemo } from 'react';
import classNames from 'classnames';

export default function PCPageIndicator({
  className = '',
  page,
  pageSize,
  totalCount,
  showingPageCount = 5,
  onPageChange,
}) {
  const { pageList, showLast, canNext, canPrev, lastPage } = useMemo(() => {
    const lastPage = Math.ceil(totalCount / pageSize);

    let showingFirst = page - Math.floor(showingPageCount / 2);
    if (showingFirst < 0) {
      showingFirst = 0;
    }
    let showingLast = page + Math.ceil(showingPageCount / 2);
    if (showingLast > lastPage) {
      showingLast = lastPage;
    }

    if (lastPage >= showingPageCount) {
      const showPageCount = showingLast - showingFirst;
      const lackPage = showingPageCount - showPageCount;
      if (lackPage > 0) {
        if (showingFirst === 0) {
          showingLast += lackPage;
        } else {
          showingFirst -= lackPage;
        }
      }
    }

    let pageList = Array.from(
      { length: showingLast - showingFirst },
      (_, i) => i + showingFirst + 1
    );
    // console.log(lastPage, totalCount,);
    return {
      pageList,
      showLast: !pageList.includes(lastPage),
      canPrev: page > 0,
      canNext: page < lastPage - 1,
      lastPage,
    };
  }, [page, pageSize, totalCount, showingPageCount]);

  const toPage = (p) => {
    onPageChange?.(p);
  };

  const onNext = () => {
    toPage(Math.min(lastPage, page + 1));
  };

  const onPrev = () => {
    toPage(Math.max(0, page - 1));
  };

  return (
    <div>
      <div
        className={`pagenaition justify-between items-center pt-6 border-t flex-wrap gap-4 ${className}`}
      >
        <button
          className={classNames({
            'page_btn_prev btn_all': true,
            'pointer-events-none': !canPrev,
          })}
          type="button"
          onClick={onPrev}
        >
          이전
        </button>

        <div className="flex page_num items-center">
          {pageList.map((it, index) => (
            <button
              key={`page_indicator_${index}`}
              className={classNames({
                num: true,
                active: it === page + 1,
              })}
              onClick={() => toPage(it - 1)}
            >
              {it}
            </button>
          ))}
          {showLast && (
            <>
              <span className="flex items-center justify-center">...</span>
              <button className="num" onClick={() => toPage(lastPage - 1)}>
                {lastPage}
              </button>
            </>
          )}
        </div>
        <button
          className={classNames({
            'page_btn_next btn_all': true,
            'pointer-events-none': !canNext,
          })}
          type="button"
          onClick={onNext}
        >
          다음
        </button>
      </div>
    </div>
  );
}
