import { CircularProgress, Collapse } from '@mui/material';
import { secToMmss } from '@/lib/timeUtil';
import OutlineLabelField from '@/components/form/OutlineLabelField';
import classNames from 'classnames';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import useJoinEmailAuth from '@/page/join/useJoinEmailAuth';
import { EmailAuthStatus } from '@/page/join/useJoinEmailAuth';
import LoginEmailInput from '@/components/user/login/LoginEmailInput';
import { EmailValidator } from '@/components/form/useRegexValidate';
import { isValidEmail } from '@/lib/textUtil';
import useCompanyList from '@/components/user/company/useCompanyList';
import { EmailState } from '@/components/form/useEmailValidate';
import useDebugWeb from '@/components/debug/useDebugWeb';

const EmailAuthField = forwardRef(
  ({ isCo = false, onRefId, onAuthorized, onChangeValue }, ref) => {
    const {
      refId,
      authState,
      reqCode,
      reqAuth,
      remainTime,
      showAuthInput,
      isAuthSendLoading,
      isAuthValidLoading,
    } = useJoinEmailAuth({
      isCo,
    });

    useEffect(() => {
      if (refId != null) {
        onRefId?.(refId);
      }
      setState((s) => ({
        ...s,
        refId: refId,
      }));
    }, [refId]);

    const { sendLog } = useDebugWeb();

    const companyList = useCompanyList({ excludeNormal: true });

    const [state, setState] = useState({
      refId,
      email: '',
      authNumber: '',
      errorMessage: null,
      errorMessage2: null,
    });

    const canSendAuth = useMemo(() => {
      return (
        state.email != '' &&
        authState !== EmailAuthStatus.verified &&
        state.errorMessage !== EmailValidator.state.notValidFormat &&
        state.errorMessage !== EmailState.unknownDomain
      );
    }, [state.errorMessage, state.email]);

    const onChange = (value) => {
      let errorMessage = null;
      console.log(isValidEmail(value));
      if (EmailValidator.validator(value) == null) {
        errorMessage = EmailValidator.state.notValidFormat;
      } else {
        if (isCo) {
          let domain = value.split('@')[1];
          let targetCompany = companyList.find(
            (it) => it.compDomain === domain
          );
          if (targetCompany == null) {
            errorMessage = EmailState.unknownDomain;
          } else {
            errorMessage = null;
          }
        }
      }
      onChangeValue(value);
      setState((s) => ({
        ...s,
        email: value,
        errorMessage,
      }));
    };

    const onSendAuth = async () => {
      try {
        setState((s) => ({
          ...s,
          errorMessage: null,
        }));

        await reqCode({
          refId: state.refId,
          email: state.email,
        });
      } catch (e) {
        console.error(e);
        setState((s) => ({
          ...s,
          errorMessage: e.message,
        }));
      }
    };

    const onChangeAuthNumber = (value) => {
      setState((s) => ({
        ...s,
        authNumber: value,
      }));
    };

    const onCheckAuth = async (authNumber) => {
      let param = {
        refId: state.refId,
        email: state.email,
        authCode: state.authNumber,
      };
      try {
        setState((s) => ({
          ...s,
          errorMessage2: null,
        }));
        let res = await reqAuth(param);
        if (res === true) {
          setState((s) => ({
            ...s,
            errorMessage: null,
            errorMessage2: null,
          }));
          onAuthorized?.(state.email);
        }
      } catch (e) {
        console.error(e);
        setState((s) => ({
          ...s,
          errorMessage2: e.message,
        }));
      }
    };

    useImperativeHandle(ref, () => ({
      validate() {
        if (authState === EmailAuthStatus.verified) {
          return true;
        } else {
          setState((s) => ({
            ...s,
            errorMessage: '이메일 인증을 진행해 주세요',
          }));
          return false;
        }
      },

      get email() {
        return state.email;
      },

      get refId() {
        return state.refId;
      },

      set refId(value) {
        setState((s) => ({
          ...s,
          refId: value,
        }));
      },
    }));

    return (
      <_EmailAuthField
        isCo={isCo}
        isAuthSendLoading={isAuthSendLoading}
        email={state.email}
        canSendAuth={canSendAuth}
        showAuthInput={showAuthInput}
        authNumber={state.authNumber}
        authRemainTime={remainTime}
        onChange={onChange}
        onSendAuth={onSendAuth}
        onChangeAuthNumber={onChangeAuthNumber}
        onCheckAuth={onCheckAuth}
        errorMessage={state.errorMessage}
        errorMessage2={state.errorMessage2}
        isVerified={authState === EmailAuthStatus.verified}
      />
    );
  }
);

export default EmailAuthField;

function _EmailAuthField({
  isCo,
  isAuthSendLoading,
  email,
  onChange,
  onSendAuth,
  canSendAuth,
  errorMessage,
  showAuthInput,
  authNumber,
  onChangeAuthNumber,
  authRemainTime,
  onCheckAuth,
  errorMessage2,
  isVerified,
}) {
  return (
    <>
      {isCo && (
        <LoginEmailInput
          name="email"
          autoComplete="off"
          disabled={isVerified}
          value={email}
          onChange={onChange}
          className={classNames({
            error: errorMessage != null,
          })}
          message={errorMessage}
        >
          {!isVerified && (
            <button
              type="button"
              className={classNames({
                'btn_check f14_w300 flex items-center justify-center': true,
                'pointer-events-none': !canSendAuth,
              })}
              onClick={canSendAuth ? onSendAuth : null}
            >
              {isAuthSendLoading && (
                <CircularProgress className="loading absolute" />
              )}
              <div
                className={classNames({
                  f14_w300: true,
                  invisible: isAuthSendLoading,
                  'text-gray-400': !canSendAuth,
                })}
              >
                인증하기
              </div>
            </button>
          )}
        </LoginEmailInput>
      )}
      {!isCo && (
        <OutlineLabelField
          label="이메일 아이디"
          type="email"
          name="email"
          disabled={isVerified}
          value={email}
          onChange={onChange}
          className={classNames({
            error: errorMessage != null,
          })}
          message={errorMessage}
          autoComplete="off"
          customButton={
            <>
              {!isVerified && (
                <button
                  type="button"
                  className={classNames({
                    'btn_check f14_w300 flex items-center justify-center': true,
                    'pointer-events-none': !canSendAuth,
                  })}
                  onClick={canSendAuth ? onSendAuth : null}
                >
                  {isAuthSendLoading && (
                    <CircularProgress className="loading absolute" />
                  )}
                  <div
                    className={classNames({
                      f14_w300: true,
                      invisible: isAuthSendLoading,
                      'text-gray-400': !canSendAuth,
                    })}
                  >
                    인증하기
                  </div>
                </button>
              )}
            </>
          }
        />
      )}
      <div className="mb-4" />
      <Collapse in={showAuthInput}>
        <div className="row mb-4">
          <div className="member_input relative">
            <OutlineLabelField
              label="인증번호"
              placeholder="인증번호입력"
              id="mobileAuth"
              value={authNumber}
              className={classNames({
                error: errorMessage2 != null,
              })}
              message={errorMessage2}
              onChange={onChangeAuthNumber}
              maxLength={6}
              customButton={
                <>
                  {authRemainTime && (
                    <span className="co_orange f12_w300 timer_code">
                      {secToMmss(authRemainTime / 1000)}
                    </span>
                  )}
                  <button
                    type="button"
                    onClick={(e) => onCheckAuth(authNumber)}
                    className="btn_check f14_w300 flex items-center justify-center"
                  >
                    확인
                  </button>
                </>
              }
            />
          </div>
        </div>
      </Collapse>
    </>
  );
}
