import { Link, Outlet } from 'react-router-dom';
import Link2 from '@/components/common/transition/Link2';

export default function TestMain() {
  return (
    <div>
      <ul>
        <li>
          <Link2 to={'/test/easyLogin'}>bio</Link2>
        </li>
        <li>
          <Link2 to={'/test/editor'}>editor</Link2>
        </li>
        <li>
          <Link2 to={'/test/editor2'}>editor2</Link2>
        </li>
        <li>
          <Link2 to={'/test/player'}>player</Link2>
        </li>
        <li>
          <Link2 to={'/test/crypto'}>crypto</Link2>
        </li>
        <li>
          <Link2 to={'/test/pin'}>pin</Link2>
        </li>
      </ul>
      <br />
      <Outlet />
    </div>
  );
}
