import { apiInstance } from '@/app/config/network';
import AuthUtil from '@/components/auth/AuthUtil';

export default class CompanyApi {
  static async getCompanyList() {
    return apiInstance.get('/api/cms/comp/compList');
  }
  static async getDeptList(compCd) {
    return (
      await apiInstance.post('/api/cms/user/deptList', {
        compCd,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
    ).data;
  }
  static async getRankList(compCd) {
    return (
      await apiInstance.post('/api/cms/user/rankTypeList', {
        compCd,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
    ).data;
  }
  static async getPositionList(compCd) {
    return (
      await apiInstance.post('/api/cms/user/positionList', {
        compCd,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
    ).data;
  }
}
