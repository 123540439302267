import useWindowDimensions from '@/lib/useWindowDimensions';
import DeviceApi from '@/components/device/DeviceApi';
import BackgroundPlayApi from '@/components/player/BackgroundPlayApi';
import { useSelector } from 'react-redux';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import useMenu from '@/components/menu/useMenu';

export default function IOSBackgroundPlay() {
  const { isMobile } = useWindowDimensions();
  const isIOS = DeviceApi.isIOSApp();
  const playOption = useSelector((state) => state.playerReducer.option);
  const { menuId } = useParams();
  const { findMenu, isMediaContentType } = useMenu();
  const isMedia = useMemo(() => {
    return isMediaContentType(menuId);
  }, [menuId]);

  const goBackgroundPlay = () => {
    console.log('goBackgroundPlay');

    let src = playOption?.qualities[0]?.src;
    let player = window.WooriPlayer?.instance?.wooriPlayer?.player;
    // console.log('paused', player?.paused());
    let duration = player?.duration();
    let currentTime = player?.currentTime();
    let title = playOption?.title;
    let author = playOption?.author;
    let thumbnailUrl = playOption?.thumbnail;
    if (src == null || player == null) {
      console.error('src or player is null', src, player);
      return;
    }

    BackgroundPlayApi.backgroundPlay({
      src,
      title,
      author,
      thumbnailUrl,
      duration,
      currentTime,
    });

    // player.pause();
  };

  if (isMobile && isIOS && isMedia) {
    return <QueueMusicIcon onClick={goBackgroundPlay}></QueueMusicIcon>;
  } else {
    return <></>;
  }
}
