import classNames from 'classnames';
import { toShortCount } from '@/lib/mathUtil';
import TagView from '@/components/ucms/common/Tag';
import Link2 from '@/components/common/transition/Link2';

export default function BoardCard({ id, content, onDelete, onClick }) {
  const handleClick = (e, cb) => {
    e.stopPropagation();
    e.preventDefault();
    cb();
  };
  return (
    <li
      id={id}
      className={classNames({
        dis: content.disabled,
        flex: true,
      })}
    >
      <div
        className="border block rounded-2xl alert_box flex-1"
        onClick={(e) => handleClick(e, onClick)}
      >
        <Link2 to={!content.disabled ? `${content.link}` : null}>
          <div className="sm:p-8 p-6" onClick={content?.onClick}>
            <div className="mb-4 flex justify-between">
              <span className="co_gray999 f12_w300">{content.date}</span>
              {content.tag && <TagView tag={content.tag} />}
            </div>
            <h5
              className={classNames({
                'mb-5': true,
                read: content.viewYn === 'Y',
              })}
            >
              {content.title}
            </h5>
            <div className="flex justify-between items-center">
              <div className="flex gap-4 view_wrap">
                {content.author && (
                  <span className="f12_w300 co_gray999">{content.author}</span>
                )}
                {content.viewCount != null && (
                  <span className="f12_w300 co_gray999">
                    조회 {toShortCount(content.viewCount)}
                  </span>
                )}
                {(content.fileCount ?? 0) != 0 ? (
                  <span className="f12_w300 co_gray999 file_ico flex">
                    <img
                      className="mr-1"
                      src="/images/file_ico.png"
                      alt="첨부파일"
                    />
                    첨부파일 {content.fileCount ?? 0}
                  </span>
                ) : null}
              </div>
              <div className="flex row items-center">
                {onDelete != null && (
                  <img
                    src="/images/alert_garbage_ico.png"
                    alt="삭제"
                    onClick={(e) => handleClick(e, () => onDelete(content.cid))}
                  />
                )}
                {content.isLocked && (
                  <img src="/images/ico_lock.svg" alt="비밀글" />
                )}
              </div>
            </div>
          </div>
        </Link2>
      </div>
    </li>
  );
}
