import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import ChipTab from '@/components/common/chip/ChipTab';
import { CircularProgress, Collapse } from '@mui/material';
import { ContentType } from '@/app/config/ContentType';
import CommonSearchTab from '@/page/ucms/user-content/tab/CommonSearchTab';
import TotalSearchTab from '@/page/ucms/user-content/tab/TotalSearchTab';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import useUserContent from '@/page/ucms/user-content/useUserContent';
import usePage from '@/components/infiniteScroll/usePage';
import useLogin from '@/components/user/login/useLogin';
import Popup from '@/components/popup/Popup';
import { useDispatch } from 'react-redux';

export const userContentData = {
  uploaded: {
    searchType: '등록영상',
    title: '등록영상',
    contentType: ContentType.VOD,
  },
  wrote: {
    searchType: '작성글',
    title: '작성글',
    contentType: ContentType.BBS,
  },
};

export const userContentMenus = [
  {
    menuId: 1,
    menuNm: '전체',
    code: 'all',
  },
  {
    menuId: 2,
    menuNm: '등록영상',
    code: 'uploaded',
  },
  {
    menuId: 3,
    menuNm: '작성글',
    code: 'wrote',
  },
];

export default function UserContentContainer() {
  const param = useParams();
  const { query } = useLocation2();
  const { menuId: _menuId, userNo, userNickNm } = query;
  const dispatch = useDispatch();

  const { isLoggedIn, isLoginLoading, checkSessionValidity } = useLogin();

  const menuId = useMemo(() => {
    const menuNum = Number(_menuId);
    return isNaN(menuNum) ? userContentMenus[0].menuId : menuNum;
  }, [_menuId]);
  const code = useMemo(
    () => userContentMenus.find((menu) => menu.menuId === menuId)?.code,
    [menuId]
  );

  const [page, __] = usePage();
  const [_, setSearchParams] = useSearchParams();
  const [popup, setPopup] = useState(null);

  const setMenu = (menuId, reload) => {
    if (reload)
      if (menuId === 2) uploadedVideoQuery.setParamAndReload('page', 1);
      else if (menuId === 3) uploadedPostQuery.setParamAndReload('page', 1);
    setSearchParams({ ...query, menuId: menuId, page: 0 });
  };

  const { uploadedVideoQuery, uploadedPostQuery, totalData } = useUserContent({
    userNo,
    page: page + 1,
    code,
    enabled: true,
  });

  useEffect(() => {
    checkSessionValidity();
  }, []);
  useEffect(() => {
    if (!userNo || userNo === '')
      setPopup({
        message: '사용자를 찾을 수 없습니다.',
        okCallback: () => {
          setPopup(null);
        },
      });
  }, [userNo]);

  return (
    <div id="contents">
      {
        // 최초로딩이 완료되지 않은게 하나라도 존재하면
        totalData.datas.find((data) => !data.initLoadCompleted) != null ||
        !totalData.nickNm ? (
          <div
            className="w-full flex items-center justify-center"
            style={{ height: 320 }}
          >
            <CircularProgress className="loading" />
          </div>
        ) : (
          <div className="inner">
            <div className="result_box mt-12 mb-6 text-center">
              <span className="f24 w700">
                {totalData.nickNm ?? '알 수 없음'}
              </span>
              <span className="f24 w700 co_gray_aaa">님의 콘텐츠</span>
            </div>

            <ChipTab
              categories={userContentMenus}
              category={menuId}
              setCategory={(id) => setMenu(id, true)}
            />

            <Collapse in={menuId === 1}>
              <div>
                <TotalSearchTab
                  onTabChange={(id) => setMenu(id)}
                  datas={totalData.datas.map((item, idx) => ({
                    ...item,
                    menuId: idx + 2,
                  }))}
                />
              </div>
            </Collapse>

            <Collapse in={menuId === 2}>
              <div>
                {menuId === 2 && (
                  <CommonSearchTab
                    code={'uploaded'}
                    data={{
                      ...uploadedVideoQuery,
                    }}
                    param={{ size: 8 }}
                    isVideo={true}
                  />
                )}
              </div>
            </Collapse>

            <Collapse in={menuId === 3}>
              <div>
                {menuId === 3 && (
                  <CommonSearchTab
                    code={'wrote'}
                    data={{
                      ...uploadedPostQuery,
                    }}
                    param={{ size: 6 }}
                    isVideo={false}
                  />
                )}
              </div>
            </Collapse>
          </div>
        )
      }
      {/*<LoginPopup*/}
      {/*    show={showLoginPopup}*/}
      {/*    setShow={setShowLoginPopup}*/}
      {/*/>*/}
      {popup && <Popup {...popup} />}
    </div>
  );
}
