import useWindowDimensions from '@/lib/useWindowDimensions';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import classNames from 'classnames';

export default function ThankyouTokenManageTableRow({
  itemDate,
  regCount,
} = {}) {
  const navigate = useNavigate2();
  const { width } = useWindowDimensions();

  return (
    <tr
      className={classNames({
        'tr_wrap grid-cols-12 grid-rows-full': false,
        // 'cursor-pointer': link != null,
      })}
      onClick={(e) => {
        console.log(e.target);
        // if (e.target.tagName === 'INPUT' || e.target.tagName === 'LABEL') {
        //   return true;
        // }
        // if (link != null) {
        //   navigate(link);
        // }
      }}
    >
      {/* <td className="sm:col-span-2 col-span-1  row-span-full row-start-1 check_td">
        <div className="thumb_check">
          <input
            id={`${id}_check`}
            className="id_save"
            type="checkbox"
            checked={isSelected === true}
            onChange={(e) => {
              // e.preventDefault();
              e.stopPropagation();
              onSelectChange(e.target.checked);
            }}
          />
          <label htmlFor={`${id}_check`} className="check_text"></label>
        </div>
      </td> */}

      <td>{itemDate}</td>
      <td>{regCount}</td>
      {/* {width < 768 && (
        <>
          <td className="td_cate col-span-3 ">조회수 {count}</td>
          <td className="td_cate col-span-3 ">{date}</td>
        </>
      )}
      {width >= 768 && date != null && (
        <td className="td_tit col-start-1 col-span-12 row-start-2">{date}</td>
      )}
      {width >= 768 && count != null && (
        <td className="td_cate col-span-full row-start-3 mt-3 ">{count}</td>
      )} */}
    </tr>
  );
}
