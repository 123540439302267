import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import Config from '@/app/config/config';
import { myInfoMenuMap } from '@/app/config/myinfoMenuMap';
import { useEffect, useMemo, useState } from 'react';
import MyInfoCard from '@/components/user/my/MyInfoCard';
import ChipTab from '@/components/common/chip/ChipTab';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import useMenu, { menuType } from '@/components/menu/useMenu';
import useLogin from '@/components/user/login/useLogin';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { ErrorBoundary } from '@/components/common/ErrorBoundary';

export default function MyFollowContainer({} = {}) {
  const navigator = useNavigate2();
  const { pathname, query } = useLocation2();
  const { isLoading, menuList, findMenu } = useMenu();
  const { isLoggedIn, isLoading: isLoginLoading } = useLogin();

  const _childMenuList = useMemo(() => {
    return menuList
      .filter(
        (it) =>
          it.menuType === menuType.my &&
          it.useYN === 'Y' &&
          it.menuUrl !== '' &&
          it.menuLevel === 2 &&
          it.parentId == Config.myFollowId &&
          myInfoMenuMap[it.menuUrl] != null
      )
      .sort((a, b) => a.sortSeq - b.sortSeq);
  }, [menuList]);

  const [currentTab, setCurrentTab] = useState(null);

  useEffect(() => {
    // setCurrentTab()
    let s = pathname.split('/');
    let childPath = s[s.length - 1]?.split('?')?.[0];
    let existChildMenu = _childMenuList.find(
      (it) => it.menuUrl.replace('/', '') === childPath
    );
    setCurrentTab(existChildMenu?.menuId ?? _childMenuList?.[0]?.menuId);
  }, [_childMenuList]);

  useEffect(() => {
    if (currentTab == null) {
      return;
    }
    const url = findMenu(currentTab)?.menuUrl;
    if (url != null) {
      navigator(`${url}?page=${query.page ?? 0}`, { replace: true });
    }
  }, [currentTab]);

  const [_, setSearchParams] = useSearchParams();
  if (isLoggedIn === false) {
    // window.location.href = '/';
    return '';
  }

  if (isLoading || _childMenuList == null) {
    return '';
  }

  return (
    <div id="contents">
      <div className="mypage_wrap white pt-12">
        <div className="inner grid grid-cols-4 gap-8 pt-6 lg:pb-12 pb-24">
          <MyInfoCard />
          <div className="grid-cols-3 col-span-4 lg:col-span-3">
            <ChipTab
              align={'start'}
              categories={_childMenuList}
              category={currentTab}
              setCategory={(id) => {
                setSearchParams({ ...query, page: '0' });
                setCurrentTab(id);
              }}
            />
            <ErrorBoundary
              key={`my_follow_error_boundary_${pathname}`}
              className="md:!h-[400px]"
            >
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
}
