import { useEffect, useState } from 'react';
import BannerApi from '@/components/banner/BannerApi';

export const BannerType = {
  live: 'LIVE',
  menu: 'MENU',
  main: 'MAIN',
};

export default function useBanner({ bannerType, menuId }) {
  const l = useState([]);
  /**
   *
   * @type {BannerModel[]}
   */
  const list = l[0];
  const setList = l[1];

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    load(bannerType, menuId);
  }, [bannerType, menuId]);

  const load = async (bannerType, menuId) => {
    const res = await BannerApi.getBanner({ bannerType, menuId });
    if (res?.code === '00') {
      setList(
        res.data?.map((it) => ({
          ...it,
          bannerLink: it.bannerLink,
        }))
      );
    } else {
      setHasError(true);
    }
    setIsLoading(false);
  };

  /**
   *
   * @type {{isLoading: boolean, hasError: boolean, list: BannerModel[]}}
   */
  const res = {
    list,
    hasError,
    isLoading,
  };

  return res;
}
