import { useEffect, useState } from 'react';
import useInterval from '@/lib/useInterval';

/**
 * @param time ms 단위의 시간
 * @param checkPeriod
 */
export function useTimer({ time, checkPeriod = 1000 }) {
  const [target, setTarget] = useState(
    !time ? null : new Date(new Date().getTime() + time)
  );

  const countdown = useCountDown({
    targetTime: target,
    checkPeriod,
  });
  const start = () => {
    setTarget(!time ? null : new Date(new Date().getTime() + time));
    countdown.start();
  };

  const restart = () => {
    setTarget(!time ? null : new Date(new Date().getTime() + time));
    countdown.restart();
  };

  return {
    ...countdown,
    start,
    restart,
  };
}

/**
 * 날짜로 파싱 가능한 문자열 혹은 Date 객체를 받아 밀리초로 반환합니다.
 * @param date
 * @return {number} 밀리초
 */
const toSafeDate = (date) => {
  let target = null; // milliseconds
  if (date instanceof Date) {
    target = date.getTime();
  } else if (typeof targetTime === 'string') {
    try {
      target = Date.parse(date);
    } catch (e) {
      console.error(`[useCountDown] can not parse time ${date}`);
    }
  }
  return date;
};

/**
 * 주기마다 목표 시간까지 카운트다운을 하여 반환합니다.
 * remainTime이 0 미만이면 멈춥니다.
 * @param {boolean} autoStart 자동으로 시작하는가?
 * @param {Date|string} targetTime 카운트 다운 목표 시간
 * @param {number} checkPeriod 확인 주기
 * @returns {{remainTime: number, start: () => void, restart: () => void, clear: () => void}} 남은 시간과 시작하는 함수
 */
export default function useCountDown({
  autoStart,
  targetTime,
  checkPeriod = 1000,
}) {
  /**
   *
   * @type {[number, React.Dispatch<React.SetStateAction<number>>]}
   */
  let t = useState(null);
  let [remainTime, setRemainTime] = t;

  const [isRunning, setIsRunning] = useState(autoStart);
  const clear = () => {
    if (!isRunning) throw new Error('이미 정지된 타이머입니다!');
    setIsRunning(false);
  };

  const restart = () => {
    console.log('타이머 재시작, targetTime:', targetTime);
    setIsRunning(false);
    const target = toSafeDate(targetTime);
    setRemainTime(target - new Date().getTime());
    setIsRunning(true);
  };

  const handleExpire = () => {
    setIsRunning(false);
  };

  const handleRemainTime = (target) => {
    const now = new Date().getTime();
    const remain = target - now;
    setRemainTime(remain);

    if (remain <= 0) {
      handleExpire();
    }
  };

  useEffect(() => {
    if (!isRunning) return;
    const target = toSafeDate(targetTime);
    if (target != null) {
      handleRemainTime(target);
    }
  }, []);

  useInterval(
    () => {
      if (!isRunning) return;
      const target = toSafeDate(targetTime);
      if (target != null) {
        handleRemainTime(target);
      }
    },
    isRunning ? checkPeriod : null
  );

  const start = () => {
    console.log('시작됨');
    if (isRunning) throw new Error('이미 시작된 타이머입니다!');
    setIsRunning(true);
  };
  return {
    remainTime: remainTime < 0 ? 0 : remainTime,
    isRunning,
    restart,
    start,
    clear,
  };
}
