import { toShortCount } from '@/lib/mathUtil';
import './videoInfoItem.css';
import '../common/common.css';
import ContentCounter from '@/components/ucms/common/ContentCounter';
import UserSubscribeButton from '@/components/ucms/common/UserSubscribeButton';
import HtmlEmbed from '@/components/embed/HtmlEmbed';
import ProfileImage from '@/components/user/ProfileImage';
import PostOption from '@/components/ucms/common/PostOption';
import useDeletePopup from '@/components/ucms/common/useDeletePopup';
import useReportPopup from '@/components/ucms/common/useReportPopup';
import Link2 from '@/components/common/transition/Link2';
import classNames from 'classnames';

/**
 * @param {DefaultContentDetailDto} video
 * @param {boolean} authorSubscribed
 * @param {(boolean) => void} onAuthorSubscribedChange
 * @param {(number) => void} onShareVideo
 * @param {() => void} onDelete
 * @param {() => void} onEdit
 * @param {boolean} isDeletable
 * @param {boolean} isEditable
 * @return {JSX.Element}
 * @constructor
 */
export default function VideoInfoItem({
  headerText,
  video,
  curViewCnt,
  onShareVideo,
  onLikeClick,
  onAuthorSubscribedChange,
  onDelete,
  onEdit,
  onReport,
  dragLockYn,
}) {
  const { popup: deletePopup, onDelete: _delete } = useDeletePopup({
    onDelete,
  });
  const { popup: reportPopup, onReport: _report } = useReportPopup({
    onReport,
  });

  return (
    <>
      <div className="flex justify-between flex-wrap md:flex-nowrap">
        <h5 className="f20 mb-2 md:mb-0 ">
          {headerText != null && <>[{headerText}] </>}
          {video.title}
        </h5>
        <div />{' '}
        {/* 제목 길어졌을때, flex space-between으로 아래 항목 우측 붙게*/}
        <div className="flex items-baseline pr-4 md: mt-1">
          {video.liveYn === 'Y' && (
            <span className="state gap-1 mr-6">
              <img src="/images/ico_wifi.png" alt="LIVE" />
              LIVE
              <span>{toShortCount(curViewCnt)}</span>
            </span>
          )}
          <div className="info_icon flex gap-3 items-center">
            <ContentCounter
              commentCount={video.commentCount}
              viewCount={video.viewCount}
              isLiked={video.isLiked}
              likeCount={video.likeCount}
              shareCount={video.shareCount}
              onLikeClick={onLikeClick}
              onShareClick={() => {
                onShareVideo(video.id);
              }}
            />
          </div>

          <div className="relative ml-1">
            <PostOption
              canReport={video.isReportable}
              canDelete={video.isDeletable}
              canEdit={video.isEditable}
              onDelete={_delete}
              onEdit={onEdit}
              onReport={_report}
            />
          </div>
        </div>
      </div>
      <div className="chanel_row flex items-center my-4">
        <ProfileImage
          className="mr-3"
          userNo={video.authorNo}
          profileUrl={video.profileUrl}
          profileSt={video.profileSt}
        />
        <Link2 to={`/user/content?userNo=${video.authorNo}`}>
          <span className="ch_name pr-4 relative f14">{video.author}</span>
        </Link2>
        <UserSubscribeButton
          userNo={video.authorNo}
          followerCount={video.followerCount}
          onSubscribeChange={onAuthorSubscribedChange}
        />
        <span className="date f14_w300 co_gray999">
          {video.date.substring(0, 10)}
        </span>
      </div>
      <p
        className={classNames({
          'text_chanel md:pb-8 board_container mb-6': true,
          'select-none': dragLockYn === 'Y',
        })}
      >
        <HtmlEmbed html={video.cData} />
      </p>
      {deletePopup}
      {reportPopup}
    </>
  );
}
