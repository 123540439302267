import { useEffect, useMemo, useState } from 'react';
import { SearchApi } from '@/components/search/SearchApi';
import useLogin from '@/components/user/login/useLogin';
import { LoadType } from '@/components/ucms/search/useUcmsSearch';

/**
 * @param initialPage 최초 로딩 시 history에 잡혀 있는 페이지 수 입니다.
 * @return {{isInitialLoading: boolean, isLoading: boolean, sortChange: sortChange, isLast: unknown, param: {loadType: string, size: number, page: number, sort: string}, loadMore: loadMore, hasError: boolean, list: *[], setPage: setPage}}
 */
export default function useHomeRecentList(initialPage) {
  const { isLoggedIn } = useLogin();
  const [param, setParam] = useState({
    page: initialPage,
    size: 12,
    sort: SearchApi.searchSort.recent,
    loadType: LoadType.refresh,
  });

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);
  const [hasError, setHasError] = useState(false);
  const isLast = useMemo(() => {
    if (total == null) return false;
    return param.size * param.page >= total;
  }, [total, param]);

  useEffect(() => {
    _loadList();
  }, [param, isLoggedIn]);

  const _loadList = async () => {
    if (isLoading) {
      // console.warn('로딩중입니다.');
      return;
    }
    // console.log('continue with this param: ', param);
    if (param.loadType === LoadType.refresh) {
      await loadPageRange(param.page);
      return;
    }
    setIsLoading(true);
    try {
      const res = await SearchApi.homeList(param);
      if (param.loadType === LoadType.scrollPaging) {
        setList((l) => [...l, ...res.data]);
      } else {
        setList(res.data);
      }
      setTotal(res.total ?? 0);
    } catch (e) {
      console.error(e);
      setHasError(true);
    }
    setIsLoading(false);
    setIsInitialLoading(false);
  };

  const loadMore = () => {
    if (isLoading) return;
    setParam((p) => ({
      ...p,
      page: p.page + 1,
      loadType: LoadType.scrollPaging,
    }));
  };

  const setPage = (newPage) => {
    if (isLoading) return;
    setParam((p) => ({ ...p, page: newPage, loadType: LoadType.refresh }));
  };

  /**
   * @param page 0부터 page 까지의 페이지들을 불러옵니다.
   */
  const loadPageRange = async (page) => {
    // 고려할 점: state로 구현한 fetching 과 겹치지 않게 구성할 필요가 있다.
    if (isLoading) return;
    setIsLoading(true);
    const res = await SearchApi.homeList({
      ...param,
      page: 0,
      size: 12 * (page + 1),
    });
    setList(res.data);
    setTotal(res.total ?? 0);
    setIsLoading(false);
    setIsInitialLoading(false);
  };

  const sortChange = (sort) => {
    setList([]);
    setParam((p) => ({ ...p, sort: sort, page: initialPage }));
  };

  return {
    isInitialLoading,
    isLast,
    hasError,
    isLoading,
    list,
    param,
    loadMore,
    sortChange,
    setPage,
    loadPageRange,
  };
}
