export default class BioApi {
  static async canBioAuth() {
    if (window.flutter_inappwebview != null) {
      return await window.flutter_inappwebview?.callHandler('bio_can_auth');
    } else {
      return false;
    }
  }

  /**
   *
   * @param {BioRequest} request
   * @returns {Promise<*>}
   */
  static async requestBioAuth(request) {
    if (window.flutter_inappwebview != null) {
      return await window.flutter_inappwebview.callHandler(
        'bio_request_auth',
        request.biometricOnly,
        request.localizedReason,
        request.androidTitle
      );
    } else {
      throw Error('앱 환경이 아닙니다.');
    }
  }
}
