import { useMemo, useState } from 'react';
import { Button } from '@mui/material';
import useLogin from '@/components/user/login/useLogin';
import LoginPopup from '@/components/popup/LoginPopup';

export default function EventFormButton({
  title = '참여하기',
  url,
  formJson,
  formKey,
  defaultFieldText = '채널W ID',
}) {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const { isLoggedIn, data } = useLogin();

  let json = useMemo(() => {
    try {
      return JSON.parse(formJson);
    } catch (e) {
      return formJson;
    }
  }, [formJson]);

  let targetFormKey = useMemo(() => {
    if (formKey != null && formKey !== '') {
      return formKey;
    } else {
      let fieldList = json?.['QuestionFieldList'];
      let targetKey = fieldList?.find(
        (it) => it['QuestionText'] === defaultFieldText
      )?.['AnswerSubmissionId'];
      if (targetKey == null) {
        return null;
      } else if (targetKey?.startsWith('entry.')) {
        return targetKey;
      } else {
        return `entry.${targetKey}`;
      }
    }
  }, [formKey]);

  const userId = useMemo(() => {
    return data?.userId;
  }, [data]);

  const handleClick = () => {
    if (!isLoggedIn) {
      setShowLoginPopup(true);
      return;
    }

    let u = url.replace('/edit', '/viewform');
    if (u.includes('?')) {
      u = u + `&${targetFormKey}=${userId}`;
    } else {
      u = u + `?${targetFormKey}=${userId}`;
    }
    window.open(u, u);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClick}>
        <span className="text-white w-max">{title}</span>
      </Button>
      <LoginPopup show={showLoginPopup} setShow={setShowLoginPopup} />
    </div>
  );
}
