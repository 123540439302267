import { useEffect, useMemo, useState } from 'react';
import { SearchApi } from '@/components/search/SearchApi';
import useMenu from '@/components/menu/useMenu';
import { ContentType } from '@/app/config/ContentType';

export const LoadType = {
  refresh: 'refresh',
  scrollPaging: 'scrollPaging',
  paging: 'paging',
};
Object.freeze(LoadType);
export default function useUcmsSearch({
  isVideo,
  menuId,
  isLive,
  pagination = true,
  pageSize = 8,
  interval,
  initialPage = 0,
  isInfiniteLoading = false,
}) {
  const [isInitLoading, setIsInitLoading] = useState(true);
  const { findMenu, shouldShowAllMenu } = useMenu();
  const menu = useMemo(() => {
    return findMenu(menuId);
  }, [menuId]);

  const [intervalTick, setIntervalTick] = useState(null);

  const [param, setParam] = useState({
    page: initialPage,
    size: pageSize,
    keyword: '',
    menuId: menuId,
    isLive: isLive,
    sort: SearchApi.searchSort.recent,
    loadType: LoadType.refresh,
    contentType: isLive ? '' : menu?.contentType ?? ContentType.VOD,
  });

  useEffect(() => {
    let size;
    if (isLive) {
      size = 9999;
    } else if (menu.mediaYn === 'Y' || shouldShowAllMenu(menuId)) {
      size = 8;
    } else {
      // TODO 메뉴별 카드 타입으로 페이지 크기 지정
      if (pageSize) size = pageSize;
      else if (menu.menuId == 13) {
        size = 8;
      } else {
        size = 9;
      }
    }

    init();

    // 초기상태는 refresh
    setParam((p) => ({
      ...p,
      menuId: menuId,
      isLive: isLive,
      page: initialPage,
      size: size,
      loadType: LoadType.refresh,
      contentType: isLive ? '' : menu.contentType,
    }));
  }, [menu, isLive]);

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);
  const [hasError, setHasError] = useState(false);
  const isLast = useMemo(() => {
    if (total == null || list == null) return false;
    return list.length >= total;
  }, [total, list]);

  const dataList = useMemo(() => {
    return (
      list?.map((it) => SearchApi.infoToThumbnailOption(it, isVideo)) ?? []
    ); // useUcmsSearch
  }, [list]);

  useEffect(() => {
    if (interval != null) {
      let intervalId = setInterval(() => {
        setIntervalTick((t) => (t ?? 0) + 1);
      }, interval);
      return () => {
        if (intervalId != null) {
          clearInterval(intervalId);
        }
      };
    }
  }, [interval]);

  useEffect(() => {
    _loadList();
  }, [param]);

  useEffect(() => {
    if (intervalTick != null) {
      _loadList();
    }
  }, [intervalTick]);

  const _loadList = async () => {
    if (isLoading) {
      // console.warn('로딩중입니다.');
      return;
    }
    // console.log('Loading!!', param);
    if (param.loadType === LoadType.refresh && isInfiniteLoading) {
      await loadPageRange(param.page);
      return;
    }
    setIsLoading(true);
    try {
      let res;
      if (param.isLive) {
        res = await SearchApi.liveSearch(param);
      } else {
        res = await SearchApi.inMenuSearch(param);
      }

      if (pagination && param.loadType === LoadType.scrollPaging) {
        setList((l) => [...(l ?? []), ...res.data]);
      } else {
        setList(res.data);
      }
      setTotal(res.total ?? 0);
    } catch (e) {
      console.error(e);
      setHasError(true);
    }
    setIsInitLoading(false);
    setIsLoading(false);
  };

  /**
   * 페이지 당 크기가 기존 (페이지 * 페이지크기)인 0페이지를 불러와 (기존 페이지 - 1) 만큼을 모두 로드한 후
   * page를 기존 페이지로 지정해 실제 페이지 사이즈만큼 한 페이지를 더 불러와요.
   * @param page 0부터 page 까지의 페이지들을 불러옵니다.
   */
  const loadPageRange = async (page) => {
    // 고려할 점: state로 구현한 fetching 과 겹치지 않게 구성할 필요가 있다. => 모든 요청은 param의 변화 시에만 일어나게 하는게 좋을듯? (폴링제외)
    if (isLoading) return;
    setIsLoading(true);
    const initialPageSize = pageSize * (page === 0 ? 1 : page);
    const res = await SearchApi.inMenuSearch({
      ...param,
      page: 0,
      size: initialPageSize,
    });
    setList(res.data);
    setTotal(res.total ?? 0);
    setIsLoading(false);
    if (page !== 0)
      setParam((prev) => ({
        ...prev,
        page: page,
        loadType: LoadType.scrollPaging,
      }));
    else setIsInitLoading(false);
  };

  const search = (keyword = '', force = false) => {
    if (force) {
      init();
    }
    setParam((p) => ({
      ...p,
      keyword: keyword,
      page: initialPage,
      loadType: LoadType.refresh,
    }));
  };

  const loadMore = () => {
    if (isLoading) return;
    setParam((p) => ({
      ...p,
      page: p.page + 1,
      loadType: LoadType.scrollPaging,
    }));
  };

  const setPage = (page) => {
    if (isLoading) return;
    setParam((p) => ({ ...p, page: page, loadType: LoadType.refresh }));
  };

  const sortChange = (sort) => {
    if (isLoading) return;
    setParam((p) => ({ ...p, sort: sort, loadType: LoadType.refresh }));
  };

  const init = () => {
    setIsInitLoading(true);
    setIsLoading(false);
    setList([]);
    setTotal(null);
    setHasError(false);
  };

  return {
    isInitLoading,
    isLast,
    hasError,
    isLoading,
    dataList,
    list,
    total,
    search,
    page: param.page,
    pageSize: param.size,
    setPage,
    loadMore,
    sortChange,
  };
}
