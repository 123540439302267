import { useEffect, useMemo, useState } from 'react';
import ContentApi, { ContentFileType } from '@/components/content/ContentApi';
import DefaultContentDetailDto from '@/model/DefaultContentDetailDto';
import { dateFormat } from '@/lib/timeUtil';
import Comment from '@/components/comment/model/Comment';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import { SearchApi } from '@/components/search/SearchApi';
import useLogin from '@/components/user/login/useLogin';
import { ContentType } from '@/app/config/ContentType';
import useMenu from '@/components/menu/useMenu';
import useInterval from '@/lib/useInterval';
import Config from '@/app/config/config';
import useContentViewTimeReporter from '@/components/content/useContentViewTimeReporter';

export const ContentState = {
  loading: 'loading',
  notFound: 'notFound',
  needPassword: 'needPassword',
  needLogin: 'needLogin',
  needPermission: 'needPermission',
  error: 'error',
  success: 'success',
  deleted: 'deleted',
  hided: 'hided',
  hidden: 'hidden',
};

export const ContentStateCode = {
  21: ContentState.notFound,
  // '92': ContentState.notFound,
  92: ContentState.needPassword, // TODO 구버전 호환
  22: ContentState.needPermission,
  23: ContentState.needPassword,
  24: ContentState.hided,
  25: ContentState.deleted,
  26: ContentState.hidden,
};

export default function useContentInfo() {
  const { getContentType } = useMenu();
  const navigate = useNavigate2();
  const [password, setPassword] = useState(null);
  const { isLoggedIn, data: loginData, manageMenu } = useLogin();
  const [isInitLoading, setIsInitLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [eduInfo, setEduInfo] = useState(null);
  const [files, setFiles] = useState([]);
  const [live, setLive] = useState(null);
  const [viewTime, setViewTime] = useState(null);
  const [contentState, setContentState] = useState(ContentState.loading);
  const [nextVideo, setNextVideo] = useState(null);
  const [likeState, setLikeState] = useState(null);
  const [oneononeResponse, setOneononeResponse] = useState(null);
  const [pollingCount, setPollingCount] = useState(0);
  const isPolling = pollingCount > 0;

  const { updateViewTime } = useContentViewTimeReporter({ data, eduInfo });

  const polling = async (
    menuId,
    cid,
    contentType,
    forceInit = false,
    relCount = 6
  ) => {
    try {
      const res2 = await ContentApi.getContentCountInfo(cid);
      const countInfo = res2.data?.[0];
      const res = await ContentApi.getLiveTime(cid);
      const liveTimeInfo = res.data?.[0];

      // const code = res.code;
      // if (Object.keys(ContentStateCode).includes(code)) {
      //     setContentState(ContentStateCode[code]);
      //     setIsInitLoading(false);
      //     setIsLoading(false);
      //     return;
      // }

      setData({
        ...data,
        ...countInfo,
      });

      if (live != null) {
        setLive(
          new LiveInfo({
            ...live,
            ...countInfo,
            ...liveTimeInfo,
          })
        );
      }

      setContentState(ContentState.success);
    } catch (e) {
      console.error(e);
      setHasError(true);
      setContentState(ContentState.error);
    }
    setPollingCount((prevState) => ++prevState);
  };

  useInterval(() => {
    // Todo: 폴링
    if (!isLoading && !isInitLoading && data.liveYn === 'Y')
      polling(data.menuId, data.cid, data.contentType);
  }, Config.liveUpdateInterval);

  const _v = useState(null);
  /**
   *
   * @type {VideoFile}
   */
  const videoFile = _v[0];
  const setVideoFile = _v[1];

  const _t = useState([]);
  /**
   *
   * @type {[ImageFile]}
   */
  const thumbnails = _t[0];
  const setThumbnails = _t[1];

  const _ut = useState([]);
  /**
   *
   * @type {[ImageFile]}
   */

  const userThumbnails = _ut[0];
  const setUserThumbnails = _ut[1];

  const _et = useState([]);
  /**
   *
   * @type {[ImageFile]}
   */

  const emergencyThumbnails = _et[0];
  const setEmergencyThumbnails = _et[1];

  const _at = useState([]);
  /**
   *
   * @type {[Attachment]}
   */

  const attachments = _at[0];
  const setAttachments = _at[1];

  const [replies, setReplies] = useState(null);
  const [hasError, setHasError] = useState(false);

  const canDelete = () => {
    if (data == null) return false;
    return (
      (loginData?.userNo != null && loginData?.userNo === data.userNo) ||
      manageMenu?.find((it) => it.menuId === data.menuId) != null
    );
  };

  const canEdit = () => {
    if (data == null) return false;
    return loginData?.userNo != null && loginData?.userNo === data.userNo;
  };

  const canReport = () => {
    if (data == null) return false;
    return (
      loginData?.userNo != null &&
      loginData?.userNo !== data.userNo &&
      (data.adminGroupId == null || data.adminGroupId === 0)
    );
  };

  // 라이브 진입 시 1분마다 시청정보 업데이트
  useEffect(() => {
    if (isPolling) return;
    let interval;
    if (live != null) {
      let cid = data?.cid;
      let liveId = live?.liveId;
      if (cid == null || liveId == null) {
        return;
      }
      if (isLoggedIn !== true) {
        return;
      }
      ContentApi.liveViewNotify({ cid, liveId });
      interval = setInterval(() => {
        ContentApi.liveViewNotify({ cid, liveId });
      }, 60 * 1000);
    }

    return () => {
      if (interval != null) {
        clearInterval(interval);
      }
    };
  }, [live, isLoggedIn]);

  const content = useMemo(() => {
    if (isInitLoading) return null;
    if (data == null) return null;

    const contentType = getContentType(data.menuId);
    const useOnlyDate =
      contentType === ContentType.VOD || contentType === ContentType.VOD_USER;
    let date = '';
    if (useOnlyDate) {
      date = dateFormat({
        value: data.publishDt,
        format: 'yyyy.MM.dd',
      });
    } else {
      date = dateFormat({
        value: data.publishDt,
        format: 'yyyy.MM.dd HH:mm',
      });
    }

    let gfUrl = data.gfUrl;
    if (gfUrl != null && gfUrl.indexOf('http') < 0) {
      gfUrl = atob(gfUrl);
    }

    return new DefaultContentDetailDto({
      // TODO 게시판, 글 종류에 따른 TAG 지정
      // tag: Tag.notice.value,
      id: data.cid,
      cid: data.cid,
      category: data.menuNm,
      contentType: contentType,
      title: data.title,
      date: date,
      tag: SearchApi.getTag(contentType, data.topFixedYn, data.oneononeYn),
      cData: data.cData,
      author: data.nickNm,
      authorNo: data.userNo,

      // TODO 수정/삭제권한에 본인글 + 관리 권한까지 체크하기
      isDeletable: canDelete(),
      isEditable: canEdit(),
      isReportable: canReport(),
      chatYn: data.chatYn,
      viewCount: data.viewCnt,
      commentCount: data.replyCnt,
      likeCount: data.likeCnt,
      openLvl: data.openLvl,
      profileUrl: data.profileUrl,
      profilePath: data.profileSt,
      followerCount: data.followerCnt,
      openOpt: data.openOpt,
      shareCount: data.shareCnt,
      posterFile: data.posterFile,
      liveYn: data.liveYn,
      isLiked: likeState,
      nextVideo: nextVideo,

      gfUrl: gfUrl,
      gfKey: data.gfKey,
      gfData: data.gfData,
      gfBtnText: data.gfBtnText,

      eventStartDt: data.eventStartDt,
      eventEndDt: data.eventEndDt,
      eventStatus: data.eventStatus,

      comments: replies?.map(
        (it) =>
          new Comment({
            id: it.replyId,
            resId: it.resId,
            userNo: it.userNo,
            replySt: it.replySt,
            profileUrl: it.profileUrl,
            organization: it.compNm,
            author: it.nickNm,
            content: it.replyData,
            timeElapsed: dateFormat({
              value: it.regDt,
              format: 'yyyy.MM.dd HH:mm',
            }), // 날짜를 텍스트로
            subComments: it.rereply?.map(
              (sub) =>
                new Comment({
                  id: sub.resId,
                  parentId: it.replyId,
                  organization: it.compNm,
                  resSt: sub.resSt,
                  author: sub.nickNm,
                  content: sub.resData,
                  userNo: sub.userNo,
                  profileUrl: sub.profileUrl,
                  timeElapsed: dateFormat({
                    value: sub.regDt,
                    format: 'yyyy.MM.dd HH:mm',
                  }), // 날짜를 텍스트로
                })
            ),
          })
      ),
    });
  }, [data, replies, thumbnails, nextVideo, loginData, likeState]);

  const _load = async (
    menuId,
    cid,
    contentType,
    pw,
    forceInit = false,
    relCount = 0
  ) => {
    if (!forceInit && isLoading) {
      // console.warn('로딩중입니다.');
      return;
    }
    setIsLoading(true);
    if (forceInit) {
      init();
    }
    setPassword(pw);
    try {
      const res = await ContentApi.getContent(
        menuId,
        cid,
        contentType,
        pw,
        relCount
      );

      const code = res.code;
      if (Object.keys(ContentStateCode).includes(code)) {
        setContentState(ContentStateCode[code]);
        setIsInitLoading(false);
        setIsLoading(false);
        return;
      }

      const data = res.data?.[0];
      setData(data);
      setEduInfo(res.eduInfo?.[0]);
      setViewTime(res.viewTime?.[0]);
      setOneononeResponse(res.oneononeResponse?.[0]);
      setFiles(res.file);
      setLikeState(data?.likeYn === 'Y');
      setNextVideo(parseNextVideo(res));
      const v = res.file.find(
        (it) =>
          it.fileType === ContentFileType.video ||
          it.fileType === ContentFileType.audio
      );
      if (v != null) {
        setVideoFile(new VideoFile({ ...v }));
      }
      const t = res.file.filter(
        (it) => it.fileType === ContentFileType.thumbnail
      );
      if (t.length > 0) {
        setThumbnails(t.map((it) => new ImageFile({ ...it })));
      }

      const ut = res.file.filter(
        (it) => it.fileType === ContentFileType.userThumbnail
      );
      if (ut.length > 0) {
        setUserThumbnails(ut.map((it) => new ImageFile({ ...it })));
      }

      const et = res.file.filter(
        (it) => it.fileType === ContentFileType.emergencyThumbnail
      );
      if (et.length > 0) {
        setEmergencyThumbnails(et.map((it) => new ImageFile({ ...it })));
      }

      const live = res.live?.[0];
      if (live != null) {
        setLive(new LiveInfo({ ...live }));
      }

      const attach =
        res.file.filter((it) => it.fileType === ContentFileType.attach) ?? [];
      setAttachments(attach.map((it) => new Attachment({ ...it })));

      // let r = await fetchReReplies2(cid);
      // setReplies(r);
      setContentState(ContentState.success);
    } catch (e) {
      console.error(e);
      setHasError(true);
      setContentState(ContentState.error);
    }
    setIsInitLoading(false);
    setIsLoading(false);
  };

  const parseNextVideo = (res) => {
    let data = res.propose?.[0];
    if (data == null) {
      return null;
    } else {
      return {
        cid: data.cid,
        title: data.title,
        menuId: data.menuId,
        imgSrc: SearchApi.getThumbnailUrl(data),
        rotateYN: data.thumbRotateYn,
      };
    }
  };

  const init = () => {
    setIsInitLoading(true);
    setData(null);
    setEduInfo(null);
    setViewTime(null);
    setFiles([]);
    setNextVideo(null);
    setVideoFile(null);
    setThumbnails([]);
    setPassword(null);
    setContentState(ContentState.loading);
    setUserThumbnails([]);
  };

  const load = (menuId, cid, contentType, pw, forceInit = false) => {
    _load(menuId, cid, contentType, pw, forceInit);
  };

  const onWriteComment = async (menuId, cid, comment, replyId) => {
    let res;
    if (replyId == null) {
      res = (await ContentApi.postComment(cid, comment)).replyId;
    } else
      res = (await ContentApi.postResponseComment(cid, replyId, comment)).resId;
    await _load(menuId, cid, data.contentType, password);
    return res;
  };

  const onEdit = async () => {
    if (!canEdit()) {
      return false;
    }
    if (password == null) {
      navigate(`/ucms/write/${data.menuId}/${data.cid}`);
    } else {
      navigate(`/ucms/write/${data.menuId}/${data.cid}?password=${password}`);
    }
  };

  const onDelete = async () => {
    if (!canDelete()) {
      return false;
    }
    let res = await ContentApi.contentDelete(data.cid);
    if (res?.code === '00') {
      return true;
    } else {
      throw res;
    }
  };

  const onLikeClick = async () => {
    if (!isLoggedIn) {
      return;
    }
    let res;
    if (likeState) {
      res = await ContentApi.contentLikeDelete(data.cid);
    } else {
      res = await ContentApi.contentLike(data.cid);
    }
    if (res?.code === '00') {
      await _load(data.menuId, data.cid, data.contentType, password);
      setLikeState(!likeState);
    } else if (res?.code === '81') {
      // 좋아요 싱크 문제로 중복
      setLikeState(!likeState);
    }
  };

  const onReport = async () => {
    return await ContentApi.contentClaim({ cid: data.cid });
  };

  const onShared = async ({ cid, shareType }) => {
    return await ContentApi.contentShareCountIncrease({ cid, shareType });
  };

  const onAuthorSubscribedChange = async (isSubscribed) => {
    return _load(data.menuId, data.cid, data.contentType, password);
  };

  // const _updateViewTimeThrottle = throttle(_updateViewTime, 5 * 1000, {
  //   leading: true,
  //   trailing: true,
  // });

  // const updateViewTime = async (time, force) => {
  //   // if (force === true) {
  //   // 즉시 호출
  //   _updateViewTime(time);
  //   // } else {
  //   // console.log(data, content, videoFile)
  //   // _updateViewTimeThrottle(time);
  //   // }
  // };

  return {
    isInitLoading,
    isLoading,
    hasError,
    contentState,
    load,
    data,
    eduInfo,
    live,
    content,
    emergencyThumbnails,
    userThumbnails,
    thumbnails,
    videoFile,
    attachments,
    files,
    oneononeResponse,
    replies,
    onLikeClick,
    onEdit,
    onDelete,
    onWriteComment,
    onAuthorSubscribedChange,
    onShared,
    onReport,
    viewTime,
    updateViewTime,
    pollingCount,
    isLivePolling: isPolling,
    password,
  };
}

export class VideoFile {
  constructor({
    fileId,
    fileType,
    cdnStorageId,
    url,
    thumbPath,
    videoPath,
    cdnPath,
    cdnFile1,
    cdnFile2,
    cdnFile3,
    cdnFile4,
    cdnFile5,
    duration,
    uploadSt,
    rotateYn,
    orgSt,
    transferSt,
    cdnSt,
  }) {
    const isPortrait = rotateYn === 'Y';

    let sprites = [];
    if (
      cdnFile4 != null &&
      cdnFile4 != '' &&
      cdnFile5 != null &&
      cdnFile5 != ''
    ) {
      const count = Number(cdnFile5);
      const s = cdnFile4.split('_0');
      const subS = s.splice(0, s.length - 1);
      const basePath = subS.join('_0');
      const ext = s[s.length - 1];
      for (let i = 0; i < count; i++) {
        sprites.push({
          url: `${url}${thumbPath}${cdnPath}${basePath}_${i}${ext}`,
        });
      }

      console.log('basePath', basePath, ext, sprites);
    }

    this.spriteThumbnail = {
      use: sprites.length > 0,
      url: sprites,
      interval: 2,
      width: isPortrait ? 90 : 160,
      height: isPortrait ? 160 : 90,
      frame: 200,
    };

    this.fileId = fileId;
    this.fileType = fileType;
    // 경우에 따라 화질이 다르지 않을 수 있음. url이 같으면 src 변경 감지 불가능
    this.qualities = [
      {
        label: 'FHD',
        src: `${url}${videoPath}${cdnPath}${cdnFile1}?fhd`,
        type: 'video/mp4',
      },
      {
        label: 'HD',
        src: `${url}${videoPath}${cdnPath}${cdnFile2}?hd`,
        type: 'video/mp4',
      },
      {
        label: 'SD',
        src: `${url}${videoPath}${cdnPath}${cdnFile3}?sd`,
        type: 'video/mp4',
      },
    ];
    this.duration = duration;
    this.uploadSt = uploadSt;
    this.rotateYn = rotateYn;
    this.orgSt = orgSt;
    this.transferSt = transferSt;
    this.cdnSt = cdnSt;
  }
}

export class ImageFile {
  constructor({
    fileId,
    fileType,
    cdnStorageId,
    url,
    thumbPath,
    videoPath,
    cdnPath,
    cdnFile1,
    cdnFile2,
    cdnFile3,
    cdnFile4,
    cdnFile5,
    duration,
    uploadSt,
    rotateYn,
    orgSt,
    transferSt,
    cdnSt,
  }) {
    this.fileId = fileId;
    this.fileType = fileType;
    this.thumbnailUrl = `${url}${thumbPath}/${cdnPath}${cdnFile1}`;
    this.poasterUrl =
      cdnFile2 != null && cdnFile2 !== ''
        ? `${url}${thumbPath}/${cdnPath}${cdnFile2}`
        : null;
    this.uploadSt = uploadSt;
    this.orgSt = orgSt;
    this.transferSt = transferSt;
    this.rotateYn = rotateYn;
    this.cdnSt = cdnSt;
  }
}

export class Attachment {
  constructor({
    fileId,
    fileType,
    cdnStorageId,
    url,
    thumbPath,
    videoPath,
    cdnPath,
    cdnFile1,
    cdnFile2,
    cdnFile3,
    cdnFile4,
    cdnFile5,
    duration,
    uploadSt,
    orgSt,
    transferSt,
    cdnSt,
  }) {
    this.fileName = cdnFile1;
    this.fileId = fileId;
    this.fileType = fileType;
    this.fileLink = `${url}${cdnPath}`.normalize('NFC'); // 아이폰때문
    // this.fileLink = `${url}${thumbPath}${cdnPath}${cdnFile1}`.normalize('NFC'); // 아이폰때문
    // this.fileLink = `${url}${thumbPath}${cdnPath}${cdnFile1}`.normalize('NFD'); // 아이폰때문
    this.uploadSt = uploadSt;
    this.orgSt = orgSt;
    this.transferSt = transferSt;
    this.cdnSt = cdnSt;
  }
}

// TODO livest 체크
export class LiveInfo {
  constructor({
    liveId,
    cid,
    liveStartDt,
    livePlayTime,
    liveUrl,
    liveSt,
    liveCh,
    liveVodMode,
    rotateYn,
    chatYn,
    regDt,
    modDt,
    curViewCnt,
  }) {
    this.liveId = liveId;
    this.cid = cid;
    this.liveStartDt = liveStartDt;
    this.livePlayTime = livePlayTime;
    this.liveUrl = liveUrl;
    this.liveSt = liveSt;
    this.liveCh = liveCh;
    this.liveVodMode = liveVodMode;
    this.rotateYn = rotateYn;
    this.chatYn = chatYn;
    this.regDt = regDt;
    this.modDt = modDt;
    this.curViewCnt = curViewCnt;
    this.qualities = [
      {
        label: 'LIVE',
        src: liveUrl,
        type: 'application/x-mpegURL',
      },
    ];
  }
}
