import { useEffect } from 'react';
import DeviceApi from '@/components/device/DeviceApi';

export default function DownloadPage() {
  const run = () => {
    const isApp = DeviceApi.getAppOS() != null;
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    const isIOS = /ipad|iphone|ipod/.test(ua) && !window.MSStream;

    if (isApp) {
      window.location.href = '/';
    } else if (isIOS) {
      // TODO ios link
      window.location.href = 'https://apps.apple.com/app/id6447748194?mt=8';
    } else if (isAndroid) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.woori.wbn';
    } else {
      // navigate('https://wooriwbn.com/ucms/view/11/5259');
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.woori.wbn';
    }
  };

  useEffect(() => {
    run();
  }, []);

  return <div>연결중...</div>;
}
