import { useMemo } from 'react';

export default function MobilePageIndicator({
  className = '',
  page,
  pageSize,
  totalCount,
  onPageChange,
}) {
  const canNext = useMemo(() => {
    const lastPage = Math.ceil(totalCount / pageSize);
    return page < lastPage - 1;
  }, [page, pageSize, totalCount]);

  const toNext = () => {
    onPageChange?.(page + 1);
  };

  return (
    <div className={className}>
      {canNext && (
        <div className="flex items-center justify-center" onClick={toNext}>
          <span className="alert_more f14_w300 mb-8">더보기</span>
        </div>
      )}
      {!canNext && (
        <span className="text_lastbrod justify-center co_gray_aaa f14_w300">
          마지막입니다.
        </span>
      )}
    </div>
  );
}
