import { useEffect } from 'react';

const KAKAO_SDK_KEY = process.env.REACT_APP_KAKAO_SDK_KEY;

export default function KaKaoShareButton({
  link,
  thumbnailUrl,
  title,
  description,
  profileThumbnailUrl,
  profileName,
  likeCount,
  commentCount,
  shareCount,
  onClick,
}) {
  const kakao = window.Kakao;
  useEffect(() => {
    if (!kakao.isInitialized()) {
      kakao.init(KAKAO_SDK_KEY);
    }

    document
      .getElementById('kakaotalk-sharing-btn')
      .addEventListener('click', () => {
        onClick();
      });

    if (thumbnailUrl == null) {
      kakao.Share.createDefaultButton({
        container: '#kakaotalk-sharing-btn',
        objectType: 'text',
        text: title?.substring(0, 50),
        link: {
          mobileWebUrl: link,
          webUrl: link,
        },
        buttons: [
          {
            title: '보러가기',
            link: {
              mobileWebUrl: link,
              webUrl: link,
            },
          },
        ],
      });
    } else {
      kakao.Share.createDefaultButton({
        container: '#kakaotalk-sharing-btn',
        objectType: 'feed',
        content: {
          title: title?.substring(0, 50),
          description: description?.substring(0, 100),
          imageUrl: thumbnailUrl ?? profileThumbnailUrl,
          link: {
            mobileWebUrl: link,
            webUrl: link,
          },
        },
        itemContent: {
          profileText: profileName,
          profileImageUrl: profileThumbnailUrl,
        },
        social: {
          likeCount: likeCount,
          commentCount: commentCount,
          sharedCount: shareCount,
        },
        buttons: [
          {
            title: '보러가기',
            link: {
              mobileWebUrl: link,
              webUrl: link,
            },
          },
        ],
      });
    }
  }, []);

  return (
    <button id="kakaotalk-sharing-btn">
      <img src="/images/sns_ico01.png" alt="카카오톡 공유" />
    </button>
  );
}
