import { toShortCount } from '@/lib/mathUtil';
import './ContentCounter.css';
import classNames from 'classnames';
import useLogin from '@/components/user/login/useLogin';
import { useState } from 'react';
import LoginPopup from '@/components/popup/LoginPopup';

export default function ContentCounter({
  commentCount,
  viewCount,
  likeCount,
  isLiked = false,
  shareCount,
  onCommentClick,
  onLikeClick,
  onShareClick,
}) {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const { isLoggedIn } = useLogin();

  const _onLikeClick = () => {
    if (!isLoggedIn) {
      setShowLoginPopup(true);
      return;
    }
    onLikeClick();
  };

  const handleClick = (e, cb) => {
    e.preventDefault();
    e.stopPropagation();
    cb();
  };
  return (
    <>
      <div className="flex">
        <label className="view">{toShortCount(viewCount)}</label>
      </div>
      <div className="flex" onClick={(e) => handleClick(e, onCommentClick)}>
        <label
          className={classNames({
            comment: true,
            pointer: onCommentClick,
          })}
        >
          {toShortCount(commentCount)}
        </label>
      </div>
      <div className="flex" onClick={(e) => handleClick(e, _onLikeClick)}>
        <label
          aria-checked={isLiked}
          className={classNames({
            like: true,
            pointer: onLikeClick,
          })}
        >
          {toShortCount(likeCount)}
        </label>
      </div>
      <div className="flex" onClick={(e) => handleClick(e, onShareClick)}>
        <label
          className={classNames({
            share: true,
            pointer: onShareClick,
          })}
        >
          {toShortCount(shareCount)}
        </label>
      </div>
      <LoginPopup
        show={showLoginPopup}
        setShow={() => setShowLoginPopup(false)}
      />
    </>
  );
}
