import { useState } from 'react';
import MyPageApi from '@/components/user/my/MyPageApi';
import useSubscribe from '@/components/user/my/useSubscribe';

export default function useFollowing({ initialPage }) {
  const { unsubscribe, fetchFollowing } = useSubscribe();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(initialPage);

  const load = async ({ page = 0, size = 9 } = {}) => {
    setPage(page);
    setIsLoading(true);
    console.log(page);
    let res = await MyPageApi.followingList({ page, size });
    setList(res.data);
    setTotal(res.totalCount ?? 0);
    setIsLoading(false);
    return res.data;
  };

  const unsubscribeList = async (userNumbers) => {
    setIsLoading(true);
    for (let userNumber of userNumbers) {
      await unsubscribe(userNumber, false);
    }
    await fetchFollowing();
    setIsLoading(false);
    return true;
  };

  console.log(page);

  return {
    isLoading,
    list,
    total,
    load,
    page,
    unsubscribeList,
  };
}
