import { createPortal } from 'react-dom';
import { useEffect, useMemo } from 'react';
import Dim from '@/components/popup/Dim';

/**
 *
 * @param {PopupOption} options
 * @returns {React.ReactPortal}
 * @constructor
 */
export default function Popup(options) {
  const container = document.querySelector('.popup_portal');
  return createPortal(<PopupContainer {...options} />, container);
}

export function PopupContainer({
  title,
  message,
  className,
  okMessage = '확인',
  cancelMessage = '취소',
  children,
  okCallback,
  cancelCallback,
  useDim,
} = {}) {
  const _message = useMemo(() => {
    try {
      return message?.replace('\n', '<br/>');
    } catch (e) {
      return message;
    }
  }, [message]);

  return (
    <>
      {useDim && <Dim />}
      <div
        className={`popup_join mb-1 popup-z-index flex flex-col justify-center items-center ${className}`}
      >
        <h5 className="mt-4 mb-1">{title}</h5>
        <div
          className="s_tit f14_w300 mb-4 co_gray999 text-center"
          dangerouslySetInnerHTML={{
            __html: _message,
          }}
        />
        {children}
        <div className="btn_wrap flex justify-around">
          {cancelCallback != null && (
            <button className="cancel_btn" onClick={cancelCallback}>
              {cancelMessage}
            </button>
          )}
          <button className="ok_btn co_pri" onClick={okCallback}>
            {okMessage}
          </button>
        </div>
      </div>
    </>
  );
}

class PopupOption {
  title;
  message;
  okMessage;
  cancelMessage;
  okCallback;
  cancelCallback;
}
