import useWindowDimensions from '@/lib/useWindowDimensions';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import classNames from 'classnames';

export default function CommentTableRow({
  id,
  link,
  isSelected,
  onSelectChange,
  type,
  category,
  title,
  comment,
  date,
} = {}) {
  const navigate = useNavigate2();
  const { width } = useWindowDimensions();

  return (
    <tr
      className={classNames({
        'tr_wrap grid-cols-12 grid-rows-full': true,
        'cursor-pointer': link != null,
      })}
      onClick={(e) => {
        console.log(e.target);
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'LABEL') {
          return true;
        }
        if (link != null) {
          navigate(link);
        }
      }}
    >
      <td className="sm:col-span-2 col-span-1  row-span-full row-start-1 check_td">
        <div className="thumb_check">
          <input
            id={`${id}_check`}
            className="id_save"
            type="checkbox"
            checked={isSelected === true}
            onChange={(e) => {
              // e.preventDefault();
              e.stopPropagation();
              onSelectChange(e.target.checked);
            }}
          />
          <label htmlFor={`${id}_check`} className="check_text"></label>
        </div>
      </td>

      {category != null && (
        <td className="td_sub_tit col-span-full col-start-1 row-start-1 mb-1">
          {category}
        </td>
      )}
      {type != null && (
        <td className="td_sub_tit  col-span-full col-start-1 row-start-1 mb-1">
          {type}
        </td>
      )}

      {width < 768 && type != null && (
        <td className="td_sub_tit col-span-full col-start-1 row-start-1 mb-1">
          {category}&nbsp;&nbsp;|&nbsp;&nbsp;{type}
        </td>
      )}

      {title != null && (
        <td className="td_sub_tit  col-span-full col-start-1 row-start-1 mb-1">
          {title}
        </td>
      )}
      {comment != null && (
        <td className="td_tit col-start-1 col-span-full row-start-2">
          {comment}
        </td>
      )}

      {width >= 768 && date != null && (
        <td className="td_tit col-start-1 col-span-full row-start-2">{date}</td>
      )}

      {width < 768 && (
        <>
          {/*<td className="td_cate col-span-2 ">{type}</td>*/}
          <td className="td_cate col-span-full ">{date}</td>
        </>
      )}
    </tr>
  );
}
