export default class Comment {
  constructor({
    id,
    author,
    content,
    organization,
    timeElapsed,
    subComments,
    parentId,
    replySt,
    resSt,
    userNo,
    profileUrl,
    profileSt,
  }) {
    this.id = id;
    this.author = author;
    this.content = content;
    this.organization = organization;
    this.timeElapsed = timeElapsed;
    this.subComments = subComments;
    this.parentId = parentId;
    this.userNo = userNo;
    this.replySt = replySt;
    this.resSt = resSt;
    this.profileUrl = profileUrl;
    this.profileSt = profileSt;
  }
}
