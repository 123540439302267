import Link2 from '@/components/common/transition/Link2';
import useMenu from '@/components/menu/useMenu';
import classNames from 'classnames';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function MobileBottomNavigation() {
  const { pathname } = useLocation2();
  const { rootMenuList } = useMenu();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation2();
  const iconList = [
    '/images/nav_m_ico01.png',
    '/images/nav_m_ico02.png',
    '/images/nav_m_ico03.png',
    '/images/nav_m_ico04.svg',
    '/images/nav_m_ico05.png',
  ];

  const createBottomMenu = (menu, index) => {
    let to = `/ucms/${menu.menuId}`;
    let onClick = null;
    if (menu.menuNm === '땡큐토큰') {
      to = null;
      onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate('.', {
          state: {
            thankyoutoken: '/',
          },
        });
      };
      return (
        <Link2
          to={to}
          onClick={onClick}
          className={classNames({
            relative: true,
            active: pathname.startsWith(`/ucms/${menu.menuId}`),
          })}
        >
          <div
            style={{
              backgroundColor: 'white',
              aspectRatio: 1,
              position: 'absolute',
              borderRadius: '100%',
              width: '120%',
              border: '1px solid #eee',
              transform: 'translate(-10%, -20%)',
              zIndex: -2,
            }}
          />

          <div
            style={{
              width: '120%',
              height: '100%',
              transform: 'translate(-10%)',
              backgroundColor: 'white',
              position: 'absolute',
              zIndex: -1,
            }}
          />
          <div className="h-full flex flex-col items-center justify-center">
            <div
              style={{
                overflow: 'hidden',
                borderRadius: '100%',
                transform: 'translate(-2px, -15%) ',
              }}
            >
              <img
                className="flex-1 h-full w-full aspect-square object-contain"
                style={{
                  transform: 'scale(1.2)',
                }}
                src="/images/nav_m_thankyou_icon.gif"
                alt={menu.menuNm}
              />
            </div>
          </div>
        </Link2>
      );
    } else {
      return (
        <Link2
          to={to}
          onClick={onClick}
          className={classNames({
            active: pathname.startsWith(`/ucms/${menu.menuId}`),
          })}
        >
          <div className="ico_wrap flex flex-col items-center justify-center gap-1.5">
            <img src={iconList[index] ?? iconList[0]} alt={menu.menuNm} />
            <p>{menu.menuNm}</p>
          </div>
        </Link2>
      );
    }
  };

  const modifiedNavigation = useMemo(() => {
    return rootMenuList.map((menu, index) =>
      menu.menuId !== 78 ? (
        <li key={`mobile_nav_${index}`}>{createBottomMenu(menu, index)}</li>
      ) : null
    );
  }, [rootMenuList]);

  return <ul className="mobile_nav sm:px-2">{modifiedNavigation}</ul>;
}
