import { useEffect, useRef } from 'react';
import useWindowDimensions from '@/lib/useWindowDimensions';

export default function usePlayerGesture({ doubleTapThreshold = 500 } = {}) {
  const doubleTapDetectTimeoutRef = useRef(null);
  const lastTapRef = useRef(0);
  const { isMobile } = useWindowDimensions();

  const getTouchPosition = (e) => {
    const rect = e.target.getBoundingClientRect();
    // console.log(e, e.touches)
    const offsetX = e.touches[0].clientX - window.scrollX - rect.left;
    const offsetY = e.touches[0].clientY - window.scrollY - rect.top;
    console.log(rect, offsetX, offsetY);
    if (offsetY > rect.height) {
      return 'unknown';
    }
    if (offsetX < rect.width / 2) {
      return 'left';
    } else if (offsetX > rect.width / 2) {
      return 'right';
    } else {
      return 'unknown';
    }
  };

  useEffect(() => {
    // if(!isMobile) return;
    let target = document.querySelector('#wooriPlayer-container');
    if (target == null) return;

    const callback = async function (e) {
      // console.log('usePlayerGesture click')
      let currentTime = new Date().getTime();
      let tapLength = currentTime - lastTapRef.current;

      // e.preventDefault();
      clearTimeout(doubleTapDetectTimeoutRef.current);

      lastTapRef.current = currentTime;

      if (tapLength < doubleTapThreshold && tapLength > 0) {
        console.log(e);
        // console.log('usePlayerGesture double click')
        const player = window.WooriPlayer?.instance?.wooriPlayer?.player;
        if (player != null) {
          const currentTime = player.currentTime();
          const duration = player?.duration() ?? 99999999;
          const position = getTouchPosition(e);
          if (position === 'left') {
            e.stopPropagation();
            e.stopImmediatePropagation();
            e.preventDefault();
            await player.currentTime(Math.min(0, currentTime - 5));
            await player.play();
          } else if (position === 'right') {
            e.stopPropagation();
            e.stopImmediatePropagation();
            e.preventDefault();
            await player.currentTime(Math.min(duration - 5, currentTime + 5));
            await player.play();
          }
        }
      } else {
        doubleTapDetectTimeoutRef.current = setTimeout(function () {
          //Single Tap/Click code here
          clearTimeout(doubleTapDetectTimeoutRef.current);
        }, doubleTapThreshold);
      }
    };

    target.addEventListener('touchstart', callback);
    // target.addEventListener('click', callback);

    return () => {
      target.removeEventListener('touchstart', callback);
      // target.removeEventListener('click', callback);
    };

    // const detectTwoFinger = (e) => {
    //     // console.log('detectTwoFinger', e.touches.length);
    //     if (e.touches.length === 2) {
    //         isTwoFingerRef.current = true;
    //     }
    // };
    // const scale = (e) => {
    //     if (isTwoFingerRef.current === true) {
    //         e.preventDefault();
    //         // console.log(e, e.touches, e.originalEvent?.touches);
    //         let dist = Math.hypot(
    //             e.touches[0].pageX - e.touches[1].pageX,
    //             e.touches[1].pageY - e.touches[1].pageY
    //         );
    //         dist = Math.floor(dist / 10);
    //
    //         if (pinchDist.current === 0) pinchDist.current = dist;
    //         if (pinchDist.current < dist) {
    //             zoomRef.current += 0.1;
    //             zoomRef.current = zoomRef.current < 10 ? zoomRef.current : 10;
    //             pinchDist.current = dist;
    //
    //         } else if (pinchDist.current > dist) {
    //             zoomRef.current -= 0.1;
    //             zoomRef.current = zoomRef.current < 1 ? 1 : zoomRef.current;
    //             pinchDist.current = dist;
    //         }
    //         window.WooriPlayer?.instance?.wooriPlayer?.player?.zoomrotate({zoom: zoomRef.current});
    //     }
    // };
    //
    // target?.addEventListener('touchstart', detectTwoFinger);
    // target?.addEventListener('touchmove', scale);
    // target?.addEventListener('touchend', () => {
    //     isTwoFingerRef.current = false;
    //     pinchDist.current = 0;
    // });
    //
    // return () => {
    //     target?.removeEventListener('touchstart', detectTwoFinger);
    //     target?.removeEventListener('touchmove', scale);
    //     isTwoFingerRef.current = false;
    //     pinchDist.current = 0;
    //     zoomRef.current = 1;
    // };
  }, [isMobile]);

  return null;
}
