import { useEffect, useMemo, useRef, useState } from 'react';
import useLogin from '@/components/user/login/useLogin';
import axios from 'axios';
import useAESCrypto from '@/components/user/useAESCrypto';
import { closeThankYouTokenSideBar } from '@/components/sidebar/thankYouTokenReducer';
import { useDispatch, useSelector } from 'react-redux';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import { openSideBar } from '@/components/sidebar/sidebarReducer';
import { Dialog } from '@mui/material';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { useSearchParams } from 'react-router-dom';
import useDebugWeb from '@/components/debug/useDebugWeb';

// 초기화 실패시 iframe 진입 타이머
const fallbackLoadTimeMS = 7000;

// 초기화 빠를 시 이미지 최소 표시 시간
const minimumSplashTimeMS = 500;

export default function ThankYouTokenIframe({ className, src }) {
  const { sendLog } = useDebugWeb();
  const { state: _state, query } = useLocation2();
  const [_, setSearchParams] = useSearchParams();
  const isOpen = useSelector((state) => state.thankYouTokenReducer.isOpen);
  const dispatch = useDispatch();
  const navigate = useNavigate2();
  const ref = useRef();
  const { data } = useLogin();
  const { encrypt } = useAESCrypto({
    key: 'ThankyouToken!@#',
  });

  const thankyoutokenState = useMemo(() => {
    let page = _state?.thankyoutoken ?? query.thankyoutoken ?? '';
    if (page === true || page === 'true') {
      // 예전 기능으로 true 가 오는 경우가 있음.
      page = '';
    }
    return page;
  }, []);

  useEffect(() => {
    sendLog({
      type: 'thankyoutoken_iframe',
      thankYouTokenMenu: thankyoutokenState,
    });
  }, []);

  useEffect(() => {
    // thankyoutoken queryparam 제거
    setSearchParams(
      (prev) => {
        let res = {
          ...Object.fromEntries(prev),
        };

        delete res.thankyoutoken;
        return res;
      },
      { replace: true }
    );
  }, []);

  const encryptedEmpNo = useMemo(() => {
    if (data?.wbnEmpNo == null) {
      return null;
    } else {
      return encrypt(data.wbnEmpNo);
    }
  }, [data]);

  const [state, setState] = useState({
    isLoading: false,
    rendered: false,
  });

  const loginUrl = useMemo(() => {
    return `${src}/accounts/signin/`;
  }, [src]);

  const pageUrl = useMemo(() => {
    return `${src}${thankyoutokenState}`;
  }, [src]);

  const login = async (encodedEmpNo) => {
    if (state.isLoading) {
      return;
    }
    setState((p) => ({
      ...p,
      isLoading: true,
    }));
    let timeoutFallback = setTimeout(() => {
      // 로그인 못해도 7초 뒤 넘어감
      console.log('fallback go');
      go();
    }, fallbackLoadTimeMS);
    let before = new Date().getTime();

    await tryLogin(encodedEmpNo);

    clearTimeout(timeoutFallback);
    timeoutFallback = null;

    let minimumDelay = Math.max(
      minimumSplashTimeMS + before - new Date().getTime(),
      0
    );
    // console.log('minimunDelay', minimumDelay);
    setTimeout(() => {
      go();
    }, [minimumDelay]);
  };

  const tryLogin = async (encodedEmpNo) => {
    try {
      console.log('ThankYouTokenIframe login start');
      let res = await axios.post(
        loginUrl,
        {
          userId: encodedEmpNo,
        },
        {
          withCredentials: true,
        }
      );
      console.log('ThankYouTokenIframe login end');
    } catch (e) {
      console.error(e);
    }
  };

  // 두번 go 불가능
  const go = () => {
    console.log('iframe go');
    if (state.rendered) {
      return;
    }
    setState((p) => ({
      ...p,
      rendered: true,
    }));
    ref.current.src = pageUrl;
    ref.current.addEventListener('load', (e) => {
      onIframeLoad(e);
    });
    console.log('iframe go end');
  };

  // iframe 로딩 완료 시 이미지 숨김
  const onIframeLoad = (e) => {
    console.log('onIframeLoad', e, e.target, e.target.src);
    if (e.target.src === '') {
      return;
    }
    setState((p) => ({
      ...p,
      isLoading: false,
    }));
  };

  useEffect(() => {
    if (src == null || src === '') {
      if (isOpen) {
        dispatch(closeThankYouTokenSideBar());
      } else {
        navigate(-1);
      }
    }
  }, [src]);

  useEffect(() => {
    if (encryptedEmpNo != null) {
      login(encryptedEmpNo);
    } else {
      dispatch(openSideBar());
      if (isOpen) {
        dispatch(closeThankYouTokenSideBar());
      } else {
        navigate(-1);
      }
    }
  }, [loginUrl, encryptedEmpNo]);

  return (
    <>
      <iframe
        ref={ref}
        className={className}
        id="thankyoutokenIframe"
        key="thankyoutokenIframe"
        name="thankyoutokenIframe"
      />
      <Dialog
        open={state.isLoading}
        fullScreen={true}
        sx={{
          zIndex: 1302,
        }}
        PaperProps={{
          sx: {
            boxShadow: 'none',
            backgroundColor: 'white',
          },
        }}
      >
        <div className="w-full h-full flex items-center justify-center">
          <img
            className="object-cover h-full"
            src="https://cdn.wooriwbn.com/thb/wooribank/2023/12/18/KakaoTalk_20231218_142931693.jpg"
          />
          <img
            className="object-cover absolute"
            style={{
              height: '18%',
              opacity: '0.7',
              left: '-3%',
              top: '37.2%',
            }}
            src="https://cdn.wooriwbn.com/thb/wooribank/2024/02/22/nav_m_thankyou_icon_bg_none.gif"
          />
          <img
            className="object-cover absolute"
            style={{
              height: '18%',
              opacity: '0.7',
              right: '-3%',
              top: '37.2%',
            }}
            src="https://cdn.wooriwbn.com/thb/wooribank/2024/02/22/nav_m_thankyou_icon_bg_none.gif"
          />
        </div>
      </Dialog>
    </>
  );
}
