import { useMemo } from 'react';

/**
 *
 * @param {[number]}colRatios
 * @param {[Element]} ths
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function CommonTable({ colRatios = [], ths, children }) {
  const colRatiosSum = useMemo(
    () => colRatios.reduce((p, c) => p + c, 0),
    [colRatios]
  );

  return (
    <table className="chw_table">
      {colRatiosSum > 0 && (
        <colgroup>
          {colRatios.map((it) => (
            <col width={`${Math.floor((it / colRatiosSum) * 100)}%`} />
          ))}
        </colgroup>
      )}

      <thead>
        <tr>
          {ths.map((it) => (
            <th>{it}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}
