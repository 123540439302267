import PrivacyTerm from '@/components/term/개인정보 처리방침.htm';
import BreadCrumb from '@/components/ucms/common/BreadCrumb';
import Link2 from '@/components/common/transition/Link2';

export default function PrivacyTermPage() {
  const setHeight = (e) => {
    let frame = e.target;
    frame.height = frame.contentWindow.document.body.scrollHeight;
  };

  return (
    <div className="inner w-[600px]">
      <div className="mt-16" />
      <BreadCrumb
        menu1={<Link2 to={'/terms/privacy'}>개인정보처리방침</Link2>}
        showOnMobile={true}
      />
      {/*<div className="flex flex-col items-center h-[80vh]">*/}
      <iframe
        id="privacyTermIframe"
        onLoad={setHeight}
        className="w-full min-h-[500px]"
        src={PrivacyTerm}
      />
      {/*</div>*/}
    </div>
  );
}
