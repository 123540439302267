const ACTIONS = {
  OPEN_SIDEBAR: 'thankyoutoken/OPEN_SIDEBAR',
  CLOSE_SIDEBAR: 'thankyoutoken/CLOSE_SIDEBAR',
};

Object.freeze(ACTIONS);

export const openThankYouTokenSideBar = () => ({ type: ACTIONS.OPEN_SIDEBAR });
export const closeThankYouTokenSideBar = () => ({
  type: ACTIONS.CLOSE_SIDEBAR,
});

const initialState = {
  isOpen: false,
};

export default function thankYouTokenReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.OPEN_SIDEBAR: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case ACTIONS.CLOSE_SIDEBAR: {
      return {
        ...state,
        isOpen: false,
      };
    }
    default:
      return state;
  }
}
