import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bioRequestAuth } from './BioReducer';
import BioRequest from './dto/BioRequest';

export default function BioTestPage() {
  const dispatch = useDispatch();
  const bioCanAuth = useSelector((state) => state.bioReducer.bioCanAuth);
  const bioRequest = useSelector((state) => state.bioReducer.bioRequest);

  useEffect(() => {
    console.log('bioCanAuth', bioCanAuth?.data);
  }, [bioCanAuth]);

  const requestAuth = () => {
    dispatch(
      bioRequestAuth(
        new BioRequest({
          localizedReason: '테스트 인증 요청',
          androidTitle: '테스트',
        })
      )
    );
  };

  // const checkCanBioAuth = async () => {
  //     try{
  //         let res = await BioApi.canBioAuth();
  //         setMessage(JSON.stringify(res));
  //     }
  //     catch(e) {
  //         setMessage(e.message);
  //     }
  // }
  //
  //
  // const requestAuth = async () => {
  //     try{
  //         let res = await BioApi.requestBioAuth(new BioRequest({
  //             localizedReason: '인증 테스트',
  //             androidTitle: '생체 인증',
  //         }));
  //         console.log(res)
  //         let response = new BioResponse({...res})
  //         console.log(JSON.stringify(response))
  //         setMessage(JSON.stringify(response));
  //     }
  //     catch(e) {
  //         setMessage(e.message);
  //     }
  // }

  return (
    <div>
      <span>{`생체인증 가능 : ${bioCanAuth?.data === true}`}</span>
      <br />
      {bioCanAuth?.data === true && (
        <>
          <button onClick={requestAuth}>생체 인증 요청</button>
          <br />
        </>
      )}
      <span>{JSON.stringify(bioRequest)}</span>
    </div>
  );
}
