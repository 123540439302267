import { useSelector } from 'react-redux';

/**
 *
 * @param excludeNormal
 * @returns {[CompanyItem]}
 */
export default function useCompanyList({ excludeNormal = false } = {}) {
  const companyList = useSelector((state) => state.loginReducer.companyList);
  // return [
  //     {
  //         compId: 1,
  //         compNm: '테스트',
  //         compDomain: 'gmail.com'
  //     }
  // ]
  return (
    companyList?.data?.data?.data
      .filter((it) => !excludeNormal || it.compId > 0)
      .sort((a, b) => a.compId - b.compId) ?? []
  );
}

class CompanyItem {
  compId;
  compNm;
  compDomain;
}
