import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import sidebarReducer from '../../components/sidebar/sidebarReducer';
import loginReducer from '../../components/user/login/LoginReducer';
import audioPipReducer from '../../components/player/pip/AudioPipReducer';
import playerReducer from '@/components/player/playerReducer';
import menuReducer from '@/components/menu/MenuReducer';
import mobileNavigationReducer from '@/components/navigation/mobileNavigationReducer';
import detailHistoryReducer from '@/components/ucms/detailHistoryReducer';
import thankYouTokenReducer from '@/components/sidebar/thankYouTokenReducer';
import bugReportReducer from '@/components/popup/bugreport/bugReportReducer';
import pinReducer from '@/components/easyLogin/pin/PinReducer';
import easyLoginReducer from '@/components/easyLogin/EasyLoginReducer';
import bioReducer from '@/components/easyLogin/bio/BioReducer';
import wonderingReducer from '@/components/sidebar/wonderingReducer';

const rootReducer = combineReducers({
  sidebarReducer,
  thankYouTokenReducer,
  bioReducer,
  pinReducer,
  easyLoginReducer,
  loginReducer,
  pipReducer: audioPipReducer,
  playerReducer,
  menuReducer,
  mobileNavigationReducer,
  detailHistoryReducer,
  bugReportReducer,
  wonderingReducer,
});

export function* rootSaga() {
  yield all([
    // menuSaga(),
  ]);
}

export default rootReducer;
