import MenuApi from '@/components/menu/MenuApi';
import {
  createPromiseThunk,
  handleAsyncActions,
  reducerUtils,
} from '@/lib/asyncUtils';

const API_ACTION = {
  GET_MENU_LIST: 'GET_MENU_LIST',
  GET_MENU_LIST_ERROR: 'GET_MENU_LIST_ERROR',
  GET_MENU_LIST_SUCCESS: 'GET_MENU_LIST_SUCCESS',
};
Object.freeze(API_ACTION);

export const getMenuList = createPromiseThunk(
  API_ACTION.GET_MENU_LIST,
  MenuApi.getMenuList
);

const initialState = {
  menuList: reducerUtils.initial(),
};

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case API_ACTION.GET_MENU_LIST:
    case API_ACTION.GET_MENU_LIST_ERROR:
    case API_ACTION.GET_MENU_LIST_SUCCESS:
      return handleAsyncActions(API_ACTION.GET_MENU_LIST, 'menuList')(
        state,
        action
      );

    default:
      return state;
  }
}
