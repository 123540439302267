import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomBuildEditor from 'ckeditor5-custom-build/build/ckeditor';

export default function BoardEditor({}) {
  return (
    <CKEditor
      editor={CustomBuildEditor}
      data="<p>Hello from CKEditor 5!</p>"
      onReady={(editor) => {
        // You can store the "editor" and use when it is needed.
        console.log('Editor is ready to use!', editor);
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        console.log({ event, editor, data });
      }}
      onBlur={(event, editor) => {
        console.log('Blur.', editor);
      }}
      onFocus={(event, editor) => {
        console.log('Focus.', editor);
      }}
    />
  );
}
