export default function VideoOptionModalHeader({ onClose, onBack, title }) {
  if (onClose) {
    return (
      <div className="flex justify-between items-center">
        <span className="title">{title}</span>
        <button className="close-wrap" onClick={onClose}>
          <img src="/images/login_close.png" alt="닫기" className="close" />
        </button>
      </div>
    );
  } else if (onBack) {
    return (
      <div className="flex items-center gap-4">
        <button className="close-wrap" onClick={onBack}>
          <img src="/images/ico_left_arrow.svg" alt="닫기" className="close" />
        </button>
        <span className="title">{title}</span>
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <span className="title">{title}</span>
    </div>
  );
}
