import { forwardRef, useEffect, useMemo, useState } from 'react';
import useChatLog from '@/components/ucms/chat/log/useChatLog';
import LiveChatNotice from '@/components/ucms/chat/live/LiveChatNotice';
import {
  ChatItem,
  NoMessageItem,
  SystemMessageItem,
} from '@/components/ucms/chat/common/ChatItem';
import { ChatMessageType } from '@/components/ucms/chat/live/useLiveChat';
import useInfiniteScroll from '@/components/infiniteScroll/useInfiniteScroll';

export const ChatLogList = forwardRef(({ cid }, tabContentRef) => {
  const { isLoading, hasNext, list, loadMore } = useChatLog(cid);
  const [expanded, setExpanded] = useState(true);

  const load = () => {
    console.log('chat log load');
    loadMore();
  };

  const _list = useMemo(() => {
    return list.filter(
      (it) =>
        it.messageType === ChatMessageType.Message ||
        it.messageType === ChatMessageType.Ban ||
        it.messageType === ChatMessageType.Report
    );
  }, [list]);

  const notice = useMemo(() => {
    return list.filter((it) => it.messageType === ChatMessageType.Info).pop();
  }, [list]);

  const [_, listBottomRef] = useInfiniteScroll({
    querySelector: '#chat_log',
    isLoading: isLoading,
    isLast: !hasNext,
    loadMore: load,
    page: 0,
  });

  return (
    <div className="broad_box tab_content chat_height" ref={tabContentRef}>
      <h4 className="f14 mb-4 cont_head">채팅 내역</h4>
      <div
        className={`chat_box h-full overflow-hidden ${
          expanded ? '' : 'hidden'
        }`}
      >
        {notice?.message?.length !== 0 && (
          <LiveChatNotice message={notice?.message} />
        )}
        <div className="chat flex-1 overflow-hidden">
          <ul className="flex flex-col chat_inner chat_height" id="chat_log">
            {!isLoading && !hasNext && _list.length === 0 && <NoMessageItem />}
            {_list.map((item) =>
              item.type === 'system' ? (
                <SystemMessageItem item={item} />
              ) : (
                <ChatItem item={item} />
              )
            )}
            <div ref={listBottomRef} className="mb-3" />
          </ul>
        </div>
      </div>

      <button
        data-toggle="collapse"
        aria-expanded={expanded}
        className="f14_w300 flex items-center justify-center py-4 chat_hidden"
        onClick={() => setExpanded((prevState) => !prevState)}
      >
        {expanded ? '채팅 숨기기' : '채팅 보이기'}
      </button>
    </div>
  );
});
