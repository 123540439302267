export default class DeviceApi {
  static async getOS() {
    if (window.flutter_inappwebview != null) {
      return await window.flutter_inappwebview?.callHandler('get_os');
    } else {
      return null;
    }
  }

  static async getFcmToken() {
    // console.log('getFcmToken');
    if (window.flutter_inappwebview != null) {
      return await window.flutter_inappwebview?.callHandler('get_fcm_token');
    } else {
      return null;
    }
  }

  /**
   * 앱 저장소에 값을 저장합니다.
   * @param {string} key
   * @param {string} value
   * @returns {Promise<bool>} 값 반영 유무
   */
  static async setValue(key, value) {
    return await window.flutter_inappwebview?.callHandler(
      'set_value',
      key,
      value
    );
  }

  /**
   * 앱 저장소에서 값을 읽어옵니다.
   * @param {string} key
   * @returns {Promise<string|null>}
   */
  static async getValue(key) {
    return await window.flutter_inappwebview?.callHandler('get_value', key);
  }

  // v2.0.111 >= version
  static getAppVersion() {
    return window.webAppVersion ?? '1.0.0';
  }

  // v2.0.111 >= version
  static getAppBuildNumber() {
    return window.webAppBuildNumber ?? 0;
  }

  // v2.0.111 >= version
  static getIsDeviceSupportBio() {
    const os = this.getAppOS();
    if (os === 'Android') {
      return window.isDeviceBioSupport;
    } else if (os === 'iOS') {
      return true;
    } else {
      return null;
    }
  }

  // v2.0.111 >= version
  static getIsDeviceBioRegistered() {
    return window.isDeviceBioRegistered;
  }

  // v2.0.111 >= version
  static getAppOS() {
    try {
      return window.webAppOS;
    } catch (e) {
      return null;
    }
  }

  static isIOSApp() {
    return this.getAppOS() === 'iOS';
  }

  static isIOSWeb() {
    let ua = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
    return (
      ua.indexOf('iphone') > -1 ||
      ua.indexOf('ipad') > -1 ||
      ua.indexOf('ipod') > -1
    );
  }
}
