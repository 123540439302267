import { apiInstance } from '@/app/config/network';
import AuthUtil from '@/components/auth/AuthUtil';

export default class BannerApi {
  static async getBanner({ bannerType, menuId }) {
    return apiInstance
      .post('/api/cms/content/bannerList', {
        bannerType,
        menuId,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async getLiveBanner({ cid }) {
    return apiInstance
      .post('/api/cms/content/bannerList', {
        bannerType: 'LIVE',
        cid,
      })
      .then((res) => {
        return res.data;
      });
  }
}
