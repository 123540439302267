import { useMemo, useState } from 'react';
import LoginUtil from '@/components/user/login/LoginUtil';

export default function useSaveLoginId() {
  const [isSaved, setSaved] = useState(LoginUtil.getSavedLoginUserId() != null);

  const save = async (id) => {
    await LoginUtil.setSavedLoginUserId(id);
    setSaved(true);
  };

  const load = async () => {
    return await LoginUtil.getSavedLoginUserId();
  };

  const remove = async () => {
    await LoginUtil.setSavedLoginUserId(null);
    setSaved(false);
  };

  return {
    save,
    load,
    remove,
    isSaved,
  };
}
