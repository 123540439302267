import { forwardRef, useMemo, useState } from 'react';
import useLiveChat from '@/components/ucms/chat/live/useLiveChat';
import LiveChatList from '@/components/ucms/chat/live/LiveChatList';
import useLogin from '@/components/user/login/useLogin';
import { ChatItemModel } from '@/components/ucms/chat/common/ChatItem';
import { newDate } from '@/lib/timeUtil';

export const LiveChat = forwardRef(({ cid, isLive }, tabContentRef) => {
  const { isLoggedIn, data } = useLogin();
  const isAdmin = useMemo(
    () => isLoggedIn && data.adminGroupId > 0,
    [isLoggedIn, data]
  );
  const [chatList, setChatList] = useState([]);
  const [notice, setNotice] = useState(null);

  const onMessage = (message) => {
    let mine = data?.userNo != null && message.userNo == data.userNo;
    let m = new ChatItemModel({
      id: message.chatId,
      userNo: message.userNo,
      profileUrl: message.profile,
      nickName: message.nickName,
      message: message.message,
      regDt: newDate(message.datetime),
      canReport: !mine && isLoggedIn,
      canBan: !mine && isLoggedIn && isAdmin,
      canBanCancel: !mine && isLoggedIn && isAdmin && message.isBan === true,
      isBan: false,
      isReported: false,
      mine,
      type: 'chat',
    });
    setChatList((prev) => [...prev, m]);
  };

  const onNoticeMessage = (message) => {
    setNotice(message);
  };

  const onReport = (message) => {
    changeMessageState(message.chatId, null, message.isBan, true);
  };

  const changeMessageState = (
    id,
    message,
    isBan = false,
    isReported = false
  ) => {
    setChatList((prev) => {
      let oldList = [...prev];
      let mine = false;
      let index = oldList.findIndex((item) => item.id === id);
      if (index < 0) {
        return oldList;
      }
      let oldMessage = oldList[index];
      let targetMessage = new ChatItemModel({
        ...oldMessage,
        message: message ?? oldMessage.message,
        isBan,
        isReported,
        canReport: !mine && isLoggedIn && !isBan,
        canBan: !mine && isLoggedIn && isAdmin && !isBan,
        canBanCancel: !mine && isLoggedIn && isAdmin && isBan,
      });
      // console.log(targetMessage);
      let res = [
        ...oldList.slice(0, index),
        targetMessage,
        ...oldList.slice(index + 1),
      ];
      // console.log(oldList,res);
      return res;
    });
  };

  const onBan = (message) => {
    changeMessageState(
      message.chatId,
      message.message,
      true,
      message.isReported
    );
  };
  const onBanCancel = (message) => {
    changeMessageState(
      message.chatId,
      message.message,
      false,
      message.isReported
    );
  };

  const handleChatSend = (chatContent) => {
    messageRoom(chatContent);
  };

  const onHistory = (messages) => {
    // 입장 시 최근 채팅 수신.
    // 목록으로 여러번 수신, 정렬 필요
    let convertedHistory = messages.map((message) => {
      let mine = data?.userNo != null && message.userNo == data.userNo;
      return new ChatItemModel({
        id: message.chatId,
        userNo: message.userNo,
        profileUrl: message.profile,
        nickName: message.nickName,
        message: message.message,
        regDt: newDate(message.regDt),
        canReport: !mine && isLoggedIn,
        canBan: !mine && isLoggedIn && isAdmin,
        canBanCancel: !mine && isLoggedIn && isAdmin && message.isBan === true,
        isBan: false,
        isReported: false,
        mine,
        type: 'chat',
      });
    });
    // console.log(messages)
    // console.log(convertedHistory)

    setChatList((prev) => {
      console.log(JSON.stringify(prev));
      // 채팅 id 중복 제거
      let newItems = convertedHistory.filter(
        (item) => !prev.some((prevItem) => prevItem.id == item.id)
      );
      // console.log(JSON.stringify(newItems))
      // 채팅 id 순 정렬
      let list = [...newItems, ...prev];

      list.sort((a, b) => a.id - b.id);
      // console.log(JSON.stringify(list))
      // console.log('history', list);
      return list;
    });
  };

  const {
    socketConnected,
    messageRoom,
    noticeRoom,
    cancelBanChat,
    banChat,
    reportChat,
  } = useLiveChat({
    cid,
    useChat: true,
    onMessage,
    onReport,
    onBan,
    onBanCancel,
    onNoticeMessage,
    onHistory,
  });

  return (
    <LiveChatList
      ref={tabContentRef}
      isAdmin={isAdmin}
      chatList={chatList}
      notice={notice}
      onNoticeSend={noticeRoom}
      onChatSend={handleChatSend}
      onReport={reportChat}
      onBlock={banChat}
      onBlockCancel={cancelBanChat}
    />
  );
});
