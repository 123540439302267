import Popup from '@/components/popup/Popup';
import { useDispatch } from 'react-redux';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import { userLogout } from '@/components/user/login/LoginReducer';

export default function TermsRequiredPopup({ show = false, setShow } = {}) {
  const navigate = useNavigate2();
  const dispatch = useDispatch();
  return (
    <>
      {show && (
        <Popup
          useDim={true}
          title="개인정보 동의 필요"
          message="서비스 이용을 위해 개인정보<br/> 수집∙이용∙제공 동의가 필요합니다."
          okMessage="내용확인"
          cancelMessage="로그아웃"
          cancelCallback={() => {
            dispatch(userLogout());
          }}
          okCallback={() => {
            navigate('/my/terms');
          }}
        />
      )}
    </>
  );
}
