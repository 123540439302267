import MyPageApi from '@/components/user/my/MyPageApi';
import { useEffect, useState } from 'react';

export default function useUserPointView() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const load = async () => {
    setIsLoading(true);
    let res = await MyPageApi.userPointView();
    console.log(res);
    setData(res.data?.[0]);
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return {
    isLoading,
    data,
    load,
  };
}
