import PageIndicator from '../../../components/content/paging/PageIndicator';
import PointTableRow from '../../../components/table/point/PointTableRow';
import { CircularProgress } from '@mui/material';
import useEarnPointList from '@/components/user/my/useEarnPointList';
import CommonTable from '@/components/table/CommonTable';
import usePage from '@/components/infiniteScroll/usePage';
import { useMyPagePaging } from '@/page/my/tabs/useMyPagePaging';

export default function EarnPoint({} = {}) {
  const [historyPage, _] = usePage();

  const { isLoading, list, load, remove, total, page } = useEarnPointList({
    initialPage: historyPage,
  });

  const { setPage } = useMyPagePaging({ loader: load, page });
  // const year = useMemo(() => new Date().getFullYear(), [])

  if (list == null || isLoading === true) {
    return (
      <div className="w-full h-[80vh] flex items-center justify-center">
        <CircularProgress className="loading" />
      </div>
    );
  }

  return (
    <div className="box_wrap grid sm:gap-3 sm:gap-y-2">
      <div className="min-h-[400px]">
        <CommonTable
          colRatios={[15, 20, 35, 30]}
          ths={['일시', '분류', '포인트적립내용', '적립포인트']}
        >
          {list.length === 0 && (
            <PointTableRow
              category={'-'}
              date={'-'}
              point={0}
              description={'포인트 내역이 없습니다.'}
            />
          )}
          {list.map((item, index) => (
            <PointTableRow
              category={item?.pType}
              date={item?.itemDt?.replace('T', ' ')}
              point={item?.pvalue}
              description={item?.title}
            />
          ))}
        </CommonTable>
        {/*<p className="f12_w300 co_gray999 mt-4 sm:px-0 px-4">・적립된 포인트는 {year}년 12월 31일까지 사용 가능하며 {year+1}년 1월1일에 초기화 됩니다.</p>*/}
      </div>
      {total > 0 && (
        <PageIndicator
          option={{
            page: page - 1,
            totalCount: total,
            pageSize: 10,
            onPageChange: setPage,
          }}
        />
      )}
    </div>
  );
}
