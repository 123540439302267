import React from 'react';
import Link2 from '@/components/common/transition/Link2';
import { Link } from 'react-router-dom';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className={`w-full h-[100vh] flex flex-col items-center justify-center ${
            this.props.className ?? ''
          }`}
        >
          {this.props.message != null && this.props.message}
          {this.props.message == null && '알 수 없는 오류입니다.'}
          <button onClick={() => window.location.reload()}>새로고침</button>
        </div>
      );
    }

    return this.props.children;
  }
}
