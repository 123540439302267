import { useEffect, useRef, useState } from 'react';
import useLogin from '@/components/user/login/useLogin';
import AuthUtil from '@/components/auth/AuthUtil';
import { getDefaultProfileImage } from '@/components/user/ProfileImage';
import Config from '@/app/config/config';
import ChatApi from '@/components/ucms/chat/common/ChatApi';
import { normalizeNFC } from '@/lib/stringUtil';

export const ChatMessageType = {
  Connect: 'Connect',
  LogIn: 'LogIn',
  Enter: 'Enter',
  Message: 'Message',
  Leave: 'Leave',
  Info: 'Info',
  Report: 'Report',
  Ban: 'Ban',
  BanCancel: 'Cancel',
  Heartbeat: 'Heartbeat',
  History: 'History',
};

export const getMessageTypeFromChatType = (chatType) => {
  if (chatType == 0) {
    return ChatMessageType.Message;
  } else if (chatType == 1) {
    return ChatMessageType.Info;
  } else if (chatType == 2) {
    return null; // 퇴장
  } else if (chatType == 3) {
    return ChatMessageType.Report;
  } else if (chatType == 4) {
    return ChatMessageType.Ban;
  } else {
    return null;
  }
};
export const refineMessage = (message) => {
  return {
    ...message,
    messageType:
      message.messageType ?? getMessageTypeFromChatType(message.chatType),
    profile: message.profile ?? message.profileUrl,
    chatId: message.itemId ?? message.chatId,
    id: message.itemId ?? message.chatId,
    message: message.msg ?? message.message,
    nickName: message.userNick ?? message.nickNm ?? message.nickName,
  };
};

export default function useLiveChat({
  useChat,
  cid,
  onMessage,
  onNoticeMessage,
  onReport,
  onBan,
  onBanCancel,
  onHistory,
} = {}) {
  const connectCheckTimerRef = useRef(null);
  const [connectCheckTimeTick, setConnectCheckTimeTick] = useState(null);
  const socketServer = Config.chatUrl;
  const socketSessionKeyRef = useRef(null);
  const { isLoggedIn, data: userData } = useLogin();
  const wsRef = useRef();
  const [socketConnected, setSocketConnected] = useState(false);
  const callbackListRef = useRef([]);
  const isSocketLoggedInRef = useRef(false);
  const isRoomEntered = useRef(false);

  const connectSocket = async () => {
    if (!useChat || !isLoggedIn || cid == null) {
      return;
    }
    if (wsRef.current == null) {
      let chatServerUrl = await ChatApi.getChatServer();
      // console.log('getChatServerRes', chatServerUrl);
      wsRef.current = new WebSocket(chatServerUrl);
      wsRef.current.onopen = () => {
        console.log('websocket connected to');
        setSocketConnected(true);
      };
      wsRef.current.onclose = (error) => {
        console.warn('websocket disconnect from');
        console.warn(error);
        setSocketConnected(false);
      };
      wsRef.current.onerror = (error) => {
        console.log('websocket connection error');
        console.error(error);
        setSocketConnected(false);
      };
      wsRef.current.onmessage = async (evt) => {
        const data = JSON.parse(evt.data);
        if (data.code !== '00') {
          return;
        }
        // console.log('onmessage', data);
        switch (data.messageType) {
          case ChatMessageType.Connect:
            sendAuth();
            break;
          case ChatMessageType.LogIn: {
            let isSuccess = handleLoginResult(data);
            if (isSuccess) {
              enterRoom();
            }
            break;
          }
          case ChatMessageType.Enter:
            handleEnterResult(data);
            break;
          case ChatMessageType.Message:
            handleMessage(data);
            break;
          case ChatMessageType.Info:
            handleInfo(data);
            break;
          case ChatMessageType.Report:
            handleReport(data);
            break;
          case ChatMessageType.Ban:
            handleBan(data);
            break;
          case ChatMessageType.BanCancel:
            handleBanCancel(data);
            break;
          case ChatMessageType.Heartbeat:
            break;
          case ChatMessageType.History:
            handleHistory(data);
            break;
          default:
            console.warn('unknown message type', data);
            break;
        }
      };
    }
  };

  const handleHistory = (data) => {
    onHistory?.(data.history.map((it) => refineMessage(it)));
  };

  const send = (data) => {
    const str = JSON.stringify(data);
    // console.log('chat send', str);
    wsRef.current?.send(str);
  };

  const sendAuth = async () => {
    let authData = await AuthUtil.getOneTimeSessionKey();
    let profile = userData.profileUrl;
    if (profile == null) {
      profile = getDefaultProfileImage(userData.userNo);
    }
    send({
      messageType: ChatMessageType.LogIn,
      ...authData,
      userNo: userData.userNo,
      profile: profile,
      nickName: userData.nickNm,
    });
  };

  const handleLoginResult = (result) => {
    if (result.code === '00' && result.sessionKey != null) {
      socketSessionKeyRef.current = result.sessionKey;
      isSocketLoggedInRef.current = true;
      isRoomEntered.current = false;
      return true;
    } else {
      socketSessionKeyRef.current = null;
      isSocketLoggedInRef.current = false;
      isRoomEntered.current = false;
      return false;
    }
  };

  const handleEnterResult = (result) => {
    if (result.code === '00') {
      isRoomEntered.current = true;
      return true;
    } else {
      isRoomEntered.current = false;
      return false;
    }
  };

  // const createRoom = async () => {
  //     let authData = getUserKey();
  //     send({
  //         messageType: "Create",
  //         roomNo: cid,
  //         ...authData,
  //     });
  //     roomCreateSentRef.current = true;
  // }

  const enterRoom = async () => {
    send({
      sessionKey: socketSessionKeyRef.current,
      messageType: ChatMessageType.Enter,
      roomNo: cid,
    });
  };
  const messageRoom = async (message) => {
    send({
      sessionKey: socketSessionKeyRef.current,
      messageType: ChatMessageType.Message,
      roomNo: cid,
      message: normalizeNFC(message),
    });
  };

  const leaveRoom = async () => {
    send({
      messageType: ChatMessageType.Leave,
      roomNo: cid,
      sessionKey: socketSessionKeyRef.current,
    });
  };

  const handleMessage = (message) => {
    onMessage?.(message);
  };

  const handleInfo = (message) => {
    onNoticeMessage?.(refineMessage(message));
  };
  const handleBan = (message) => {
    onBan?.(message);
  };
  const handleBanCancel = (message) => {
    onBanCancel?.(message);
  };
  const handleReport = (message) => {
    onReport?.(message);
  };

  const reportChat = (chatNo, desc) => {
    send({
      messageType: ChatMessageType.Report,
      roomNo: cid,
      chatNo,
      sessionKey: socketSessionKeyRef.current,
      desc: normalizeNFC(desc ?? ''),
    });
  };

  const banChat = (chatNo, desc) => {
    // TODO 권한 확인
    send({
      messageType: ChatMessageType.Ban,
      roomNo: cid,
      chatNo,
      sessionKey: socketSessionKeyRef.current,
      desc: normalizeNFC(desc ?? ''),
    });
  };

  const cancelBanChat = (chatNo) => {
    // TODO 권한 확인
    send({
      messageType: ChatMessageType.BanCancel,
      roomNo: cid,
      chatNo,
      sessionKey: socketSessionKeyRef.current,
    });
  };

  const noticeRoom = (message) => {
    // TODO 권한 확인
    send({
      messageType: ChatMessageType.Info,
      roomNo: cid,
      message: normalizeNFC(message),
      sessionKey: socketSessionKeyRef.current,
    });
  };

  const sendHeartbeat = () => {
    send({
      messageType: ChatMessageType.Heartbeat,
      sessionKey: socketSessionKeyRef.current,
    });
  };

  useEffect(() => {
    if (connectCheckTimeTick == null) {
      return;
    }

    if (!socketConnected) {
      wsRef.current?.close();
      wsRef.current = null;
      connectSocket();
    }
  }, [connectCheckTimeTick]);

  // 연결 후 하트비트
  useEffect(() => {
    let heartBeatTimer = null;
    if (socketConnected) {
      heartBeatTimer = setInterval(() => {
        sendHeartbeat();
      }, 1000 * 60);
    }
    return () => {
      if (heartBeatTimer != null) {
        clearInterval(heartBeatTimer);
      }
    };
  }, [socketConnected]);

  useEffect(() => {
    // 로그인 필수, cid 있어야함, 채팅 활성화 되어있어야함.
    connectSocket();

    connectCheckTimerRef.current = setInterval(() => {
      // console.warn('소켓 재연결 tick')
      setConnectCheckTimeTick(new Date().getTime());
    }, 1000 * 3);

    return () => {
      if (connectCheckTimerRef.current != null) {
        clearInterval(connectCheckTimerRef.current);
      }
      leaveRoom();
      setSocketConnected(false);
      wsRef.current?.close();
    };
  }, [isLoggedIn, cid, useChat]);

  return {
    socketConnected,
    messageRoom,
    noticeRoom,
    reportChat,
    cancelBanChat,
    banChat,
  };
}
