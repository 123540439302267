import { useEffect, useRef } from 'react';

/**
 * **⚠️ 사용시 주의점: 드롭다운의 경우 새로 표시되는게 아닌 원래 있던 요소에 e.stopPropagation 으로 이벤트 전파를 막아주어야 합니다!**
 * @param callback
 * @return {[React.MutableRefObject<undefined>,React.MutableRefObject<undefined>]}
 */
export default function useOuterClick(callback) {
  const callbackRef = useRef(); // Mutable ref to store callback
  const innerRef = useRef();
  const outerRef = useRef(); // 클릭 이벤트 영역을 제한할 태그의 ref. ref를 연결해주지 않으면 document로 ㄱㄱ

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const el = outerRef.current ?? document;
    el.addEventListener('click', handleClick);
    return () => el.removeEventListener('click', handleClick);

    function handleClick(e) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      )
        callbackRef.current(e);
    }
  }, []); // no dependencies -> stable click listener

  return [outerRef, innerRef]; // convenience for client (doesn't need to init ref himself)
}
