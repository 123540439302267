import { createPortal } from 'react-dom';

export default function TermPopup(options) {
  const container = document.querySelector('#term_detail_portal');
  return createPortal(<TermPopupContainer {...options} />, container);
}

function TermPopupContainer({ children } = {}) {
  return (
    // <div className="popup_join mb-1 popup-z-index">
    // <div className="popup-z-index">
    <div
      className="term-popup popup-z-index relative"
      style={
        {
          // height: '800px',
        }
      }
    >
      {children}
    </div>
  );
}
