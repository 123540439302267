import FileSelector from '@/components/content/editor/FileSelector';
import React, { useEffect, useMemo, useState } from 'react';

/**
 *
 * @param {ContentEditorImageListFieldOption} option
 * @returns {JSX.Element}
 * @constructor
 */
export default function ContentEditorImageListField({ option }) {
  const [existFiles, setExistFiles] = useState(option?.imageUrlList ?? []);
  const [files, setFiles] = useState([]);

  const previews = useMemo(() => {
    return files.map((file) => URL.createObjectURL(file));
  }, [files]);

  useEffect(() => {
    option?.onFiles([...existFiles, ...files]);
  }, [files, existFiles]);

  const removeFile = (index) => {
    setFiles((fs) => fs.filter((_, i) => i !== index));
  };

  return (
    <div className="file_row_wrap mt-4 flex flex-nowrap">
      <div className="file_col thumb w-full">
        <div className="flex gap-4">
          <FileSelector
            option={{
              className: 'gap-y-1 flex-none',
              title: '사진추가',
              fileType: 'image/png,image/jpeg,image/jpg',
              maxFiles: 100,
              onFile: (files) => setFiles((fs) => [...fs, ...files]),
            }}
          />
          <div className="flex gap-4 overflow-x-auto overflow-y-hidden">
            {existFiles.map((imageUrl, index) => (
              <div className="thumb_box2">
                <img src={imageUrl} alt="이미지" />
                <button
                  type="button"
                  className="remove"
                  onClick={() =>
                    setExistFiles((fs) => fs.filter((_, i) => i !== index))
                  }
                />
              </div>
            ))}
            {previews.map((preview, index) => (
              <div className="thumb_box2">
                <img src={preview} alt="이미지" />
                <button
                  type="button"
                  className="remove"
                  onClick={() => removeFile(index)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * @typedef {Object} ContentEditorThumbnailFieldOption
 * @property {string} title 표시 텍스트
 * @property {string} thumbnailUrl 기존 썸네일
 * @property {(files: File[]) => void} onFile 새 썸네일
 */
export class ContentEditorImageListFieldOption {
  constructor({ imageUrlList, onFiles }) {
    this.imageUrlList = imageUrlList;
    this.onFiles = onFiles;
  }
}
