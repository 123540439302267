import { useState } from 'react';
import MyPageApi from '@/components/user/my/MyPageApi';
import useSubscribe from '@/components/user/my/useSubscribe';

export default function useLikeVODList() {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);

  const load = async ({ page = 1, size = 9 } = {}) => {
    setPage(page);
    setIsLoading(true);
    let res = await MyPageApi.userVODLikeList({
      _pageNo: page,
      _pageSize: size,
    });
    setList(res.data);
    setTotal(res.total_like ?? 0);
    setIsLoading(false);
    return res.data;
  };

  const remove = async (cids) => {
    setIsLoading(true);
    for (let cid of cids) {
      await MyPageApi.likeDelete(cid);
    }
    setIsLoading(false);
    return true;
  };

  return {
    isLoading,
    list,
    total,
    load,
    page,
    remove,
  };
}
