import { useEffect, useMemo, useRef } from 'react';
import { CircularProgress } from '@mui/material';
import BoardList from '@/components/content/list/BoardList';
import { SearchApi } from '@/components/search/SearchApi';
import { ContentType } from '@/app/config/ContentType';
import { userContentData } from '@/page/ucms/user-content/UserContentContainer';
import useInfiniteScroll from '@/components/infiniteScroll/useInfiniteScroll';
import usePage from '@/components/infiniteScroll/usePage';
import { history } from '@/app/App';

export default function CommonSearchTab({
  isVideo,
  code,
  data,
  param,
  onTabChange,
}) {
  console.log(data.data?.pages);
  const list = data.list;

  const [historyPage, setPage] = usePage();
  const [gridView, gridViewBottom] = useInfiniteScroll({
    isLoading: data.isLoading,
    isInitLoading: !data.initLoadCompleted,
    loadMore: data.loadMore,
    isLast: data.isLast,
    page: data.data?.pageParams?.[0] ?? historyPage + 1,
    defaultPage: historyPage,
  });

  useEffect(() => {
    // 최초 로딩인데
    if (
      historyPage === 0 &&
      (data.infiniteQuery?.data?.pages?.[0]?.res?.data?.length ?? 0) > 0
    )
      setPage((prev) => prev + 1);
  }, [data.initLoadCompleted]);

  const dataList = useMemo(() => {
    return list?.map((it) => ({
      ...SearchApi.infoToThumbnailOption(it, isVideo),
      author: it.menuNm,
    })); // search tab
  }, [list]);

  return (
    <>
      <div ref={gridView}>
        {!data.initLoadCompleted ? (
          <div
            className="w-full flex items-center justify-center"
            style={{ height: 320 }}
          >
            <CircularProgress className="loading" />
          </div>
        ) : (
          <BoardList
            option={{
              title: userContentData[code]?.title ?? code,
              contentType:
                userContentData[code]?.contentType ?? ContentType.BBS,
              pageOption: {
                totalCount: data.total,
              },
              showPage: false,
              thumbnailCardOptions: dataList,
            }}
          />
        )}
      </div>
      <div ref={gridViewBottom} />
    </>
  );
}
