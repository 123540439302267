import {
  Collapse,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { useMemo, useRef, useState } from 'react';

export default function OutlineLabelField({
  id,
  name,
  autoComplete,
  inputRef,
  label,
  value,
  autoFocus = false,
  onChange,
  className,
  disabled,
  placeholder,
  message,
  customButton = null,
  onKeyDown,
  useClearAdornment = true,
  usePasswordAdornment = true,
  maxLength,
  type,
} = {}) {
  const _inputRefSelf = useRef();

  const _inputRef = useMemo(() => {
    return inputRef ?? _inputRefSelf;
  }, [inputRef]);

  const [showPassword, setShowPassword] = useState(type !== 'password');

  const clearValue = () => {
    onChange?.('');
    _inputRef.current?.focus();
  };

  /**
   * 하단 메세지 표시 여부 계산
   * @type {boolean}
   */
  const showMessage = useMemo(() => {
    if (message != null && message !== '') {
      return true;
    } else {
      return false;
    }
  }, [message]);

  const createClearTextAdornment = () => {
    const isValueEmpty = (value == null) | (value === '');
    if (!useClearAdornment) {
      return null;
    }
    return (
      <Fade in={!isValueEmpty && useClearAdornment} mountOnEnter unmountOnExit>
        <IconButton
          tabIndex={-1}
          aria-label="clear text"
          onClick={clearValue}
          size="large"
          edge="end"
        >
          <img
            src="/images/input_del.svg"
            className="input_icon"
            alt="입력창 비우기"
          />
        </IconButton>
      </Fade>
    );
  };

  const createPasswordAdornment = () => {
    if (type !== 'password' || !usePasswordAdornment) {
      return null;
    }
    return (
      <Fade in={type === 'password'} mountOnEnter unmountOnExit>
        <IconButton
          tabIndex={-1}
          aria-label="toggle password visibility"
          onClick={() => setShowPassword((prev) => !prev)}
          size="large"
          edge="end"
        >
          <img
            className="input_icon"
            src={
              showPassword
                ? '/images/input_password_invisible.svg'
                : '/images/input_password_visible.svg'
            }
            alt="비밀번호 보이기/숨기기"
          />
        </IconButton>
      </Fade>
    );
  };

  /**
   * 입력창에 표시 할 부가 아이콘 계산
   * @type {React.ReactNode}
   */
  const adornment = useMemo(() => {
    let clearAdornment = createClearTextAdornment();
    let passwordAdornment = createPasswordAdornment();
    if (
      clearAdornment == null &&
      passwordAdornment == null &&
      customButton == null
    ) {
      return '';
    }

    return (
      <InputAdornment position="end" className="justify-end">
        {clearAdornment != null && clearAdornment}

        {passwordAdornment != null && passwordAdornment}

        {customButton != null && customButton}
        {/*<div*/}
        {/*    className="mr-4"*/}
        {/*/>*/}
      </InputAdornment>
    );
  }, [value, showPassword, type, customButton]);

  // 타입을 이용해 실제 textFieldType 정의. 비밀번호 말고도 type으로 분기 들어가야 하는 경우 여기에 선언하시면 됩니다.
  const textFieldType =
    type === 'password' ? (showPassword ? 'text' : 'password') : type ?? 'text';

  return (
    <div className={`outline_field ${className}`}>
      <FormControl variant="outlined" className={`w-full ${className}`}>
        <TextField
          autoFocus={autoFocus}
          inputRef={_inputRef}
          id={id}
          autoComplete={autoComplete}
          name={name}
          type={textFieldType}
          // type='text'
          label={label}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange?.(e.target.value)}
          InputProps={{
            endAdornment: adornment,
          }}
          inputProps={{ maxLength }}
          onKeyDown={onKeyDown}
          endadornment={adornment}
          maxLength={maxLength}
        />
      </FormControl>
      {/*메세지 영역*/}
      <Collapse in={showMessage}>
        <div
          className={`outline_field_message ${className}`}
          style={{ height: '100%', padding: '10px 16px' }}
        >
          {message}
        </div>
      </Collapse>
    </div>
  );
}
