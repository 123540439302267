import FileSelector from '@/components/content/editor/FileSelector';
import React, { useEffect, useState } from 'react';
import NotateToolTip from '@/components/content/editor/NotateToolTip';

/**
 *
 * @param {ContentEditorThumbnailFieldOption} option
 * @returns {JSX.Element}
 * @constructor
 */
export default function ContentEditorThumbnailField({ option }) {
  const [file, setFile] = useState();
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (file != null) {
      option?.onFile?.(file);
      setPreview(URL.createObjectURL(file));
    } else {
      option?.onFile?.(file);
      setPreview(null);
    }
  }, [file]);

  return (
    <div className="file_col thumb">
      <div className="flex gap-4">
        <FileSelector
          option={{
            className: 'gap-y-1 flex-none',
            title: '썸네일',
            fileType: 'image/png,image/jpeg',
            onFile: (file) => setFile(file?.[0]),
          }}
        />
        <div className="thumb_box">
          {option?.thumbnailUrl == null &&
            preview == null &&
            option.thumbnailFile == null && (
              <div className="flex items-center justify-center h-full">
                <img
                  className="!w-[50px]"
                  src="/images/photo_noimg.png"
                  alt="이미지 없음"
                />
                <div className="flex">
                  <span className="noti">*</span>
                  {option?.needThumbnail && (
                    <span className="thumbnail_auto_text">
                      필수
                      <br />
                      등록
                    </span>
                  )}
                  {!option?.needThumbnail && (
                    <span className="thumbnail_auto_text">
                      미등록시
                      <br />
                      자동생성
                    </span>
                  )}
                  {option?.isSquareThumbnail && (
                    <NotateToolTip title="썸네일 권장 해상도 380x380" />
                  )}
                  {!option?.isSquareThumbnail && (
                    <NotateToolTip title="썸네일 권장 해상도 640x360" />
                  )}
                </div>

                {/*<span className="thumbnail_auto_text">썸네일 권장 해상도 380x380</span>*/}
              </div>
            )}

          {option?.thumbnailFile != null && preview == null && (
            <img src={option?.thumbnailFile?.thumbnailUrl} alt="썸네일" />
          )}
          {preview != null && (
            <>
              <img src={preview} alt="썸네일" />
              <button
                type="button"
                className="remove"
                onClick={() => setFile(null)}
              />
            </>
          )}
          {option?.thumbnailUrl != null && (
            <img src={option.thumbnail} alt="썸네일" />
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * @typedef {Object} ContentEditorThumbnailFieldOption
 * @property {string} title 표시 텍스트
 * @property {string} thumbnailUrl 기존 썸네일
 * @property {(files: File[]) => void} onFile 새 썸네일
 */
export class ContentEditorThumbnailFieldOption {
  constructor({
    thumbnailFile,
    thumbnail,
    onFile,
    needThumbnail,
    isSquareThumbnail,
  }) {
    this.thumbnailFile = thumbnailFile;
    this.thumbnail = thumbnail;
    this.onFile = onFile;
    this.needThumbnail = needThumbnail;
    this.isSquareThumbnail = isSquareThumbnail;
  }
}
