import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [isWebView, setIsWebView] = useState(window.isWebViewApp === true);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const isMobile = useMemo(
    () => windowDimensions.width < 768 || isWebView,
    [isWebView, windowDimensions]
  );
  const _setIsWebView = (value) => {
    if (value === true) {
      window.dispatchEvent(new Event('webAppInitialized'));
      window.isWebViewApp = value;
    }
  };

  const _handleResize = useCallback(
    () =>
      _.throttle(() => {
        setWindowDimensions(getWindowDimensions());
      }, 1000),
    []
  );

  useEffect(() => {
    function webAppInitialized() {
      setIsWebView(true);
    }

    window.addEventListener('resize', _handleResize);
    window.addEventListener('webAppInitialized', webAppInitialized);
    return () => {
      window.removeEventListener('resize', _handleResize);
      window.removeEventListener('webAppInitialized', webAppInitialized);
    };
  }, []);

  return {
    ...windowDimensions,
    isMobile,
    setIsWebView: _setIsWebView,
    isWebView,
  };
}
