import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import FileSelector from '@/components/content/editor/FileSelector';
import Link2 from '@/components/common/transition/Link2';
import UploadFileDto from '@/components/content/editor/upload/UploadFileDto';

/**
 *
 * @param {ContentEditorFileListFieldOption} option
 * @returns {JSX.Element}
 * @constructor
 */
export default function ContentEditorFileField({ option }) {
  // const [files, setFiles] = useState(option?.fileList ?? []);

  // useEffect(() => {
  //     option?.onFiles(files);
  // }, [files])

  // const toggleDelete = (index) => {
  //     const result = !files[index].deleted;
  //     setFiles(s => {
  //         const f = [...s];
  //         f[index].deleted = result;
  //         return f;
  //     })
  // }

  /**
   *
   * @param {[File]} files
   */
  const addNewFile = (files) => {
    let newFiles = files.map((it) => UploadFileDto.fromFile(it));
    option.onNewList([...(option.newList ?? []), ...newFiles]);
  };

  const toggleDeleteNewFile = (index) => {
    const list = [...option.newList];
    list[index] = {
      ...list[index],
      deleted: !list[index].deleted,
    };
    option.onNewList(list);
  };

  const toggleDeleteExistFile = (index) => {
    const list = [...option.existList];
    list[index] = {
      ...list[index],
      deleted: !list[index].deleted,
    };
    option.onExistList(list);
  };

  return (
    <div className="file_row_wrap mb-10">
      <div className="file_col2 flex items-center flex-wrap gap-2">
        <div className="flex gap-4 mr-4 mb-4 sm:mb-0">
          <FileSelector
            option={{
              className: 'gap-y-1 flex items-center gap-2',
              title: '파일선택',
              maxFiles: 100,
              onFile: (files) => addNewFile(files),
            }}
          />
        </div>
        {option.existList?.map((it, index) => (
          <div
            key={`existListFile_${index}`}
            className="flex items-center h-[40px]"
          >
            <Link2 to={it.url}>
              <p
                className={classNames({
                  'f14_w300 underline': true,
                  'line-through': it.deleted,
                })}
              >
                {it.name}
              </p>
            </Link2>
            <div className="mr-2" />
            <button type="button" onClick={() => toggleDeleteExistFile(index)}>
              <img src="/images/file_close.png" alt="파일삭제" />
            </button>
          </div>
        ))}
        {option.newList?.map((it, index) => (
          <div
            key={`newListFile_${index}`}
            className="flex items-center h-[40px]"
          >
            <Link2 to={it.url}>
              <p
                className={classNames({
                  'f14_w300 underline': true,
                  'line-through': it.deleted,
                })}
              >
                {it.name}
              </p>
            </Link2>
            <div className="mr-2" />
            <button type="button" onClick={() => toggleDeleteNewFile(index)}>
              <img src="/images/file_close.png" alt="파일삭제" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export class ContentEditorFileListFieldOption {
  /**
   *
   * @param {[UploadFileDto]}existList
   * @param {[UploadFileDto]}newList
   * @param {function(UploadFileDto[])} onExistList
   * @param {function(UploadFileDto[])} onNewList
   */
  constructor({ existList, newList, onExistList, onNewList }) {
    this.existList = existList;
    this.newList = newList;
    this.onExistList = onExistList;
    this.onNewList = onNewList;
  }
}
