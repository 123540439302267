import { forwardRef, useEffect, useRef, useState } from 'react';
import '../common/chatCustom.css';
import { ChatMessageType } from '@/components/ucms/chat/live/useLiveChat';
import ScrollableFeed from 'react-scrollable-feed';
import useLogin from '@/components/user/login/useLogin';
import LiveChatNotice from '@/components/ucms/chat/live/LiveChatNotice';
import LiveChatInput from '@/components/ucms/chat/live/LiveChatInput';
import {
  ChatItem,
  SystemMessageItem,
} from '@/components/ucms/chat/common/ChatItem';

const LiveChatList = forwardRef(
  (
    {
      isAdmin,
      notice,
      chatList,
      onChatSend,
      onNoticeSend,
      id,
      onReport,
      onBlock,
      onBlockCancel,
    },
    tabContentRef
  ) => {
    const [expanded, setExpanded] = useState(true);
    const messagesEndRef = useRef(null);
    const { isLoggedIn, data } = useLogin();
    useEffect(() => {
      scrollToBottom();
    }, [chatList]);

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollToBottom();
    };

    const sendChat = (message, isAnnounce = false) => {
      if (message == null || message.trim().length === 0) {
        return;
      }
      if (isAnnounce) {
        onNoticeSend(message);
      } else {
        onChatSend(message);
      }
      scrollToBottom();
    };

    const handleMenuSelect = (value, item) => {
      switch (value) {
        case ChatMessageType.Report: {
          onReport(item.id);
          break;
        }
        case ChatMessageType.Ban: {
          onBlock(item.id);
          break;
        }
        case ChatMessageType.BanCancel: {
          onBlockCancel(item.id);
          break;
        }
      }
      setChatMenuAttached(null);
    };

    const [chatMenuAttached, setChatMenuAttached] = useState(null);

    return (
      <div
        className="broad_box tab_content chat_height"
        id={id}
        ref={tabContentRef}
      >
        <h4 className="f14 mb-4 cont_head">실시간 채팅</h4>
        <div
          className={`chat_box h-full overflow-hidden ${
            expanded ? '' : 'hidden'
          }`}
        >
          {notice?.message?.length !== 0 && (
            <LiveChatNotice
              onNoticeDeleted={() => onNoticeSend('')}
              message={notice?.message}
              isAdmin={isAdmin}
            />
          )}
          <div className="chat flex-1 overflow-hidden">
            {/*<ul className="flex flex-col chat_inner !overflow-hidden !h-full">*/}
            <ul className="flex flex-col chat_inner chat_height">
              <ScrollableFeed ref={messagesEndRef}>
                {chatList.map((item) =>
                  item.type === 'system' ? (
                    <SystemMessageItem item={item} />
                  ) : (
                    <ChatItem
                      isAdmin={isAdmin}
                      item={item}
                      onMenuSelected={(value) => handleMenuSelect(value, item)}
                      menuVisible={chatMenuAttached == item.id}
                      onMenuVisibilityChanged={(visibility) =>
                        setChatMenuAttached(visibility ? item.id : null)
                      }
                    />
                  )
                )}
              </ScrollableFeed>
            </ul>
          </div>

          {isLoggedIn && (
            <div className="chat_box flex-grow-0 flex-shrink-0">
              <div className="chat">
                <LiveChatInput
                  nickNm={data?.nickNm}
                  isAdmin={isAdmin}
                  onSend={sendChat}
                />
              </div>
            </div>
          )}
        </div>

        <button
          data-toggle="collapse"
          aria-expanded={expanded}
          className="f14_w300 flex items-center justify-center py-4 chat_hidden"
          onClick={() => setExpanded((prevState) => !prevState)}
        >
          {expanded ? '채팅 숨기기' : '채팅 보이기'}
        </button>
      </div>
    );
  }
);

export default LiveChatList;
