import useNavigate2 from '@/components/common/transition/useNavigate2';
import { useMemo, useState } from 'react';
import ContentPasswordPopup from '@/components/content/password/ContentPasswordPopup';
import PopupPortal from '@/components/popup/PopupPortal';
import useAESCrypto from '@/components/user/useAESCrypto';
import ContentApi from '@/components/content/ContentApi';
import useMenu from '@/components/menu/useMenu';

export default function usePasswordPopup() {
  const { encrypt } = useAESCrypto();
  const navigator = useNavigate2();
  const { findMenu } = useMenu();
  const [passwordOpenOption, setPasswordOpenOption] = useState(null);

  const popup = useMemo(() => {
    if (passwordOpenOption == null) {
      return <></>;
    } else {
      return (
        <PopupPortal>
          <ContentPasswordPopup
            showClose={true}
            isPasswordError={passwordOpenOption?.error === 'password'}
            onSubmit={async (password) => {
              let option = passwordOpenOption;
              if (password === '') {
                setPasswordOpenOption(null);
                return;
              }
              if (option.link != null) {
                let menu = findMenu(option.menuId);
                let res = await ContentApi.getContent(
                  option.menuId,
                  option.cid,
                  menu.contentType,
                  encrypt(password)
                );
                if ((res.code ?? res.cod) !== '00') {
                  setPasswordOpenOption({
                    ...option,
                    error: 'password',
                  });
                } else {
                  setPasswordOpenOption(null);
                  navigator(option.link, {
                    state: { password: encrypt(password) },
                  });
                }
              }
            }}
          />
        </PopupPortal>
      );
    }
  }, [passwordOpenOption]);
  const onClick = (e, menuId, cid, link, isLocked) => {
    if (isLocked) {
      e.preventDefault();
      e.stopPropagation();
      setPasswordOpenOption({
        menuId: menuId,
        cid: cid,
        link: link,
      });
    }
  };

  const close = () => {
    setPasswordOpenOption(null);
  };

  return {
    popup,
    close,
    onClick,
  };
}
