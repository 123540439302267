import { Navigate, Outlet } from 'react-router-dom';
import useLogin from '@/components/user/login/useLogin';

export default function MyContainer({} = {}) {
  const { isLoggedIn } = useLogin();
  if (isLoggedIn === false) {
    window.location.href = '/';
    return '';
  }

  return (
    <div className="min-h-[100vh]">
      <Outlet />
    </div>
  );
}
