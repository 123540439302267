import axios from 'axios';

export default function useDynamicLink() {
  const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;

  const shortenUrl = async (url) => {
    return await axios
      .post(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseApiKey}`,
        {
          longDynamicLink: `https://wbn.page.link/?link=${url}&apn=com.woori.wbn&ibi=com.woori.wbn&isi=6447748194&efr=1`,
        }
      )
      .then((res) => {
        return res.data?.shortLink;
      });
  };

  return {
    shortenUrl,
  };
}
