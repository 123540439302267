import useWindowDimensions from '@/lib/useWindowDimensions';
import Link2 from '@/components/common/transition/Link2';
import { openThankYouTokenSideBar } from '@/components/sidebar/thankYouTokenReducer';
import { useDispatch } from 'react-redux';
import { openSideBar } from '@/components/sidebar/sidebarReducer';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import useLogin from '@/components/user/login/useLogin';
import { SearchApi } from '@/components/search/SearchApi';
import useThankyouRegList from '@/components/content/list/useThankyouRegList';
import { openWonderingSideBar } from '@/components/sidebar/wonderingReducer';

const gridItems = [
  {
    name: '땡큐토큰',
    icon: '/images/home_grid_thankyou.svg',
    link: '',
    onClick: ({ event, dispatch }) => {
      event.preventDefault();
      dispatch(openThankYouTokenSideBar());
    },
  },
  {
    name: ' 오늘의<br/>주요 뉴스',
    icon: '/images/home_grid_news.svg',
    link: '/ucms/78',
    onClick: null,
  },
  {
    name: '우리방송',
    icon: '/images/home_grid_lms.svg',
    link: '',
    onClick: ({ event, dispatch, navigate, isLoggedIn }) => {
      event.preventDefault();
      if (!isLoggedIn) {
        dispatch(openSideBar());
      } else {
        navigate('/ucms/1');
      }
    },
  },
  {
    name: '이벤트',
    icon: '/images/home_grid_event.svg',
    link: '/ucms/5/15',
    onClick: null,
  },
  {
    name: '우리가족',
    icon: '/images/home_grid_family.svg',
    link: '/ucms/4',
    onClick: null,
  },
  {
    name: '원더링',
    icon: '/images/home_grid_wondering.svg',
    link: '',
    onClick: ({ event, dispatch }) => {
      event.preventDefault();
      dispatch(openWonderingSideBar());
    },
  },
  {
    name: '자유게시판',
    icon: 'images/home_grid_board.svg',
    link: '/ucms/5/12',
    onClick: null,
  },
  {
    name: '라디오',
    icon: 'images/home_grid_radio.svg',
    link: '/ucms/1/9',
    onClick: null,
  },
  {
    name: '1:1문의',
    icon: 'images/home_grid_qna.svg',
    link: '/ucms/5/16',
    onClick: null,
  },
];

export default function MainQuickGrid() {
  const { isMobile } = useWindowDimensions();
  const { isLoggedIn } = useLogin();
  const navigate = useNavigate2();
  const dispatch = useDispatch();
  const { hasError, list } = useThankyouRegList();

  if (!isMobile) {
    return '';
  }

  return (
    <div
      className="m-4 gap-2 grid grid-cols-3"
      style={{
        gridAutoRows: '1fr',
      }}
    >
      {gridItems.map((it, index) => (
        <Link2
          key={`main_quick_grid_item_${index}`}
          to={it.link}
          onClick={
            it.onClick != null
              ? (event) => it.onClick({ event, dispatch, navigate, isLoggedIn })
              : null
          }
        >
          <div
            className="flex flex-col items-center justify-center w-full h-full aspect-square flex p-[14px] "
            style={{
              backgroundColor: '#edf0f4',
              borderRadius: '8px',
            }}
          >
            <img
              className="aspect-square object-contain sm:w-[100px]"
              src={it.icon}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: it.name,
              }}
              className="flex-shrink-0 text-center mt-2"
              style={{
                fontWeight: 'bold',
                color: '#418BD6',
                fontSize: '0.8125rem',
                lineHeight: 1,
              }}
            ></span>
            {it.name === '땡큐토큰' && list != null && (
              <div className="flex-shrink-0 text-center contents">
                {/* <span
                  dangerouslySetInnerHTML={{
                    __html: '가입자수',
                  }}
                  className="flex-shrink-0 text-center"
                  style={{
                    fontWeight: 'bold',
                    color: '#979899',
                    fontSize: '0.8125rem',
                  }}
                ></span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: list[0]?.count.toLocaleString('ko-KR') + '명',
                  }}
                  className="flex-shrink-0 text-center"
                  style={{
                    fontWeight: 'bold',
                    color: '#418BD6',
                    fontSize: '0.8125rem',
                    lineHeight: 0.5,
                  }}
                ></span> */}
              </div>
            )}
          </div>
        </Link2>
      ))}
    </div>
  );
}
