import { useMemo } from 'react';
import useHomeLiveList from '@/components/content/list/useHomeLiveList';
import { SearchApi } from '@/components/search/SearchApi';
import LiveList from '@/components/content/list/LiveList';

export default function HomeLiveList() {
  const { isInitialLoading, isLoading, list, param, loadMore, sortChange } =
    useHomeLiveList();

  const dataList = useMemo(() => {
    return list?.map((it) => SearchApi.infoToThumbnailOption(it, false)); // home live
  }, [list]);

  return (
    <div className="pl-6 pr-6">
      <LiveList isLoading={isInitialLoading} list={dataList} />
    </div>
  );
}
