import PinDialog from '@/components/easyLogin/pin/PinDialog';
import { useNavigate } from 'react-router-dom';

export default function PinTestPage() {
  const navigate = useNavigate();

  return (
    <PinDialog
      open={true}
      onCancel={() => navigate(-1)}
      onSubmit={() => navigate(-1)}
    />
  );
}
