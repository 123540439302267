import LoginForm from './LoginForm';
import { Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closeSideBar, openSideBar } from '../../sidebar/sidebarReducer';
import usePathnameChange from '../../sidebar/hook/usePathnameChange';
import { useEffect, useState } from 'react';
import useLocation2 from '../../sidebar/hook/useLocation2';
import PasswordForm from '@/page/join/PasswordForm';

export default function LoginSideBar({}) {
  const dispatch = useDispatch();
  const { state } = useLocation2();

  useEffect(() => {
    // console.log(state);
    if (state?.login !== undefined) {
      console.log('login sidebar open');
      open();
    }
  }, [state]);

  const isOpen = useSelector((state) => state.sidebarReducer.isLoginOpen);

  usePathnameChange((pathname) => {
    close();
  });

  const open = () => {
    dispatch(openSideBar());
  };

  const close = () => {
    if (isOpen) {
      dispatch(closeSideBar());
    }
  };

  const [form, setForm] = useState('login');

  return (
    <>
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={close}
        ModalProps={{
          sx: {
            zIndex: 1301, // 헤더 보다 높게
          },
        }}
      >
        {form.startsWith('login') && (
          <LoginForm
            onFormChange={setForm}
            email={form.replace(/^login/, '').replace(/^\?email=/, '')}
          />
        )}
        {form.startsWith('password') && (
          <PasswordForm
            email={form.replace('password?email=', '')}
            onFormChange={setForm}
          />
        )}
      </Drawer>
    </>
  );
}
