import SearchSideBar from '@/components/sidebar/search/SearchSideBar';
import LoginSideBar from '@/components/user/login/LoginSideBar';
import { Outlet } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { getCompanyList } from '@/components/user/login/LoginReducer';
import { useDispatch, useSelector } from 'react-redux';
import useLogin from '@/components/user/login/useLogin';
import Popup from '@/components/popup/Popup';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import ThankYouTokenSideBar from '@/components/navigation/ThankyouTokenSideBar';
import useDebugLog from '@/components/debug/useDebugLog';
import TermsRequiredPopup from '@/components/popup/TermsRequiredPopup';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import EmployInfoEditRequiredPopup from '@/components/popup/EmployInfoEditRequiredPopup';
import MobileSidebarSiteMap from '@/components/header/MobileSidebarSiteMap';
import useEasyLogin from '@/components/easyLogin/useEasyLogin';
import useWindowDimensions from '@/lib/useWindowDimensions';
import LoginUtil from '@/components/user/login/LoginUtil';
import WonderingSideBar from '@/components/navigation/WonderingSideBar';

export default function LoginContainer() {
  const dispatch = useDispatch();
  const { isWebView, isMobile, width } = useWindowDimensions();
  const { isTryingBioLogin } = useSelector((state) => state.easyLoginReducer);
  const { pathname } = useLocation2();
  const {
    code,
    isLoggedIn,
    isSessionLogin,
    data,
    shouldGoTermPage,
    shouldEditEmployInfo,
    shouldEditMyInfo,
    tryLogin,
  } = useLogin();
  const [showRequireTermsPopup, setShowRequireTermsPopup] = useState(false);
  const [showEmployInfoEditPopup, setShowEmployInfoEditPopup] = useState(false);
  const [popup, setPopup] = useState(null);
  const {
    isRegistered,
    canRegister,
    easyLoginRegisterSuggestShowed,
    setEasyLoginRegisterSuggestShowed,
  } = useEasyLogin();
  const navigate = useNavigate2();
  const { sendLog } = useDebugLog();

  useEffect(() => {
    dispatch(getCompanyList());
    _tryLog();
  }, []);

  const _tryLog = async () => {
    let logData = {
      location: 'LoginContainer foreground > tryLogin',
      ...(await LoginUtil.getDebugInfo()),
      previousLoginCode: code,
      previousIsLoggedIn: isLoggedIn,
      previousIsSessionLogin: isSessionLogin,
      isWebView,
      isMobile,
      screenWidth: width,
      userAgent: window.navigator.userAgent,
    };
    console.log('logData', JSON.stringify(logData));
  };

  const handleTermAgree = (data) => {};

  useEffect(() => {
    console.log('isTryingBioLogin', isTryingBioLogin);
  }, [isTryingBioLogin]);

  const backgroundStopCallback = useCallback(() => {
    if (isTryingBioLogin) {
      console.log('ignore foreground at bioLoginTry');
    } else {
      console.log('tryLogin from foreground');
      _tryLogin();
    }
  }, [isTryingBioLogin]);

  const _tryLogin = async () => {
    let logData = {
      location: 'LoginContainer foreground > tryLogin',
      ...(await LoginUtil.getDebugInfo()),
      previousLoginCode: code,
      previousIsLoggedIn: isLoggedIn,
      previousIsSessionLogin: isSessionLogin,
      isWebView,
      isMobile,
      screenWidth: width,
      userAgent: window.navigator.userAgent,
    };
    sendLog(logData);
    tryLogin();
  };

  useEffect(() => {
    if (isWebView) {
      document.addEventListener('foreground', backgroundStopCallback);
    } else {
      window.addEventListener('focus', backgroundStopCallback);
    }
    return () => {
      document.removeEventListener('foreground', backgroundStopCallback);
      window.removeEventListener('focus', backgroundStopCallback);
    };
  }, [isTryingBioLogin]);

  useEffect(() => {
    if (data != null && code === '00') {
      handleTermAgree(data);
    }
  }, [code, data, pathname]);

  useEffect(() => {
    // 필수 약관 동의 확인
    let shouldGoTermsPage = shouldGoTermPage();
    if (shouldGoTermsPage) {
      if (pathname === '/my/terms') {
        setShowRequireTermsPopup(false);
      } else {
        setShowRequireTermsPopup(true);
      }
      // 약관 동의 필요하면 이후 팝업 안띄움
      return;
    }

    let shouldGoEditEmployInfo = shouldEditEmployInfo();
    let shouldGoEditMyInfo = shouldEditMyInfo();
    if (shouldGoEditEmployInfo || shouldGoEditMyInfo) {
      if (pathname === '/myinfo/info') {
        setShowEmployInfoEditPopup(false);
      } else {
        setShowEmployInfoEditPopup(true);
      }
      // 약관 동의 필요하면 이후 팝업 안띄움
      return;
    }

    sendLog({
      location: 'LoginContainer.useEffect.code',
      code,
      isSessionLogin,
      canRegister,
      isRegistered,
      easyLoginRegisterSuggestShowed,
    });
    if (code === '00') {
      if (
        canRegister &&
        !isRegistered &&
        !isSessionLogin &&
        !easyLoginRegisterSuggestShowed
      ) {
        setPopup({
          title: '간편로그인 등록하기',
          message: '간편인증 등록을 하지 않으셨습니다. 지금 등록 하시겠습니까?',
          okMessage: '등록하기',
          cancelMessage: '취소',
          okCallback: () => {
            setEasyLoginRegisterSuggestShowed(true);
            sendLog('go to bioLoginRegister');
            setPopup(null);
            navigate('/myinfo/info');
          },
          cancelCallback: () => {
            setEasyLoginRegisterSuggestShowed(true);
            sendLog('dismiss go to bioLoginRegister');
            setPopup(null);
          },
        });
      }
    }
  }, [code, data, pathname]);

  return (
    <>
      <Outlet />
      <SearchSideBar />
      <LoginSideBar />
      <MobileSidebarSiteMap />
      <ThankYouTokenSideBar />
      <WonderingSideBar />
      <TermsRequiredPopup
        show={showRequireTermsPopup}
        setShow={setShowRequireTermsPopup}
      />
      <EmployInfoEditRequiredPopup
        show={showEmployInfoEditPopup}
        setShow={setShowEmployInfoEditPopup}
      />
      {popup != null && <Popup {...popup} />}
    </>
  );
}
