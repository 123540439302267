import LoginUtil from '@/components/user/login/LoginUtil';
import useAESCrypto from '@/components/user/useAESCrypto';
import AuthApi from '@/components/auth/AuthApi';

export default function useAuth() {
  const { encrypt } = useAESCrypto();
  const getOneTimeSessionKey = async () => {
    let { userId, sessionKey } =
      (await LoginUtil.getSessionKeyWithUserId()) ?? {};
    if (
      userId == null ||
      sessionKey == null ||
      userId === '' ||
      sessionKey === ''
    ) {
      return null;
    }

    const { id: oneTimeSessionKey } = (await AuthApi.getAuthKey()) ?? {};
    if (oneTimeSessionKey == null || oneTimeSessionKey === '') {
      console.error('oneTimeSessionKey is null');
      return null;
    }

    return encrypt(`${userId}:${sessionKey}:${oneTimeSessionKey}`);
  };

  const getLoginKey = async (userId, pw) => {
    if (userId == null || pw == null || userId === '' || pw === '') {
      return null;
    }

    const { id: oneTimeSessionKey } = (await AuthApi.getAuthKey()) ?? {};
    if (oneTimeSessionKey == null || oneTimeSessionKey === '') {
      console.error('oneTimeSessionKey is null');
      return null;
    }

    return encrypt(`${userId}:${pw}:${oneTimeSessionKey}`);
  };

  return {
    getOneTimeSessionKey,
    getLoginKey,
  };
}
