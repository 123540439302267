import { toShortCount } from '../../../lib/mathUtil';
import Thumbnail from '@/components/card/thumbnail/Thumbnail';
import { SearchApi } from '@/components/search/SearchApi';
import OptionalLinkWrapper from '@/components/card/OptionalLinkWrapper';
import { useMemo } from 'react';

/**
 * @param {ThumbnailCardOption} video
 * @return {JSX.Element}
 * @constructor
 */
export default function RelatedVideoItem({ video }) {
  // console.log(video);

  const data = useMemo(() => {
    return SearchApi.relateInfoToThumbnailOption(video); // relate video
  }, [video]);

  return (
    <OptionalLinkWrapper to={data.isTranscoding ? null : data.link}>
      <li className="video_rel flex mb-5">
        <div className="thumb">
          <Thumbnail
            imgClassName="aspect-[16/9]"
            option={{ ...data, isSmall: true }}
          />
        </div>
        <div className="rel_text">
          <p>{video.title}</p>
          <div className="flex items-center relative reply_text gap-4 my-2">
            <span className="f12_w400 co_gray999">{video.nickNm}</span>
            <span className="f12_w400 co_gray999">
              {video.publishDt?.split('T')?.[0]}
            </span>
          </div>
          <form className="info_icon flex gap-6 items-center">
            <div className="flex">
              <input id="222" name="rel" type="checkbox" className="view" />
              <label htmlFor="222" className="view">
                {toShortCount(video.viewCnt)}
              </label>
            </div>
            <div className="flex">
              <input id="111" name="rel" type="checkbox" className="comment" />
              <label htmlFor="111" className="comment">
                {toShortCount(video.replyCnt)}
              </label>
            </div>
            <div className="flex">
              <input id="333" name="rel" type="checkbox" className="good" />
              <label htmlFor="333" className="cogoodmment">
                {toShortCount(video.likeCnt)}
              </label>
            </div>
          </form>
        </div>
      </li>
    </OptionalLinkWrapper>
  );
}
