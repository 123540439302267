import ContentPasswordPopup from '@/components/content/password/ContentPasswordPopup';
import useAESCrypto from '@/components/user/useAESCrypto';

export default function ContentPassword({ onSubmit }) {
  const { encrypt } = useAESCrypto();

  return (
    <div
      id="all_wrapper"
      className="h-full fixed top-0 left-0 right-0 bottom-0 z-40"
    >
      <div id="contents" className="h-full">
        <div className="secrit_url_pop flex flex-col">
          <img src="/images/url_logo.png" alt="로고" />
          <div className="text-center mt-6 mb-10">
            <h3 className="url_tit f28_w700 text-white">
              우리의 공감소통
              <br />
              미디어 플랫폼 <span className="co_pri_light f28_w700">채널W</span>
            </h3>
          </div>
          <ContentPasswordPopup
            onSubmit={(value) => onSubmit?.(encrypt(value))}
          />
        </div>
      </div>
    </div>
  );
}
