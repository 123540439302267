import { useEffect, useMemo, useState } from 'react';
import MyPageHeader from '@/page/my/MypageHeader';
import useThankyouRegList from '@/components/user/my/useThankyouRegList';
import {
  NumericValidator,
  useRegexArrayValidator,
} from '@/components/form/useRegexValidate';
import { dateFormat } from '@/lib/timeUtil';
import { CircularProgress } from '@mui/material';
import ThankyouTokenManageTableRow from '@/components/table/thanyou/ThankyouTokenManageTableRow';
import CommonTable from '@/components/table/CommonTable';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ValidatorInput } from '@/page/my/tabs/MyInfoEdit';

export default function ThankyouReg() {
  const { isLoading, list, load, update } = useThankyouRegList();
  const dateNow = new Date();
  // const today = dateNow.toISOString().slice(0, 10);
  // const [todayDate, setTodayDate] = useState(today);
  const [startDate, setStartDate] = useState();

  const numericValidator = useRegexArrayValidator(NumericValidator, '', null);

  const onTryUpdate = async () => {
    let param = {
      itemDt: dateFormat({
        value: startDate,
        format: 'yyyy-MM-dd',
      }),
      count: parseInt(numericValidator.value),
    };
    let res = await update(param);
    if (res.code === '00') {
      load();
    }
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <MyPageHeader
        option={{
          title: '땡큐토큰 가입자수 관리',
          // selectCount: selectedItems.length,
          // isDeleteMode: isSelectMode,
          // onEnterDeleteMode: async () => {
          //   if (selectedItems.length === 0) {
          //     return;
          //   }
          //   await remove(selectedItems);
          //   setSelectMode(false);
          //   setSelectedItems([]);
          //   load();
          // },
          // onCancel: () => {
          //     setSelectMode(false);
          //     setSelectedItems([]);
          // },
          // onAllSelect: () => setSelectedItems(dataList.map(it => it.cid)),
          // onDelete: async () => {
          // }
        }}
      />
      {isLoading && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <CircularProgress className="loading" />
        </div>
      )}
      {!isLoading && (list == null || list?.length === 0) && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <span>가입자 목록이 없습니다.</span>
        </div>
      )}

      {!isLoading && list?.length > 0 && (
        <>
          <div className="box_wrap grid">
            <CommonTable colRatios={[50, 50]} ths={['등록일', '가입자수']}>
              {list.map((item, index) => (
                <ThankyouTokenManageTableRow
                  key={item.itemDt}
                  itemDate={item.itemDt}
                  regCount={item.count}
                />
              ))}
            </CommonTable>
          </div>
          {/* <div className="mt-4 file_row_wrap">
            <div className="file_col flex items-center">
              <div className="flex items-center md:w-[unset] w-full">
                <DatePicker
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                />
                <div className="mr-4" />
              </div>
              <div className="member_input relative">
                  <div className="row flex">
                    <div className="member_input relative flex-1">
                      <ValidatorInput
                        label="구독자수"
                        type="text"
                        validator={numericValidator}
                        // message={formData.empNoMessage}
                      />
                    </div>
                  </div>
                </div>
              <div className="flex items-center md:w-[unset] w-full">
                <button className="pri_btn" onClick={onTryUpdate}>
                  등록
                </button>
                <div className="mr-4" />
              </div>
            </div>
          </div> */}
          <div>
            <div>
              <DatePicker
                value={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div className="member_input relative mt-4">
              <div className="row flex">
                <div className="member_input relative flex-1">
                  <ValidatorInput
                    label="구독자수"
                    type="text"
                    validator={numericValidator}
                    // message={formData.empNoMessage}
                  />
                </div>
              </div>
            </div>
            <div>
              <button className="pri_btn mt-4" onClick={onTryUpdate}>
                등록
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
