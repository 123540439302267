import useBanner, { BannerType } from '@/components/banner/useBanner';
import { useMemo } from 'react';
import { CircularProgress } from '@mui/material';

export default function MenuBanner({ menuId }) {
  const { list, hasError, isLoading } = useBanner({
    bannerType: BannerType.menu,
    menuId,
  });

  const banner = useMemo(() => {
    return list?.[0];
  }, [list]);

  return (
    <>
      {isLoading && (
        <div
          className="w-full flex items-center justify-center"
          style={{ height: '240px' }}
        >
          <CircularProgress className="loading" />
        </div>
      )}
      {!isLoading && banner != null && (
        <div>
          <img
            className="menu_banner_p no_drag mb-14"
            src={banner.bannerPath1}
          />
          <img
            className="menu_banner_m no_drag mb-10"
            src={banner.bannerPath2}
          />
        </div>
      )}
    </>
  );
}
