import TagView from '@/components/ucms/common/Tag';
import { useMemo, useState } from 'react';
import ContentCounter from '@/components/ucms/common/ContentCounter';
import FileView from '@/components/ucms/common/FileView';
import '../../components/ucms/post/PostDetailView.css';
import AutoBreadCrumb from '@/components/ucms/common/AutoBreadCrumb';
import ShareModal from '@/components/modal/share/ShareModal';
import PostBottom from '@/components/ucms/post/PostBottom';
import HtmlEmbed from '@/components/embed/HtmlEmbed';
import ProfileImage from '@/components/user/ProfileImage';
import UcmsMobileHeader from '@/components/ucms/common/UcmsMobileHeader';
import useWindowDimensions from '@/lib/useWindowDimensions';
import useMenu from '@/components/menu/useMenu';
import CanZoomHeader from '@/components/common/ux/CanZoomHeader';
import PostOption from '@/components/ucms/common/PostOption';
import useDeletePopup from '@/components/ucms/common/useDeletePopup';
import useReportPopup from '@/components/ucms/common/useReportPopup';
import { ContentType } from '@/app/config/ContentType';
import UserSubscribeButton from '@/components/ucms/common/UserSubscribeButton';
import CommentListWrap from '@/components/comment/CommentListWrap';
import Link2 from '@/components/common/transition/Link2';
import classNames from 'classnames';
import EventFormButton from '@/components/ucms/post/EventFormButton';

export function PostHeader({ post, onAuthorSubscribedChange }) {
  return (
    <>
      <div className="notice_detail read_inner">
        <div className="flex flex-col items-center w-full text-center">
          <div className="md:hidden block mt-4 w-full flex items-start">
            {post.tag != null && <TagView tag={post.tag} className="" />}
          </div>
          <h5 className="wetube_read_tit md:pb-20 pb-4 mt-4 w-full">
            {post.title}
            <div className="md:!flex hidden mt-3 items-center justify-center">
              {post.tag != null && <TagView tag={post.tag} className="" />}
            </div>
          </h5>
        </div>
        <div className="flex items-center gap-3 md:justify-center flex-wrap">
          <ProfileImage
            userNo={post.authorNo}
            profileUrl={post.profileUrl}
            profileSt={post.profileSt}
            nickNm={post.author}
          />
          <Link2
            className="f14_w300 co_gray999"
            to={`/user/content?userNo=${post.authorNo}`}
          >
            {post.author}
          </Link2>
          <UserSubscribeButton
            userNo={post.authorNo}
            followerCount={post.followerCount}
            onSubscribeChange={onAuthorSubscribedChange}
          />
          <span className="f14_w300 co_gray999">{post.date}</span>
        </div>
      </div>
    </>
  );
}

export default function UcmsPostDetailPage({
  isInitLoading,
  isLoading,
  data,
  content,
  videoFile,
  userThumbnails,
  thumbnails,
  files,
  attachments,
  replies,
  load,
  onLikeClick,
  onWriteComment,
  fetchNextReply,
  reportComment,
  oneononeResponse,
  onAuthorSubscribedChange,
  onDelete,
  onEdit,
  onReport,
  onShared,
  dragLockYn,
}) {
  const { findMenu } = useMenu();
  const { isMobile } = useWindowDimensions();
  const onCommentSubmit = async (comment, replyId) => {
    return await onWriteComment(data.cid, comment, replyId);
  };

  const { popup: deletePopup, onDelete: _delete } = useDeletePopup({
    onDelete,
  });
  const { popup: reportPopup, onReport: _report } = useReportPopup({
    onReport,
  });

  const [shareModalVisible, setShareModalVisible] = useState(false);

  const shareModalData = useMemo(() => {
    return {
      ...content,
      thumbnailUrl:
        userThumbnails?.[0]?.thumbnailUrl ?? thumbnails?.[0]?.thumbnailUrl,
      link: window.location.href,
      profileName: '채널W',
      profileThumbnailUrl: window.location.origin + '/images/logo.png',
    };
  }, [content]);

  const menuName = useMemo(() => {
    return findMenu(data?.menuId); // 권한 없는 메뉴도 비공개 글인 경우 접근 가능
  }, [data]);

  return (
    <div>
      <CanZoomHeader />
      <div className="breadcrumb_wrap mt-8 pl-4 lg:pl-16 mb-6">
        <AutoBreadCrumb
          showOnMobile={false}
          menuId={data.menuId}
          className=""
        />
      </div>
      <PostHeader
        post={content}
        onAuthorSubscribedChange={onAuthorSubscribedChange}
      />

      <ShareModal
        data={shareModalData}
        visible={shareModalVisible}
        useVideoEmbed={false}
        onVisibilityChanged={setShareModalVisible}
        onShared={onShared}
      />

      {isMobile && <UcmsMobileHeader title={menuName?.menuNm} />}
      <span className=" w-full border-b flex line mt-6 md:mt-10 mb-4 md:mb-8" />
      <div className="read_inner">
        <div className=" info_icon flex gap-5 items-center justify-end mb-4">
          <ContentCounter
            isLiked={content.isLiked}
            commentCount={content.commentCount}
            viewCount={content.viewCount}
            likeCount={content.likeCount}
            shareCount={content.shareCount}
            onLikeClick={() => {
              onLikeClick();
            }}
            onShareClick={() => {
              setShareModalVisible(true);
            }}
          />

          <div className="relative">
            <PostOption
              canReport={content.isReportable}
              canDelete={content.isDeletable}
              canEdit={content.isEditable}
              onDelete={_delete}
              onEdit={onEdit}
              onReport={_report}
            />
          </div>
        </div>
        <div
          className={classNames({
            'mb-6 md:mb-20 board_container': true,
            'select-none': dragLockYn === 'Y',
          })}
        >
          {<HtmlEmbed html={content.cData} />}
        </div>

        {content.contentType === ContentType.EVENT &&
          content.gfUrl != null &&
          content.gfUrl !== '' &&
          content.gfData != null &&
          content.gfData !== '' && (
            <div className="flex items-center justify-center w-full mt-4 mb-4">
              <EventFormButton
                url={content.gfUrl}
                title={content.gfBtnText}
                formJson={content.gfData}
                formKey={content.gfKey}
              />
            </div>
          )}
        {attachments.length > 0 && (
          <FileView files={attachments} isAuthor={false} />
        )}
        {oneononeResponse != null && (
          <div className="comu_reply flex sm:gap-6 gap-2 flex-wrap mt-7 mb-7">
            <span className="reply_tag">답변</span>
            {<HtmlEmbed html={oneononeResponse?.cData} />}
          </div>
        )}
        {content.contentType !== ContentType.OOO && (
          // <CommentList
          //     fetchNextReply={fetchNextReply}
          //     comments={content?.comments}
          //     onCommentSubmit={onCommentSubmit}
          //     onCommentEventEmitted={handleCommentEvent}
          // />
          <CommentListWrap
            cid={data.cid}
            onCommentSubmit={onCommentSubmit}
            totalReply={data.replyCnt}
          />
        )}
      </div>
      <PostBottom
        isEditable={content?.isEditable === true}
        isDeletable={content?.isDeletable === true}
        onEdit={onEdit}
        onDelete={_delete}
      />

      {deletePopup}
      {reportPopup}
    </div>
  );
}
