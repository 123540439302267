import { SearchApi } from '@/components/search/SearchApi';
import { SelectorModal } from '@/components/modal/select/SelectorModal';
import { useState } from 'react';

export default function SortFilter({ selected, onSelect }) {
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <div className="relative mr-3">
      <button
        className="list_array"
        onClick={(e) => {
          e.stopPropagation();
          setMenuOpened(true);
        }}
      >
        {SearchApi.searchSort[selected]}
      </button>
      <SelectorModal
        items={Object.keys(SearchApi.searchSort).map((key) => ({
          value: key,
          label: SearchApi.searchSort[key],
        }))}
        setOpened={setMenuOpened}
        opened={menuOpened}
        onSelect={(value) => {
          onSelect(value);
          setMenuOpened(false);
        }}
      />
    </div>
  );
}
