import Link2 from '@/components/common/transition/Link2';

export default function BreadCrumb({
  menu1,
  menu2,
  menu3,
  className,
  showOnMobile,
  ...props
}) {
  console.log('showonmobile', showOnMobile);
  return (
    <div
      className={`sm:flex justify-between items-center lg:mt-0 mt-6 mb-4 px-4 sm:px-6 md:px-0 ${
        className ?? ''
      } ${showOnMobile ? '' : 'breadcrumb_wrap'}`}
      {...props}
    >
      <ul className="breadcrumb flex">
        <li>
          <Link2 to="/">
            <img src="/images/breadcrumb_ico_home.png" alt="홈" />
          </Link2>
        </li>
        {menu1 != null && menu1 != '' && <li>{menu1}</li>}
        {menu2 != null && menu2 != '' && <li>{menu2}</li>}
        {menu3 != null && menu3 != '' && <li>{menu3}</li>}
      </ul>
    </div>
  );
}
