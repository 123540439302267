import useSubscribe from '@/components/user/my/useSubscribe';
import { useEffect, useRef, useState } from 'react';
import { NotLoggedInError } from '@/components/user/login/LoginApi';
import { toShortCount } from '@/lib/mathUtil';
import Chip from '@/components/common/chip/chip';
import Popup from '@/components/popup/Popup';
import LoginPopup from '@/components/popup/LoginPopup';

/**
 * useSubscribe를 사용해 타 사용자의 구독 여부를 표시하고, 버튼 동작을 한다.
 * @param userNo
 * @param followerCount
 * @param onSubscribeChange
 * @returns {JSX.Element}
 * @constructor
 */
export default function UserSubscribeButton({
  userNo,
  followerCount,
  onSubscribeChange,
}) {
  const [popup, setPopup] = useState(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const { subscribe, unsubscribe, isSubscribed, updateTime } = useSubscribe();
  const [authorSubscribed, setAuthorSubscribed] = useState(false);
  const authorSubscribedRef = useRef();

  useEffect(() => {
    authorSubscribedRef.current = authorSubscribed;
  }, [authorSubscribed]);

  useEffect(() => {
    setAuthorSubscribed(isSubscribed(userNo));
  }, [updateTime]);

  const onAuthorSubscribedChange = async () => {
    try {
      if (!authorSubscribedRef.current) {
        let res = await subscribe(userNo);
        // setAuthorSubscribed(res);
      } else {
        let res = await unsubscribe(userNo);
        // setAuthorSubscribed(res);
      }
      onSubscribeChange?.();
    } catch (e) {
      if (e instanceof NotLoggedInError) {
        setShowLoginPopup(true);
      } else {
        setPopup({
          title: '구독 알림',
          message: e.message,
          okCallback: () => {
            setPopup(null);
          },
        });
      }
    }
  };

  return (
    <>
      <Chip
        className="sm mr-2"
        borderColor="#eee"
        defaultTextColor="#999"
        checked={authorSubscribed}
        onChecked={() => onAuthorSubscribedChange()}
        label={`구독 ${toShortCount(followerCount)}`}
      />
      {popup != null && <Popup {...popup} />}
      <LoginPopup show={showLoginPopup} setShow={setShowLoginPopup} />
    </>
  );
}
