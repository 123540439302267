const ACTIONS = {
  PLAY_PLAYER: 'PLAY_PLAYER',
  DISPOSE_PLAYER: 'DISPOSE_PLAYER',
  SHOULD_DISPOSE_PLAYER: 'SHOULD_DISPOSE_PLAYER',
  SET_PIP_TIME: 'SET_PIP_TIME',
  SHOW_PIP: 'SHOW_PIP',
  CLOSE_PIP: 'CLOSE_PIP',
};

Object.freeze(ACTIONS);

export const showPIP = (param) => ({ type: ACTIONS.SHOW_PIP, param });
export const closePIP = (param) => ({ type: ACTIONS.CLOSE_PIP, param });
export const play = (param, keepPip = false) => ({
  type: ACTIONS.PLAY_PLAYER,
  param,
  keepPip,
});
export const setPipPlayTime = (param) => ({
  type: ACTIONS.SET_PIP_TIME,
  param,
});
export const disposePlayer = (param) => ({
  type: ACTIONS.DISPOSE_PLAYER,
  param,
});
export const shouldDisposePlayer = (param) => ({
  type: ACTIONS.SHOULD_DISPOSE_PLAYER,
  param,
});

/**
 * @type {{isPip: boolean, lastPlayTime: number, option?: WooriPlayerOption}}
 */
const initialState = {
  option: null,
  isPip: false,
  lastPlayTime: 0,
};

export default function playerReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.PLAY_PLAYER: {
      return {
        ...state,
        option: action.param,
        isPip: action.keepPip ? state.isPip : false,
        lastPlayTime: 0,
      };
    }

    case ACTIONS.SET_PIP_TIME: {
      return {
        ...state,
        lastPlayTime: action.param,
      };
    }

    case ACTIONS.DISPOSE_PLAYER: {
      return {
        ...state,
        option: null,
        isPip: false,
        lastPlayTime: 0,
      };
    }

    case ACTIONS.SHOULD_DISPOSE_PLAYER: {
      return {
        ...state,
        option: state.isPip ? state.option : null,
        isPip: state.isPip,
        lastPlayTime: 0,
      };
    }

    case ACTIONS.SHOW_PIP: {
      return {
        ...state,
        isPip: true,
      };
    }

    case ACTIONS.CLOSE_PIP: {
      return {
        ...state,
        isPip: false,
        lastPlayTime: action.param,
      };
    }

    default:
      return state;
  }
}
