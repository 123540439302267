import useWindowDimensions from '@/lib/useWindowDimensions';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { useEffect, useMemo } from 'react';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import useMenu from '@/components/menu/useMenu';
import useLogin from '@/components/user/login/useLogin';
import { openSideBar } from '@/components/sidebar/sidebarReducer';
import Popup from '@/components/popup/Popup';
import { useDispatch, useSelector } from 'react-redux';
import useDebugWeb from '@/components/debug/useDebugWeb';

export default function ThankYouTokenRoutePage() {
  const { sendLog } = useDebugWeb();
  const { isLoggedIn } = useLogin();
  const { isMobile } = useWindowDimensions();
  const { pathname } = useLocation2();
  const navigator = useNavigate2();
  const dispatch = useDispatch();
  const { menuList, findThankYouTokenMenu, getFullPath } = useMenu();
  const { isLoginOpen } = useSelector((state) => state.sidebarReducer);

  const thankYouTokenMenu = useMemo(() => {
    return findThankYouTokenMenu();
  }, [menuList]);

  const redirectOption = useMemo(() => {
    return pathname.replace(/^\/thankyoutoken/, '');
  }, [pathname]);

  useEffect(() => {
    sendLog({
      type: 'thankyoutoken_route',
      pathname,
      thankYouTokenMenu: thankYouTokenMenu?.menuUrl,
      isLoggedIn,
      isMobile,
      redirectOption,
    });
  }, []);

  if (!isLoggedIn) {
    return (
      <div className="h-[80vh]">
        {!isLoginOpen && (
          <Popup
            title="로그인이 필요합니다"
            okMessage="로그인하기"
            okCallback={() => {
              // setShow(false);
              dispatch(openSideBar());
            }}
          />
        )}
      </div>
    );
  }

  if (thankYouTokenMenu == null) {
    navigator('/', { replace: true });
  } else {
    // console.log('thankyoutoken route ismobile', isMobile);
    if (isMobile) {
      navigator(`/?thankyoutoken=${redirectOption}`, {
        replace: true,
        state: { thankyoutoken: redirectOption },
      });
    } else {
      navigator(`/ucms${getFullPath(thankYouTokenMenu)}`, {
        replace: true,
        state: { thankyoutoken: redirectOption },
      });
    }
  }

  return (
    <div className="w-full h-[80vh] flex items-center justify-center">
      <div></div>
    </div>
  );
}
