import { useEffect, useMemo } from 'react';
import PointTableRow from '../../../components/table/point/PointTableRow';
import CommonTable from '@/components/table/CommonTable';
import PageIndicator from '@/components/content/paging/PageIndicator';
import useUsedPointList from '@/components/user/my/useUsedPointList';
import { CircularProgress } from '@mui/material';
import usePage from '@/components/infiniteScroll/usePage';
import { useMyPagePaging } from '@/page/my/tabs/useMyPagePaging';

export default function UsedPoint({} = {}) {
  const [historyPage, _] = usePage();
  const { isLoading, list, load, remove, total, page } = useUsedPointList({
    initialPage: historyPage,
  });

  const { setPage } = useMyPagePaging({ loader: load, page });

  useEffect(() => {
    load();
  }, []);

  if (list == null || isLoading === true) {
    return (
      <div className="w-full h-[80vh] flex items-center justify-center">
        <CircularProgress className="loading" />
      </div>
    );
  }

  return (
    <div className="box_wrap grid sm:gap-3 sm:gap-y-2">
      <div className="min-h-[400px]">
        <CommonTable
          colRatios={[20, 55, 30]}
          ths={['일시', '포인트 사용 내역', '사용포인트']}
        >
          {list.length === 0 && (
            <PointTableRow
              date={'-'}
              point={0}
              description={'포인트 내역이 없습니다.'}
            />
          )}
          {list.map((item, index) => (
            <PointTableRow
              date={item?.itemDt?.replace('T', ' ')}
              point={item?.pvalue}
              description={item?.title}
            />
          ))}
        </CommonTable>
        <p className="f12_w300 co_gray999 mt-4 sm:px-0 px-4">
          ・사용 포인트 내역은 최근 1년까지만 보여집니다.
        </p>
      </div>
      {total > 0 && (
        <PageIndicator
          option={{
            page: page - 1,
            totalCount: total,
            pageSize: 10,
            onPageChange: setPage,
          }}
        />
      )}
    </div>
  );
}
