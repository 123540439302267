import secureLocalStorage from 'react-secure-storage';
import DeviceApi from '@/components/device/DeviceApi';

class AppCompatStorage {
  static cacheMap = {};
  static async getItem(key) {
    let cache = this.cacheMap[key];
    if (cache != null) {
      // console.log('AppCompatStorage getItem cached', key, cache);
      return cache;
    }
    let local = secureLocalStorage.getItem(key);
    if (DeviceApi.getAppBuildNumber() > 170) {
      let appValue = await DeviceApi.getValue(key);
      if (appValue == null) {
        // 웹 -> 앱 동기화
        await DeviceApi.setValue(key, local);
        appValue = local;
      } else {
        // 앱 -> 웹 동기화
        secureLocalStorage.setItem(key, appValue);
      }
      // console.log('AppCompatStorage getItem', key, local, appValue);
      this.cacheMap[key] = appValue;
      return appValue;
    } else {
      // console.log('AppCompatStorage getItem', key, local);
      this.cacheMap[key] = local;
      return local;
    }
  }

  static async setItem(key, value) {
    // console.log('AppCompatStorage setItem', key, value);
    this.cacheMap[key] = value;
    if (value == null || value === '') {
      secureLocalStorage.removeItem(key);
    } else {
      secureLocalStorage.setItem(key, value);
    }
    if (DeviceApi.getAppBuildNumber() > 170) {
      return await DeviceApi.setValue(key, value);
    }
  }
}

export default AppCompatStorage;
