import { useState } from 'react';
import MyPageApi from '@/components/user/my/MyPageApi';

export default function useCommentList() {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);
  const [hasError, setHasError] = useState(false);

  const load = async ({ page = 1, size = 10 } = {}) => {
    setPage(page);
    setIsLoading(true);
    setHasError(false);
    let res = await MyPageApi.userReplyList({ _pageNo: page, _pageSize: size });
    setHasError(res.code !== '00');
    setList(res.data);
    setTotal(res.total ?? 0);
    setIsLoading(false);
    return res.data;
  };

  const remove = async (list) => {
    setIsLoading(true);
    console.log('remove', list);
    for (let i of list) {
      let { rType: type, rId: id } = i;
      if (type?.toLowerCase() === 'reply') {
        await MyPageApi.deleteComment({ replyId: id });
      } else if (type?.toLowerCase() === 'response') {
        await MyPageApi.deleteCommentResponse({ resId: id });
      }
    }
    setIsLoading(false);
    return true;
  };

  return {
    hasError,
    isLoading,
    list,
    total,
    load,
    page,
    remove,
  };
}
