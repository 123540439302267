import { useDispatch } from 'react-redux';
import { closeSearchSideBar } from '../sidebarReducer';

export default function SearchForm({}) {
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeSearchSideBar());
  };

  return (
    <div className="search_wrap sidebar open">
      <div className="inner">
        <button className={'close'} onClick={close}>
          <img src="/images/login_close.png" />
        </button>
        <form className="inputIn">
          <input
            type="search"
            id="input_search"
            className="input_search w700"
            placeholder="어떤 걸 찾고 계세요?"
          />
          <button type="button" className="search_button">
            <img src="/images/search_input_ico.png" alt="검색" />
          </button>
          <button
            type="button"
            id="search_del"
            className="search_del"
            onClick="delKey();"
          >
            <img src="/images/search_del.png" alt="삭제버튼" />
          </button>
        </form>
      </div>
    </div>
  );
}
