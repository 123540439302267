import Popup from '@/components/popup/Popup';
import { useDispatch } from 'react-redux';
import { openSideBar } from '@/components/sidebar/sidebarReducer';

export default function LoginPopup({ show = false, setShow } = {}) {
  const dispatch = useDispatch();
  return (
    <>
      {show && (
        <Popup
          title="로그인이 필요합니다"
          okMessage="로그인하기"
          okCallback={() => {
            setShow(false);
            dispatch(openSideBar());
          }}
        />
      )}
    </>
  );
}
