import CryptoJS from 'crypto-js';

export default function useHmacCrypto() {
  const getHmacHash = (key, message) => {
    const _key = CryptoJS.enc.Utf8.parse(key);
    const _message = CryptoJS.enc.Utf8.parse(message);
    const _messageBase64 = CryptoJS.enc.Base64.stringify(_message);
    let hmac = CryptoJS.HmacSHA256(_messageBase64, _key);
    hmac = CryptoJS.enc.Base64.stringify(hmac);
    return hmac;
  };

  return {
    getHmacHash,
  };
}
