import OutlineLabelField from '../../components/form/OutlineLabelField';
import { useState } from 'react';
import LoginEmailInput from '../../components/user/login/LoginEmailInput';

export default function FormTestPage() {
  const [value0, setValue0] = useState('');
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('is disabled');
  const [value4, setValue4] = useState('');

  return (
    <div style={{ width: '300px' }} className="flex flex-col gap-4">
      <LoginEmailInput value={value4} onChange={(value) => setValue4(value)} />

      <OutlineLabelField
        label="비밀번호"
        type="password"
        className="w-full"
        value={value1}
        onChange={(value) => setValue1(value)}
      />
      <OutlineLabelField
        label="비밀번호"
        type="password"
        className="w-full error"
        message={value2}
        value={value2}
        onChange={(value) => setValue2(value)}
      />

      <OutlineLabelField
        label="비밀번호"
        type="password"
        className="w-full"
        disabled={true}
        value={value3}
        message={value3}
        onChange={(value) => setValue3(value)}
      />
    </div>
  );
}
