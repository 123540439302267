export default function TermPopupButton({ onAgree, onClose }) {
  return (
    <div className="flex gap-4 w-full justify-center mt-4">
      <button
        className="pri_btn !flex-1"
        onClick={() => {
          onAgree(false);
          onClose();
        }}
      >
        동의하지 않음
      </button>
      <button
        className="pri_btn !flex-1"
        onClick={() => {
          onAgree(true);
          onClose();
        }}
      >
        동의함
      </button>
    </div>
  );
}
