import MyInfoCard from '../../components/user/my/MyInfoCard';
import { useEffect, useMemo, useState } from 'react';
import ChipTab from '../../components/common/chip/ChipTab';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { myInfoMenuMap } from '../../app/config/myinfoMenuMap';
import Config from '../../app/config/config';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import useMenu, { menuType } from '@/components/menu/useMenu';
import useLogin from '@/components/user/login/useLogin';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { ErrorBoundary } from '@/components/common/ErrorBoundary';

export default function MyPageContainer({} = {}) {
  const navigator = useNavigate2();
  const { pathname, query } = useLocation2();
  const { isLoading, menuList, findMenu } = useMenu();
  const { isLoggedIn, data, isLoading: isLoginLoading } = useLogin();

  const _childMenuList = useMemo(() => {
    return menuList
      .filter(
        (it) =>
          it.menuType === menuType.my &&
          it.useYN === 'Y' &&
          it.menuUrl !== '' &&
          it.menuLevel === 2 &&
          it.parentId == Config.myMenuId &&
          myInfoMenuMap[it.menuUrl] != null
      )
      .filter(_ttMenuUserAuthFct)
      .sort((a, b) => a.sortSeq - b.sortSeq);
  }, [menuList]);

  /** 2024.01.15 땡큐토큰 관리 특정 사용자만 표출 */
  function _ttMenuUserAuthFct(menu) {
    if (menu.menuUrl === 'ttmanage') {
      return Config.myMenuTTAuth.find((it) => it === data.userNo) > 0;
    }
    return true;
  }

  const [currentTab, setCurrentTab] = useState(null);

  useEffect(() => {
    // setCurrentTab()
    let s = pathname.split('/');
    let childPath = s[s.length - 1]?.split('?')?.[0];
    let existChildMenu = _childMenuList.find(
      (it) => it.menuUrl.replace('/', '') === childPath
    );
    setCurrentTab(existChildMenu?.menuId ?? _childMenuList?.[0]?.menuId);
  }, [_childMenuList]);

  const [_, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (currentTab == null) {
      return;
    }
    const url = findMenu(currentTab)?.menuUrl;
    let s = pathname.split('/');
    let childPath = s[s.length - 1]?.split('?')?.[0];
    if (url === childPath) return;

    if (url != null)
      navigator(`${url}?page=${query.page ?? 0}`, { replace: true });
  }, [currentTab]);

  if (isLoggedIn === false) {
    window.location.href = '/';
    return '';
  }

  if (isLoading || _childMenuList == null) {
    return '';
  }

  return (
    <div id="contents">
      <div className="mypage_wrap pt-12">
        <div className="inner grid grid-cols-4 gap-8 pt-6 lg:pb-12 pb-24">
          <MyInfoCard />
          <div className="grid-cols-3 col-span-4 lg:col-span-3">
            <ChipTab
              align={'start'}
              categories={_childMenuList}
              category={currentTab}
              setCategory={(id) => {
                // page: 0인 스택을 쌓기 (탭 전환 발생하면 쌓인 스택에 replace)
                setSearchParams({ page: '0' });
                setCurrentTab(id);
              }}
            />

            <ErrorBoundary
              key={`my_page_error_boundary_${pathname}`}
              className="md:!h-[400px]"
            >
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
}
