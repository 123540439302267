import { useEffect, useState } from 'react';
import ContentApi from '@/components/content/ContentApi';
import { bitArrayToHex, hexToBitArray } from '@/lib/textUtil';
import useLogin from '@/components/user/login/useLogin';
import useDebugWeb from '@/components/debug/useDebugWeb';
import { useDispatch } from 'react-redux';
import {
  BugReportPopupParam,
  closeBugReportPopup,
  showBugReportPopup,
} from '@/components/popup/bugreport/bugReportReducer';
import { shouldReportViewTime } from '@/app/config/ContentType';

export default function useContentViewTimeReporter({ data, eduInfo }) {
  const dispatch = useDispatch();
  const { isLoggedIn } = useLogin();
  const { sendLog } = useDebugWeb();
  const [errorState, setErrorState] = useState({
    errorSent: false,
    errorList: [],
  });
  const getCurrentVideoDuration = () => {
    return Math.floor(getPlayer().duration());
  };
  const getCurrentVideoPlayTime = () => {
    return Math.floor(getPlayer().currentTime());
  };

  const getPlayer = () => {
    return window.WooriPlayer.instance?.wooriPlayer.player;
  };
  let timer;
  let startTime;

  useEffect(() => {
    setErrorState({
      errorSent: false,
      errorList: [],
    });
    if (isLoggedIn) {
      if (shouldReportViewTime(data?.contentType)) {
        timer = setInterval(() => {
          updateViewTime(getCurrentVideoPlayTime());
        }, 5000);
      }
    }
    return () => {
      if (timer != null) {
        clearInterval(timer);
        timer = null;
        startTime = null;
      }
    };
  }, [data, eduInfo, isLoggedIn]);

  useEffect(() => {
    if (errorState.errorSent === false && errorState.errorList.length >= 3) {
      let list = errorState.errorList;
      let last = list[list.length - 1];
      let req = last.req;
      if (req == null) {
        console.error('error check req is null');
        return;
      }
      if (req.totalSec <= req.viewSec) {
        setErrorState((p) => ({
          errorSent: true,
          errorList: p.errorList,
        }));
        let title = '채널W 버그 리포팅';
        let sendList = [list[0], last];
        let body = `채널W 오류로인해 아래 내용을 전달합니다.\n\n\n----------\n${JSON.stringify(
          sendList
        )}`;
        let link = `mailto:master.woori@gmail.com?subject=${encodeURIComponent(
          title
        )}&body=${encodeURIComponent(body)}`;
        dispatch(
          showBugReportPopup(
            new BugReportPopupParam({
              title: '학습 진도 반영 오류',
              body: '오류로 인해 학습 진도가 반영 되지 않았습니다. <br/>메일을 통해 오류를 공유 하시겠습니까?',
              cancelButton: '닫기',
              okButton: '메일보내기',
              okCallback: () => {
                let aTag = document.createElement('a');
                aTag.href = link;
                aTag.target = '_blank';
                document.body.append(aTag);
                aTag.click();
                aTag.remove();
                dispatch(closeBugReportPopup());
              },
              cancelCallback: () => {
                dispatch(closeBugReportPopup());
              },
            })
          )
        );
      }
    }
  }, [errorState]);

  const updateViewTime = async (time) => {
    let res;
    let error;
    let currentViewTimeInfo;
    try {
      if (isLoggedIn === false) {
        return;
      }
      // console.log('updateViewTime throttle', time);

      let content = await ContentApi.userViewTimeInfo({ cid: data.cid });
      if (content == null || content.code !== '00') {
        console.warn('content is null');
        return;
      }

      let durationSec = getCurrentVideoDuration();
      if (durationSec == null) {
        console.warn('Slow loading.. Skip time update');
        return;
      }
      let segmentSize = Math.ceil(durationSec / 10);
      let segmentSizeWithPadding = Math.ceil(segmentSize / 4) * 4; // hex로 나타내므로, 4의 배수로 맞춰줌
      let segmentList = Array.from(
        { length: segmentSizeWithPadding },
        (_, __) => 0
      );
      currentViewTimeInfo = content?.viewTime?.[0];
      if (currentViewTimeInfo == null) {
        currentViewTimeInfo = {
          cid: data.cid,
          viewTag: '',
          totalSec: durationSec,
          lastSec: 0,
          viewSec: 0,
          eduId: eduInfo?.eduId ?? -1,
        };
      } else {
        let viewTag = currentViewTimeInfo.viewTag;
        let existSegmentList = hexToBitArray(viewTag);
        if (existSegmentList.length > segmentList.length) {
          // 영상 길이가 짧아졌을 경우 기존 segment를 잘라냄
          segmentList = [...existSegmentList.slice(0, segmentSizeWithPadding)];
        } else if (existSegmentList.length < segmentList.length) {
          // 영상 길이가 길어졌을경우 기존 있던부분 까지만 덮어씀
          segmentList = [
            ...existSegmentList,
            ...segmentList.slice(existSegmentList.length),
          ];
        } else {
          segmentList = [...existSegmentList];
        }

        // 길이가 4의 배수가 아니면 0으로 채워줌
        if (segmentList.length % 4 !== 0) {
          segmentList = [
            ...segmentList,
            ...Array.from(
              { length: 4 - (segmentList.length % 4) },
              (_, __) => 0
            ),
          ];
        }
      }

      let targetSegment = Math.floor(time / 10);
      if (eduInfo != null) {
        // edu인 경우 스킵 불가능하니까 처음부터 지금까지 모두 시정상태로 변경
        segmentList.fill(1, 0, targetSegment + 1);
      } else {
        segmentList[targetSegment] = 1;
      }

      if (time >= durationSec) {
        // 영상 끝이면 마지막 자투리 블럭 처리
        if (segmentList.length > targetSegment) {
          segmentList[segmentList.length - 1] = 1;
        }
      }

      currentViewTimeInfo.viewTag = bitArrayToHex(segmentList);
      currentViewTimeInfo.totalSec = durationSec;
      currentViewTimeInfo.lastSec = Math.ceil(time);
      let viewSec = segmentList.filter((it) => it === 1).length * 10;
      let isFull = viewSec >= durationSec;
      currentViewTimeInfo.viewSec = isFull ? durationSec : viewSec;
      currentViewTimeInfo.eduId = eduInfo?.eduId ?? -1;
      res = await ContentApi.userViewTimeUpdate(currentViewTimeInfo);
    } catch (e) {
      error = e;
      res = e?.response?.data;
      console.log(e);
    }
    let logData = {
      type: 'updateViewTime',
      req: currentViewTimeInfo ?? {
        data: error?.config?.data,
        baseUrl: error?.config?.baseUrl,
        url: error?.config?.url,
        method: error?.config?.method,
      },
      res: res,
      error: error?.message,
    };

    sendLog(logData);

    if (eduInfo != null) {
      if (error != null || res.code !== '00') {
        if (errorState.errorSent === false) {
          console.log(errorState);
          setErrorState((p) => ({
            ...p,
            errorList: [...p.errorList, logData],
          }));
        }
      }
    }
    // console.log('updateViewTime res', res);
  };

  return {
    updateViewTime,
  };
}
