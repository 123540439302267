import { useMemo, useState } from 'react';
import Config from '../../app/config/config';
import JoinApi from '../join/JoinApi';

export default function useNicknameValidate(__value, originValue) {
  const [value, _setValue] = useState(__value);
  const [state, setState] = useState({
    message: '',
    hasError: false,
    isValidate: true,
  });

  const setValue = (v) => {
    _setValue(v);
    if (v === originValue && originValue != null) {
      setState({
        hasError: false,
        isValidate: true,
        message: NicknameState.valid,
      });
    } else if (v == null || v === '') {
      setState({
        hasError: false,
        isValidate: true,
        message: NicknameState.empty,
      });
    } else if (
      v.length < Config.nicknameMinLength ||
      v.length > Config.nicknameMaxLength
    ) {
      setState({
        hasError: false,
        isValidate: false,
        message: NicknameState.notValidFormat,
      });
    } else {
      setState({
        hasError: false,
        isValidate: false,
        message: NicknameState.needValidate,
      });
    }
  };

  const validate = async () => {
    let isValidate = false;
    if (
      value == null ||
      value.length < Config.nicknameMinLength ||
      value.length > Config.nicknameMaxLength
    ) {
      setState({
        hasError: false,
        isValidate,
        message: NicknameState.notValidFormat,
      });
    } else if (value === originValue) {
      isValidate = true;
      setState({
        hasError: false,
        isValidate,
        message: NicknameState.valid,
      });
    } else {
      let validateRes = await JoinApi.checkNickUnique(value);
      let dupCount = validateRes?.data?.data?.[0]?.cnt;
      if (dupCount == null) {
        setState({
          hasError: true,
          isValidate,
          message: NicknameState.error,
        });
      } else if (dupCount > 0) {
        setState({
          hasError: false,
          isValidate,
          message: NicknameState.exist,
        });
      } else {
        isValidate = true;
        setState({
          hasError: false,
          isValidate,
          message: NicknameState.valid,
        });
      }
    }
    return isValidate;
  };
  return {
    ...state,
    fieldName: 'nickname',
    value,
    validate,
    setValue,
  };
}

export const NicknameState = {
  empty: '',
  error: '오류가 발생하였습니다. 잠시 후 시도해 주세요',
  needValidate: '중복 확인을 해주세요',
  notValidFormat: '닉네임은 3~10자 사이입니다.',
  valid: '',
  exist: '이미 사용중인 닉네임 입니다.',
};
