const ACTIONS = {
  SET_POP_SHOWED: 'SET_POP_SHOWED',
  SET_IS_TRY_BIO_LOGIN: 'SET_IS_TRY_BIO_LOGIN',
};

Object.freeze(ACTIONS);

export const setPopupShowed = (state) => ({
  type: ACTIONS.SET_POP_SHOWED,
  state,
});

export const setIsTryBioLogin = (state) => ({
  type: ACTIONS.SET_IS_TRY_BIO_LOGIN,
  state,
});

const initialState = {
  registerPopupShowed: false,
  isTryingBioLogin: false,
};

export default function easyLoginReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_POP_SHOWED: {
      return {
        ...state,
        registerPopupShowed: action.state,
      };
    }
    case ACTIONS.SET_IS_TRY_BIO_LOGIN: {
      return {
        ...state,
        isTryingBioLogin: action.state,
      };
    }

    default:
      return state;
  }
}
