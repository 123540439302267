import UcmsChipTab from '@/components/ucms/common/category/UcmsChipTab';
import CollapsableChip from '@/components/ucms/common/category/CollapsableChip';
import { Collapse } from '@mui/material';
import ChipTab from '@/components/common/chip/ChipTab';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import useMenu from '@/components/menu/useMenu';

export default function BoardCategoryChipTab({} = {}) {
  const { menuId, menuId2 } = useParams();
  const navigate = useNavigate2();
  const {
    menuList,
    findMenu,
    findChildren,
    findChildrenWithoutChild,
    findChildrenWithChild,
    shouldShowAllMenu,
  } = useMenu();
  const [openChildMenuTarget, setOpenChildMenuTarget] = useState(null);

  // 하위메뉴 있는 항목은 CollapsableChip으로 사용
  const collapsableChipMenu = useMemo(() => {
    return findChildrenWithChild(menuId);
  }, [menuId, menuList]);

  const useShowAllTab = useMemo(() => {
    return shouldShowAllMenu(Number(menuId));
  }, [menuId]);

  const selectedMenuId = useMemo(() => {
    return menuId2;
  }, [menuId2]);

  const rootMenu = useMemo(() => {
    return findMenu(menuId);
  }, [menuId]);

  // 선택한 접이식 메뉴의 하위 메뉴
  const childMenuList = useMemo(() => {
    if (openChildMenuTarget == null || collapsableChipMenu.length === 0) {
      return [];
    } else {
      return findChildren(openChildMenuTarget);
    }
  }, [openChildMenuTarget, menuId]);

  // 하위 메뉴 없는 메뉴만 chip tab으로 사용
  const chipTabMenu = useMemo(() => {
    let res = findChildrenWithoutChild(menuId);
    let rootMenu = findMenu(menuId);
    if (useShowAllTab && rootMenu != null) {
      rootMenu = {
        ...rootMenu,
        menuNm: '전체',
      };
      return [rootMenu, ...res];
    } else {
      return res;
    }
  }, [useShowAllTab, menuList, menuId]);

  // 선택된 2차메뉴가 없으면 redirect(replace)
  useEffect(() => {
    if (selectedMenuId == null) {
      navigate(`/ucms/${menuId}/${chipTabMenu?.[0]?.menuId ?? menuId}`, {
        replace: true,
      });
    }
  }, [selectedMenuId]);

  useEffect(() => {
    // url 접근시, 해당 메뉴가 접혀있으면 열어준다.
    if (
      menuId2 != null &&
      collapsableChipMenu != null &&
      collapsableChipMenu.length > 0
    ) {
      let targetCollapseChipMenu = collapsableChipMenu.find(
        (it) =>
          findChildren(it.menuId).find((it) => it.menuId == menuId2) != null
      );
      if (targetCollapseChipMenu != null) {
        setOpenChildMenuTarget(targetCollapseChipMenu.menuId);
      }
    }
  }, [menuId2, collapsableChipMenu]);

  const onMenuChange = (id) => {
    navigate(`/ucms/${menuId}/${id}?page=0`);
  };

  // 단일 하위 메뉴에, 메뉴명이 상위 메뉴와 같으면 탭 표시 안함
  if (chipTabMenu.length === 1 && collapsableChipMenu.length === 0) {
    let child = chipTabMenu[0];
    if (rootMenu != null && child.menuNm === rootMenu.menuNm) {
      return '';
    }
  }

  return (
    <>
      {menuId != 2 && (
        <UcmsChipTab
          categories={chipTabMenu}
          category={selectedMenuId}
          setCategory={onMenuChange}
        >
          {collapsableChipMenu.map((menu, index) => (
            <CollapsableChip
              key={`collapsableMenu ${menu.menuId}_${index}`}
              checked={openChildMenuTarget == menu.menuId}
              onChecked={(value) => {
                if (value) {
                  setOpenChildMenuTarget(menu.menuId);
                } else {
                  setOpenChildMenuTarget(null);
                }
              }}
              label={menu.menuNm}
            />
          ))}
        </UcmsChipTab>
      )}

      <Collapse
        in={openChildMenuTarget != null && collapsableChipMenu.length >= 0}
      >
        <ChipTab
          categories={childMenuList}
          category={selectedMenuId}
          setCategory={onMenuChange}
        />
      </Collapse>
    </>
  );
}
