import { apiInstance } from '@/app/config/network';
import LoginUtil from './LoginUtil';
import AuthUtil from '@/components/auth/AuthUtil';

export class NotLoggedInError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NotLoggedInError';
  }
}

export default class LoginApi {
  static async login({ id, pw, hmacHash, timestamp, ezAuthHash }) {
    let res = await apiInstance.post(
      '/api/cms/signin/signin',
      {
        userId: id,
        ...(pw
          ? await AuthUtil.getLoginKey(id, pw)
          : hmacHash
          ? { hmacHash, timestamp }
          : { ezAuthHash, timestamp }),
        ...(await LoginUtil.getDeviceInfo()),
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    await LoginUtil.parseResponse(res);
    return res;
  }

  static async sessionLogin({ userId, sessionKey, apiAuthKey }) {
    let res = await apiInstance.post(
      '/api/cms/user/getSessionData',
      {
        userId,
        sessionKey,
        apiAuthKey,
        ...(await LoginUtil.getDeviceInfo()),
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    console.log(
      'sessionLogin',
      userId,
      sessionKey,
      apiAuthKey,
      JSON.stringify(res)
    );
    await LoginUtil.parseResponse(res);
    return res;
  }

  static async bioLogin({ id, hmacHash, timestamp }) {
    return await this.login({ id, hmacHash, timestamp });
  }

  static async ezPinLogin({ id, ezAuthHash, timestamp }) {
    return await this.login({ id, ezAuthHash, timestamp });
  }
}
