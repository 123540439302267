import { useEffect } from 'react';

export default function useNoContextMenu() {
  useEffect(() => {
    // define a custom handler function
    // for the contextmenu event
    const handleContextMenu = (e) => {
      // console.log('context menu')
      // prevent the right-click menu from appearing
      e.preventDefault();
    };

    // attach the event listener to
    // the document object
    if (document.querySelector('.select-none') != null) {
      document.querySelectorAll('.select-none').forEach((el) => {
        el.addEventListener('contextmenu', handleContextMenu);
      });
    }

    // clean up the event listener when
    // the component unmounts
    return () => {
      // document.querySelectorAll('.select-none').forEach((el) => {
      //     el.removeEventListener("contextmenu", handleContextMenu);
      // })
    };
  }, []);
}
