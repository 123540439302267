import PageIndicator from '@/components/content/paging/PageIndicator';
import ProfileImage from '@/components/user/ProfileImage';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import useFollower from '@/components/user/my/useFollower';
import { useMyPagePaging } from '@/page/my/tabs/useMyPagePaging';

export default function Follower() {
  const { isLoading, page, list, load, total } = useFollower();

  useEffect(() => {
    load();
  }, []);
  const { setPage } = useMyPagePaging({
    loader: load,
    page,
    isStartFromOne: false,
  });

  return (
    <div>
      <h5 className="mb-6 justify-between flex my_tit_wrap">
        <div className="my_tit">
          구독자<span className="co_orange ml-2 my_tit_num">{total}</span>
        </div>
      </h5>
      <div className="box_wrap grid sm:gap-3 sm:gap-y-2">
        <div className="lg:min-h-[400px]">
          {isLoading && (
            <div className="h-72 w-full flex items-center justify-center">
              <CircularProgress className="loading" />
            </div>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-4 ">
            {!isLoading &&
              list?.map((it) => (
                <label
                  key={it.userNo}
                  htmlFor={it.userNo}
                  className="flex following_box items-center"
                >
                  <ProfileImage
                    className="profile_img mr-6"
                    profileUrl={it.profileUrl}
                    profileSt={it.profileSt}
                    userNo={it?.userNo}
                  />
                  <div>
                    <p>{it.nickNm}</p>
                    <p className="f14_w300">우리은행</p>
                  </div>
                </label>
              ))}
          </div>
        </div>

        <PageIndicator
          forcePage={true}
          option={{
            page,
            totalCount: total,
            pageSize: 9,
            showingPageCount: 5,
            onPageChange: setPage,
          }}
        />
      </div>
    </div>
  );
}
