export default function TagView({ tag, ...props }) {
  return (
    <span
      className={`notice_tag ${props?.className ?? ''} ${
        Tag[tag]?.className ?? ''
      }`}
    >
      {Tag[tag].label}
    </span>
  );
}

// value 필드는 더미데이터 오타를 방지하기 위해 사용합니다!
const Tag = {
  notice: { label: '공지', value: 'notice' },
  inquiryWaiting: {
    label: '답변대기',
    value: 'inquiryWaiting',
    className: 'wait',
  },
  inquiryDone: { label: '답변완료', value: 'inquiryDone', className: 'clear' },
  reported: { label: '신고', value: 'reported' },
};

Object.freeze(Tag);
export { Tag };
