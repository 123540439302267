import { Box, Drawer, Slide } from '@mui/material';
import HeaderSearchInput from './HeaderSearchInput';
import { useEffect, useState } from 'react';
import useWindowDimensions from '@/lib/useWindowDimensions';
import useNavigate2 from '@/components/common/transition/useNavigate2';

export default function HeaderSearchIcon() {
  const { isMobile } = useWindowDimensions();
  const navigator = useNavigate2();

  const [keyword, setKeyword] = useState('');
  const [isPCOpen, setPCOpen] = useState(false);
  const [isMobileOpen, setMobileOpen] = useState(false);

  const onSearch = (str, menuId) => {
    if (isMobile) {
      setMobileOpen(false);
    }
    if (menuId != null) {
      navigator(`/search/${str}?menuId=${menuId}`);
    } else {
      navigator(`/search/${str}`);
    }
  };

  useEffect(() => {
    setMobileOpen(isPCOpen);
    setPCOpen(isMobileOpen);
  }, [isMobile]);

  return (
    <>
      <div className="search_button3 cursor-pointer">
        <img
          className="search_btn"
          src="/images/search_nav_input.png"
          alt="검색버튼"
          onClick={() => setPCOpen(true)}
        />
        <img
          className="search_btn_m"
          src="/images/search_nav_input.png"
          alt="모바일검색"
          onClick={() => setMobileOpen(true)}
        />
      </div>

      {/*PC*/}
      <div className="new_search active">
        <Slide direction="left" in={isPCOpen} mountOnEnter unmountOnExit>
          <Box>
            <HeaderSearchInput
              keyword={keyword}
              setKeyword={setKeyword}
              onSearch={onSearch}
              onClose={() => setPCOpen(false)}
            />
          </Box>
        </Slide>
      </div>

      {/*모바일*/}
      <Drawer
        anchor="top"
        open={isMobileOpen}
        mountOnEnter
        unmountOnExit
        onClose={() => setMobileOpen(false)}
      >
        {/*<div className="new_search_m block">*/}

        <div className="flex items-center justify-center h-16">
          <HeaderSearchInput
            keyword={keyword}
            setKeyword={setKeyword}
            onSearch={onSearch}
            onClose={() => setMobileOpen(false)}
          />
        </div>
      </Drawer>
    </>
  );
}
