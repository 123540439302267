import { useEffect, useMemo } from 'react';
import { CommonSearchParam, SearchApi } from '@/components/search/SearchApi';
import { LoadType } from '@/components/ucms/search/useUcmsSearch';
import { makeFlatPages } from '@/components/infiniteScroll/useInfiniteQueryWithInitialData';
import { normalizeNFC } from '@/lib/stringUtil';
import useInfiniteQueryWithInitialData from '@/components/infiniteScroll/useInfiniteQueryWithInitialData';

const InfiniteSearchQueryKey = {
  all: ['InfiniteSearchQueryKey'],
  filtered: (filter) => [...InfiniteSearchQueryKey.all, 'filtered', filter],
  firstData: (filter) => [...InfiniteSearchQueryKey.all, 'firstData', filter],
};
export default function useCategorySearch({
  initialPage,
  searchType,
  keyword,
  sort,
}) {
  console.log(
    'useCategorySearch',
    initialPage,
    searchType.searchType,
    keyword,
    sort
  );

  const {
    initLoadCompleted,
    setParamAndReload,
    initialDataQuery,
    infiniteQuery,
    ...rest
  } = useInfiniteQueryWithInitialData({
    QueryKeyMap: InfiniteSearchQueryKey,
    dataFn: SearchApi.commonSearch,
    initialParam: {
      page: initialPage > 1 ? initialPage - 1 : initialPage,
      size: 8,
      keyword: normalizeNFC(keyword),
      sort: sort, //SearchApi.searchSort.recent,
      loadType: LoadType.refresh,
      searchType: searchType.searchType,
    },
    ParamClass: CommonSearchParam,
  });

  const list = useMemo(() => {
    return [
      ...(initialDataQuery?.data?.list ?? []),
      ...(makeFlatPages(infiniteQuery.data?.pages ?? []) ?? []),
    ];
  }, [initialDataQuery?.data?.list, infiniteQuery.data?.pages]);

  useEffect(() => {
    setParamAndReload('searchType', searchType.searchType);
    setParamAndReload('sort', sort);
  }, [searchType.searchType, sort]);

  return {
    ...rest,
    initLoadCompleted,
    list,
  };
}
