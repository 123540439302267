export default function PointTableRow({
  date,
  category,
  description,
  point,
} = {}) {
  return (
    <tr className="tr_wrap grid-cols-12 grid-rows-full">
      {date != null && (
        <td className="td_sub_tit col-span-full row-start-3 mt-3 ">{date}</td>
      )}
      {category != null && (
        <td className="col-span-full col-start-1 row-start-1 mb-1 td_join">
          {category}
        </td>
      )}
      {description != null && (
        // <td className="text-left td_tit col-start-1 col-span-12 row-start-2">{description}</td>
        <td className="td_tit col-start-1 col-span-12 row-start-2">
          {description}
        </td>
      )}
      {point != null && (
        <>
          {point >= 0 && (
            <td className="co_pri col-start-11 row-start-2 point">
              {point.toLocaleString()}
            </td>
          )}
          {point < 0 && (
            <td className="col-start-11 row-start-2 point point2">
              {(point * -1).toLocaleString()}
            </td>
          )}
        </>
      )}
    </tr>
  );
}
