import { useQuery } from 'react-query';
import CompanyApi from '@/components/user/company/CompanyApi';

const QueryKey = {
  all: ['rankSearch'],
  filtered: (compCd) => [...QueryKey.all, compCd],
};

export default function (compCd) {
  return useQuery(QueryKey.filtered(compCd), {
    queryFn: async ({ queryKey: [_, _compCd] }) =>
      (await CompanyApi.getRankList(_compCd)).data.map((data) => ({
        ...data,
        rankTypeCd: data.rankTypeCd.trim(),
      })),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
