import UseAuth from '@/components/auth/useAuth';
import LoginUtil from '@/components/user/login/LoginUtil';
import UseAESCrypto from '@/components/user/useAESCrypto';

export default class AuthUtil {
  static async getOneTimeSessionKey() {
    const { getOneTimeSessionKey } = UseAuth();
    const apiAuthKey = await getOneTimeSessionKey();
    if (apiAuthKey == null) {
      return {};
    }
    return {
      userId: await LoginUtil.getUserId2(), // onetimekey
      sessionKey: await LoginUtil.getSessionKey(), // onetimekey
      apiAuthKey: apiAuthKey,
    };
  }

  static async getLoginKey(id, pw) {
    const { getLoginKey } = UseAuth();
    return {
      apiAuthKey: await getLoginKey(id, pw),
    };
  }

  static async getUserTag() {
    const { encrypt } = UseAESCrypto();
    const userNo = await LoginUtil.getUserNo2(); // usertag
    const userId = await LoginUtil.getUserId2(); // usertag
    const sessionKey = await LoginUtil.getSessionKey(); // usertag
    const now = Math.floor(new Date().getTime() / 1000);
    // const now = new Date().getTime();
    const key = `${userNo}:${userId}:${sessionKey}:${now}`;
    const userTag = encrypt(key);
    console.log('getUserTag', key, userTag);
    return {
      userId,
      sessionKey,
      userTag,
    };
  }
}
