import { useRef, useState } from 'react';
import classNames from 'classnames';
import OutlineLabelField from '../../form/OutlineLabelField';
import { IconButton } from '@mui/material';
import useCompanyList from '@/components/user/company/useCompanyList';

export default function LoginEmailInput({
  id = 'email_input',
  name,
  value,
  onChange,
  className,
  message,
  onKeyDown,
  disabled,
  children: rightElement,
} = {}) {
  const companyList = useCompanyList({ excludeNormal: true });
  const [isOpen, setOpen] = useState(false);
  const inputRef = useRef();
  // const [isRunning, setIsRunning] = useState(false);
  const triesRef = useRef(0);

  // useInterval(() => {
  //     if (triesRef.current >= 2) console.error("leak detected!");
  //     inputRef.current?.setAttribute('type', 'text');
  //     if (inputRef.current?.selectionStart === 0 || triesRef.current >= 10) {
  //         setIsRunning(false);
  //         triesRef.current = 0;
  //     } else {
  //         inputRef.current?.setSelectionRange(0, 0);
  //         inputRef.current?.setAttribute('type', 'email');
  //         triesRef.current++;
  //     }
  // }, isRunning ? 50 : null);

  const selectEmail = (email) => {
    // 직접입력
    if (email === '') {
    } else {
      onChange?.(`${inputRef.current.value?.split('@')?.[0]}${email}`);
    }
    setOpen(false);
    inputRef.current?.focus();
    // setIsRunning(true);
  };

  return (
    <div className="input_box single">
      <OutlineLabelField
        id={id}
        name={name}
        autoComplete="on"
        label="이메일 아이디"
        inputRef={inputRef}
        className={`${className}`}
        value={value}
        message={message}
        onChange={(value) => onChange?.(value)}
        onKeyDown={onKeyDown}
        disabled={disabled}
        type="email"
        customButton={
          <>
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => setOpen(!isOpen)}
              size="small"
              edge="end"
            >
              <img
                className="input_icon input_arrow_down"
                src="/images/input_arrow_down.png"
                alt="메일목록"
              />
            </IconButton>
            {rightElement}
          </>
        }
      />
      <ul
        className={classNames({
          input_select_box: true,
          active: isOpen,
        })}
      >
        <li className="domain_li" onClick={() => selectEmail('')}>
          <span className="domain">직접입력</span>
          <span className="f12_w300"></span>
        </li>
        {
          // 0번 일반사용자 제외
          companyList
            ?.sort((a, b) => a.compId - b.compId)
            ?.map((it) => (
              <li
                key={it.compId}
                className="domain_li"
                onClick={() => selectEmail(`@${it.compDomain}`)}
              >
                <span className="domain">{it.compDomain}</span>
                <span className="f12_w300">{it.compNm}</span>
              </li>
            ))
        }
      </ul>
    </div>
  );
}
