import ThumbnailCard from '../../../components/card/ThumbnailCard';
import { useMemo } from 'react';
import PageIndicator from '../../../components/content/paging/PageIndicator';
import MyPageHeader from '@/page/my/MypageHeader';
import { SearchApi } from '@/components/search/SearchApi';
import { CircularProgress } from '@mui/material';
import { useMyPagePaging } from '@/page/my/tabs/useMyPagePaging';
import useMyLMSSearch from '@/components/lms/useMyLMSSearch';

export default function MyLMS({}) {
  const { isError, isLoading, page, list, load, total } = useMyLMSSearch();

  const { setPage } = useMyPagePaging({
    loader: load,
    page,
    isStartFromOne: false,
  });

  return (
    <>
      <MyPageHeader
        option={{
          title: '학습영상',
          count: total,
        }}
      />
      {isError && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <span>오류가 발생했습니다.</span>
        </div>
      )}
      {!isError && isLoading && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <CircularProgress className="loading" />
        </div>
      )}
      {!isError && !isLoading && (list == null || list?.length === 0) && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <span>참여 가능한 학습이 없습니다.</span>
        </div>
      )}
      {!isError && !isLoading && list?.length > 0 && (
        <div>
          <div className="box_wrap grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 sm:gap-y-12 gap-y-12">
            {list.map((item, index) => (
              <div key={`thunbmail_card_${index}`} className="col">
                <ThumbnailCard
                  option={{
                    ...item,
                    id: item.cid,
                    author: item.category,
                    showCounter: false,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {total > 0 && (
        <PageIndicator
          option={{
            page: page,
            totalCount: total,
            pageSize: 9,
            showingPageCount: 5,
            onPageChange: (p) => setPage(p),
          }}
        />
      )}
    </>
  );
}
