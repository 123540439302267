import VideoOptionRadio from '@/components/modal/videoOption/VideoOptionRadio';

export default function VideoOptionRadioList({ options, value, onClick }) {
  return (
    <div>
      {options.map((option, index) => (
        <VideoOptionRadio
          option={option}
          isSelected={value.value === option.value}
          onClick={(e) => {
            onClick?.(option);
          }}
        />
      ))}
    </div>
  );
}
