import CommentItem from './CommentItem';
import useLogin from '@/components/user/login/useLogin';
import { forwardRef, useState } from 'react';
import CommentWriteItem from '@/components/comment/CommentWriteItem';
import LoginPopup from '@/components/popup/LoginPopup';
import RefreshIcon from '@mui/icons-material/Refresh';

/**
 * @param {Comment[]} comments
 * @param onCommentSubmit
 * @return {JSX.Element}
 */
const CommentList = forwardRef(
  (
    { total, comments, onCommentSubmit, onCommentEventEmitted, commentQuery },
    ref
  ) => {
    const { isLoggedIn } = useLogin();
    const [showLoginPopup, setShowLoginPopup] = useState(false);

    const addComment = async (value) => {
      if (!isLoggedIn) {
        setShowLoginPopup(true);
        return false;
      }
      if (value.trim() == '') {
        return false;
      }
      await onCommentSubmit(value);
      return true;
    };

    // 댓글 새로고침
    const _refetch = () => {
      commentQuery.refetch();
    };

    console.log(comments);

    return (
      <div className="py-8 border-t">
        <h5 className="mb-4">
          댓글 {total?.toLocaleString()}개
          <RefreshIcon
            onClick={_refetch}
            style={{
              paddingBottom: '2px',
              cursor: 'pointer',
            }}
          />
        </h5>
        <div className="comment_filed_wrap">
          <div className="comment_filed_inner mb-6">
            <div className="flex justify-between">
              <CommentWriteItem
                initialValue={''}
                isEdit={false}
                isResponsive={false}
                useCancel={false}
                onSubmit={addComment}
              />
            </div>
          </div>
          <div ref={ref}>
            {comments.map((comment, idx) => (
              <>
                <CommentItem
                  key={`comment_${idx}`}
                  comment={comment}
                  parentId={null}
                  onCommentSubmit={onCommentSubmit}
                  onCommentEventEmitted={onCommentEventEmitted}
                />
                {idx === comments.length / 50 && (
                  <hr style={{ color: 'red' }} />
                )}
              </>
            ))}
          </div>
        </div>
        <LoginPopup show={showLoginPopup} setShow={setShowLoginPopup} />
      </div>
    );
  }
);

export default CommentList;
