import { useMemo, useState } from 'react';
import useLogin from '@/components/user/login/useLogin';
import { SearchApi } from '@/components/search/SearchApi';
import { ContentType } from '@/app/config/ContentType';
import useMenu from '@/components/menu/useMenu';

export default function useMyLMSSearch() {
  const { findLMSMenu } = useMenu();
  const lmsMenu = useMemo(() => {
    return findLMSMenu();
  }, []);

  const [state, setState] = useState({
    isLoading: false,
    isError: lmsMenu,
    list: [],
    total: 0,
    page: 0,
  });
  const { data } = useLogin();

  const load = async ({ page = 0, size = 9 } = {}) => {
    let menuId = lmsMenu?.menuId; // TODO 하드코딩 제거
    if (menuId == null) {
      setState((p) => ({
        ...p,
        isLoading: false,
        isError: true,
      }));
      console.error('LMS 메뉴를 찾을 수 없습니다.');
      return;
    }

    if (state.isLoading) {
      return;
    }
    setState((p) => ({
      ...p,
      isLoading: true,
      isError: false,
    }));
    try {
      let res = await SearchApi.inMenuSearch({
        menuId,
        contentType: ContentType.EDU_VOD,
        page,
        size,
        isLive: false,
      });
      setState((p) => ({
        page,
        list:
          res.data.map((it) =>
            SearchApi.infoToThumbnailOption2({
              it,
              useTranscode: true,
              useLink: true,
              ignoreTranscode: true,
            })
          ) ?? [],
        total: res.total ?? 0,
        isLoading: false,
        isError: false,
      }));
      return res.data;
    } catch (e) {
      console.error(e);
      setState((p) => ({
        page,
        isLoading: false,
        isError: true,
      }));
    }
    return null;
  };

  return {
    isError: state.isError,
    isLoading: state.isLoading,
    list: state.list,
    total: state.total,
    page: state.page,
    load,
  };
}
