import useLogin from '@/components/user/login/useLogin';
import { SelectorModal } from '@/components/modal/select/SelectorModal';
import { useMemo, useState } from 'react';

export default function OptionBase({
  canReport,
  canEdit,
  canDelete,
  onEdit,
  onDelete,
  onReport,
  children,
}) {
  const { isLoggedIn } = useLogin();
  const [menuOpened, setMenuOpened] = useState(false);
  const handleSelect = (menu) => {
    setMenuOpened(false);
    if (menu == 'edit') {
      onEdit();
    } else if (menu == 'delete') {
      onDelete();
    } else if (menu == 'report') {
      onReport();
    }
  };

  const menuList = useMemo(() => {
    let list = [];
    if (canEdit) list.push({ label: '수정', value: 'edit' });
    if (canDelete) list.push({ label: '삭제', value: 'delete' });
    if (canReport) list.push({ label: '신고', value: 'report' });
    return list;
  }, [canEdit, canDelete]);

  if (!isLoggedIn || menuList.length === 0) {
    return '';
  } else {
    return (
      <div>
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpened((prevState) => !prevState);
          }}
        >
          {children}
        </div>
        <SelectorModal
          items={menuList}
          onSelect={handleSelect}
          setOpened={setMenuOpened}
          opened={menuOpened}
        />
      </div>
    );
  }
}
