import React from 'react';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MenuItem, Select } from '@mui/material';
import { dateFormat } from '@/lib/timeUtil';
import dayjs from 'dayjs';

const eventStatusType = [
  // 미사용. ContentEventConfigField 하위 호환 동작 확인
  // {
  //   type: 'R',
  //   text: '준비',
  // },
  {
    type: 'I',
    text: '진행',
  },
  {
    type: 'E',
    text: '종료',
  },
];

export default function ContentEventConfigField({
  eventStatus = 'I',
  eventStartDt,
  eventEndDt,
  onStatusChange,
  onStartChange,
  onEndChange,
}) {
  // 이벤트 대기 상태 제거
  if (eventStatus === 'R') {
    onStatusChange('I');
  }
  return (
    <div className="mt-4 file_row_wrap">
      <div className="file_col flex items-center">
        {/*<input type="date" />*/}
        <div className="flex items-center md:w-[unset] w-full">
          <span className="flex-shrink-0 f14_w300 mr-4">이벤트 상태</span>
          <Select
            className="bg-white"
            value={eventStatus}
            onChange={(e) => onStatusChange(e.target.value)}
          >
            {eventStatusType.map((it) => (
              <MenuItem value={it.type}>{it.text}</MenuItem>
            ))}
          </Select>
          <div className="mr-4" />
        </div>
        <div className="flex items-center md:w-[unset] w-full">
          <span className="flex-shrink-0 f14_w300 mr-4">이벤트 시작일</span>
          <DatePicker
            className="md:w-[170px] w-full bg-white"
            value={eventStartDt}
            onChange={(e) => onStartChange(e)}
          />
          <div className="mr-4" />
        </div>
        <div className="flex items-center md:w-[unset] w-full">
          <span className="flex-shrink-0 f14_w300 mr-4">이벤트 종료일</span>
          <DatePicker
            className="md:w-[170px] w-full bg-white"
            value={eventEndDt}
            onChange={(e) => onEndChange(e)}
          />
          <div className="mr-4" />
        </div>
        {/*<NotateToolTip*/}
        {/*  title={'"채녈W ID" 입력란이 있는 구글 폼 URL을 입력해 주세요.'}*/}
        {/*/>*/}
        {/*<OutlineLabelField*/}
        {/*  className="w-full max-w-[unset] ml-2 w-40 mr-2"*/}
        {/*  value={value}*/}
        {/*  placeholder={'구글 폼 URL'}*/}
        {/*  useClearAdornment={false}*/}
        {/*  onChange={(value) => onChange?.(value)}*/}
        {/*/>*/}

        {/*<span className="flex-shrink-0 f14_w300">버튼 문구</span>*/}
        {/*<OutlineLabelField*/}
        {/*  className="w-[150px] ml-2 w-40 mr-2 "*/}
        {/*  value={buttonText}*/}
        {/*  placeholder={'버튼 문구'}*/}
        {/*  useClearAdornment={false}*/}
        {/*  onChange={(value) => onButtonTextChange?.(value)}*/}
        {/*/>*/}
      </div>
    </div>
  );
}
