import Link2 from '@/components/common/transition/Link2';
import { useMemo } from 'react';

export default function PostBottom({
  isEditable,
  isDeletable,
  onEdit,
  onDelete,
}) {
  const showButton = useMemo(() => {
    return isDeletable || isEditable;
  }, [isEditable, isDeletable]);

  return (
    <div className="list_view flex items-center justify-center border-t py-6 mt-16 lg:mb-0 mb-16">
      <div className="read_inner flex justify-between">
        {!showButton && <div />}
        {showButton && <div className="md:!block hidden" />}
        <Link2 className="flex items-center" to={-1}>
          <div className="mr-2">
            <span />
            <span />
            <span />
          </div>
          목록가기
        </Link2>
        {showButton && (
          <div className="gap-2">
            {isEditable === true && (
              <button className="btn_stroke_s" onClick={onEdit}>
                수정
              </button>
            )}
            {isDeletable === true && (
              <button className="btn_stroke_s" onClick={onDelete}>
                삭제
              </button>
            )}
          </div>
        )}
        {!showButton && <div />}
      </div>
    </div>
  );
}
