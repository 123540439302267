import useLogin from '@/components/user/login/useLogin';
import ProfileImage from '@/components/user/ProfileImage';

/**
 * 기본 이미지를 포함한 프로필 이미지
 * 파일 정보가 없으면, userNo기반으로 기본 프로필 이미지를 보여준다.
 * @param className
 * @param useUserContentLink
 * @returns {JSX.Element}
 * @constructor
 */

export default function MyProfileImage({
  className = '',
  useUserContentLink = true,
}) {
  const { data } = useLogin();

  return (
    <ProfileImage
      {...data}
      className={className}
      useUserContentLink={useUserContentLink}
    />
  );
}
