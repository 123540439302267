import { parseServerHtml } from '@/lib/textUtil';
import OembedContainer from '@/components/embed/OembedContainer';
import useNoImageDrag from '@/components/common/ux/NoImageDrag';
import useNoContextMenu from '@/components/common/ux/NoContextMenu';

export default function HtmlEmbed({ html = '' } = {}) {
  useNoImageDrag();
  useNoContextMenu();

  return (
    <OembedContainer key={html} data={html}>
      <div
        dangerouslySetInnerHTML={{
          __html: parseServerHtml(html),
        }}
      />
    </OembedContainer>
  );
}
