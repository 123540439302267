export default function useStartViewTransitionCompat() {
  return (some) => {
    if (document.startViewTransition != null) {
      document.startViewTransition(() => {
        some?.();
      });
    } else {
      some?.();
    }
  };
}
