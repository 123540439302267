import { createPortal } from 'react-dom';
import React from 'react';

/**
 *
 * @param {MobileDeleteNavOption} option
 * @returns {React.ReactPortal}
 * @private
 */
export default function MobileDeleteNav({ option }) {
  const container = document.querySelector('.mobile_del_nav');
  return createPortal(<MobileDeleteNavImpl option={option} />, container);
}

/**
 *
 * @param {MobileDeleteNavOption} option
 * @returns {JSX.Element}
 * @private
 */
function MobileDeleteNavImpl({ option }) {
  return (
    <div className="del_checking_bar ">
      <div className="del_bar_box grid grid-cols-3 h-full items-center">
        <button type="button" onClick={option.onAllSelect}>
          전체선택
        </button>
        <button type="button" onClick={option.onCancel}>
          취소
        </button>
        <button type="button" className="del_btn" onClick={option.onDelete}>
          삭제
        </button>
      </div>
    </div>
  );
}

export class MobileDeleteNavOption {
  onAllSelect;
  onCancel;
  onDelete;
}
