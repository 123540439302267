import usePage from '@/components/infiniteScroll/usePage';
import { useEffect } from 'react';

export function useMyPagePaging({
  loader,
  page,
  isStartFromOne = true,
  size = 9,
}) {
  const [historyPage, setPageHistory] = usePage();

  useEffect(() => {
    loader({ page: isStartFromOne ? historyPage + 1 : historyPage, size });
  }, [historyPage]);

  const setPage = (pageToSet) => {
    setPageHistory(pageToSet, { replace: false });
    loader({ page: isStartFromOne ? pageToSet + 1 : pageToSet, size });
  };

  return {
    setPage,
    historyPage,
  };
}
