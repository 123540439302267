import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Link2 from '@/components/common/transition/Link2';
import IOSBackgroundPlay from '@/components/ucms/video/IOSBackgroundPlay';

export default function UcmsMobileHeader({ title }) {
  const [ready, setReady] = useState();
  useEffect(() => {
    setReady(true);
  }, []);
  if (ready)
    return createPortal(
      <MobileVideoHeaderContainer title={title} />,
      document.querySelector('#mobile_head')
    );
}

function MobileVideoHeaderContainer({ title }) {
  return (
    <div className="mobile_head text-center border-b">
      <Link2 to={-1}>
        <img className="back" src="/images/back_arrow.png" alt="뒤로가기" />
      </Link2>
      <span className="text-center">{title}</span>
      <IOSBackgroundPlay />
    </div>
  );
}
