import { useState } from 'react';
import MyPageApi from '@/components/user/my/MyPageApi';

export default function useUserViewList() {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);

  const load = async ({ page = 1, size = 9 } = {}) => {
    setPage(page);
    setIsLoading(true);
    let res = await MyPageApi.userViewList({ _pageNo: page, _pageSize: size });
    setList(res.data);
    setTotal(res.total ?? 0);
    setIsLoading(false);
    return res.data;
  };

  const remove = async (cids) => {
    setIsLoading(true);
    for (let cid of cids) {
      await MyPageApi.userViewDelete(cid);
    }
    setIsLoading(false);
    return true;
  };

  return {
    isLoading,
    list,
    total,
    load,
    page,
    remove,
  };
}
