export default class BackgroundPlayApi {
  static async play({
    src,
    title,
    thumbnailUrl,
    duration,
    currentTime,
    author,
  }) {
    console.log(
      'BackgroundPlayApi play',
      src,
      title,
      thumbnailUrl,
      duration,
      currentTime,
      author
    );
    return await window.flutter_inappwebview?.callHandler(
      'audio_play',
      src,
      title,
      thumbnailUrl,
      duration,
      currentTime,
      author
    );
  }

  static async backgroundPlay({
    src,
    title,
    thumbnailUrl,
    duration,
    currentTime,
    author,
  }) {
    console.log(
      'BackgroundPlayApi play',
      src,
      title,
      thumbnailUrl,
      duration,
      currentTime,
      author
    );
    return await window.flutter_inappwebview?.callHandler(
      'audio_background_play',
      src,
      title,
      thumbnailUrl,
      duration,
      currentTime,
      author
    );
  }

  // static async playReady({
  //                       src,
  //                       title,
  //                       thumbnailUrl,
  //                       duration,
  //                       currentTime,
  //                   }) {
  //
  //     console.log('BackgroundPlayApi play', src, title, thumbnailUrl, duration, currentTime);
  //     return await window.flutter_inappwebview?.callHandler(
  //         'audio_play_ready',
  //         src,
  //         title,
  //         thumbnailUrl,
  //         duration,
  //         currentTime,
  //     );
  // }

  static async stop() {
    let res = await window.flutter_inappwebview?.callHandler('audio_stop');
    console.log('BackgroundPlayApi stop', res);
    return res;
  }

  static async isPlaying() {
    let res = await window.flutter_inappwebview?.callHandler('audio_playing');
    console.log('BackgroundPlayApi audio_playing', res);
    return res;
  }

  static async ready() {
    console.log('BackgroundPlayApi ready');
    return await window.flutter_inappwebview?.callHandler(
      'background_play_ready'
    );
  }

  static async readyRelease() {
    console.log('BackgroundPlayApi ready release');
    return await window.flutter_inappwebview?.callHandler(
      'background_play_disable'
    );
  }
}
