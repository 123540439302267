import useMenu from '@/components/menu/useMenu';
import { useMemo } from 'react';
import BreadCrumb from '@/components/ucms/common/BreadCrumb';
import Link2 from '@/components/common/transition/Link2';
import useWindowDimensions from '@/lib/useWindowDimensions';

export default function AutoBreadCrumb({
  menuId,
  className,
  showOnMobile,
  ...props
}) {
  const { menuList, findRootMenu } = useMenu();
  const { isMobile } = useWindowDimensions();

  const findParentMenu = (menu) => {
    return menuList.find((it) => it.menuId == menu.parentId);
  };

  const menu = useMemo(() => {
    return menuList.find((it) => it.menuId == menuId);
  }, [menuId]);

  const menu1 = useMemo(() => {
    let m = menu;
    if (m?.menuLevel == null || m.menuLevel < 1) {
      return null;
    }
    while (m?.menuLevel == null || m.menuLevel > 1) {
      m = findParentMenu(m);
    }
    return m;
  }, [menu]);

  const menu2 = useMemo(() => {
    let m = menu;
    if (m?.menuLevel == null || m.menuLevel < 2) {
      return null;
    }
    while (m?.menuLevel == null || m.menuLevel > 2) {
      m = findParentMenu(m);
    }
    return m;
  }, [menu]);

  const menu3 = useMemo(() => {
    let m = menu;
    if (m?.menuLevel == null || m.menuLevel < 3) {
      return null;
    }
    while (m?.menuLevel == null || m.menuLevel > 3) {
      m = findParentMenu(m);
    }
    return m;
  }, [menu]);

  const isUcms = useMemo(() => {
    return menu1.menuUrl == null || menu1.menuUrl === '';
  }, [menu1]);

  if (isMobile && showOnMobile) {
    return (
      <span className="cont_tit">
        {menu3?.menuNm ?? menu2?.menuNm ?? menu1?.menuNm ?? ''}
      </span>
    );
  }

  if (isUcms) {
    return (
      <>
        {' '}
        {menu1 != null && (
          <BreadCrumb
            menu1={<Link2 to={`/ucms/${menu1.menuId}`}>{menu1.menuNm}</Link2>}
            menu2={
              menu2 != null ? (
                <Link2 to={`/ucms/${menu1.menuId}/${menu2.menuId}`}>
                  {menu2.menuNm}
                </Link2>
              ) : null
            }
            menu3={
              menu3 != null ? (
                <Link2
                  to={`/ucms/${menu1.menuId}/${menu2.menuId}/${menu3.menuId}`}
                >
                  {menu3.menuNm}
                </Link2>
              ) : null
            }
            className={className}
            showOnMobile={showOnMobile}
            {...props}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        {' '}
        {menu1 != null && (
          <BreadCrumb
            menu1={<Link2 to={`/${menu1.menuUrl}`}>{menu1.menuNm}</Link2>}
            menu2={
              menu2 != null ? (
                <Link2 to={`/${menu1.menuUrl}/${menu2.menuUrl}`}>
                  {menu2.menuNm}
                </Link2>
              ) : null
            }
            menu3={
              menu3 != null ? (
                <Link2
                  to={`/${menu1.menuUrl}/${menu2.menuUrl}/${menu3.menuUrl}`}
                >
                  {menu3.menuNm}
                </Link2>
              ) : null
            }
            className={className}
            showOnMobile={showOnMobile}
            {...props}
          />
        )}
      </>
    );
  }
}
