import { useEffect, useMemo, useState } from 'react';
import Config from '../../app/config/config';
import JoinApi from '../join/JoinApi';
import { isValidEmail } from '@/lib/textUtil';

export default function useEmailValidate(__value, originValue) {
  const [value, _setValue] = useState(__value);
  const [state, setState] = useState({
    message: '',
    hasError: false,
    isValidate: true,
    value,
  });

  const setValue = (v) => {
    _setValue(v);
    if (v === originValue && originValue != null) {
      setState({
        hasError: false,
        isValidate: true,
        message: EmailState.valid,
        // value,
      });
    } else if (v == null || v === '') {
      setState({
        hasError: false,
        isValidate: true,
        message: EmailState.empty,
        // value,
      });
    } else if (!isValidEmail(v)) {
      setState({
        hasError: false,
        isValidate: false,
        message: EmailState.notValidFormat,
        // value,
      });
    } else {
      setState({
        hasError: false,
        isValidate: false,
        message: EmailState.needValidate,
        // value,
      });
    }
  };

  useEffect(() => {
    if (!state.isValidate) {
      validate();
    }
  }, [state.isValidate, value]);

  const validate = async () => {
    let isValidate = false;
    if (value === null || value === '' || !isValidEmail(value ?? '')) {
      setState((s) => ({
        ...s,
        hasError: false,
        isValidate,
        message: EmailState.notValidFormat,
      }));
    } else if (value === originValue) {
      setState((s) => ({
        ...s,
        hasError: false,
        isValidate,
        message: EmailState.valid,
      }));
    } else {
      let validateRes = await JoinApi.checkEmailUnique(value);
      let dupCount = validateRes?.data?.data?.[0]?.cnt;
      isValidate = dupCount === 0;
      if (dupCount == null) {
        setState((s) => ({
          ...s,
          hasError: false,
          isValidate,
          message: EmailState.error,
        }));
      } else if (dupCount > 0) {
        setState((s) => ({
          ...s,
          hasError: false,
          isValidate,
          message: EmailState.exist,
        }));
      } else {
        setState((s) => ({
          ...s,
          hasError: false,
          isValidate,
          message: EmailState.valid,
        }));
      }
    }
    return isValidate;
  };
  //
  return {
    ...state,
    fieldName: 'email',
    value,
    setValue,
    validate,
  };
}

export const EmailState = {
  empty: '',
  error: '오류가 발생하였습니다. 잠시 후 시도해 주세요',
  needValidate: '중복 확인을 해주세요',
  notValidFormat: '올바른 이메일 형식이 아닙니다.',
  unknownDomain: '알 수 없는 임직원 메일 주소입니다.',
  valid: '',
  exist: '이미 사용중인 이메일 입니다.',
};
