import { Upload } from '@aws-sdk/lib-storage';
import { S3 } from '@aws-sdk/client-s3';
import { AbortController } from '@aws-sdk/abort-controller';
import { Buffer } from 'buffer';
import Config from '@/app/config/config';

// eslint-disable-next-line no-restricted-globals
let s = self;

let abortController = null;

/**
 *
 * @param {FileUploadParam} data
 */
s.onmessage = async ({ data }) => {
  const { method } = data;
  if (method === FileUploadWorkerRunMethod.upload) {
    const { file: fileDto, path, bucket } = data.data;
    const { file, name: fileName } = fileDto;

    // TODO S3 설정 받아오기?
    const accessKey = 'r8aylLOvCr21ExIk';
    const secretKey = 'SuY8mpjiuITjhkungrwUdUjXhDQqZ6E2';
    const region = 'kr-standard';
    const endPoint = Config.s3Url;

    const s3 = new S3({
      endpoint: endPoint,
      region: region,
      forcePathStyle: true,
      credentials: {
        accessKeyId: accessKey,
        secretAccessKey: secretKey,
      },
    });

    const chunkSize = 10 * 1024 * 1024;
    const key = `${path}/${fileName}`;

    try {
      abortController = new AbortController();
      const parallelUploads3 = new Upload({
        client: s3,
        params: {
          Bucket: bucket,
          Key: key,
          Body: file,
        },
        abortController: abortController,
        // queueSize: 4, // default
        partSize: chunkSize,
        // leavePartsOnError: false, // default
      });

      parallelUploads3.on('httpUploadProgress', (progress) => {
        s.postMessage({
          method: FileUploadWorkerEventMethod.progress,
          data: progress,
        });
      });

      s.postMessage({
        method: FileUploadWorkerEventMethod.progress,
        data: { loaded: 0, total: file.size },
      });

      await parallelUploads3.done();
      s.postMessage({
        method: FileUploadWorkerEventMethod.uploaded,
      });
    } catch (e) {
      console.error(e);

      if (e.name === 'AbortError') {
        s.postMessage({
          method: FileUploadWorkerEventMethod.canceled,
          data: e,
        });
      } else {
        s.postMessage({
          method: FileUploadWorkerEventMethod.error,
          data: e,
        });
      }
      // setState(FileUploadState.error);
    }
  }
  if (method === FileUploadWorkerRunMethod.uploadBase64) {
    const { body, path, fileInfo } = data.data;

    // TODO S3 설정 받아오기?
    const accessKey = 'r8aylLOvCr21ExIk';
    const secretKey = 'SuY8mpjiuITjhkungrwUdUjXhDQqZ6E2';
    const region = 'kr-standard';
    const bucket = 'user';
    const endPoint = Config.s3Url;

    const s3 = new S3({
      endpoint: endPoint,
      region: region,
      forcePathStyle: true,
      credentials: {
        accessKeyId: accessKey,
        secretAccessKey: secretKey,
      },
    });

    const chunkSize = 10 * 1024 * 1024;
    const key = `${path}/${fileInfo.uploadFile}`;
    const buf = Buffer.from(body, 'base64');

    try {
      abortController = new AbortController();
      const parallelUploads3 = new Upload({
        client: s3,
        params: {
          Bucket: bucket,
          Key: key,
          Body: buf,
          ContentEncoding: 'base64',
          ContentType: 'image/png',
        },
        abortController: abortController,
        // queueSize: 4, // default
        // leavePartsOnError: false, // default
        partSize: chunkSize,
      });

      parallelUploads3.on('httpUploadProgress', (progress) => {
        s.postMessage({
          method: FileUploadWorkerEventMethod.progress,
          data: progress,
        });
      });

      s.postMessage({
        method: FileUploadWorkerEventMethod.progress,
        data: { loaded: 0, total: body.length },
      });

      await parallelUploads3.done();
      s.postMessage({
        method: FileUploadWorkerEventMethod.uploaded,
      });
    } catch (e) {
      console.error(e);

      if (e.name === 'AbortError') {
        s.postMessage({
          method: FileUploadWorkerEventMethod.canceled,
          data: e,
        });
      } else {
        s.postMessage({
          method: FileUploadWorkerEventMethod.error,
          data: e,
        });
      }
      // setState(FileUploadState.error);
    }
  } else if (method === FileUploadWorkerRunMethod.cancel) {
    // 업로드 취소
    abortController?.abort();
  }
};

export const FileUploadWorkerRunMethod = {
  upload: 'upload',
  uploadBase64: 'uploadBase64',
  cancel: 'cancel',
};

export const FileUploadWorkerEventMethod = {
  progress: 'progress',
  canceled: 'canceled',
  error: 'error',
  uploaded: 'uploaded',
};

export class FileUploadParam {
  method; // upload, cancel
  data;
}
