export default function FacebookShareButton({ link, onClick }) {
  return (
    <a
      href={`http://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        link
      )}`}
      target={'_blank'}
      onClick={onClick}
      rel="noreferrer"
    >
      <img src="/images/sns_ico03.png" alt="페이스북 공유" />
    </a>
  );
}
