import { useEffect } from 'react';

export default function useNoImageDrag({} = {}) {
  useEffect(() => {
    document.querySelectorAll('.select-none .img').ondragstart = function () {
      return false;
    };

    return () => {
      // document.querySelectorAll('.select-none .img').ondragstart = function() { return true; };
    };
  }, []);
}
