import { Dialog } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';

export default function PinDialog({
  title = '간편비밀번호',
  open,
  length = 4,
  onSubmit,
  onCancel,
}) {
  const inputRef = useRef();
  const [code, setCode] = useState('');

  const focus = () => {
    inputRef.current?.focus();
  };

  const PinFields = useMemo(() => {
    return (
      <>
        {Array(length)
          .fill(0)
          .map((it, index) => (
            <PinItem key={`pin_item_${index}`} filled={code.length > index} />
          ))}
      </>
    );
  }, [length, code.length]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        focus();
      }, 0);
    }
  }, [open]);

  useEffect(() => {
    if (code.length >= length) {
      _submit();
    }
  }, [code]);

  const _submit = () => {
    onSubmit?.(code);
    setCode('');
  };

  const _cancel = () => {
    onCancel?.();
    setCode('');
  };

  return (
    <Dialog
      sx={{
        zIndex: 2001,
      }}
      open={open}
      onClose={focus}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <div className="pinContainer" onClick={focus}>
        <div className="pinText">{title}</div>
        <div className="flex gap-4">{PinFields}</div>
        <input
          ref={inputRef}
          autoFocus={true}
          type="number"
          id="easy-login-pin"
          className="!w-[0px] !h-[0px]"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </div>
      <button style={{ color: 'white' }} onClick={_cancel}>
        취소
      </button>
    </Dialog>
  );
}

function PinItem({ key, filled }) {
  return <div key={key} className={`pinField ${filled ? 'active' : ''}`}></div>;
}
