import { useRef } from 'react';
import useNavigate2 from '@/components/common/transition/useNavigate2';

export default function Maintenance() {
  const navigator = useNavigate2();
  const countRef = useRef();
  const timerRef = useRef();

  return (
    <div
      className="absolute w-full h-full flex justify-center items-center bg-[#F7F8FA]"
      onClick={() => {
        if ((countRef.current ?? 0) == 0) {
          // console.log('dev timer set')
          timerRef.current = setTimeout(() => {
            countRef.current = 0;
            try {
              // console.log('dev timer clear')
              clearTimeout(timerRef.current);
              timerRef.current = null;
            } catch (e) {
              console.error(e);
            }
          }, 2000);
        }
        countRef.current = (countRef.current ?? 0) + 1;
        if (countRef.current >= 10) {
          localStorage.setItem('isDev', true);
          navigator('/');
        }
      }}
    >
      <img src="/images/maintenence.svg" />
    </div>
  );
}
