import {
  isValidPassword,
  isValidNumberPassword,
  isValidEmail,
} from './textUtil';

export const validEmail = (email) => {
  let isValid = true;
  let message = null;
  if (email == null || email?.trim() === '') {
    message = '이메일을 입력 해주세요';
    isValid = false;
  } else if (!isValidEmail(email)) {
    message = '올바른 이메일이 아닙니다.';
    isValid = false;
  }
  // else if(isCo){
  // TODO 이메일 목록에 없으면 반려
  // message = '올바른 이메일이 아닙니다.';
  // isValid = false;
  // }
  else {
    isValid = true;
    message = null;
  }
  return {
    message,
    isValid,
  };
};

export const validPassword = (pw) => {
  let isValid = true;
  let message = null;
  if (!pw) {
    message = '비밀번호를 입력 해주세요';
    isValid = false;
  } else if (!isValidPassword(pw)) {
    message = '8자 이상, 영문, 숫자, 특수문자를 포함해야합니다.';
    isValid = false;
  } else if (isValidNumberPassword(pw)) {
    message = '연속된 숫자 2개 이하로 입력해야합니다.';
    isValid = false;
  } else {
    message = null;
    isValid = true;
  }

  return {
    message,
    isValid,
  };
};
export const validPassword2 = (pw1, pw2) => {
  let isValid = true;
  let message = null;

  if (!pw2) {
    message = '비밀번호를 입력 해주세요';
    isValid = false;
  } else if (pw1 !== pw2) {
    message = '비밀번호가 일치하지 않습니다.';
    isValid = false;
  } else {
    message = null;
    isValid = true;
  }

  return {
    message,
    isValid,
  };
};

export const validUserComp = (userComp) => {
  const isValid = 0 < userComp.length && userComp.length < 20;
  return {
    message: isValid ? null : '직업명은 1자 이상 20자 미만입니다.',
    isValid,
  };
};

export const validateTerms = (data) => {
  let isValid = true;
  let message = null;

  // 약관 필수 처리 확인 부분
  if (!data.termPrivacyMust) {
    message = '필수 약관 동의가 필요합니다.';
    isValid = false;
  } else {
    message = null;
    isValid = true;
  }

  return {
    message,
    isValid,
  };
};
