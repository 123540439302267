import React, { useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import useCompanyList from '@/components/user/company/useCompanyList';

export default function ContentShowGroup({ openLvl, openOpt, onChange }) {
  const companyList = useCompanyList({ excludeNormal: false });
  const [opt, setOpt] = useState(
    new Set(
      openOpt
        ?.split(',')
        .filter((it) => it !== '')
        .filter((it) => !isNaN(Number(it)))
        .map((it) => Number(it)) ?? []
    )
  );
  // console.log('opt', [...opt]);

  const addOpt = (opt) => {
    setOpt((s) => new Set([opt, ...s]));
  };

  const removeOpt = (opt) => {
    setOpt((s) => new Set([...s].filter((it) => it !== opt)));
  };

  useEffect(() => {
    if (opt.size > 0) {
      onChange?.(`${[...opt].join(',')}`);
    } else {
      onChange?.('');
    }
  }, [opt]);

  return (
    <Collapse in={openLvl === 2}>
      <div className="file_check_box mb-5 ">
        <div className="md:flex gap-5 justify-start">
          <div className="flex all_agree_wrap items-start mb-5">
            <input
              id="99999"
              type="checkbox"
              defaultChecked="true"
              checked={opt.size == companyList.length}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  setOpt((s) => new Set(companyList.map((it) => it.compId)));
                } else {
                  setOpt((s) => new Set([]));
                }
              }}
            />
            <label htmlFor="99999">회원전체</label>
          </div>
          <div className="grid md:grid-cols-5 sm:grid-cols-3 grid-cols-2 gap-x-3 gap-y-4 flex-wrap flex-1 flex-wrap">
            {companyList.map((it, index) => (
              <div className="flex all_agree_wrap items-center">
                <input
                  id={it.compId}
                  type="checkbox"
                  checked={opt.has(it.compId)}
                  // onChange={e => updateItems(index+1, 'checked', e.target.checked)}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      addOpt(it.compId);
                    } else {
                      removeOpt(it.compId);
                    }
                  }}
                />
                <label htmlFor={it.compId}>{it.compNm}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Collapse>
  );
}
