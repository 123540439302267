import './chipstyle.css';
import { useEffect, useRef } from 'react';

export default function Chip({
  checked,
  onChecked,
  label,
  value,
  className,
  borderColor,
  defaultTextColor,
}) {
  const ref = useRef();
  const scrollToElement = () => {
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  useEffect(() => {
    if (checked) {
      scrollToElement();
    }
  }, [checked]);

  return (
    <label
      ref={ref}
      className={`button_label chip no_drag ${className}`}
      data-checked={checked}
      onClick={(e) => onChecked(value, e)}
      style={{
        borderColor: borderColor ?? 'inherit',
        color: defaultTextColor ?? 'inherit',
      }}
    >
      {label}
    </label>
  );
}
