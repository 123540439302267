import { apiInstance } from '@/app/config/network';
import { ContentJobType } from '@/components/content/editor/ContentEditor';
import ContentApi, {
  ContentFileJobType,
  ContentFileType,
} from '@/components/content/ContentApi';
import AuthUtil from '@/components/auth/AuthUtil';
import { normalizeNFC } from '@/lib/stringUtil';

/**
 * @field userRank 직위
 */
export class UserModifyDto {
  constructor({
    userNo,
    nickNm,
    userNm,
    userRank,
    userMobile,
    userComp,
    wbnEmpNo,
    wbnCompCd,
    wbnDeptCd,
    wbnRankNm,
    wbnRankCd,
    wbnPositionCd,
  }) {
    this.userNo = userNo;
    this.nickNm = normalizeNFC(nickNm);
    this.userNm = normalizeNFC(userNm);
    this.userRank = userRank;
    this.userMobile = userMobile;
    this.userComp = userComp;
    this.wbnEmpNo = wbnEmpNo;
    this.wbnCompCd = wbnCompCd;
    this.wbnDeptCd = wbnDeptCd;
    this.wbnRankNm = normalizeNFC(wbnRankNm);
    this.wbnRankTypeCd = wbnRankCd;
    this.wbnPositionCd = wbnPositionCd;
  }
}

export class UserChangePassDto {
  constructor({ userNo, userPwd, tempPwd }) {
    this.userNo = userNo;
    this.userPwd = userPwd;
    this.tempPwd = tempPwd;
  }
}

export class UserProfileRegist {
  constructor({
    jobGroupType = ContentFileJobType.profile,
    jobtype = ContentJobType.upload,
    fileType = ContentFileType.profile,
    uploadPath,
    uploadFile,
  }) {
    this.jobGroupType = jobGroupType;
    this.jobtype = jobtype;
    this.fileType = fileType;
    this.uploadPath = uploadPath;
    this.uploadFile = uploadFile;
  }
}

export class UserContentListParam {
  /**
   *
   * @param {string} searchType [VOD, BBS]
   * @param pageSize
   * @param pageNo
   */
  constructor({ userNo, searchType, pageSize, size, pageNo, page }) {
    this._searchUserNo = userNo;
    this._searchType = searchType;
    this._pageSize = pageSize ?? size ?? 8;
    this._pageNo = (pageNo === 0 ? 1 : pageNo) ?? (page === 0 ? 1 : page) ?? 1;
  }
}

export class CodeError extends Error {
  constructor({ code, message }) {
    super(message);
    super.name = code;
  }
}

export default class MyPageApi {
  /**
   * @param {UserModifyDto} body
   * @return {Promise<axios.AxiosResponse<any>>}
   */
  static async modifyUserInfo(body) {
    return apiInstance.post('/api/cms/user/modify', {
      ...body,
      ...(await AuthUtil.getOneTimeSessionKey()),
    });
  }

  /**
   * @param {UserChangePassDto} body
   * @return {Promise<axios.AxiosResponse<any>>}
   */
  static async changeMyPassword(body) {
    return apiInstance.post('/api/cms/user/change_password', {
      ...body,
      ...(await AuthUtil.getOneTimeSessionKey()),
    });
  }

  /**
   * @param {Partial<UserModifyDto>|{userId: string, userNm: string}} body
   * @return {Promise<axios.AxiosResponse<any>>}
   */
  static async resetUserPassword(body) {
    return apiInstance.post('/api/cms/user/reset_password', {
      ...body,
      ...(await AuthUtil.getOneTimeSessionKey()),
    });
  }

  /**
   * @return {Promise<axios.AxiosResponse<any>>}
   */
  static async registerBio() {
    return apiInstance
      .post('/api/cms/user/hmacRegist', {
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   * @return {Promise<axios.AxiosResponse<any>>}
   */
  static async getEzPin() {
    return apiInstance.get('/api/cms/user/getEzAuthUUID').then((res) => {
      return res.data;
    });
  }

  /**
   * @return {Promise<axios.AxiosResponse<any>>}
   */
  static async registerEzPin({ ezAuthHash }) {
    return apiInstance
      .post('/api/cms/user/ezAuthChange', {
        ezAuthHash,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @param {UserProfileRegist} body
   * @returns {Promise<*>}
   */
  static async profileRegist(body) {
    return apiInstance
      .post('/api/cms/user/profileRegist', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        ...body,
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @param {ContentJobUpdateFileData} body
   * @returns {Promise<*>}
   */
  static async profileFileUpdate(body) {
    return apiInstance
      .post('/api/cms/user/profileJobUpdate', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        ...body,
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @returns {Promise<*>}
   */
  static async subscribe(userNo) {
    return apiInstance
      .post('/api/cms/user/subscribe', {
        pubNo: userNo,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @returns {Promise<*>}
   */
  static async unsubscribe(userNo) {
    return apiInstance
      .post('/api/cms/user/unsubscribe', {
        pubNo: userNo,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @returns {Promise<*>}
   */
  static async followingList({ page = 0, size = 9 }) {
    return apiInstance
      .post('/api/cms/user/followingList', {
        _pageNo: page + 1,
        _pageSize: size,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @returns {Promise<*>}
   */
  static async followerList({ page = 0, size = 10 }) {
    return apiInstance
      .post('/api/cms/user/followerList', {
        _pageNo: page + 1,
        _pageSize: size,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @returns {Promise<*>}
   */
  static async resign(reason) {
    return apiInstance
      .post('/api/cms/user/resign', {
        resignReason: reason,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   * @param {UserContentListParam} param
   * @returns {Promise<*>}
   */
  static async userContentList(param) {
    return apiInstance
      .post('/api/cms/user/userContentList', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        ...param,
      })
      .then((res) => {
        if (res.data.data == null)
          throw new CodeError({
            message: res.data.message,
            code: res.data.code,
          });
        return res.data;
      });
  }

  static async userBBSLikeList(param) {
    return apiInstance
      .post('/api/cms/user/likeBbsList', {
        ...param,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        if (res.data.data == null)
          throw new CodeError({
            message: res.data.message,
            code: res.data.code,
          });
        return res.data;
      });
  }

  static async userThankyouRegList() {
    return apiInstance.get('/api/cms/tt/regCountList').then((res) => {
      return res.data;
    });
  }

  static async updateThankyouRegList(param) {
    return apiInstance
      .post('/api/cms/tt/regCountSave', {
        ...param,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async userVODLikeList(param) {
    return apiInstance
      .post('/api/cms/user/likeVodList', {
        ...param,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async likeDelete(cid) {
    return ContentApi.contentLikeDelete(cid);
  }

  static async userEarnPointList(param) {
    return apiInstance
      .post('/api/cms/user/userPointList', {
        ...param,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async userReplyList(param) {
    return apiInstance
      .post('/api/cms/user/userReplyList', {
        ...param,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async deleteComment(resId) {
    return ContentApi.deleteComment(resId);
  }

  static async deleteCommentResponse(resId) {
    return ContentApi.deleteCommentResponse(resId);
  }

  static async userPointView() {
    return apiInstance
      .post('/api/cms/user/userPointView', {
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async userViewList(param) {
    return apiInstance
      .post('/api/cms/user/userViewList', {
        ...param,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async userViewDelete(cid) {
    return apiInstance
      .post('/api/cms/user/userViewDelete', {
        cid,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async alertList(param) {
    return apiInstance
      .post('/api/cms/user/alertList', {
        ...param,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async alertViewUpdate(itemId) {
    return apiInstance
      .post('/api/cms/user/alertViewUpdate', {
        itemIdList: itemId,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async alertDelete(itemId) {
    return apiInstance
      .post('/api/cms/user/alertDelete', {
        itemIdList: itemId,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async changeMobileCodeReq({ userMobile }) {
    return apiInstance
      .post('/api/cms/signup/smsChangeReq', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        userMobile: userMobile,
      })
      .then((res) => {
        return res.data;
      });
  }

  static async changeMobileCodeAuth({ authNo }) {
    return apiInstance
      .post('/api/cms/signup/smsChangeAuth', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        auth2No: authNo,
      })
      .then((res) => {
        return res.data;
      });
  }
  static async changeUserAgree(param) {
    return apiInstance
      .post('/api/cms/sing/userAgree', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        ...param,
      })
      .then((res) => {
        return res.data;
      });
  }

  static async getPrivacyPopup(userNo) {
    try {
      console.log('getPrivacyPopup start');
      return apiInstance
        .post('/api/cms/content/getPrivacyPopup', {
          ...userNo,
          ...(await AuthUtil.getOneTimeSessionKey()),
        })
        .then((res) => {
          return res.data;
        });
      console.log('getPrivacyPopup end');
    } catch (e) {
      console.error(e);
    }
  }

  static async setPrivacyPopup(userNo) {
    console.log('setPrivacyPopup start');

    try {
      return apiInstance
        .post('/api/cms/content/setPrivacyPopup', {
          ...userNo,
          ...(await AuthUtil.getOneTimeSessionKey()),
        })
        .then((res) => {
          return res.data;
        });
      console.log('setPrivacyPopup end');
    } catch (e) {
      console.error(e);
    }
  }
}
