import { forwardRef, useEffect } from 'react';
import CommentList from '@/components/comment/CommentList';
import { CircularProgress } from '@mui/material';
import useInfiniteScroll from '@/components/infiniteScroll/useInfiniteScroll';
import useComment from '@/components/comment/useComment';

/**
 * @param {Comment[]} comments
 * @param onCommentSubmit
 * @return {JSX.Element}
 */
const CommentListWrap = forwardRef(
  ({ cid, onCommentSubmit, totalReply }, ref) => {
    const {
      isLast,
      commentQuery,
      commentMutation,
      isInitLoading,
      total,
      addedReplies,
      _onCommentSubmit,
      list,
    } = useComment({
      cid,
      onCommentSubmit,
      totalReply,
    });

    const [_, listBottomRef] = useInfiniteScroll({
      isLast,
      isLoading: commentQuery.isLoading,
      loadMore: commentQuery.fetchNextPage,
    });

    useEffect(() => {
      console.log('CommentListWrap useEffect');

      // commentQuery.fetchNextPage();
    }, []);
    const onCommentEventEmitted = (event, params) => {
      commentMutation.mutate({ event, params });
    };

    return (
      <>
        {isInitLoading && (
          <div className="w-full flex items-center justify-center min-h-[100px]">
            <CircularProgress className="loading" />
          </div>
        )}
        {!isInitLoading && (
          <>
            <CommentList
              total={total + addedReplies}
              comments={list ?? []}
              onCommentSubmit={_onCommentSubmit}
              onCommentEventEmitted={onCommentEventEmitted}
              commentQuery={commentQuery}
            />
          </>
        )}
        <div ref={listBottomRef} />
        {!isInitLoading &&
          (commentQuery.isLoading || commentQuery.isFetchingNextPage) && (
            <div className="w-full flex items-center justify-center min-h-[100px]">
              <CircularProgress className="loading" />
            </div>
          )}
      </>
    );
  }
);

export default CommentListWrap;
