import AppDownloadPopup from '@/components/popup/AppDownloadPopup';
import { useEffect, useMemo, useState } from 'react';
import useMobile from '@/lib/useMobile';
import useWindowDimensions from '@/lib/useWindowDimensions';
import useDynamicLink from '@/lib/useDynamicLink';
import DeviceApi from '@/components/device/DeviceApi';

export default function useAppDownloadPopup() {
  const isIOSWeb = DeviceApi.isIOSWeb();
  const load = () => {
    if (!popupClosed) {
      if (!isMobileApp && isMobileWeb && !isIOSWeb) {
        setShowPopup(true);
      }
    }
  };
  const { isMobile: isMobileWeb } = useMobile();
  const { isWebView: isMobileApp } = useWindowDimensions();
  const [popupClosed, setPopupClosed] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { shortenUrl } = useDynamicLink();

  useEffect(() => {
    load();
  }, [isMobileApp, isMobileWeb, popupClosed]);

  useEffect(() => {
    if (showPopup === true) {
      console.log('앱 다운로드 연결');
      shortenUrl(window.location.href).then((url) => {
        // shortenUrl('https://wooriwbn.com/ucms/view/11/5259').then((url) => {
        if (url != null) {
          window.location.href = url;
        }
      });
    }
  }, [showPopup]);

  // const popup = useMemo(() => {
  //     if(showPopup === true){
  //         return <AppDownloadPopup
  //             cancelCallback={() => {
  //                 setShowPopup(false);
  //                 setPopupClosed(true);
  //             }}
  //         />
  //     }
  //     else {
  //         return null;
  //     }
  // }, [showPopup]);

  const popup = <></>;

  return {
    load,
    popup,
  };
}
