import { createPortal } from 'react-dom';

export default function PipVideoPlayer(options) {
  const container = document.querySelector('.pip_player_portal');
  return createPortal(<PipVideoPlayerContainer {...options} />, container);
}

function PipVideoPlayerContainer({ children } = {}) {
  return <div className="popup-z-index fixed bottom-0 right-0">{children}</div>;
}
