import React from 'react';
import NotateToolTip from '@/components/content/editor/NotateToolTip';

const tooltip = (
  <div>
    <span className="tooltip-text block">작성자에게만 노출됩니다.</span>
    <span className="tooltip-text block">
      단, 공유하기를 통한 링크는 공유 가능
    </span>
  </div>
);

export const OpenLevel = {
  public: 0,
  hidden: 1,
  target: 2,
};

export default function ContentShowRadio({ value, onChange }) {
  return (
    <div className="flex gap-x-5 ">
      <div className="wetube_radio_row flex ">
        <input
          type="radio"
          name="report"
          className="report_radio"
          id="01"
          checked={value == null || value === '' || value === OpenLevel.public}
          onChange={(e) => {
            if (e.target.checked === true) {
              onChange?.(OpenLevel.public);
            }
          }}
        />
        <label htmlFor="01" className="f14_w300">
          전체공개
        </label>
      </div>
      <div className="flex">
        <div className="wetube_radio_row ">
          <input
            type="radio"
            name="report"
            className="report_radio"
            id="02"
            checked={value === OpenLevel.hidden}
            onChange={(e) => {
              if (e.target.checked === true) {
                onChange?.(OpenLevel.hidden);
              }
            }}
          />
          <label htmlFor="02" className="f14_w300">
            비공개
          </label>
        </div>
        <NotateToolTip title={tooltip} />
      </div>
      <div className="flex ">
        <div className="wetube_radio_row">
          <input
            type="radio"
            name="report"
            className="report_radio"
            id="03"
            checked={value === OpenLevel.target}
            onChange={(e) => {
              if (e.target.checked === true) {
                onChange?.(OpenLevel.target);
              }
            }}
          />
          <label htmlFor="03" className="f14_w300">
            부분공개
          </label>
        </div>
        <NotateToolTip
          title={
            <span className="tooltip-text">
              선택 회원 그룹에게만 노출 됩니다
            </span>
          }
        />
      </div>
    </div>
  );
}
