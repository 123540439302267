const ACTIONS = {
  SET_COMMENT_FOCUSED: 'SET_COMMENT_FOCUSED',
  SET_COMMENT_BLUR: 'SET_COMMENT_BLUR',
  SET_CHAT_FOCUSED: 'SET_CHAT_FOCUSED',
  SET_CHAT_BLUR: 'SET_CHAT_BLUR',
};

Object.freeze(ACTIONS);

export const setCommentFocused = () => ({ type: ACTIONS.SET_COMMENT_FOCUSED });
export const setCommentBlur = () => ({ type: ACTIONS.SET_COMMENT_BLUR });

export const setChatFocused = () => ({ type: ACTIONS.SET_CHAT_FOCUSED });
export const setChatBlur = () => ({ type: ACTIONS.SET_CHAT_BLUR });

const initialState = {
  commentFocused: false,
  chatFocused: false,
};

export default function mobileNavigationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_COMMENT_FOCUSED: {
      return {
        ...state,
        commentFocused: true,
      };
    }
    case ACTIONS.SET_COMMENT_BLUR: {
      return {
        ...state,
        commentFocused: false,
      };
    }
    case ACTIONS.SET_CHAT_FOCUSED: {
      return {
        ...state,
        chatFocused: true,
      };
    }
    case ACTIONS.SET_CHAT_BLUR: {
      return {
        ...state,
        chatFocused: false,
      };
    }
    default:
      return state;
  }
}
