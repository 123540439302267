import { apiInstance } from '@/app/config/network';
import { extLowercase } from '@/lib/fileUtil';
import AuthUtil from '@/components/auth/AuthUtil';
import { normalizeNFC } from '@/lib/stringUtil';

export default class ContentApi {
  static async getContent(menuId, cid, contentType, pw, relCount) {
    return apiInstance
      .post('/api/cms/content/contentInfo', {
        cid: cid,
        menuId: menuId,
        contentType: contentType,
        contentPwd: pw,
        _relSize: relCount,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async getRelateContentList({ menuId, cid, pageNo, pageSize }) {
    return apiInstance
      .post('/api/cms/content/relationContentList', {
        cid: cid,
        menuId: menuId,
        _pageNo: pageNo,
        _pageSize: pageSize,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async getContentCountInfo(cid) {
    return apiInstance
      .post('/api/cms/content/contentCountInfo', {
        cid: cid,
      })
      .then((res) => {
        return res.data;
      });
  }

  static async getLiveTime(cid) {
    return apiInstance
      .post('/api/cms/content/liveTimeInfo', {
        cid: cid,
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   * 최초 로딩용
   * @param cid
   * @return {Promise<axios.AxiosResponse<any>>}
   */
  static async getReplyList(cid) {
    return apiInstance
      .post('/api/cms/content/replyList', {
        cid: cid,
        _pageNo: 1,
        _pageSize: 300,
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   * infinite scroll 용
   * @param cid
   * @param page
   * @param size
   * @return {Promise<axios.AxiosResponse<any>>}
   */
  static async getReplyList2(cid, page, size) {
    return apiInstance
      .post('/api/cms/content/replyList', {
        cid: cid,
        _pageNo: page,
        _pageSize: size,
      })
      .then((res) => {
        return res.data;
      });
  }

  static async getReReply(replyId) {
    return apiInstance
      .post('/api/cms/content/responseList', {
        replyId: replyId,
        _pageNo: 1,
        _pageSize: 9999,
      })
      .then((res) => {
        return res.data;
      });
  }

  static async postComment(cid, message) {
    return apiInstance
      .post('/api/cms/content/replyRegist', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        cid: cid,
        replyData: normalizeNFC(message),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async postResponseComment(cid, replyId, message) {
    return apiInstance
      .post('/api/cms/content/responseRegist', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        replyId: replyId,
        resData: normalizeNFC(message),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async reportComment(replyId) {
    return apiInstance
      .post('/api/cms/content/replyComplain', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        replyId: replyId,
      })
      .then((res) => {
        return res.data;
      });
  }

  static async editComment({ replyId, replyData }) {
    return apiInstance
      .post('/api/cms/content/replyModify', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        replyId: replyId,
        replyData: normalizeNFC(replyData),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async deleteComment({ replyId }) {
    return apiInstance
      .post('/api/cms/content/replyDelete', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        replyId: replyId,
      })
      .then((res) => {
        return res.data;
      });
  }

  static async reportCommentResponse({ resId }) {
    return apiInstance
      .post('/api/cms/content/responseComplain', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        resId: resId,
      })
      .then((res) => {
        return res.data;
      });
  }

  static async editCommentResponse({ resId, resData }) {
    return apiInstance
      .post('/api/cms/content/responseModify', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        resId: resId,
        resData: normalizeNFC(resData),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async deleteCommentResponse({ resId }) {
    return apiInstance
      .post('/api/cms/content/responseDelete', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        resId: resId,
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @param {ContentRegistData} data
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async contentRegist(data) {
    return apiInstance
      .post('/api/cms/content/contentRegist', {
        ...data,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @param {ContentRegistData} data
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async contentModify(data) {
    return apiInstance
      .post('/api/cms/content/contentModify', {
        ...data,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @param {ContentJobUpdateFileData} data
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async contentJobUpdate(data) {
    return apiInstance
      .post('/api/cms/content/contentJobUpdate', {
        ...data,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @param {number} cid
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async contentDelete(cid) {
    return apiInstance
      .post('/api/cms/content/contentDelete', {
        cid,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @param {number} cid
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async contentLike(cid) {
    return apiInstance
      .post('/api/cms/content/likeRegist', {
        cid,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @param {number} cid
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async contentLikeDelete(cid) {
    return apiInstance
      .post('/api/cms/content/likeDelete', {
        cid,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @param {number} cid
   * @param {string} shareType
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async contentShareCountIncrease({ cid, shareType }) {
    return apiInstance
      .post('/api/cms/content/shareRegist', {
        cid,
        shareType,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @param {number} cid
   * @param {string} shareType
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async contentClaim({ cid }) {
    return apiInstance
      .post('/api/cms/content/contentClaim', {
        cid,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async userViewTimeUpdate({
    cid,
    totalSec,
    lastSec,
    viewSec,
    viewTag,
    eduId = -1,
  }) {
    return apiInstance
      .post('/api/cms/user/userViewTimeUpdate', {
        cid,
        totalSec,
        lastSec,
        viewSec,
        viewTag,
        eduId,
        // ...(await AuthUtil.getOneTimeSessionKey()),
        ...(await AuthUtil.getUserTag()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async userViewTimeInfo({ cid }) {
    return apiInstance
      .post('/api/cms/content/userViewTimeInfo', {
        cid,
        ...(await AuthUtil.getUserTag()),
        // ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async liveViewNotify({ cid, liveId }) {
    return apiInstance
      .post('/api/cms/user/liveViewNotify', {
        cid,
        liveId,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   * 240215 yiseul
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async userLiveViewTimeUpdate({ cid, eduId }) {
    return apiInstance
      .post('/api/cms/user/userLiveViewTimeUpdate', {
        cid,
        eduId,
        ...(await AuthUtil.getUserTag()),
      })
      .then((res) => {
        return res.data;
      });
  }
}

export class ContentRegistData {
  constructor({
    cid,
    userId,
    sessionKey,
    menuId,
    title,
    cData,
    contentType,
    contentPwdYn,
    contentPwd,
    openLvl,
    openOpt,
    exposureYn,
    reserveYn,
    reserveDt,
    topFixedYn,
    files,
    gfUrl = '',
    gfKey,
    gfBtnText,
    eventStatus,
    eventStartDt,
    eventEndDt,
  }) {
    this.userId = userId;
    this.sessionKey = sessionKey;
    this.cid = cid;
    this.menuId = menuId;
    this.title = title;
    this.cData = cData;
    this.contentType = contentType;
    this.contentPwdYn = contentPwdYn;
    this.contentPwd = contentPwd;
    this.openLvl = openLvl;
    this.openOpt = openOpt;
    this.exposureYn = exposureYn;
    this.reserveYn = reserveYn;
    this.reserveDt = reserveDt;
    this.topFixedYn = topFixedYn;
    this.files = files;
    this.gfUrl = gfUrl;
    this.gfKey = gfKey;
    this.gfBtnText = gfBtnText;
    this.eventStatus = eventStatus;
    this.eventStartDt = eventStartDt;
    this.eventEndDt = eventEndDt;
  }
}

export class ContentRegistFileData {
  constructor({ jobGroupType, jobtype, fileType, uploadPath, uploadFile }) {
    this.jobGroupType = jobGroupType;
    this.jobtype = jobtype;
    this.fileType = fileType;
    this.uploadPath = uploadPath;
    this.uploadFile = extLowercase(uploadFile);
  }
}

export class ContentDeleteFileData {
  constructor({
    jobGroupType,
    jobtype,
    fileId,
    fileType,
    uploadPath,
    uploadFile,
  }) {
    this.jobGroupType = jobGroupType;
    this.jobtype = jobtype;
    this.fileId = fileId;
    this.fileType = fileType;
    this.uploadPath = uploadPath;
    this.uploadFile = uploadFile;
  }
}

export class ContentJobUpdateData {
  constructor({ userId, sessionKey, cid, files }) {
    this.userId = userId;
    this.sessionKey = sessionKey;
    this.cid = cid;
    this.files = files;
  }
}

export class ContentJobUpdateFileData {
  constructor({
    jobGroupType,
    jobtype,
    fileType,
    uploadPath,
    uploadFile,
    jobGroupSt,
    jobGroupPrg,
    jobGroupId,
    jobSt,
    jobPrg,
    jobId,
    fileId,
    cid,
  }) {
    this.jobGroupType = jobGroupType;
    this.jobtype = jobtype;
    this.fileType = fileType;
    this.uploadPath = uploadPath;
    this.uploadFile = uploadFile;
    this.jobGroupSt = jobGroupSt;
    this.jobGroupPrg = jobGroupPrg; // upload succes = 20%
    this.jobGroupId = jobGroupId;
    this.jobSt = jobSt;
    this.jobPrg = jobPrg;
    this.jobId = jobId;
    this.fileId = fileId;
    this.cid = cid;
  }
}

export const ContentJobStatus = {
  working: 'W',
  success: 'S',
};

// V : 동영상
// T : 자동 섬네일 (CDN에서 동영상 변환시 자동 생성)
// A : 오디오
// UT : 사용자 섬네일
// ATTACH : 첨부파일
// PR : 프로필 사진
// ET : 비상배포 이미지
// B1 : PC용 배너
// B2 : 모바일용 배너
export const ContentFileType = {
  video: 'V',
  thumbnail: 'T',
  audio: 'A',
  userThumbnail: 'UT',
  attach: 'ATTACH',
  profile: 'PR',
  emergencyThumbnail: 'ET',
  bannerPC: 'B1',
  bannerMobile: 'B2',
};

// VR : 동영상 등록
// AR : 오디오 등록
// TR : 사용자 섬네일 등록
// ATTACH : 첨부파일 등록
// PR : 프로필 사진 등록 (콘텐츠 등록에서는 사용되지 않음)
// ER : 비상배포 이미지 등록 (사용자 포털에서는 사용되지 않음)
// BR : 배너 등록 (사용자 포털에서는 사용되지 않음)

export const ContentFileJobType = {
  video: 'VR',
  audio: 'AR',
  userThumbnail: 'TR',
  attach: 'ATTACH',
  profile: 'PR',
};
