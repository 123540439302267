import BioConfig from '@/components/easyLogin/bio/BioConfig';
import PinLoginConfig from '@/components/easyLogin/pin/PinLoginConfig';
import { setPopupShowed } from '@/components/easyLogin/EasyLoginReducer';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export default function EasyLoginConfig() {
  const dispatch = useDispatch();

  // 컴포넌트 표시 (마이페이지 진입) 시 팝업 표시 된것으로 처리.
  useEffect(() => {
    dispatch(setPopupShowed(true));
  }, []);

  return (
    <>
      <BioConfig />
      <PinLoginConfig />
    </>
  );
}
