import ThumbnailCard from '@/components/card/ThumbnailCard';
import { useEffect, useMemo, useRef, useState } from 'react';
import useUcmsSearch from '@/components/ucms/search/useUcmsSearch';
import AutoBreadCrumb from '@/components/ucms/common/AutoBreadCrumb';
import SortFilter from '@/components/content/list/SortFilter';
import { SearchApi } from '@/components/search/SearchApi';
import BoardCard from '@/components/ucms/common/BoardCard';
import { BigThumbnailCard } from '@/components/content/list/BoardList';
import GridView from '@/components/ucms/GridView';
import PageIndicator from '@/components/content/paging/PageIndicator';
import { CircularProgress } from '@mui/material';
import usePasswordPopup from '@/components/ucms/common/usePasswordPopup';
import useLogin from '@/components/user/login/useLogin';
import usePage from '@/components/infiniteScroll/usePage';
import useMenu from '@/components/menu/useMenu';
import { ContentType } from '@/app/config/ContentType';
import useSort from '@/components/infiniteScroll/useSort';
import useWindowDimensions from '@/lib/useWindowDimensions';
import useInfiniteScroll from '@/components/infiniteScroll/useInfiniteScroll';

export const sizeClassMap = {
  media_lg: {
    list: 'event_wrap mb-16 grid sm:gap-4 gap-12 grid-cols-1 xl:grid-cols-3 md:grid-cols-2 justify-items-center',
    item: (option) => (
      <BigThumbnailCard
        id={`element-${option.cid}`}
        key={`media_lg_${option.cid}`}
        option={option}
      />
    ),
    rowCount: 1,
    columnCount: 3,
  },
  media_md: {
    list: 'box_wrap grid sm:gap-4 gap-12 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1',
    item: (option) => (
      <div
        className="col"
        id={`element-${option.cid}`}
        key={`media_md_${option.cid}`}
      >
        <ThumbnailCard option={{ ...option, type: 'image' }} />
      </div>
    ),
    rowCount: 2,
    columnCount: 4,
  },
  media_sm: {
    list: 'box_wrap grid sm:gap-4 gap-12 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1',
    item: (option) => (
      <div
        className="col"
        id={`element-${option.cid}`}
        key={`media_sm_${option.cid}`}
      >
        <ThumbnailCard option={{ ...option, type: 'video' }} />
      </div>
    ),
    rowCount: 4,
    columnCount: 4,
  },
  post: {
    list: 'flex grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 row mb-12',
    item: (option) => (
      <BoardCard
        id={`element-${option.cid}`}
        key={`post_${option.cid}`}
        content={option}
      />
    ),
    rowCount: 3,
    columnCount: 3,
  },
};
export default function UcmsPostGridContainer({ menuId }) {
  const { isLoggedIn } = useLogin();
  const { isMobile } = useWindowDimensions();
  const { findMenu } = useMenu();
  const { popup: passwordPopup, onClick, close } = usePasswordPopup();

  const menu = useMemo(() => {
    return findMenu(menuId);
  }, [menuId]);
  const cardType = useMemo(() => {
    switch (menu.contentType) {
      case ContentType.VOD:
      case ContentType.VOD_USER:
      case ContentType.AL:
      case ContentType.VL:
        return 'media_sm';
      case ContentType.OOO:
      case ContentType.OOOR:
      case ContentType.BBS:
      case ContentType.NOTICE:
        return 'post';
      case ContentType.EVENT:
        return 'media_lg';
      case ContentType.IMG_BBS:
        return 'media_lg';
      // return 'media_md';
      default:
        return 'post';
    }
  }, [menuId]);

  const cardOption = useMemo(() => {
    return sizeClassMap[cardType];
  }, [cardType]);

  const pageSize = useMemo(
    () => cardOption.columnCount * cardOption.rowCount,
    [cardType]
  );

  const [sort, setSort] = useSort();

  const [historyPage, setPageRoute] = usePage();
  useEffect(() => {
    // alert(`historyPage changed: ${historyPage}`);
    setPage(historyPage);
  }, [historyPage]);

  console.log(historyPage);

  const {
    dataList: list,
    page,
    setPage,
    isLast,
    isInitLoading,
    isLoading,
    total,
    search,
    sortChange,
    loadMore,
  } = useUcmsSearch({
    initialPage: historyPage,
    isInfiniteLoading: isMobile,
    menuId: menuId,
    isVideo: false,
    isLive: false,
    pageSize,
  });

  const [gridRef, gridBottomRef] = useInfiniteScroll({
    isLoading,
    isLast,
    loadMore,
    setPage,
    isInitLoading: isInitLoading,
    page,
  });

  // const [sortSelected, setSortSelected] = useState(Object.keys(SearchApi.searchSort)[0]);

  useEffect(() => {
    sortChange(SearchApi.searchSort[sort]);
  }, [sort]);

  useEffect(() => {
    search();
  }, [isLoggedIn]);

  // 화면 이동시 자동으로 닫기
  useEffect(() => {
    close();
  }, [isLoading, menuId]);

  const _list = useMemo(() => {
    return list.map((it) => ({
      ...it,
      onClick: (e) => {
        onClick(e, it.menuId, it.cid, it.link, it.isLocked);
      },
    }));
  }, [list]);

  // useInfiniteLoading({isLoading, isLast, loadMore});

  return (
    <>
      <div className="cont_tit flex justify-between items-center mb-8 lg:mb-8">
        <AutoBreadCrumb menuId={menuId} showOnMobile={true} />
        <div />
        <SortFilter selected={sort} onSelect={setSort} />
      </div>

      {isInitLoading ? (
        <div className="w-full flex items-center justify-center">
          <CircularProgress className="loading" />
        </div>
      ) : (
        <>
          {_list.length === 0 && (
            <div className="notice_none text-center h-80 flex flex-col items-center justify-center ">
              <p className="f14 mb-2">게시글이 없습니다.</p>
            </div>
          )}
          {_list.length > 0 && (
            <div className="post-grid" ref={gridRef}>
              <GridView
                dataList={_list ?? []}
                rowCount={cardOption.rowCount}
                columnCount={cardOption.columnCount}
                parentClassName={cardOption.list}
                itemRender={(item, i) => cardOption.item(item)}
              />
              {isMobile && <div ref={gridBottomRef} />}
              <PageIndicator
                option={{
                  page,
                  onPageChange: (page) => {
                    setPageRoute(page, {});
                    if (isMobile) {
                      loadMore();
                    } else {
                      setPage(page);
                    }
                  },
                  totalCount: total,
                  pageSize,
                }}
              />
            </div>
          )}
        </>
      )}

      {passwordPopup}
    </>
  );
}
