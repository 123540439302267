import Chip from '../../../common/chip/chip';
import HorizontalCarousel from '../HorizontalCarousel';
import ChipTab from '../../../common/chip/ChipTab';
import { Collapse } from '@mui/material';

/**
 * 게시판 카테고리를 Chip 형태로 표시
 * @param categories
 * @param category
 * @param setCategory
 * @param twoDepthCategories
 * @param twoDepthCategory
 * @param setTwoDepthCategory
 * @param {React.ReactNode} children ChipTab 뒤에 덧붙일 Element
 * @return {JSX.Element}
 * @constructor
 */
export default function UcmsChipTab({
  categories,
  category,
  setCategory,
  twoDepthCategories,
  twoDepthCollapsed,
  children,
}) {
  // Todo: Collapsable!!

  return (
    <>
      {/*One-Depth tab*/}
      <ChipTab
        categories={categories}
        category={category}
        setCategory={setCategory}
      >
        {children}
      </ChipTab>
      {/*Two-Depth tab*/}
      {twoDepthCategories && (
        <Collapse in={!twoDepthCollapsed}>
          <ChipTab
            categories={twoDepthCategories}
            category={category}
            setCategory={setCategory}
          />
        </Collapse>
      )}
    </>
  );
}
