import { useEffect, useRef } from 'react';

/**
 * 게시판 하위 카테고리 그룹을 접고 펼치기 위한 버튼
 * @param checked
 * @param onChecked
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
export default function CollapsableChip({ checked, onChecked, label }) {
  const ref = useRef();
  const scrollToElement = () => {
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  useEffect(() => {
    if (checked) {
      scrollToElement();
    }
  }, [checked]);

  return (
    <label
      ref={ref}
      className="button_label no_drag chip collapsable"
      data-checked={checked}
      onClick={() => onChecked(!checked)}
    >
      {label}
      <img
        src={`/images/input_arrow_${checked ? 'up' : 'down'}${
          checked ? '_wt' : ''
        }.png`}
      />
    </label>
  );
}
