import { CircularProgress } from '@mui/material';
import BoardList from '@/components/content/list/BoardList';
import { SearchApi } from '@/components/search/SearchApi';
import useMenu, { menuTypeData } from '@/components/menu/useMenu';
import useLogin from '@/components/user/login/useLogin';
import { ContentType } from '@/app/config/ContentType';

export default function TotalSearchTab({ datas, onTabChange }) {
  const { getContentType } = useMenu();
  const { isLoggedIn } = useLogin();

  /**
   * @param list
   * @param contentType
   * @return {ThumbnailOption[]}
   */
  const mapData = (list, contentType) =>
    list
      ?.slice(0, contentType === ContentType.VOD ? 8 : 6)
      .map((item, index) => {
        return {
          ...SearchApi.infoToThumbnailOption(
            item,
            contentType === ContentType.VOD ?? false
          ),
          author: item.menuNm,
        };
      });

  return (
    <div>
      {datas.find((data) => !data.initLoadCompleted) != null ? (
        <div
          className="w-full flex items-center justify-center"
          style={{ height: 320 }}
        >
          <CircularProgress className="loading" />
        </div>
      ) : (
        datas.map((data) => {
          console.log(data);
          return (
            <BoardList
              key={data.menuId}
              option={{
                title: data.title,
                contentType: data.contentType,
                // moreLink: `/ucms/${menuTypeData[key]?.menuId}`,
                onClickMore: (e) => {
                  e.preventDefault();
                  onTabChange(data.menuId);
                },
                pageOption: {
                  totalCount: data.total ?? 0,
                },
                showPage: false,
                // 항상 0페이지만 이용
                thumbnailCardOptions: mapData(
                  data?.initialDataQuery?.data?.list,
                  data.contentType
                ),
              }}
            />
          );
        })
      )}
    </div>
  );
}
