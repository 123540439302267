import './SelectorModal.css';
import useOuterClick from '@/lib/useOuterClick';

/**
 * **⚠️ 사용시 주의점: 드롭다운/버튼의 클릭이벤트에 e.stopPropagation 으로 이벤트 전파를 막아주어야 합니다!**
 * @param {{label:string, value:string}[]} items
 * @param {(string) => void} onSelect
 * @param {string} className
 * @param {boolean} opened
 * @param {(boolean) => void} setOpened
 * @return {JSX.Element}
 * @constructor
 */
export const SelectorModal = ({
  items,
  onSelect,
  opened,
  setOpened,
  className = '',
}) => {
  const [_, inner] = useOuterClick(() => {
    if (opened) setOpened(false);
  });
  return (
    <ul
      className={`select-modal relative ${opened ? 'active' : ''} ${className}`}
    >
      <div className="sheet_inner" ref={inner}>
        {items.map((item, idx) => (
          <li
            key={`selector_${idx}`}
            className="w300"
            onClick={() => onSelect(item.value)}
          >
            {item.label}
          </li>
        ))}
      </div>
    </ul>
  );
};
