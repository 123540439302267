import { useMemo, useState } from 'react';
import Popup from '@/components/popup/Popup';
import useNavigate2 from '@/components/common/transition/useNavigate2';

export default function useDeletePopup({ onDelete }) {
  const navigator = useNavigate2();
  const [popupOption, setPopupOption] = useState(null);

  const _delete = async () => {
    let res = await onDelete?.();
    if (res) {
      setPopupOption({
        title: '삭제 성공',
        message: '게시글이 삭제되었습니다.',
        okCallback: () => {
          setPopupOption(null);
          navigator(-1);
        },
      });
    }
  };

  const popup = useMemo(() => {
    if (popupOption == null) {
      return <></>;
    } else {
      return <Popup {...popupOption} />;
    }
  }, [popupOption]);

  return {
    popup,
    onDelete: _delete,
  };
}
