export const ContentType = {
  VOD: 'VOD',
  VOD_USER: 'VOD_USER',
  BBS: 'BBS',
  IMG_BBS: 'IMG_BBS',
  NOTICE: 'NOTICE',
  OOO: 'OOO',
  OOOR: 'OOOR',
  EVENT: 'EVENT',
  EDU_VOD: 'EDU_VOD',
  VL: 'VL',
  AL: 'AL',
};

export const isVideoType = (contentType) => {
  return (
    contentType === ContentType.VOD ||
    contentType === ContentType.VOD_USER ||
    contentType === ContentType.EDU_VOD
  );
};

export const shouldReportViewTime = (contentType) => {
  return (
    contentType === ContentType.VL ||
    contentType === ContentType.VOD ||
    contentType === ContentType.EDU_VOD ||
    contentType === ContentType.AL ||
    contentType === ContentType.VOD_USER
  );
};
