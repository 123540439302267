import { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

/**
 *
 * @param {FileSelectorOption} option
 * @returns {JSX.Element}
 * @constructor
 */
export default function FileSelector({ option }) {
  const inputRef = useRef();
  const [isOver, setIsOver] = useState(false);
  const allowTypes = useMemo(
    () =>
      option?.fileType?.split(',')?.map((it) => it.replace(/.*\//g, '')) ?? [
        '',
      ],
    [option?.fileType]
  );

  const _onFile = (files) => {
    option?.onFile?.([...files]);
    inputRef.current.value = null;
  };

  return (
    <>
      <label
        className={classNames({
          [option?.className ?? '']: true,
          '!bg-[#F6F6F6]': isOver,
          'cursor-pointer': true,
        })}
        onClick={(e) => inputRef?.current?.click()}
        onDragOver={(e) => {
          e.preventDefault();
          setIsOver(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setIsOver(false);
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOver(false);
          let validList = [...e.dataTransfer.files].filter(
            (file) =>
              allowTypes.filter(
                (allowType) => file.type.indexOf(allowType) >= 0
              ).length > 0
          );
          if (validList.length > 0) {
            _onFile(validList);
          }
        }}
      >
        <img src="/images/add_ico.png" alt="추가" />
        <span className="text">{option?.title}</span>
      </label>
      <input
        ref={inputRef}
        type="file"
        multiple={option?.maxFiles > 1}
        accept={option?.fileType}
        className="hidden"
        onChange={(e) => _onFile(e.target.files)}
      />
    </>
  );
}

export class FileSelectorOption {
  constructor({ title, fileType, maxFiles, onFile, className } = {}) {
    this.title = title;
    this.fileType = fileType;
    this.maxFiles = maxFiles;
    this.onFile = onFile;
    this.className = className;
  }
}
