export default class DefaultContentDto {
  constructor({
    cid,
    author,
    title,
    commentCount,
    viewCount,
    likeCount,
    shareCount,
    fileCount,
    tag,
    date,
    isLiked,
  }) {
    this.cid = cid;
    this.author = author;
    this.title = title;
    this.commentCount = commentCount;
    this.viewCount = viewCount;
    this.likeCount = likeCount;
    this.shareCount = shareCount;
    this.fileCount = fileCount;
    this.tag = tag;
    this.date = date;
    this.isLiked = isLiked;
  }
}
