import MenuApi from '@/components/menu/MenuApi';
import { useEffect, useState } from 'react';

/**
 *
 * @param excludeNormal
 * @returns {[MenuItem]}
 */
export default function useMenuList({ excludeNormal = false } = {}) {
  const [menuList, setMenuList] = useState();
  const getMenuList = async () => {
    try {
      const res = await MenuApi.getMenuListDepth();
      setMenuList(res);
    } catch (e) {}
  };

  useEffect(() => {
    if (menuList === undefined) {
      getMenuList().catch();
    }
  });

  return (
    menuList?.data
      .filter((it) => !excludeNormal || (it.menuId > 0 && it.useYn === 'Y'))
      .sort((a, b) => a.menuId - b.menuId) ?? []
  );
}

class MenuItem {
  menuId;
  menuNm;
  useYn;
}
