import { apiInstance } from '@/app/config/network';
import { dateFormat, trimDuration } from '@/lib/timeUtil';
import { ThumbnailCardOption } from '@/components/card/ThumbnailCard';
import AuthUtil from '@/components/auth/AuthUtil';
import { ContentType } from '@/app/config/ContentType';
import { normalizeNFC } from '@/lib/stringUtil';

export class CommonSearchParam {
  /**
   *
   * @param {string} searchType [VOD, BBS]
   * @param pageSize
   * @param pageNo
   */
  constructor({
    page,
    size,
    keyword = '',
    sort = sort, //SearchApi.searchSort.recent,
    searchType,
    pageNo,
    pageSize,
  }) {
    console.log('constructor: ', {
      page,
      size,
      keyword,
      sort,
      searchType,
      pageNo,
      pageSize,
    });
    this._searchType = searchType;
    this._pageSize = pageSize ?? size ?? 8;
    this._pageNo = (pageNo === 0 ? 1 : pageNo) ?? (page === 0 ? 1 : page) ?? 1;
    this._sortType = sort;
    this._searchKeyword = keyword;
  }
}

export class SearchApi {
  static async homeList({
    page = 0,
    size = 12,
    sort = SearchApi.searchSort.recent,
  }) {
    return apiInstance
      .post('/api/cms/content/contentList', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        _pageNo: page + 1, // api는 페이지 1부터
        _pageSize: size,
        _searchType: 'main',
        _sortType: sort,
        _searchKeyword: '',
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   * @param {CommonSearchParam} param
   * @returns {Promise<*>}
   */
  static async commonSearch(param) {
    console.log('검색 조건 확인 - ' + param._sortType);

    param._sortType = SearchApi.searchSort[param._sortType];

    return apiInstance
      .post('/api/cms/content/contentlist', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        ...param,
      })
      .then((res) => {
        return res.data;
      });
  }

  static async totalSearch({
    page = 0,
    size = 8,
    keyword = '',
    sort = SearchApi.searchSort.recent,
  }) {
    return apiInstance
      .post('/api/cms/content/contentlist', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        _pageNo: page + 1, // api는 페이지 1부터
        _pageSize: size,
        _searchType: '통합검색',
        _sortType: sort,
        _searchKeyword: normalizeNFC(keyword),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async inMenuSearch({
    menuId,
    page = 0,
    size = 8,
    isLive,
    keyword = '',
    sort = SearchApi.searchSort.recent,
    contentType,
  }) {
    return apiInstance
      .post('/api/cms/content/contentList', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        _pageNo: page + 1, // api는 페이지 1부터
        _pageSize: size,
        _searchType: 'menu',
        menuId: menuId,
        _liveYn: isLive ? 'Y' : null,
        _sortType: sort,
        _searchKeyword: normalizeNFC(keyword),
        contentType: contentType,
      })
      .then((res) => {
        return res.data;
      });
  }

  static async liveSearch({
    menuId,
    page = 0,
    size = 8,
    isLive,
    keyword = '',
    sort = SearchApi.searchSort.recent,
    contentType,
  }) {
    return apiInstance
      .post('/api/cms/content/contentList', {
        ...(await AuthUtil.getOneTimeSessionKey()),
        _pageNo: page + 1, // api는 페이지 1부터
        _pageSize: size,
        _searchType: 'menu',
        menuId: menuId,
        _liveYn: isLive ? 'Y' : null,
        _sortType: sort,
        _searchKeyword: normalizeNFC(keyword),
        contentType: contentType,
      })
      .then((res) => {
        return res.data;
      });
  }

  static isUserThumbnail(it) {
    return it.userThumbFile != null && it.userThumbFile != '';
  }

  static isPortraitThumbnail(it) {
    let hasDirect = it.directThumbFile != null && it.directThumbFile != '';
    if (hasDirect) return it.directThumbRotateYn === 'Y';
    let hasUserOrgFile = it.userOrgFile != null && it.userOrgFile != '';
    if (hasUserOrgFile) return false;
    // let hasUserPosterFile = it.userPosterFile != null && it.userPosterFile != '';
    // if(hasUserPosterFile) return `${it.userThumbUrl}${it.userPosterFile}`;
    let hasUserThumb = it.userThumbFile != null && it.userThumbFile != '';
    if (hasUserThumb) return it.userThumbRotateYn === 'Y';
    let hasDefaultThumb = it.thumbFile != null && it.thumbFile != '';
    if (hasDefaultThumb)
      return (
        it.thumbRRotateYn === 'Y' ||
        it.thumbRotateYn === 'Y' ||
        it.rotateYn === 'Y'
      );
    return false;
  }

  static getThumbnailUrl(it) {
    let hasDirect = it.directThumbFile != null && it.directThumbFile != '';
    if (hasDirect) return `${it.userThumbUrl}${it.directThumbFile}`;
    let hasUserOrgFile = it.userOrgFile != null && it.userOrgFile != '';
    if (hasUserOrgFile) return `${it.userThumbUrl}${it.userOrgFile}`;
    // let hasUserPosterFile = it.userPosterFile != null && it.userPosterFile != '';
    // if(hasUserPosterFile) return `${it.userThumbUrl}${it.userPosterFile}`;
    let hasUserThumb = it.userThumbFile != null && it.userThumbFile != '';
    if (hasUserThumb) return `${it.userThumbUrl}${it.userThumbFile}`;
    let hasDefaultThumb = it.thumbFile != null && it.thumbFile != '';
    if (hasDefaultThumb) return `${it.thumbUrl}${it.thumbFile}`;
    return '';
  }

  /**
   * @deprecated
   * @param it
   * @param useTranscode
   * @param useLink
   * @param ignoreTranscode
   * @returns {ThumbnailCardOption}
   */
  static infoToThumbnailOption(
    it,
    useTranscode = true,
    useLink,
    ignoreTranscode = false
  ) {
    return SearchApi.infoToThumbnailOption2({
      it,
      useTranscode,
      useLink,
      ignoreTranscode,
    });
  }

  /**
   *
   * @param it
   * @param useTranscode
   * @param useLink
   * @param ignoreTranscode
   * @param isLms
   * @returns {ThumbnailCardOption}
   */
  static infoToThumbnailOption2({
    it,
    useTranscode = true,
    useLink,
    ignoreTranscode = false,
  }) {
    let thumbUrl = SearchApi.getThumbnailUrl(it);
    let isLive = it.liveYn === 'Y';
    let isLms = it.contentType === ContentType.EDU_VOD;
    let tag = SearchApi.getTag(it.contentType, it.topFixedYn, it.oneononeYn);
    let hasLastSec = it.lastSec != null && it.lastSec > 0;
    let link = `/ucms/view/${it.menuId}/${it.cid}`;
    if (hasLastSec && !isLms) {
      link = `${link}?lastSec=${it.lastSec}`;
    }

    return new ThumbnailCardOption({
      id: it.cid,
      cid: it.cid,
      useLink: useLink,
      menuId: it.menuId,
      link: link,
      // thumbnailUrl: `${it.url}${it.thumbFile}`,
      thumbnailUrl: thumbUrl,
      category: it.menuNm,
      eduGrpNm: it.eduGrpNm,
      isPortrait: SearchApi.isPortraitThumbnail(it),
      title: it.title,
      tag: tag,
      thumbnailVideoUrl: it.proxyFile,
      isNowLive: isLive,
      // duration: it.duration?.replace(/^(00:0)|(00:)|(0)/, ''),
      duration: trimDuration(it.duration ?? it.videoDuration),
      audioDuration: trimDuration(it.audioDuration),
      isLocked: it.contentPwdYn === 'Y',
      author: it.nickNm,
      liveStartTime: it.liveStartDt,
      date: dateFormat({
        value: it.publishDt ?? it.reserveDt ?? it.regDt,
        format: 'yyyy.M.d',
      }),
      viewCount: it.viewCnt,
      likeCount: it.likeCnt,
      liveSt: it.liveSt,
      commentCount: it.replyCnt,
      shareCount: it.shareCnt,
      lastSec: it.lastSec ?? 0,
      viewSec: it.viewSec ?? 0,
      totalSec: it.totalSec,
      ignoreTranscode: ignoreTranscode,
      eventStartDt: it.eventStartDt,
      eventEndDt: it.eventEndDt,
      eventStatus: it.eventStatus,
      isLms: isLms,
      completionYn: it.completionYn,
      eduEndDt: it.eduEndDt,
      eduPrg: it.edu_prg ?? it.eduPrg,
      isTranscoding: isLive
        ? false
        : useTranscode &&
          it.videoCdnSt !== 'S' &&
          it.audioCdnSt !== 'S' &&
          it.cdnSt !== 'S',
    });
  }

  static getTag(contentType, topFixedYn, oneononeYn) {
    let tag;
    if (contentType === ContentType.OOO) {
      if (oneononeYn === 'Y') {
        tag = 'inquiryDone';
      } else {
        tag = 'inquiryWaiting';
      }
    }
    // if (contentType === ContentType.NOTICE) {
    if (topFixedYn === 'Y') {
      tag = 'notice';
    }
    // }

    return tag;
  }

  /**
   *
   * @param it
   * @param useTranscode
   * @returns {ThumbnailCardOption}
   */
  static relateInfoToThumbnailOption(it, useTranscode = true) {
    return {
      ...SearchApi.infoToThumbnailOption(it, useTranscode),
      isPortrait: it?.thumbRotateYn === 'Y',
    };
  }

  static searchSort = {
    recent: '최신순',
    viewCount: '조회수순',
    likeCount: '좋아요순',
  };

  static async ttRegSearch() {
    return apiInstance.get('/api/cms/tt/regCountList').then((res) => {
      return res.data;
    });
  }
}
