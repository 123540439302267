import { apiInstance } from '@/app/config/network';
import AuthUtil from '@/components/auth/AuthUtil';

export default class MyAffltApi {
  static async affltEmailCodeReq(changeId) {
    return apiInstance
      .post('/api/cms/signup/emailChangeReq', {
        changeEmail: changeId,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async affltEmailCodeAuth(authNo) {
    return apiInstance
      .post('/api/cms/signup/emailChangeAuth', {
        authNo: authNo,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res.data;
      });
  }

  static async changeEmailApply(body) {
    return apiInstance
      .post('/api/cms/signup/changeEmailApply', {
        ...body,
        ...(await AuthUtil.getOneTimeSessionKey()),
      })
      .then((res) => {
        return res;
      });
  }
}

export const UserType = {
  employee: 'UT01',
  normal: 'UT02',
};
