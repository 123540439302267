import { useState } from 'react';
import MyPageApi, {
  UserContentListParam,
} from '@/components/user/my/MyPageApi';
import ContentApi from '@/components/content/ContentApi';
import useLogin from '@/components/user/login/useLogin';

export default function useWriteContentSearch({ contentType }) {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);
  const { data } = useLogin();

  const load = async ({ page = 1, size = 9 } = {}) => {
    setPage(page);
    setIsLoading(true);
    let res = await MyPageApi.userContentList(
      new UserContentListParam({
        userNo: data?.userNo,
        searchType: contentType,
        pageNo: page,
        pageSize: size,
      })
    );
    setList(res.data);
    setTotal(res.total ?? 0);
    setIsLoading(false);
    return res.data;
  };

  const remove = async (cids) => {
    setIsLoading(true);
    for (let cid of cids) {
      await ContentApi.contentDelete(cid);
    }
    setIsLoading(false);
    return true;
  };

  return {
    isLoading,
    list,
    total,
    load,
    page,
    remove,
  };
}
