import { useState } from 'react';
import Chip from '@/components/common/chip/chip';
import { toShortCount } from '@/lib/mathUtil';

export default function LiveChatNotice({ message, onNoticeDeleted, isAdmin }) {
  const [headCollapsed, setHeadCollapsed] = useState(true);

  return (
    <>
      {message && (
        <div
          className={`notice flex-grow-0 flex-shrink-0 ${
            headCollapsed ? '' : 'active'
          }`}
        >
          <div
            data-toggle="collapse"
            aria-expanded={headCollapsed}
            className="notice_head"
            onClick={() => setHeadCollapsed((prevState) => !prevState)}
          >
            <span className="tag mr-2">공지</span>
            <div className="f14_w300 notice_text">
              <span className="f14_w300">{message}</span>
              <div className="delete_icon">
                {!headCollapsed && isAdmin && (
                  <Chip
                    className="sm mr-2"
                    borderColor="#eee"
                    defaultTextColor="#999"
                    onChecked={(_, e) => {
                      e?.stopPropagation();
                      onNoticeDeleted();
                    }}
                    label="삭제"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
