import { PopupContainer } from '@/components/popup/Popup';
import { useSelector } from 'react-redux';
import { Dialog } from '@mui/material';

export default function BugReportPopup() {
  const {
    show,
    title,
    body,
    okButton,
    cancelButton,
    okCallback,
    cancelCallback,
  } = useSelector((state) => state.bugReportReducer);

  return (
    <>
      <Dialog open={show}>
        <PopupContainer
          title={title}
          message={body}
          okMessage={okButton}
          okCallback={okCallback}
          cancelMessage={cancelButton}
          cancelCallback={cancelCallback}
        />
      </Dialog>
    </>
  );
}
