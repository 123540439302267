import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closePIP, showPIP } from '@/components/player/playerReducer';
import useWindowDimensions from '@/lib/useWindowDimensions';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import BackgroundPlayApi from '@/components/player/BackgroundPlayApi';
import useMobile from '@/lib/useMobile';
import { VideoOptionModal } from '@/components/modal/videoOption/VideoOptionModal';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { useSearchParams } from 'react-router-dom';
import DeviceApi from '@/components/device/DeviceApi';

/**
 *
 * @param id
 * @param {WooriPlayerOption} option
 * @param globalName
 * @returns {{call: call, init: init, dispose: dispose}}
 */

const Config = {
  showNextContentTimeS: 5,
  resumePlayTimeThreshold: 10,
};

export default function useVideoJsPlayer(
  globalName = 'WooriPlayer',
  isEmbed = false
) {
  // console.log(isEmbed);
  const navigator = useNavigate2();
  const { query, state } = useLocation2();
  const dispatch = useDispatch();
  const [_, setSearchParam] = useSearchParams();
  const playOption = useSelector((state) => state.playerReducer.option);
  const isPip = useSelector((state) => state.playerReducer.isPip);
  const { isMobile } = useWindowDimensions();
  const { isMobile: isMobile2 } = useMobile();
  const needMobileUI = useMemo(
    () => (isMobile || isMobile2) && !isEmbed,
    [isMobile, isMobile2, isEmbed]
  );
  const [player, setPlayer] = useState(null);
  const globalObj = useMemo(() => window[globalName], [globalName]);
  const pipRef = useRef();
  const pauseTimeRef = useRef(null);
  const [showMobileConfigPopup, setShowMobileConfigPopup] = useState(false);
  // const [wasPlaying, setWasPlaying] = useState(false);
  const wasPlayingRef = useRef(false);
  // const showMobileConfigPopupRef = useRef(false);

  useEffect(() => {
    pipRef.current = isPip;
  }, [isPip]);

  /**
   *
   * @type {WooriPlayerOption}
   */
  const option = useMemo(() => {
    return {
      ...playOption,
      mobile: needMobileUI,
      customSettingBtn: needMobileUI
        ? {
            onclick: function (e, active, player) {
              setShowMobileConfigPopup(active);
              console.log('mobile player setting', active);
            },
          }
        : false,
      qualitySelector: !needMobileUI, //해상도
      settingsMenuItem: {
        qualitySelector: !needMobileUI, //해상도
        subsCapsButton: false, //자막
        playbackRateMenuButton: !needMobileUI, //배속
      },
      speeds: playOption?.liveMode === true ? [] : playOption.speeds,
      nextContents: {
        ...playOption.nextContents,
        content: {
          ...playOption.nextContents?.content,
          cancelCallback: function (e) {
            console.log('nextContentClosed');
            nextContentClosed(true);
          },
        },
      },
    };
  }, [playOption, needMobileUI]);

  const id = useMemo(() => option.playerId, [option.playerId]);

  let rateChangeTimer = null;

  useEffect(() => {
    if (!option.canSkip)
      rateChangeTimer = setInterval(() => {
        let player = getPlayer();
        try {
          player?.playbackRate(1);
        } catch (e) {
          //ignore
        }
      }, 100);
    return () => {
      if (rateChangeTimer != null) {
        clearInterval(rateChangeTimer);
        rateChangeTimer = null;
      }
    };
  }, [option]);

  // 안드로이드 웹뷰가 백그라운드로 가면 자동 pause 되므로, pause 시간이 1000ms 이내면 재생 중이었다고 판단.
  const isPlaying = () => {
    return (
      !getPlayer().paused() ||
      (pauseTimeRef.current != null &&
        new Date().getTime() - pauseTimeRef.current <= 1000)
    );
  };

  const nextContentShowed = (val) => {
    if (val != null) {
      getPlayer().nextContentShowed = val;
      return val;
    } else {
      return getPlayer().nextContentShowed === true;
    }
  };

  const nextContentClosed = (val) => {
    if (val != null) {
      getPlayer().nextContentClosed = val;
      return val;
    } else {
      return getPlayer().nextContentClosed === true;
    }
  };

  useEffect(() => {
    let callback = function (e) {
      console.log('landscape event');
      if (needMobileUI && pipRef.current === false) {
        if (getPlayer()?.isFullscreen() === false) {
          document
            .querySelector('.vjs-fullscreen-control.vjs-control.vjs-button')
            .click();
        }
      }
    };

    let foregroundCallback = async function (e) {
      if (!option.canSkip) {
        return;
      }
      await BackgroundPlayApi.stop();
      await BackgroundPlayApi.ready();
    };

    let backgroundStopCallback = async function (e) {
      if (!option.canSkip) {
        return;
      }
      console.log('backgroundStop event', JSON.stringify(e.detail));
      console.log(`backgroundStop wasPlaying ${wasPlayingRef.current}`);
      if (!wasPlayingRef.current) {
        return;
      }
      let player = getPlayer();
      if (player == null) {
        return;
      }
      if (option.liveMode) {
        await player.play();
        // 라이브인경우 Chunk가 넘어가기때문에, Seek 하지 않음.
      } else {
        await player.currentTime(e.detail.pos / 1000);
        await player.play();
      }
    };

    let backgroundCallback = async function (e) {
      if (!option.canSkip) {
        return;
      }
      // iOS에서는 버튼으로 먼저 백그라운드를 준비하여 진입한다.
      // 백그라운드 생명주기 반응 시, 백그라운드 플레이어가 준비되어있다면 다른 처리는 하지 않는다.
      let playing = isPlaying();
      if (DeviceApi.getAppBuildNumber() >= 119) {
        let backgroundPlaying = await BackgroundPlayApi.isPlaying();
        wasPlayingRef.current = backgroundPlaying || playing;
        if (backgroundPlaying) {
          console.log(
            `background event ${new Date().getTime()} but already playing`
          );
          return;
        }
        // iOS는 이제 버튼을 통한 백그라운드만 허용
        if (DeviceApi.isIOSApp()) {
          return;
        }
      } else {
        wasPlayingRef.current = playing;
      }
      if (!playing) {
        console.log(`background event ${new Date().getTime()} but not playing`);
        return;
      }
      console.log(`background event ${new Date().getTime()}`);
      let src = option?.qualities[0]?.src;
      let player = getPlayer();
      console.log('paused', player?.paused());
      let duration;
      let currentTime;
      if (option.liveMode) {
        // duration, currentTime not set
      } else {
        duration = player?.duration();
        currentTime = player?.currentTime();
      }
      let title = option?.title;
      let author = option?.author;
      let thumbnailUrl = option?.thumbnail;
      if (src == null || player == null) {
        console.error('src or player is null', src, player);
        return;
      }
      BackgroundPlayApi.play({
        src,
        title,
        thumbnailUrl,
        duration,
        currentTime,
        author,
      });

      await BackgroundPlayApi.readyRelease();
    };

    document.addEventListener('landscape', callback);
    document.addEventListener('foreground', foregroundCallback);
    document.addEventListener('background', backgroundCallback);
    document.addEventListener('backgroundStop', backgroundStopCallback);
    return () => {
      document.removeEventListener('landscape', callback);
      document.removeEventListener('foreground', foregroundCallback);
      document.removeEventListener('background', backgroundCallback);
      document.removeEventListener('backgroundStop', backgroundStopCallback);
      // videojs가 전체화면시 overflow hidden을 거는데, 뒤로가기로 이탈 시 스크롤이 안되는 문제가 있어서 해제.
      document.querySelector('html').style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (option != null && !option.isLivePolling) {
      init(option);
    }
  }, [option]);

  const getPlayer = () => {
    return globalObj.instance?.[id]?.player;
  };

  const init = (_option) => {
    dispose();
    const o = _option ?? option;
    o.floatingMode.clickCallback = (active) => {
      if (active) {
        dispatch(showPIP());
      } else {
        dispatch(closePIP());
        let targetPath = `/ucms/view/${option.menuId}/${option.cid}`;
        document
          .querySelector(`#${id}`)
          .classList.remove('woori-floating-mode');
        if (!window.location.pathname.startsWith(targetPath)) {
          let playedPosition = getPlayer()?.currentTime();
          if (playedPosition != null) {
            navigator(
              `/ucms/view/${option.menuId}/${option.cid}?lastSec=${playedPosition}`
            );
          } else {
            navigator(`/ucms/view/${option.menuId}/${option.cid}`);
          }
        }
      }
    };
    o.floatingMode.cancelCallback = (active) => {
      dispatch(closePIP());
      let targetPath = `/ucms/view/${option.menuId}/${option.cid}`;
      if (window.location.pathname.startsWith(targetPath)) {
        // pip 플레이어와 동일한 영상 상세인 경우 스타일 복구
        document
          .querySelector(`#${id}`)
          .classList.remove('woori-floating-mode');
      } else {
        dispose();
      }
    };
    const playerClass = globalObj.default;

    // backgroundPlayReady(o);
    try {
      const player = new playerClass(id, o, (_video, _player) => {
        try {
          console.log('player created', _video, _player);
          if (!globalObj.instance) {
            globalObj.instance = {};
          }
          globalObj.instance[id] = _player;
          // 사용자 동작으로 진입시만 자동재생
          _player.player.autoplay(option?.autoplay ?? false);
          nextContentClosed(false);
          nextContentShowed(false);
          let preventCurrentTime = o.startPosition ?? 0;
          let src = null;
          _player.player.on('loadedmetadata', () => {
            let duration = _player.player.duration();
            src = _player.player.src();
            // console.log('startPosition', o.startPosition);
            let safeStartPosition = Math.min(
              preventCurrentTime,
              duration - Config.resumePlayTimeThreshold
            );
            // console.log(
            //   'loadedmetadata',
            //   preventCurrentTime,
            //   safeStartPosition,
            //   duration
            // );
            _player?.player.currentTime(safeStartPosition);
            if (state?.fullscreen === true) {
              document
                .querySelector('.vjs-fullscreen-control.vjs-control.vjs-button')
                .click();
              navigator(window.location.pathname);
            }
          });
          // const fullScreenButton = document.querySelector('.vjs-fullscreen-control.vjs-control.vjs-button');
          // fullScreenButton.replaceWith(fullScreenButton.cloneNode(true));
          // document.querySelector('.vjs-fullscreen-control.vjs-control.vjs-button').addEventListener('click', function () {
          //     if (!_player?.player.isFullscreen())
          //         _player?.player.requestFullscreen();
          //     else _player?.player.exitFullscreen();
          // });

          _player.player.on('play', () => {
            console.log('play');
            pauseTimeRef.current = null;
          });
          _player.player.on('pause', () => {
            console.log('pause');
            pauseTimeRef.current = new Date().getTime();
          });
          _player.player.on('ended', () => {
            console.log('ended');
            let duration = _player.player.duration();
            option.timeUpdateCallback(duration, true);
            // 다음 영상 자동 넘어가기 기본 비활성화
            // if (!nextContentClosed()) {
            //   // 다음영상 표시를 닫지 않았으면, 다음영상으로 이동
            //   const isFullScreen = _player.player.isFullscreen();
            //   const nextContent = option?.nextContents;
            //   let menuId = nextContent.content?.menuId;
            //   let cid = nextContent.content?.cid;
            //   if (menuId != null && cid != null) {
            //     navigator(`/ucms/view/${menuId}/${cid}`, {
            //       state: { fullscreen: isFullScreen },
            //     });
            //   }
            // }
          });
          _player.player.on('ratechange', function (e) {
            let _video = e.target.player;
            // 배속설정 막혀있는데 강제로 변경했을 경우
            if (!option.canSkip) {
              // console.log('canSkip ratechange');
              _video.playbackRate(1); // 정상속도로 변경함
              e.stopImmediatePropagation();
            }
          });

          _player.player.on('timeupdate', () => {
            let currentTime = _player.player.currentTime();
            let newSrc = _player.player.src();
            // console.log('timepdate', _player.player.currentTime(), newSrc);
            if (!option.canSkip) {
              if (currentTime > preventCurrentTime + 1) {
                // console.log(
                //   'timeupdate block',
                //   currentTime,
                //   preventCurrentTime
                // );
                _player.player.currentTime(preventCurrentTime);
                return;
              }
              if (src === newSrc) {
                // console.log('timeupdate set', currentTime, preventCurrentTime);
                preventCurrentTime = currentTime;
              } else {
                // src 변경시 시간 저장 안함.
                // console.log(
                //   'timepdate newSrc',
                //   src,
                //   newSrc,
                //   currentTime,
                //   preventCurrentTime
                // );
                // src = newSrc;
                // _player.player.currentTime(preventCurrentTime);
              }
            }
            let duration = _player.player.duration();
            // useContent 에서 자체 주기로 직접 처리
            // if (option?.timeUpdateCallback != null) {
            //   option.timeUpdateCallback(currentTime);
            // }
            const nextContent = option?.nextContents;
            if (nextContent?.use === true) {
              // 5초 남았을때 다음 영상 표시
              if (duration - currentTime < Config.showNextContentTimeS) {
                if (!nextContentShowed() && !nextContentClosed()) {
                  nextContentShowed(true);
                  _player.showNextContent();
                }
              }
            }
            // console.log(_player.player.currentTime());
            // console.log(_player.player.duration());
          });

          _player.player.on('seeking', function (e) {
            if (!option.canSkip) {
              if (preventCurrentTime < _player.player.currentTime()) {
                console.log('seeking blocked');
                e.stopImmediatePropagation();
                _player.player.currentTime(preventCurrentTime);
              }
            }
          });

          // safari 오류로 제거
          // _player.player.on('seeked', function (e) {
          // if (!option.canSkip) {
          //   console.log('seeked');
          //   e.stopImmediatePropagation();
          //   if (preventCurrentTime < _player.player.currentTime()) {
          //     e.stopImmediatePropagation();
          //     _player.player.currentTime(preventCurrentTime);
          //   }
          // }
          // });
        } catch (e) {
          console.error(e);
        }
      });
      setPlayer(player);
    } catch (e) {
      console.error(e);
    }
  };

  const popup = useMemo(() => {
    if (showMobileConfigPopup) {
      // return <Popup
      //     title='테스트'
      //     c
      // />

      return (
        <VideoOptionModal
          canSkip={option.canSkip}
          onClose={() => {
            document
              .querySelector(
                '.vjs-cstm-setting-button.vjs-screen-button.screen-top'
              )
              .click();
          }}
          // opened={true}
        />
      );
    } else {
      return null;
    }
  }, [showMobileConfigPopup]);

  const dispose = () => {
    try {
      globalObj.instance?.[id]?.player?.dispose();
      delete globalObj.instance?.[id];
      player?.player?.dispose();
    } catch (e) {
      // console.error(e);
    }
    try {
      window.document.getElementById(`${id}-container`).id = id;
    } catch (e) {
      // console.error(e);
    }
  };

  const call = (method, ...args) => {
    globalObj.instance?.[id]?.player?.[method]?.(...args);
  };

  return {
    init,
    dispose,
    call,
    popup,
  };
}
