import useTotalSearch from '@/components/search/useTotalSearch';
import { useEffect, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import BoardList from '@/components/content/list/BoardList';
import { SearchApi } from '@/components/search/SearchApi';
import useMenu, { menuTypeData } from '@/components/menu/useMenu';
import useLogin from '@/components/user/login/useLogin';

export default function TotalSearchTab({ keyword, onTabChange, totalSearch }) {
  const { getContentType } = useMenu();
  const { isLoggedIn } = useLogin();
  const { hasError, isLoading, data, search } = totalSearch;

  // key와 total_key 모두 있는 경우의 키들을 추출
  const searchedKeyword = useMemo(() => {
    if (data == null) return null;
    let keys = Object.keys(data);
    return keys.filter((k) => keys.includes(`total_${k}`));
  }, [data]);

  useEffect(() => {
    search(keyword);
  }, [isLoggedIn, keyword]);

  return (
    <div>
      {data != null &&
        searchedKeyword.map((key) => {
          const type = menuTypeData[key];
          const contentType = getContentType(type?.menuId);

          return (
            <BoardList
              option={{
                title: type?.title ?? key,
                contentType: contentType,
                // moreLink: `/ucms/${menuTypeData[key]?.menuId}`,
                onClickMore: (e) => {
                  e.preventDefault();
                  onTabChange(type?.tabIndex);
                },
                pageOption: {
                  totalCount: data[`total_${key}`],
                },
                showPage: false,
                thumbnailCardOptions: data[key]?.map((item, index) =>
                  SearchApi.infoToThumbnailOption(
                    item,
                    menuTypeData[key]?.isVideo ?? false
                  )
                ), // total search
              }}
            />
          );
        })}
      {isLoading && (
        <div className="w-full flex items-center justify-center">
          <CircularProgress className="loading" />
        </div>
      )}
    </div>
  );
}
