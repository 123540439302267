export default class Config {
  static emailAuthCodeLength = 6;
  static emailMaxLength = 100;
  static nicknameMinLength = 3;
  static nicknameMaxLength = 10;

  static myMenuId = 6;
  static myPointId = 43;
  static myInfoId = 44;

  static myFollowId = 45;
  static myAffltId = 82;
  static noThumbnail = '/images/thumb_no.svg';

  static s3Url = process.env.REACT_APP_STORAGE_URL;

  static chatUrl = process.env.REACT_APP_CHAT_URL;

  static liveUpdateInterval = 1000 * 10;

  static myMenuTTAuth = [45681, 165, 4016, 4017];
}
