import { createPortal } from 'react-dom';

export default function BoardVideoPortal(options) {
  const container = document.querySelector('.player_portal');
  try {
    return createPortal(<BoardVideoContainer {...options} />, container);
  } catch (e) {
    // 글 밖에서 pip를 닫으면 에러가 발생한다.
    return '';
  }
}

function BoardVideoContainer({ children } = {}) {
  return children;
}
