import WooriPlayer, {
  WooriPlayerOption,
} from '@/components/player/WooriPlayer';

export default function PlayerTestPage() {
  return (
    <div>
      <WooriPlayer
        option={
          new WooriPlayerOption({
            qualities: [
              {
                label: '기본',
                src: 'https://cdn.wooriwbn.com/wooribank/2023/03/22//20230322-155750_1080p.mp4',
                type: 'video/mp4',
              },
            ],
          })
        }
      />
    </div>
  );
}
