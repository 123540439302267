import { forwardRef, useEffect, useMemo, useRef } from 'react';
import useVideoJsPlayer from '@/components/player/useVideojsPlayer';
import { useSelector } from 'react-redux';
import useCountDown from '@/components/card/thumbnail/useCountDown';
import { LiveState } from '@/components/card/thumbnail/Thumbnail';
import { dateFormat, newDate, secToMmss } from '@/lib/timeUtil';
import usePlayerZoom from '@/components/player/usePlayerZoom';
import usePlayerGesture from '@/components/player/usePlayerGesture';
import Config from '@/app/config/config';

const BlurryPlayer = ({ children, backgroundImage }) => {
  return (
    <div
      style={{ backgroundColor: '#fafafa', position: 'relative' }}
      className="w-full h-full"
    >
      <div
        className="countdown absolute w-full h-full live_text_border flex flex-col items-center justify-center"
        style={{ zIndex: 99 }}
      >
        {children}
      </div>
      <div
        className="absolute w-full h-full"
        style={{
          backgroundImage: `url(${backgroundImage}), url(${Config.noThumbnail})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundColor: '#fafafa',
          backgroundPosition: 'center',
          overflow: 'hidden',
          filter: 'blur(2px)',
        }}
      ></div>
    </div>
  );
};

const VideoPlayer = forwardRef(({ option, init, popup }, videoRef) => {
  useEffect(() => {
    init(option);
  }, []);
  return (
    <>
      {option?.cdnSt !== 'S' && option?.cdnSt !== 'B' && (
        <div className="w-full h-full flex items-center justify-center">
          <span className="video_processing">동영상 처리중입니다.</span>
        </div>
      )}
      {(option?.cdnSt === 'S' || option?.cdnSt === 'B') && (
        <>
          <div ref={videoRef} id={option.playerId} />
        </>
      )}
      {popup != null && popup}
    </>
  );
});

const LivePlayer = forwardRef(({ option, init, dispose }, videoRef) => {
  const { remainTime, restart } = useCountDown({
    autoStart: true,
    targetTime: newDate(option?.liveStartTime),
  });

  useMemo(() => {
    console.log('liveStartTiem/라이브 상태 변경으로 인한 useCountDown 재시작');
    restart();
  }, [option?.liveStartTime, option?.cdnSt]);

  const liveState = useMemo(() => {
    if (option?.cdnSt === 'S') return LiveState.end;
    if (option?.cdnSt === 'B') {
      return LiveState.live;
    }
    if (remainTime < 1000 * 60 * 60) {
      if (remainTime > 0) {
        return LiveState.countdown;
      } else {
        return LiveState.waitOpen;
      }
    } else {
      return LiveState.reserved;
    }
  }, [option, remainTime]);

  useEffect(() => {
    if (liveState === LiveState.live) init();
    else dispose();
  }, [liveState]);

  let content;

  if (liveState === LiveState.live)
    return <div ref={videoRef} id={option.playerId} />;
  switch (liveState) {
    case LiveState.waitOpen:
      content = (
        <>
          <span className="w-full h-full flex justify-center items-center live_text_border">
            생방송이 준비중입니다.
          </span>
        </>
      );
      break;
    case LiveState.countdown:
      content = (
        <>
          <span className="top_label">
            <span className="text top live_text_border2">
              곧 생방송이 시작됩니다.
            </span>
          </span>
          <span className="countdown live_text_border3">
            {secToMmss(remainTime / 1000)}
          </span>
        </>
      );
      break;
    case LiveState.reserved:
      content = (
        <>
          <span className="text-white live_text_border2">방송 시작 예정일</span>
          <span className="live_text_border">
            {dateFormat({
              value: option?.liveStartTime,
              format: 'MM월 dd일 a/p HH:mm',
              apText: ['AM', 'PM'],
            })}
          </span>
        </>
      );
      break;
    case LiveState.end:
      content = (
        <>
          <span className="w-full h-full flex justify-center items-center live_text_border">
            생방송이 종료되었습니다.
          </span>
        </>
      );
      break;
    default:
      content = <></>;
  }

  return (
    <BlurryPlayer backgroundImage={option.thumbnail}>{content}</BlurryPlayer>
  );
});

/**
 *
 * @param {WooriPlayerOption} option
 * @returns {JSX.Element}
 * @constructor
 */
export default function WooriPlayer({ embed = false }) {
  /**
   * @type {WooriPlayerOption}
   */
  const option = useSelector((state) => state.playerReducer.option);
  // const {init, dispose} = useVideoJsPlayer(option.playerId, option);
  const { init, dispose, popup } = useVideoJsPlayer('WooriPlayer', embed);
  const videoRef = useRef(null);
  usePlayerZoom();
  usePlayerGesture();

  useEffect(() => {
    console.log(videoRef);
    console.log(
      videoRef.current,
      videoRef.current?.querySelector(
        '.vjs-floating-button.vjs-screen-button.screen-top'
      )
    );
  }, [videoRef]);

  // useEffect(() => {
  //     const tag = 'WooriPlayer';
  //     console.log(`${tag} init`);
  //
  //     return () => {
  //         console.log(`${tag} dispose`);
  //     }
  // }, []);

  return (
    <>
      {option?.liveMode ?? false ? (
        <LivePlayer
          ref={videoRef}
          init={init}
          option={option}
          dispose={dispose}
        />
      ) : (
        <VideoPlayer ref={videoRef} init={init} option={option} popup={popup} />
      )}
    </>
  );
}

export class WooriPlayerHelper {
  static getPlayer() {
    return window.WooriPlayer?.instance?.wooriPlayer;
  }

  static getSrc() {
    return WooriPlayerHelper.getPlayer()?.player?.src();
  }

  static setSrc(src) {
    console.log('setSrc', src);
    return WooriPlayerHelper.getPlayer()?.player?.src(src);
  }

  static getCurrentTime() {
    return WooriPlayerHelper.getPlayer()?.player?.currentTime();
  }

  static setCurrentTime(sec) {
    console.log('setCurrentTime', sec);
    return WooriPlayerHelper.getPlayer()?.player?.currentTime(sec);
  }

  static getPlaybackRate() {
    return WooriPlayerHelper.getPlayer()?.player?.playbackRate();
  }

  static setPlaybackRate(rate) {
    console.log('setPlaybackRate', rate);
    return WooriPlayerHelper.getPlayer()?.player?.playbackRate(rate);
  }

  static getSources() {
    return WooriPlayerHelper.getPlayer()?.player?.options_?.sources;
  }
}

export const DefaultSpeedRates = [2.0, 1.5, 1.0, 0.75, 0.5, 0.25];

export class WooriPlayerOption {
  constructor({
    title,
    author,
    cid,
    menuId,
    cdnSt,
    thumbnail,
    startPosition = 0,
    audioPosterMode = false,
    playerId = 'wooriPlayer',
    autoplay = true,
    /** 해상도 */
    qualitySelector = true, // 해상도(수동) 버튼 활성화 여부
    qualities = [], //해상도 정보
    qualityDefault = '', //기본 해상도

    controls = true,

    fill = false,
    fluid = true,

    floatingMode = {
      // 소형 플레이어
      use: true,
      clickCallback: function (active) {
        // click Event
      },
    },

    html5 = {
      vhs: {
        overrideNative: true,
        cacheEncryptionKeys: false,
      },
    },

    // 단축키 옵션 - https://www.npmjs.com/package/videojs-hotkeys#options
    hotkey = {
      volumeStep: 0.1,
      seekStep: 5,
      enableMute: true,
      enableVolumeScroll: true,
      enableModifiersForNumbers: false,
    },

    // language: getBrowserLang(), // 다국어 지원

    // 라이브 모드
    liveMode = false,
    // 로딩 스피너
    loadingSpinner = true,

    /**
     * 모바일 모드
     */
    mobile = false,

    /**
     * 커스텀 설정버튼
     * <pre>
     *     customSettingBtn: {
     *        onclick: function(e, active, player) {
     *          click Event
     *        }
     *     }
     * </pre>
     */
    customSettingBtn = false,

    /**
     * 모바일모드 좌측 이전 버튼
     * <pre>
     *     mobileBackBtn: {
     *        onclick: function(e) {
     *          click Event
     *        }
     *     }
     * </pre>
     */
    mobileBackBtn = false,

    /**
     * 모바일모드 전체화면 채팅 버튼
     * <pre>
     *     mobileChatBtn: {
     *        onclick: function(e, active, player) {
     *          click Event
     *        }
     *     }
     * </pre>
     */
    mobileChatBtn = false,

    /** 다음영상 바로보기  */
    nextContents = {
      use: false,
      content: {},
    },

    /** 홍보/이벤트 버튼 */
    noticeEvent = {
      // use: true, // 버튼 사용 여부
      // active: true, // 광고 여부
      use: false, // 버튼 사용 여부
      active: false, // 광고 여부
      list: [
        // {
        //     src: "http://wbn.castware.co.kr:9000/banner/2023/04/26/1682494563037.png",
        // },
        // {
        //     src: "http://wbn.castware.co.kr:9000/banner/2023/04/26/1682494563037.png",
        // },
      ], // 광고 정보
      clickCallback: function (e) {
        // 클릭 이벤트
        console.log(e);
      },
    },

    poster = 'data:image/jpg;base64,',
    posterFit = 'contain', // 내용의 'object-fit'( 'auto', 'cover', 'contain' )

    preload = 'metadata',
    playPauseToggle = false, // 화면 클릭시 재생/정지

    /* sprite 섬네일 적용 */
    spriteThumbnail = {
      use: false, // 사용 여부
      url: [], // 섬네일 목록
      interval: 1, // 프레임 간격
      width: 160, // 넓이
      height: 90, // 높이
      frame: 200, // 전체 프레임 재생 시간
    },

    /** 컨트롤바 구간 스킵 */
    sectionSkip = false,
    /** 화면 위 구간 스킵 */
    screenSectionSkip = false,

    /** 설정 메뉴 */
    settingsMenuItem = {
      //세팅된 메뉴(배속, 자막, 해상도)
      qualitySelector: true, //해상도
      subsCapsButton: false, //자막
      playbackRateMenuButton: true, //배속
    },

    speeds = DefaultSpeedRates,
    subtitles = [], // 자막 정보

    techOrder = ['html5'],

    videoTitle = {
      // 영상 제목
      use: false,
      title: '',
      clickCallback: function (e) {
        // click event
      },
    },

    // custom
    timeUpdateCallback,
    liveStartTime,
    isLivePolling = false,
    canSkip = true,
  }) {
    this.title = title;
    this.author = author;
    this.cid = cid;
    this.menuId = menuId;
    this.cdnSt = cdnSt;
    this.thumbnail = thumbnail;
    this.startPosition = startPosition;
    this.audioPosterMode = audioPosterMode;
    this.playerId = playerId;
    this.autoplay = autoplay;
    this.controls = controls;
    this.fill = fill;
    this.fluid = fluid;
    this.floatingMode = floatingMode;
    this.html5 = html5;
    this.hotkey = hotkey;
    this.liveMode = liveMode;
    this.loadingSpinner = loadingSpinner;
    this.mobile = mobile;
    this.customSettingBtn = customSettingBtn;
    this.mobileBackBtn = mobileBackBtn;
    this.mobileChatBtn = mobileChatBtn;
    this.nextContents = nextContents;
    this.noticeEvent = noticeEvent;
    this.poster = poster ?? 'data:image/jpg;base64,';
    this.posterFit = posterFit;
    this.preload = preload;
    this.playPauseToggle = playPauseToggle;
    this.qualitySelector = qualitySelector;
    this.qualities = qualities;
    this.qualityDefault = qualityDefault;
    this.spriteThumbnail = spriteThumbnail;
    this.sectionSkip = sectionSkip;
    this.screenSectionSkip = screenSectionSkip;
    this.settingsMenuItem = settingsMenuItem;
    this.speeds = !canSkip ? [1.0] : speeds;
    this.subtitles = subtitles;
    this.techOrder = techOrder;
    this.videoTitle = videoTitle;
    this.timeUpdateCallback = timeUpdateCallback;
    this.liveStartTime = liveStartTime;
    this.isLivePolling = isLivePolling;
    this.canSkip = canSkip;
  }
}
