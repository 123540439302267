import { useQuery } from 'react-query';
import CompanyApi from '@/components/user/company/CompanyApi';

const QueryKey = {
  all: ['deptSearch'],
  filtered: (compCd) => [...QueryKey.all, compCd],
};

/**
 * @param {{compCd: string; deptCd: string; deptNm: string; parentDept: string}[]} deptList
 */
const generateDeptTree = (deptList) => {
  const companies = deptList.filter((dept) => dept.parentDept === '');

  const buildTree = (nodes, path = '') => {
    // 경로를 추가할 매개변수
    const tree = nodes.map((node) => {
      // 부모 deptNm이 없는 경우
      if (path === '') {
        node.deptNmWithPath = node.deptNm; // 최상위 노드의 경우, 경로가 없음
      } else {
        node.deptNmWithPath = `${path} > ${node.deptNm}`; // deptNmWithPath에 경로 추가
      }

      // 자기 자손 찾기
      const children = deptList.filter(
        (dept) => dept.parentDept === node.deptCd
      );

      // 자손들에 대해 Recursion
      if (children.length > 0) {
        node.children = buildTree(children, node.deptNmWithPath); // 경로를 자손에 전달
      }
      return node;
    });
    return tree;
  };

  return buildTree(companies);
};

function flattenTree(tree) {
  const flattened = [];

  function flatten(node) {
    flattened.push({ ...node, children: [] }); // 노드 추가 (현재 노드 복사)

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        flatten(child); // 하위 노드 재귀적으로 탐색
      }
    }
  }

  for (const node of tree) {
    flatten(node); // 루트 노드부터 시작
  }

  return flattened;
}

function makeDeptList({ list }) {
  let l = [...list];
  // Leaf 부서만 표시 제거 23.11.27
  //     .filter(
  //   (it) => list.find((sub) => sub.parentDept === it.deptCd) == null
  // );

  // 한글이 먼저 오는 정렬
  l.sort((a, b) => a.deptNm.localeCompare(b.deptNm));
  return l;
}

export default function (compCd) {
  return useQuery(QueryKey.filtered(compCd), {
    queryFn: async ({ queryKey: [_, _compCd] }) => {
      const list = (await CompanyApi.getDeptList(_compCd)).data.map((data) => ({
        ...data,
        deptCd: data.deptCd.trim(),
      }));
      return makeDeptList({ compCd, list });
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
