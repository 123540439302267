import { Collapse, Tooltip } from '@mui/material';
import OutlineLabelField from '@/components/form/OutlineLabelField';
import React from 'react';
import useAESCrypto from '@/components/user/useAESCrypto';
import NotateToolTip from '@/components/content/editor/NotateToolTip';

const tooltipText1 = (
  <span className="tooltip-text">
    비밀번호 입력이 필요한 게시글을 작성할 수 있습니다.
  </span>
);

const tooltipText2 = (
  <div>
    <span className="tooltip-text block">
      - 문자,숫자,특수문자만 입력 가능합니다.
    </span>
    <span className="tooltip-text block">- 소문자,대문자 구분합니다.</span>
    <span className="tooltip-text block">
      - 특수문자는 ‘!, @, #, $, %, ^, &, *’만 사용 가능합니다.
    </span>
    <span className="tooltip-text block">
      - 비밀번호는 6자 이상 12자 미만으로 작성해주세요
    </span>
  </div>
);

export default function ContentPasswordField({
  hasPassword,
  password,
  onPasswordChange,
  onHasPasswordChange,
}) {
  const { encrypt, tryDecrypt } = useAESCrypto();

  return (
    <>
      <input
        id="secrit"
        className="secrit"
        type="checkbox"
        checked={hasPassword}
        onClick={(e) => onHasPasswordChange?.(e.target.checked)}
      />
      <label htmlFor="secrit" className="secrit">
        비밀게시
      </label>
      <NotateToolTip title={tooltipText1} />

      <Collapse in={hasPassword} orientation="horizontal">
        <div className="flex items-center">
          <OutlineLabelField
            className="ml-2 w-40 mr-2"
            type="password"
            value={tryDecrypt(password)}
            useClearAdornment={false}
            onChange={(value) => onPasswordChange?.(encrypt(value))}
          />

          <NotateToolTip title={tooltipText2} />
        </div>
      </Collapse>
    </>
  );
}
