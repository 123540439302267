import WatchHistory from '../../page/my/tabs/WatchHistory';
import MyInfoEdit from '../../page/my/tabs/MyInfoEdit';
import MyPageContainer from '../../page/my/MyPageContainer';
import MyInfoContainer from '../../page/my/MyInfoContainer';
import MyPointContainer from '../../page/my/MyPointContainer';
import EarnPoint from '../../page/my/tabs/EarnPoint';
import UsedPoint from '../../page/my/tabs/UsedPoint';
import Following from '@/page/my/tabs/Following';
import Follower from '@/page/my/tabs/Follower';
import MyFollowContainer from '@/page/my/MyFollowContainer';
import WroteBBS from '@/page/my/tabs/WroteBBS';
import WroteVOD from '@/page/my/tabs/WroteVOD';
import LikeVODList from '@/page/my/tabs/LikeVODList';
import LikeBBSList from '@/page/my/tabs/LikeBBSList';
import UserCommentList from '@/page/my/tabs/UserCommentList';
import MyLMS from '@/page/my/tabs/MyLMS';
import MyAffltEdit from '@/page/my/tabs/MyAffltEdit';
import MyAffltContainer from '@/page/my/MyAffltContainer';
import ThankyouReg from '@/page/my/tabs/TankyouTokenManage';

export const myInfoMenuMap = {
  history: <WatchHistory />,
  like: <LikeVODList />,
  uploaded: <WroteVOD />,
  wrote: <WroteBBS />,
  bookmark: <LikeBBSList />,
  comment: <UserCommentList />,
  ttmanage: <ThankyouReg />,
  // 'recommented': <WatchHistory/>, // 기능 제거
  following: <Following />,
  follower: <Follower />,

  info: <MyInfoEdit />, // 별도 화면 구성

  use: <UsedPoint />, // 별도 화면 구성
  earn: <EarnPoint />, // 별도 화면 구성

  lms: <MyLMS />,

  afflt: <MyAffltEdit />, // 별도 화면 구성
};

export const myContainerMap = {
  mypage: <MyPageContainer />,
  mypoint: <MyPointContainer />,
  myinfo: <MyInfoContainer />,
  myfollow: <MyFollowContainer />,
  myafflt: <MyAffltContainer />,
};
