import { ClickAwayListener, Tooltip } from '@mui/material';
import React, { useState } from 'react';

export default function NotateToolTip({ title }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          arrow
          classes="tooltip-text"
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={title}
        >
          <img
            className="ex_mark cursor-pointer"
            src="/images/exmark_ico.png"
            alt="느낌표"
            onClick={handleTooltipOpen}
          />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}
