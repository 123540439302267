const ACTIONS = {
  PLAY_PIP_PLAYER: 'PLAY_PIP_PLAYER',
  PAUSE_PIP_PLAYER: 'PAUSE_PIP_PLAYER',
  SHOW_PIP_PLAYER: 'SHOW_PIP_PLAYER',
  CLOSE_PIP_PLAYER: 'CLOSE_PIP_PLAYER',
  NEXT_PIP_PLAYER: 'NEXT_PIP_PLAYER',
  PREV_PIP_PLAYER: 'PREV_PIP_PLAYER',
};

Object.freeze(ACTIONS);

export const showPIP = (param) => ({ type: ACTIONS.SHOW_PIP_PLAYER, param });
export const closePIP = (param) => ({ type: ACTIONS.CLOSE_PIP_PLAYER, param });
export const playPIP = (param) => ({ type: ACTIONS.PLAY_PIP_PLAYER, param });
export const pausePIP = (param) => ({ type: ACTIONS.PAUSE_PIP_PLAYER, param });

const initialState = {
  pipInfo: null,
  isPlaying: false,
  isShow: false,
};

export default function audioPipReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SHOW_PIP_PLAYER: {
      return {
        ...state,
        isShow: true,
      };
    }

    case ACTIONS.PLAY_PIP_PLAYER: {
      return {
        ...state,
        pipInfo: action.param,
        isShow: true,
        isPlaying: true,
      };
    }

    case ACTIONS.PAUSE_PIP_PLAYER: {
      return {
        ...state,
        isPlaying: false,
      };
    }

    case ACTIONS.CLOSE_PIP_PLAYER: {
      return {
        ...state,
        isShow: false,
      };
    }

    default:
      return state;
  }
}
