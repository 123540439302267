import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function useQuery({ useParse = true } = {}) {
  const location = useLocation();
  if (useParse) {
    return queryString.parse(location.search);
  } else {
    return location.search;
  }
}
