import { Outlet } from 'react-router-dom';
import PinDialog from '@/components/easyLogin/pin/PinDialog';
import { useSelector } from 'react-redux';
import Popup from '@/components/popup/Popup';
import { useMemo, useState } from 'react';

export default function EasyPinLoginContainer() {
  const [popup, setPopup] = useState(null);
  const { pinRegistered, pinSubmitCallback, pinCancelCallback } = useSelector(
    (state) => state.pinReducer
  );

  const showPinDialog = useMemo(() => {
    return pinRegistered && pinSubmitCallback != null;
  }, [pinRegistered, pinSubmitCallback]);

  const onCancel = () => {
    pinCancelCallback?.();
  };

  const onSubmit = async (code) => {
    try {
      await pinSubmitCallback(code);
    } catch (e) {
      setPopup({
        title: '간편로그인',
        message: '비밀번호를 확인해 주세요.',
        okCallback: () => {
          setPopup(null);
          document.querySelector('#easy-login-pin')?.focus();
        },
      });
    }
  };

  return (
    <>
      <Outlet />
      <PinDialog open={showPinDialog} onCancel={onCancel} onSubmit={onSubmit} />
      {popup != null && <Popup {...popup} className="!z-[2010]" />}
    </>
  );
}
