import useLogin from '../../../components/user/login/useLogin';
import { useDispatch } from 'react-redux';
import OutlineLabelField from '../../../components/form/OutlineLabelField';
import { useEffect, useMemo, useRef, useState } from 'react';
import Link2 from '@/components/common/transition/Link2';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import useRegexValidate, {
  EmpNo8Validator,
  EmpNoValidator,
  NicknameValidator,
  PasswordValidator,
  RankNmValidator,
  UserCompValidator,
  useRegexArrayValidator,
} from '@/components/form/useRegexValidate';
import { useTimer } from '@/components/card/thumbnail/useCountDown';
import MyPageApi, {
  UserChangePassDto,
  UserModifyDto,
} from '@/components/user/my/MyPageApi';
import JoinApi from '@/components/join/JoinApi';
import useAESCrypto from '@/components/user/useAESCrypto';
import { useMutation } from 'react-query';
import Popup from '@/components/popup/Popup';
import usePopup from '@/components/popup/usePopup';
import { userLogout } from '@/components/user/login/LoginReducer';
import classNames from 'classnames';
import MobileAuthField from '@/components/form/MobileAuthField';
import SearchableDropdown from '@/components/common/SearchableDropdown';
import useDeptList from '@/components/user/company/useDeptList';
import useRankList from '@/components/user/company/useRankList';
import useCompanyList from '@/components/user/company/useCompanyList';
import usePositionList from '@/components/user/company/usePositionList';
import { MobileAuthStatus } from '@/page/join/useMobileAuth';
import EasyLoginConfig from '@/components/easyLogin/EasyLoginConfig';

export function ValidatorInput({ readonly = false, validator, ...props }) {
  return (
    <OutlineLabelField
      className={classNames({
        error: validator.message != null && !validator.isValidate,
        'pointer-events-none': readonly,
      })}
      useClearAdornment={!readonly}
      onChange={readonly ? null : validator.setValue}
      message={validator.message}
      value={validator.value}
      {...props}
    />
  );
}

function useInputField(defaultValue) {
  const [value, setValue] = useState(defaultValue);
  return {
    value,
    setValue,
    isValidate: true,
    validate: () => true,
    message: '',
    hasError: false,
  };
}

export default function MyInfoEdit({}) {
  const { encrypt, decrypt } = useAESCrypto();
  const { data: _data, isLoading } = useLogin();
  const data = {
    ..._data,
    userDept: _data.wbnDeptCd,
    userRank: _data.wbnRankTypeCd,
    userComp: _data.wbnCompCd,
    userPosition: _data.wbnPositionCd,
  };

  const companyList = useCompanyList({ excludeNormal: true });
  const compId = data?.compId;
  const compCd = useMemo(() => {
    return companyList?.find((it) => it.compId === compId)?.wbnCompCd;
  }, [compId]);

  const { positionQuery, useRankTypeCd } = usePositionList(compCd);
  const [_formData, _setFormData] = useState({ ...data });
  const [mobileAuthCodeSent, setMobileAuthCodeSent] = useState(false);
  const formData = {
    userNo: { value: data?.userNo ?? '' },
    userNm: useInputField(data?.userNm ?? ''),
    UserTypeNm: useInputField(data?.UserTypeNm ?? ''),
    userComp: useRegexValidate(
      UserCompValidator,
      data?.compNm ?? '',
      data?.compNm
    ),
    nickNm: useRegexArrayValidator(
      NicknameValidator,
      data?.nickNm ?? '',
      data?.nickNm ?? '',
      [
        async (v) => {
          if (data?.nickNm === v) return null;
          let validateRes = await JoinApi.checkNickUnique(v);
          let dupCount = validateRes?.data?.data?.[0]?.cnt;
          switch (dupCount) {
            case null:
              return '오류가 발생하였습니다. 잠시 후 시도해 주세요';
            case 0:
              return null;
            default:
              return '이미 사용중인 닉네임 입니다.';
          }
        },
      ]
    ),
    wbnRankNm: useRegexArrayValidator(RankNmValidator, '', null),
    wbnEmpNo: useRegexArrayValidator(
      useRankTypeCd ? EmpNo8Validator : EmpNoValidator,
      '',
      null
    ),
    currPw: useInputField(''),
    pw1: useRegexValidate(PasswordValidator, '', ''),
    pw2: useRegexArrayValidator(PasswordValidator, '', '', [
      (v) => {
        return formData.pw1.value !== v
          ? '비밀번호가 일치하지 않습니다.'
          : null;
      },
    ]),
  };

  const mobileAuthRef = useRef();

  const { remainTime, start, clear } = useTimer({
    time: 3 * 60 * 1000 + 59 * 1000,
  });

  const dispatch = useDispatch();
  const navigator = useNavigate2();

  const { popup, show, hide } = usePopup();
  const changeMyPassword = useMutation(MyPageApi.changeMyPassword, {
    onSuccess: async (res) => {
      if (res.data.code === '00') {
        const modifyDto = {};
        // 기존 Object 는 {key: {value, setValue, ...}} 의 validator object 이므로 일반적인 data object 형태로 변환
        for (const key of Object.keys(formData)) {
          modifyDto[key] = formData[key].value;
        }
      } else {
        show({
          title: '비밀번호 변경에 실패했습니다.',
          message: res.data.message,
          okCallback: hide,
        });
      }
    },
  });

  const modifyMyInfo = useMutation(MyPageApi.modifyUserInfo, {
    onSuccess: (res) => {
      if (res.data.code === '00') {
        show({
          title: '회원정보 수정에 성공했습니다.',
          message: '',
          okCallback: () => {
            navigator('/');
          },
        });
      } else {
        show({
          title: '오류',
          message: res.data.message,
          okCallback: hide,
        });
      }
    },
    onError: (e) => {
      console.error(e);
      let message = e.response?.data?.message ?? e.message;
      if (message?.startsWith('timeout of')) {
        message = '서버로부터 응답을 받지 못했습니다.';
      }
      show({
        title: '오류',
        message: message,
        okCallback: hide,
      });
    },
  });

  const shouldChangePassword = () => {
    let allEmpty =
      formData.currPw.value.isEmpty() &&
      formData.pw1.value.isEmpty() &&
      formData.pw2.value.isEmpty();
    return !allEmpty;
  };

  const changePassword = async () => {
    return changeMyPassword.mutate(
      new UserChangePassDto({
        userNo: data.userNo,
        userPwd: encrypt(formData.currPw.value),
        tempPwd: encrypt(formData.pw1.value),
      })
    );
  };

  const changeInfo = async () => {
    const modifyDto = {};
    for (const key of Object.keys(formData)) {
      modifyDto[key] = formData[key].value;
    }
    // modifyDto.wbnEmpNo = data?.wbnEmpNo;
    modifyDto.wbnCompCd = compCd;
    modifyDto.wbnDeptCd = deptCd.value;
    modifyDto.wbnRankCd = useRankTypeCd ? rankTypeCd.value : positionCd.value;
    modifyDto.wbnPositionCd = positionCd.value;

    return modifyMyInfo.mutate(new UserModifyDto(modifyDto));
  };

  const onSubmit = async (e) => {
    if (modifyMyInfo.isLoading) {
      return;
    }
    try {
      if (shouldChangePassword()) {
        if (!formData.currPw.value.isEmpty() && formData.currPw.validate()) {
          if (
            !formData.pw1.value.isEmpty() &&
            !formData.pw2.value.isEmpty() &&
            formData.pw1.validate() &&
            (await formData.pw2.validate())
          ) {
            await changePassword();
          }
        } else {
          show({
            title: '오류',
            message: '현재 비밀번호를 입력해야 합니다.',
            okCallback: hide,
          });
        }
      }
    } catch (e) {
      show({
        title: '오류',
        message: '비밀번호 변경 중 오류가 발생하였습니다.',
        okCallback: hide,
      });
    }
    try {
      // 기존 Object 는 {key: {value, setValue, ...}} 의 validator object 이므로 일반적인 data object 형태로 변환
      console.log(formData);
      let isValidEmpNo = isExecutive
        ? await formData.wbnEmpNo.validate()
        : true;
      let isValidUserComp = !formData.userComp.hasError;

      let isValidRankNm = true;
      // isExecutive
      //   ? await formData.wbnRankNm.validate()
      //   : true;
      let isValidDeptCd = isExecutive
        ? deptCd?.value != null && deptCd?.value !== ''
        : true;

      let isValidRankTypeCd = useRankTypeCd
        ? rankTypeCd?.value != null && rankTypeCd?.value !== ''
        : true;

      let isValidPositionCd = isExecutive
        ? positionCd?.value != null && positionCd?.value !== ''
        : true;

      let isValidMobile = mobileAuthRef.current.validate();

      if (!isValidDeptCd) {
        _setFormData((p) => ({
          ...p,
          deptCdMessage: '부서를 선택해 주세요',
        }));
      } else {
        _setFormData((p) => ({
          ...p,
          deptCdMessage: null,
        }));
      }

      if (!isValidRankTypeCd) {
        _setFormData((p) => ({
          ...p,
          rankTypeCdMessage: '직급분류를 선택해 주세요',
        }));
      } else {
        _setFormData((p) => ({
          ...p,
          rankTypeCdMessage: null,
        }));
      }

      if (!isValidPositionCd) {
        _setFormData((p) => ({
          ...p,
          positionCdMessage: '직위를 선택해 주세요',
        }));
      } else {
        _setFormData((p) => ({
          ...p,
          positionCdMessage: null,
        }));
      }

      if (!isValidMobile) {
        _setFormData((p) => ({
          ...p,
          userMobileMessage: '휴대전화 인증해 주세요',
        }));
      }

      console.log(
        formData.userComp.value,
        '/',
        formData.wbnEmpNo.value,
        '/',
        rankTypeCd.value,
        '/',
        deptCd.value,
        '/',
        positionCd.value
      );

      if (
        !isValidUserComp ||
        !isValidEmpNo ||
        !isValidRankNm ||
        !isValidRankTypeCd ||
        !isValidDeptCd ||
        !isValidPositionCd ||
        !isValidMobile
      ) {
        return;
      }

      await changeInfo();
    } catch (e) {
      console.log(e);
      show({
        title: '오류',
        message: '사용자 정보 변경 중 오류가 발생하였습니다.',
        okCallback: hide,
      });
    }
  };

  const deptQuery = useDeptList(compCd);
  const initialDept = useMemo(() => {
    const dept = deptQuery.data?.find((dept) => dept.deptCd === data.userDept);
    return {
      name: dept?.deptNm ?? '',
      value: dept?.deptCd ?? '',
    };
  }, [deptQuery.data]);

  const rankQuery = useRankList(compCd);
  const initialRank = useMemo(() => {
    const rank = rankQuery.data?.find(
      (rankType) => rankType.rankTypeCd === data.userRank
    );
    return {
      name: rank?.rankTypeNm ?? '',
      value: rank?.rankTypeCd ?? '',
    };
  }, [rankQuery.data]);

  const initialPosition = useMemo(() => {
    const target = positionQuery.data?.find(
      (it) => it.positionCd === data.userPosition
    );
    console.log('initialPosition', positionQuery.data, data, target);
    return {
      name: target?.positionNm ?? '',
      value: target?.positionCd ?? '',
    };
  }, [positionQuery.data]);

  const [deptCd, setDeptCd] = useState(initialDept);
  const [positionCd, setPositionCd] = useState(initialPosition);
  const [rankTypeCd, setRankTypeCd] = useState(initialRank);

  useEffect(() => {
    setDeptCd(initialDept);
    setRankTypeCd(initialRank);
    setPositionCd(initialPosition);
  }, [deptQuery.data, rankQuery.data, positionQuery.data]);

  useEffect(() => {
    if (!isLoading) {
      updateFormData('wbnEmpNo', _data.wbnEmpNo);
      updateFormData('wbnRankNm', _data.wbnRankNm);
    }
  }, [isLoading]);

  useEffect(() => {
    if (rankTypeCd?.value != null && rankTypeCd?.value !== '') {
      _setFormData((p) => ({
        ...p,
        rankTypeCdMessage: null,
      }));
    }
  }, [rankTypeCd]);

  useEffect(() => {
    if (deptCd?.value != null && deptCd?.value !== '') {
      _setFormData((p) => ({
        ...p,
        deptCdMessage: null,
      }));
    }
  }, [deptCd]);

  useEffect(() => {
    if (positionCd?.value != null && positionCd?.value !== '') {
      _setFormData((p) => ({
        ...p,
        positionCdMessage: null,
      }));
    }
  }, [positionCd]);

  const updateFormData = (key, value) => {
    formData[key].setValue(value);
  };

  const onResign = () => {
    show({
      title: '회원탈퇴',
      message: '정말로 탈퇴하시겠습니까?',
      okCallback: async () => {
        hide();
        const res = await MyPageApi.resign('');
        if (res.code !== '00') {
          show({
            title: '회원탈퇴 오류',
            message: '오류가 발생하였습니다.\n잠시 후 다시 시도해 주세요.',
            okCallback: () => {
              hide();
            },
          });
        } else {
          show({
            title: '회원탈퇴 완료',
            message: '탈퇴가 완료 되었습니다.',
            okCallback: () => {
              hide();
              dispatch(userLogout());
              navigator('/');
            },
          });
        }
      },
      cancelCallback: () => {
        hide();
      },
    });
  };

  useEffect(() => {
    if (!data) {
      show({
        title: '오류',
        message: '로그인이 필요합니다.',
        okCallback: () => {
          navigator('/');
        },
      });
    }
  }, [data]);

  const onAfflt = () => {
    show({
      title: '소속계정변경',
      message: '소속계정변경을 진행 하시겠습니까?',
      okCallback: async () => {
        hide();
        navigator('/myafflt');
      },
      cancelCallback: () => {
        hide();
      },
    });
  };

  const isExecutive = data?.userType === 'UT01';

  return (
    <div id="contents">
      <div className="join_cont flex justify-center items-center">
        <div className="inner_box">
          <div className="join_box">
            <h5 className="head_tit mb-8 justify-center">
              <span>회원정보 수정</span>
            </h5>
            {data?.compNm != null && (
              <span className="f12 p-2 co_pri brand_tag ">{data?.compNm}</span>
            )}
            <div className="flex gap-2 justify-between">
              <h4 className="join_tit f28_w700 mt-4">
                {isExecutive ? '임직원' : '일반사용자'} 회원정보
              </h4>
              {data?.deptNm != null &&
                data?.rankTypeNm != null &&
                data?.wbnEmpNo != null &&
                data?.userMobile != null && (
                  <>
                    <div>
                      <button
                        className="pri_btn f14_w500 mt-1 p-4"
                        onClick={onAfflt}
                      >
                        소속계정변경
                      </button>
                    </div>
                  </>
                )}
            </div>
            <p className="tit_mail w300">{data?.userId ?? ''}</p>
            <div className="input_box flex flex-col mb-4 w-full">
              <div className="row flex gap-2 mb-4">
                <div className="member_input relative">
                  <OutlineLabelField
                    label="성명"
                    placeholder="성명"
                    name="name"
                    id="name"
                    autoComplete="off"
                    useClearAdornment={false}
                    value={formData.userNm.value}
                    onChange={(v) => updateFormData('userNm', v)}
                  />
                </div>
                <div className="member_input relative">
                  <OutlineLabelField
                    label="회원구분"
                    placeholder="회원구분"
                    id="UserTypeNm"
                    autoComplete="off"
                    disabled={true}
                    useClearAdornment={false}
                    value={formData.UserTypeNm.value}
                    onChange={(v) => updateFormData('UserTypeNm', v)}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="member_input relative">
                  <ValidatorInput
                    id="nickNm"
                    label="닉네임"
                    autoComplete="off"
                    placeholder="3 ~ 10자 사이"
                    useClearAdornment={false}
                    validator={formData.nickNm}
                    customButton={
                      <button
                        type="button"
                        className="btn_check f14_w300 flex items-center justify-center"
                        onClick={() => formData.nickNm.validate()}
                      >
                        중복확인
                      </button>
                    }
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="member_input relative">
                  <ValidatorInput
                    label="현재 비밀번호"
                    placeholder="8자 이상, 영문, 숫자, 특수문자"
                    id="currPw"
                    autoComplete="on"
                    name="current-password"
                    type="password"
                    validator={formData.currPw}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="member_input relative">
                  <ValidatorInput
                    label="변경할 비밀번호"
                    placeholder="8자 이상, 영문, 숫자, 특수문자"
                    id="pw1"
                    autoComplete="on"
                    name="new-password"
                    type="password"
                    validator={formData.pw1}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="member_input relative">
                  <ValidatorInput
                    label="비밀번호 확인"
                    placeholder="8자 이상, 영문, 숫자, 특수문자"
                    id="pw2"
                    type="password"
                    validator={formData.pw2}
                  />
                </div>
              </div>
              {isExecutive ? (
                <>
                  {/*<div className="row flex gap-2 mb-4">*/}
                  {/*  <div className="member_input relative flex-1">*/}
                  {/*    <OutlineLabelField*/}
                  {/*      label="사명"*/}
                  {/*      id="userComp"*/}
                  {/*      value={formData.userComp.value}*/}
                  {/*      onChange={(v) => updateFormData('userComp', v)}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="row flex mb-4">
                    <div className="member_input relative flex-1">
                      <SearchableDropdown
                        label="부서명"
                        noOptionsText={'결과가 없습니다.'}
                        searchQuery={{
                          ...deptQuery,
                          data: [
                            ...(deptQuery?.data?.map((item) => ({
                              name: item.deptNm,
                              value: item.deptCd,
                            })) ?? []),
                          ],
                        }}
                        className={
                          _formData.deptCdMessage != null &&
                          _formData.deptCdMessage !== ''
                            ? 'error'
                            : ''
                        }
                        message={_formData.deptCdMessage}
                        selectedItem={deptCd}
                        onSelectItem={setDeptCd}
                      />
                    </div>
                  </div>
                  {useRankTypeCd && (
                    <div className="row flex  mb-4">
                      <div className="member_input relative flex-1">
                        <SearchableDropdown
                          id={`${compCd}_positionCd`}
                          label="직위"
                          noOptionsText={'결과가 없습니다.'}
                          searchQuery={{
                            ...rankQuery,
                            data:
                              rankQuery?.data?.map((item) => ({
                                name: item.rankTypeNm,
                                value: item.rankTypeCd,
                              })) ?? [],
                          }}
                          className={
                            _formData.rankTypeCdMessage != null &&
                            _formData.rankTypeCdMessage !== ''
                              ? 'error'
                              : ''
                          }
                          message={_formData.rankTypeCdMessage}
                          selectedItem={rankTypeCd}
                          onSelectItem={setRankTypeCd}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row flex  mb-4">
                    <div className="member_input relative flex-1">
                      <SearchableDropdown
                        label={useRankTypeCd ? '직급분류' : '직위'}
                        noOptionsText={'결과가 없습니다.'}
                        openOnlyOnText={!useRankTypeCd}
                        searchQuery={{
                          ...positionQuery,
                          data:
                            positionQuery?.data?.map((item) => ({
                              name: item.positionNm,
                              value: item.positionCd,
                            })) ?? [],
                        }}
                        className={
                          _formData.positionCdMessage != null &&
                          _formData.positionCdMessage !== ''
                            ? 'error'
                            : ''
                        }
                        message={_formData.positionCdMessage}
                        selectedItem={positionCd}
                        onSelectItem={setPositionCd}
                      />
                    </div>
                  </div>
                  <div className="row flex  mb-4">
                    <div className="member_input relative flex-1">
                      <ValidatorInput
                        label="사번(행번)"
                        type="text"
                        validator={formData.wbnEmpNo}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="row flex mb-4">
                  <div className="member_input relative w-full">
                    <ValidatorInput
                      label="직업"
                      placeholder="직업명"
                      id="userComp"
                      type="text"
                      validator={formData.userComp}
                    />
                  </div>
                </div>
              )}
              <MobileAuthField
                ref={mobileAuthRef}
                mobileNumber={decrypt(data?.userMobile)}
                initAuthState={
                  decrypt(data?.userMobile) !== null &&
                  decrypt(data?.userMobile) !== ''
                    ? MobileAuthStatus.verified
                    : MobileAuthStatus.none
                }
              />
              <EasyLoginConfig />
              <span
                className="cursor-pointer underline f14_w300 mb-6"
                onClick={onResign}
              >
                회원탈퇴
              </span>
            </div>
            <div className="flex gap-2">
              <Link2 to={-1} className="pri_btn_stroke">
                <button className="w-full h-full">취소</button>
              </Link2>
              <button
                className={classNames({
                  pri_btn: !modifyMyInfo.isLoading,
                  pri_btn_dis: modifyMyInfo.isLoading,
                })}
                onClick={onSubmit}
              >
                수정
              </button>
            </div>
          </div>
        </div>
      </div>
      {popup != null && <Popup {...popup} />}
    </div>
  );
}
