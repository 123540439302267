import _ from 'lodash';
import { useMemo } from 'react';

export default function GridView({
  dataList,
  rowCount,
  columnCount,
  parentClassName,
  itemRender,
}) {
  const chunkSize = useMemo(
    () => (rowCount && columnCount ? rowCount * columnCount : 8),
    [rowCount, columnCount]
  );
  const columns = useMemo(() => columnCount ?? 4, [columnCount]);

  // 기본적으로 작은 사이즈에서는 절반의 수가 나오게 했어요. 별도의 레이아웃 지정이 필요하면 parentClassName을 통해 해주세요.
  return _.chunk(dataList, chunkSize).map((row, index) => (
    <div
      key={`thumbnail_card_row_${index}`}
      // className={parentClassName ?? `box_wrap grid lg:grid-cols-${columns} sm:grid-cols-${Math.floor(columns / 2)} gap-4`}
      className={
        parentClassName ??
        `mb-16 gap-y-16 grid lg:grid-cols-${columns} sm:grid-cols-${Math.floor(
          columns / 2
        )} gap-4`
      }
    >
      {row.map(itemRender)}
    </div>
  ));
}
