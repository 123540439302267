import { useDispatch, useSelector } from 'react-redux';
import {
  setChatBlur,
  setChatFocused,
  setCommentBlur,
  setCommentFocused,
} from '@/components/navigation/mobileNavigationReducer';
import { useMemo } from 'react';

import useDetectKeyboardOpen from 'use-detect-keyboard-open';

export default function useMobileNavigation() {
  const dispatch = useDispatch();
  const isCommentFocused = useSelector(
    (state) => state.mobileNavigationReducer.commentFocused
  );
  const isChatFocused = useSelector(
    (state) => state.mobileNavigationReducer.chatFocused
  );
  const isKeyboardOpen = useDetectKeyboardOpen();
  const isShow = useMemo(() => {
    if (isKeyboardOpen) {
      return !(isChatFocused || isCommentFocused);
    }
    return true;
  }, [isChatFocused, isCommentFocused, isKeyboardOpen]);

  const _setCommentFocused = () => {
    dispatch(setCommentFocused());
  };

  const _setCommentBlur = () => {
    dispatch(setCommentBlur());
  };

  const _setChatFocused = () => {
    dispatch(setChatFocused());
  };

  const _setChatBlur = () => {
    dispatch(setChatBlur());
  };

  return {
    setCommentFocused: _setCommentFocused,
    setCommentBlur: _setCommentBlur,
    setChatFocused: _setChatFocused,
    setChatBlur: _setChatBlur,
    isShow,
  };
}
