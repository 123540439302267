import { useEffect, useState } from 'react';
import { searchMenus } from '@/page/search/SearchPage';
import { SelectorModal } from '@/components/modal/select/SelectorModal';

export default function HeaderSearchInput({
  keyword,
  setKeyword,
  onSearch,
  onClose,
} = {}) {
  // const [searchText, setSearchText] = useState("");

  const onKeyEnter = (e) => {
    if (e.key === 'Enter') {
      _onSearch(keyword);
    }
  };

  const _onSearch = (keyword) => {
    if (keyword !== '') {
      onSearch?.(keyword, searchMenus[searchIndex].menuId);
    }
  };

  const [searchIndex, setSearchIndex] = useState(0);
  const [showSearchMenu, setShowSearchMenu] = useState(false);

  useEffect(() => {
    window.document.addEventListener('click', (e) => {
      if (
        e.target.id !== 'search_select_box' &&
        e.target.id !== 'search_select_button' &&
        e.target.id !== 'input_arrow_down'
      ) {
        setShowSearchMenu(false);
      }
    });
  }, []);

  return (
    <div className="new_search_inner flex">
      <div className="input_box_mail">
        <div className="flex flex-col relative input_search_wrap">
          {/*<button id="search_select_button" className="search_pick relative" type="button" onClick={e => setShowSearchMenu(!showSearchMenu)}>{searchMenus[searchIndex].menuNm}*/}
          {/*    <img id="input_arrow_down" className="input_arrow_down" src="/images/input_arrow_down.png" alt="메일목록"/>*/}
          {/*</button>*/}
          {/*<ul*/}
          {/*    id="search_select_box"*/}
          {/*    className="search_select_box bg-white relative border-[1px] rounded"*/}
          {/*    style={{display: showSearchMenu ? 'block': 'none',}}*/}
          {/*>*/}
          {/*    {*/}
          {/*        searchMenus.map((it, index) =>*/}
          {/*            <li className="w300" value={it.menuId} onClick={e => {setShowSearchMenu(false); setSearchIndex(index)}}>{it.menuNm}</li>*/}
          {/*        )*/}
          {/*    }*/}
          {/*</ul>   */}
          <button
            id="search_select_button"
            className="search_pick relative"
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setShowSearchMenu(!showSearchMenu);
            }}
          >
            {searchMenus[searchIndex].menuNm}
            <img
              id="input_arrow_down"
              className="input_arrow_down"
              src="/images/input_arrow_down.png"
              alt="메일목록"
            />
          </button>
          <SelectorModal
            className="mt-2"
            // items={[
            //     {label: "신고", value: "report"},
            //     {label: "수정", value: "edit"},
            //     {label: "삭제", value: "delete"},
            // ]}
            items={searchMenus.map((it) => ({
              label: it.menuNm,
              value: it.menuId,
            }))}
            onSelect={(value) => {
              setSearchIndex(
                searchMenus.findIndex((it) => it.menuId === value)
              );
              setShowSearchMenu(false);
            }}
            setOpened={setShowSearchMenu}
            opened={showSearchMenu}
          />
        </div>

        <div className="relative">
          <input
            type="search"
            id="input_search3"
            className="input_search2"
            autoFocus={true}
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            onKeyDown={onKeyEnter}
          />
          <button
            type="button"
            className="search_button2"
            onClick={() => _onSearch(keyword)}
          >
            <img src="/images/search_nav_input.png" alt="검색" />
          </button>

          <button
            type="button"
            id="search_del3"
            className="search_del3"
            onClick={() => setKeyword('')}
          >
            <img src="/images/search_del.png" alt="삭제버튼" />
          </button>
        </div>
      </div>
      <div className="close_wrap flex items-center justify-center">
        <img
          className="close flex-none"
          src="/images/login_close.png"
          alt="삭제버튼"
          onClick={() => onClose?.()}
        />
      </div>
    </div>
  );
}
