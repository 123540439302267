import DeviceApi from '@/components/device/DeviceApi';
import secureLocalStorage from 'react-secure-storage';
import AesCrypto from '@/components/user/useAESCrypto';
import AppCompatStorage from '@/lib/storage';
import uuid from 'react-uuid';

export default class LoginUtil {
  static key = 'sessionKey';
  static userIdKey = 'userId';
  static userNoKey = 'userNo';
  static savedLoginId = 'savedLoginId';
  static bioKey = 'bioKey';
  static pinKey = 'pinKey';
  static loginInfoKey = 'loginInfoKey';
  static parsedLoginInfoKey = 'parsedLoginInfoKey';
  static cachedLoginInfoString = window.cachedLoginInfoKey;
  static cachedLoginInfoKey = null;

  static _cryptor = AesCrypto();

  static async getSessionKeyWithUserId() {
    const sessionKey = await this.getSessionKey(); // getSessionKeyWithUserId
    const userId = await this.getUserId2(); // getSessionKeyWithUserId
    if (sessionKey == null || userId == null) {
      return null;
    }
    return {
      sessionKey,
      userId,
    };
  }

  static async parseResponse(res) {
    try {
      let id = res.data?.id;
      if (id == null || id === '') {
        console.warn('login result id is empty');
        return;
      }
      await this.setLoginInfo(res.data?.id);
      const data = JSON.parse(LoginUtil._cryptor.decrypt(res.data?.id ?? ''));
      const userId = data?.data?.[0]?.userId;
      const userNo = data?.data?.[0]?.userNo;
      console.log('parseResponse', JSON.stringify(data));
      await this.setUserId(userId);
      await this.setUserNo(userNo);
    } catch (e) {
      console.error(e);
    }
  }

  static async clearLoginInfo() {
    await AppCompatStorage.setItem(LoginUtil.loginInfoKey, null);
    this.cachedLoginInfoKey = null;
  }

  static async setLoginInfo(loginInfo) {
    if (loginInfo == null || loginInfo === '') {
      return this.clearLoginInfo();
    } else {
      if (loginInfo != null || loginInfo !== '') {
        let info = JSON.parse(LoginUtil._cryptor.decrypt(loginInfo));
        this.cachedLoginInfoKey = info;
      }
      return await AppCompatStorage.setItem(LoginUtil.loginInfoKey, loginInfo);
    }
  }

  static getLoginInfo() {
    let parsedInfo = this.cachedLoginInfoKey;
    if (parsedInfo == null) {
      let encInfo =
        this.cachedLoginInfoString ??
        secureLocalStorage.getItem(LoginUtil.loginInfoKey);
      if (encInfo == null || encInfo === '') return null;
      try {
        let info = JSON.parse(LoginUtil._cryptor.decrypt(encInfo));
        parsedInfo = info;
        this.cachedLoginInfoKey = info;
      } catch (e) {
        console.error(e);
      }
    }
    return parsedInfo;
  }

  static async setUserId(userId) {
    return await AppCompatStorage.setItem(LoginUtil.userIdKey, userId);
  }

  static async getUserId2() {
    return await AppCompatStorage.getItem(LoginUtil.userIdKey);
  }

  static async setUserNo(userNo) {
    return await AppCompatStorage.setItem(LoginUtil.userNoKey, userNo);
  }

  /**
   *
   * @returns {Promise<number|null>}
   */
  static async getUserNo2() {
    try {
      let value = await AppCompatStorage.getItem(LoginUtil.userNoKey);
      let value2 = Number(value);
      if (!isNaN(value2)) {
        return value2;
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  static async getSessionKey() {
    return (await this.getLoginInfo())?.data?.[0]?.sessionKey;
  }

  static loginCode(loginResult) {
    return loginResult?.data?.data?.code;
  }

  static async getSavedLoginUserId() {
    return await AppCompatStorage.getItem(LoginUtil.savedLoginId);
  }

  static async setSavedLoginUserId(savedLoginId) {
    return await AppCompatStorage.setItem(LoginUtil.savedLoginId, savedLoginId);
  }

  static async setBioKey(bioKey) {
    return await AppCompatStorage.setItem(LoginUtil.bioKey, bioKey);
  }

  static async getBioKey2() {
    return await AppCompatStorage.getItem(LoginUtil.bioKey);
  }

  static async setPinKey(pinKey) {
    return await AppCompatStorage.setItem(LoginUtil.pinKey, pinKey);
  }

  static async getPinKey2() {
    return await AppCompatStorage.getItem(LoginUtil.pinKey);
  }

  static async getDeviceInfo() {
    let data = {};
    let fcmToken = await DeviceApi.getFcmToken();
    if (fcmToken != null && fcmToken != '') {
      data.appToken = fcmToken;
    }
    let osInfo = await DeviceApi.getOS();
    try {
      osInfo = JSON.parse(osInfo);
      if (osInfo.os != null && osInfo.os != '') {
        data.appOS = osInfo.os;
      }
      if (osInfo.version != null && osInfo.version != '') {
        data.appVersion = osInfo.version;
      }
    } catch (e) {
      // ignore
    }

    return data;
  }

  static async getClientGuid() {
    let clientGuid = await AppCompatStorage.getItem('clientGuid');
    if (!clientGuid) {
      clientGuid = uuid();
      await AppCompatStorage.setItem('clientGuid', clientGuid);
    }
    return clientGuid;
  }

  static async getDebugInfo() {
    return {
      guid: await LoginUtil.getClientGuid(), // debug info
      sessionKey: await LoginUtil.getSessionKey(), // debug info
      userId: await LoginUtil.getUserId2(), // debug info
      userNo: await LoginUtil.getUserNo2(), // debug info
      bioKey: await LoginUtil.getBioKey2(), // debug info
      pinKey: await LoginUtil.getPinKey2(), // debug info
    };
  }
}
