import PathChangeDetect from '@/components/common/PathChangeDetect';
import useLogin from '@/components/user/login/useLogin';
import { useEffect } from 'react';
import useAlertCount from '@/components/user/my/useAlertCount';

export default function AlertFetch() {
  const { load } = useAlertCount();
  const { isLoggedIn } = useLogin();

  useEffect(() => {
    load();
  }, [isLoggedIn]);

  return (
    <PathChangeDetect
      callback={() => {
        load();
      }}
    />
  );
}
