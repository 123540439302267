import { PopupContainer } from '@/components/popup/Popup';
import { useDispatch, useSelector } from 'react-redux';
import { openSideBar } from '@/components/sidebar/sidebarReducer';
import { Dialog } from '@mui/material';
import { setShowLoginExpired } from '@/components/user/login/LoginReducer';

export default function LoginExpiredPopup({} = {}) {
  const { showLoginExpired } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  return (
    <Dialog open={showLoginExpired}>
      <PopupContainer
        title="세션이 만료되었습니다."
        okMessage="확인"
        okCallback={() => {
          dispatch(setShowLoginExpired(false));
          dispatch(openSideBar());
        }}
      />
    </Dialog>
  );
}
