import { useEffect, useMemo, useState } from 'react';
import PageIndicator from '../../../components/content/paging/PageIndicator';
import MyPageHeader from '@/page/my/MypageHeader';
import useWriteContentSearch from '@/components/user/my/useWriteContentSearch';
import { ContentType } from '@/app/config/ContentType';
import { SearchApi } from '@/components/search/SearchApi';
import { CircularProgress } from '@mui/material';
import WriteBBSTableRow from '@/components/table/write/WriteBBSTableRow';
import CommonTable from '@/components/table/CommonTable';
import { useMyPagePaging } from '@/page/my/tabs/useMyPagePaging';
export default function WroteBBS({}) {
  const [isSelectMode, setSelectMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { isLoading, page, list, load, total, remove } = useWriteContentSearch({
    contentType: ContentType.BBS,
  });

  const dataList = useMemo(() => {
    return list?.map((it) => SearchApi.infoToThumbnailOption(it));
  }, [list]);
  const { setPage } = useMyPagePaging({ loader: load, page });

  return (
    <>
      <MyPageHeader
        option={{
          title: '내가 쓴 글',
          count: total,
          selectCount: selectedItems.length,
          isDeleteMode: isSelectMode,
          onEnterDeleteMode: async () => {
            if (selectedItems.length === 0) {
              return;
            }
            await remove(selectedItems);
            setSelectMode(false);
            setSelectedItems([]);
            load();
          },
          // onCancel: () => {
          //     setSelectMode(false);
          //     setSelectedItems([]);
          // },
          // onAllSelect: () => setSelectedItems(dataList.map(it => it.cid)),
          // onDelete: async () => {
          // }
        }}
      />
      {isLoading && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <CircularProgress className="loading" />
        </div>
      )}
      {!isLoading && (dataList == null || dataList?.length === 0) && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <span>작성한 글이 없습니다.</span>
        </div>
      )}

      {!isLoading && dataList?.length > 0 && (
        <>
          <div className="box_wrap grid sm:gap-3 sm:gap-y-2">
            <CommonTable
              colRatios={[5, 10, 30, 10, 5]}
              ths={['', '카테고리', '제목', '등록일', '조회수']}
            >
              {dataList.map((item, index) => (
                <WriteBBSTableRow
                  id={item.cid}
                  isSelected={selectedItems.includes(item.cid)}
                  onSelectChange={(_) => {
                    if (selectedItems.includes(item.cid)) {
                      setSelectedItems(
                        selectedItems.filter((it) => it !== item.cid)
                      );
                    } else {
                      setSelectedItems([...selectedItems, item.cid]);
                    }
                  }}
                  link={item.link}
                  title={item.title}
                  date={item.date}
                  category={item.category}
                  count={item.viewCount}
                />
              ))}
            </CommonTable>
          </div>
          <PageIndicator
            option={{
              page: page - 1,
              totalCount: total,
              pageSize: 10,
              showingPageCount: 5,
              onPageChange: (p) => setPage(p),
            }}
          />
        </>
      )}
    </>
  );
}
