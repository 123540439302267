import PrivacyTerm from '@/components/term/개인정보 이용동의.htm';
import PrivacyTerm2 from '@/components/term/개인정보 처리방침.htm';
import { useMemo } from 'react';

export default function PrivacyInnerPopup({ type = '', isMobile = false }) {
  const setHeight = (e) => {
    let frame = e.target;
    frame.height = isMobile ? 870 : 1335;
  };

  const privacyTermHtml = useMemo(() => {
    if (type === 'PrivacyTerm') {
      return PrivacyTerm;
    } else {
      return PrivacyTerm2;
    }
  });

  return (
    <div className="inner_box">
      <iframe
        id="privacyTermIframe"
        onLoad={setHeight}
        className="w-full min-h-[500px]"
        src={`${privacyTermHtml}`}
      />
    </div>
  );
}
