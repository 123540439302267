import { useParams, useSearchParams } from 'react-router-dom';
import ChipTab from '@/components/common/chip/ChipTab';
import { useMemo } from 'react';
import { Collapse } from '@mui/material';
import TotalSearchTab from '@/page/search/tab/TotalSearchTab';
import { ContentType } from '@/app/config/ContentType';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import useTotalSearch from '@/components/search/useTotalSearch';
import CommonSearchTab from '@/page/search/tab/CommonSearchTab';

export const searchTypeData = {
  all: {
    searchType: '통합검색',
    title: '전체',
    contentType: ContentType.AL,
    isVideo: false,
  },
  wbn: {
    searchType: 'WBN방송',
    title: '우리방송',
    contentType: ContentType.VOD,
    isVideo: true,
  },
  tube: {
    searchType: '우리튜브',
    title: '우리튜브',
    contentType: ContentType.VOD_USER,
    isVideo: true,
  },
  radio: {
    searchType: 'Radio',
    title: 'Radio',
    contentType: ContentType.VOD,
    isVideo: true,
  },
  bbs: {
    searchType: '게시판',
    title: '게시판',
    contentType: ContentType.BBS,
    isVideo: false,
  },
};

export const searchMenus = [
  {
    menuId: 1,
    menuNm: '전체',
    code: 'all',
  },
  {
    menuId: 2,
    menuNm: '우리방송',
    code: 'wbn',
  },
  {
    menuId: 3,
    menuNm: '우리튜브',
    code: 'tube',
  },
  {
    menuId: 4,
    menuNm: 'Radio',
    code: 'radio',
  },
  {
    menuId: 5,
    menuNm: '게시판',
    code: 'bbs',
  },
];

export default function SearchPage() {
  const param = useParams();
  const keyword = param.keyword;
  const { query } = useLocation2();
  const { menuId: _menuId } = query;
  const menuId = useMemo(() => {
    const menuNum = Number(_menuId);
    return isNaN(menuNum) ? searchMenus[0].menuId : menuNum;
  }, [_menuId]);
  const code = useMemo(
    () => searchMenus.find((menu) => menu.menuId === menuId)?.code,
    [menuId]
  );

  const [_, setSearchParams] = useSearchParams();
  const setMenu = (menuId) => {
    setSearchParams({ ...query, menuId: menuId, page: 0 });
  };

  const totalSearch = useTotalSearch(keyword);

  return (
    <div id="contents">
      <div className="inner">
        <div className="result_box mt-12 mb-6 text-center">
          <span className="f24 w700">{keyword}</span>{' '}
          <span className="f24 w700 co_gray_aaa">검색결과입니다.</span>
        </div>

        <ChipTab
          categories={searchMenus}
          category={menuId}
          setCategory={setMenu}
        />

        <Collapse in={menuId === 1}>
          <div>
            <TotalSearchTab
              keyword={keyword}
              onTabChange={setMenu}
              totalSearch={totalSearch}
            />
          </div>
        </Collapse>

        {/*통합검색 제외*/}
        <Collapse in={1 < menuId && menuId < 6}>
          <div>
            {1 < menuId && menuId < 6 && (
              <CommonSearchTab
                keyword={keyword}
                code={code}
                contentType={searchTypeData[code].contentType}
              />
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
}
