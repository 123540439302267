import './ShareModal.css';
import useOuterClick from '@/lib/useOuterClick';
import { useState } from 'react';
import KaKaoShareButton from '@/components/share/KaKaoShareButton';
import TwitterShareButton from '@/components/share/TwitterShareButton';
import FacebookShareButton from '@/components/share/FacebookShareButton';
import LineShareButton from '@/components/share/LineShareButton';
import { htmlToText } from '@/lib/textUtil';
import { getDefaultProfileImage } from '@/components/user/ProfileImage';

const ShareType = {
  kakao: 'KAKAO',
  twitter: 'TWITTER',
  facebook: 'FACEBOOK',
  line: 'LINE',
  link: 'LINKCOPY',
  embed: 'EMBEDCOPY',
};
export default function ShareModal({
  data,
  onShared,
  visible,
  useVideoEmbed,
  onVisibilityChanged,
}) {
  const videoEmbedTag = `<iframe 
    src="${window.location.href.replace('/ucms/view/', '/embed/')}"
    width="560"
    height="315"
    allow="fullscreen"
    >
</iframe>
    `;

  const [_, modalRef] = useOuterClick(() => onVisibilityChanged(false));
  const [codeExpanded, setCodeExpanded] = useState(false);
  const pageUrl = window.location.href;
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (e) {
      return unsecuredCopyToClipboard(text);
    }
  };
  const unsecuredCopyToClipboard = (text) => {
    const target = document.querySelector('.popup_join') ?? document.body;
    const textArea = document.createElement('textarea');
    textArea.value = text;
    target.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      return true;
    } catch (err) {
      return false;
    } finally {
      target.removeChild(textArea);
    }
  };

  if (visible)
    return (
      <div className="popup_join" style={{ zIndex: 1000 }} ref={modalRef}>
        <img
          className="absolute right-4 top-4 close"
          src="/images/login_close.png"
          alt="닫기"
          onClick={() => onVisibilityChanged(false)}
        />
        <div className="pop_inner flex flex-col w-full">
          <h5 className="mb-4 text-center">공유하기</h5>
          <ul className="flex gap-4 mb-4">
            <li>
              <KaKaoShareButton
                title={data.title}
                description={htmlToText(data.cData)}
                commentCount={data.commentCount ?? 0}
                likeCount={data.likeCount ?? 0}
                shareCount={data.shareCount ?? 0}
                link={pageUrl}
                profileName={data.author}
                profileThumbnailUrl={
                  data.profileUrl ??
                  getDefaultProfileImage(data.authorNo, true, false)
                }
                thumbnailUrl={data.thumbnailUrl}
                onClick={() =>
                  onShared({
                    cid: data.cid,
                    shareType: ShareType.kakao,
                  })
                }
              />
            </li>
            <li>
              <TwitterShareButton
                title={data.title}
                link={pageUrl}
                onClick={() =>
                  onShared({
                    cid: data.cid,
                    shareType: ShareType.twitter,
                  })
                }
              />
            </li>
            <li>
              <FacebookShareButton
                link={pageUrl}
                onClick={() =>
                  onShared({
                    cid: data.cid,
                    shareType: ShareType.facebook,
                  })
                }
              />
            </li>
            <li>
              <LineShareButton
                link={pageUrl}
                onClick={() =>
                  onShared({
                    cid: data.cid,
                    shareType: ShareType.line,
                  })
                }
              />
            </li>
          </ul>
          <form className="url_wrap relative w-full">
            <div className="url_input">
              <input type="text" value={pageUrl} readOnly />
              <button
                type="button"
                className="btn_stroke_m right-2 top-2"
                onClick={async () => {
                  let success = await copyToClipboard(pageUrl);
                  if (success) {
                    alert(
                      'URL이 클립보드에 복사되었습니다. CTRL + V를 이용해 붙여넣기 하세요.'
                    );
                  } else {
                    alert('복사 기능이 동작하지 않습니다. 직접 복사해주세요.');
                  }

                  onShared({
                    cid: data.cid,
                    shareType: ShareType.link,
                  });
                }}
              >
                URL복사
              </button>
            </div>
          </form>
          {useVideoEmbed && (
            <>
              <div className="flex items-center justify-between my-4">
                <span></span>
                <p
                  className="code relative f14_w300"
                  aria-expanded={codeExpanded}
                  onClick={() => setCodeExpanded((prevState) => !prevState)}
                >
                  영상 삽입 코드
                </p>
                <button
                  type="button"
                  className="btn_stroke_s"
                  onClick={async () => {
                    let success = await copyToClipboard(videoEmbedTag);
                    if (success) {
                      alert(
                        'EmbedTag가 클립보드에 복사되었습니다. CTRL + V를 이용해 붙여넣기 하세요.'
                      );
                    } else {
                      alert(
                        '복사 기능이 동작하지 않습니다. 직접 복사해주세요.'
                      );
                    }

                    onShared({
                      cid: data.cid,
                      shareType: ShareType.embed,
                    });
                  }}
                >
                  복사
                </button>
              </div>
              {codeExpanded && (
                <textarea
                  className="text_filed f14_w300"
                  defaultValue={videoEmbedTag}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
}
