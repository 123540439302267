import { useState } from 'react';

import {
  isValidEmail,
  isValidEmpNo,
  isValidEmpNo8,
  isValidMobileAuth,
  isValidName,
  isValidNickname,
  isValidNumeric,
  isValidPassword,
  isValidNumberPassword,
  isValidRankNm,
  isValidTel,
} from '@/lib/textUtil';

export class RegexValidator {
  constructor({
    validator,
    state,
    autoCheckAdditionalValidators = false,
    additionalValidationRequiredMessage,
  }) {
    this.validator = validator;
    this.state = state;
    this.autoCheckAdditionalValidators = autoCheckAdditionalValidators;
    this.additionalValidationRequiredMessage =
      additionalValidationRequiredMessage;
  }
}

export const NicknameValidator = new RegexValidator({
  validator: isValidNickname,
  additionalValidationRequiredMessage: '중복 확인을 해주세요.',
  state: {
    notValidFormat: '닉네임은 3~10자로 한글과 영문만 포함해야 합니다.',
    empty: '',
    valid: '',
  },
});

export const PasswordValidator = new RegexValidator({
  validator: isValidPassword,
  state: {
    notValidFormat: '비밀번호는 8자 이상으로 영문과 숫자를 포함해야 합니다.',
    empty: '',
    valid: '',
  },
  autoCheckAdditionalValidators: true,
});

/**
 * `userComp` 는 임직원의 경우 계열사명, 일반 회원의 경우 직업명입니다.
 * 어차피 임직원의 경우 계열사명은 이메일을 따라가게 하므로 직업명에 대한 Input Validation만 고려합니다.
 * @type {RegexValidator}
 */
export const UserCompValidator = new RegexValidator({
  validator: (userComp) => userComp.length <= 20,
  state: {
    notValidFormat: '직업명은 20자 이내여야 합니다.',
    empty: '직업명은 필수입니다.',
    valid: '',
  },
});

export const TelValidator = new RegexValidator({
  validator: isValidTel,
  state: {
    notValidFormat: '전화번호는 숫자로 10~11자여야 합니다.',
    empty: '',
    valid: '',
  },
});
export const EmailValidator = new RegexValidator({
  validator: isValidEmail,
  state: {
    notValidFormat: '올바른 이메일 형식이 아닙니다.',
    empty: '이메일을 입력해 주세요',
    valid: '',
  },
  autoCheckAdditionalValidators: true,
});
export const NameValidator = new RegexValidator({
  validator: isValidName,
  state: {
    notValidFormat: '성명은 2~10자 사이여야 합니다.',
    empty: '성명은 2~10자 사이여야 합니다.',
    valid: '',
  },
  autoCheckAdditionalValidators: true,
});

export const RankNmValidator = new RegexValidator({
  validator: isValidRankNm,
  state: {
    notValidFormat: '직위는 2~15자 사이여야 합니다.',
    empty: '직위는 2~15자 사이여야 합니다.',
    valid: '',
  },
  autoCheckAdditionalValidators: true,
});
export const EmpNoValidator = new RegexValidator({
  validator: isValidEmpNo,
  state: {
    notValidFormat: '사번은 8~10자 사이 숫자와 문자여야 합니다.',
    empty: '사번은 8~10자 사이 숫자와 문자여야 합니다.',
    valid: '',
  },
  autoCheckAdditionalValidators: true,
});

export const NumericValidator = new RegexValidator({
  validator: isValidNumeric,
  state: {
    notValidFormat: '구독자수는 숫자여야 합니다.',
    empty: '',
    valid: '',
  },
  autoCheckAdditionalValidators: true,
});

export const EmpNo8Validator = new RegexValidator({
  validator: isValidEmpNo8,
  state: {
    notValidFormat: '사번은 8자의 숫자와 문자여야 합니다.',
    empty: '사번은 8자의 숫자와 문자여야 합니다.',
    valid: '',
  },
  autoCheckAdditionalValidators: true,
});

// Todo: 인증번호 조건 수정
export const MobileAuthValidator = new RegexValidator({
  validator: isValidMobileAuth,
  state: {
    notValidFormat: '인증번호는 6자리입니다.',
    empty: '',
    valid: '',
  },
});

/**
 * Regex를 이용해 값을 검증하는 훅입니다.
 * RegexValidator 객체를 받아 처리합니다.
 * @param {RegexValidator} validator
 * @param __value
 * @param originValue
 * @return {{isValidate: boolean, setValue: setValue, hasError: boolean, message: string, value: unknown}}
 */
export default function useRegexValidate(validator, __value, originValue) {
  const [value, _setValue] = useState(__value);
  const [state, setState] = useState({
    message: '',
    hasError: false,
    isValidate: true,
  });

  const validate = (v) => {
    if ((v === originValue && originValue != null) || v == null || v === '') {
      return true;
    } else if (validator.validator(v) != null) {
      return true;
    }
    return false;
  };
  const setValue = (v) => {
    _setValue(v);
    if (v === originValue && originValue != null) {
      setState({
        hasError: false,
        isValidate: true,
        message: validator.state.valid,
      });
    } else if (v == null || v === '') {
      setState({
        hasError: true,
        isValidate: false,
        message: validator.state.empty,
      });
    } else {
      const matches = validator.validator(v);
      setState({
        hasError: false,
        isValidate: matches != null,
        message:
          matches != null
            ? validator.state.valid
            : validator.state.notValidFormat,
      });
    }
  };
  return {
    validate,
    ...state,
    value,
    setValue,
  };
}

/**
 * useRegexValidate 와 비슷한 동작을 하는 훅입니다.
 * 눈여겨 볼 점은 여러 validator 함수를 받아 처리한다는 점입니다.
 * RegexValidator 자체도 사실은 string|null을 반환해 null일 경우 에러가 없는 것으로 판단하는 validator 함수로 바꿔 처리 가능해 보입니다.
 * @param {RegexValidator} regexValidator
 * @param __value
 * @param originValue
 * @param {((value) => string | null)[]} additionalValidators Additional Validators.
 * @return {{isValidate: boolean, setValue: setValue, hasError: boolean, message: string, value: unknown, validate: () => string|null}}
 */
export function useRegexArrayValidator(
  regexValidator,
  __value,
  originValue,
  additionalValidators = []
) {
  const [value, _setValue] = useState(__value);
  const [state, setState] = useState({
    message: '',
    hasError: false,
    isValidate: true,
  });

  const validate = async (v = value) => {
    if (!formatValidate(v)) {
      return false;
    }

    for (const additionalValidator of additionalValidators) {
      const message = await additionalValidator(v);
      if (message != null) {
        setState({
          hasError: false,
          isValidate: false,
          message: message,
        });
        return false;
      }
    }

    setState({
      hasError: false,
      isValidate: true,
      message: null,
    });
    return true;
  };

  const formatValidate = (v) => {
    if (v === originValue && originValue != null) {
      setState({
        hasError: false,
        isValidate: true,
        message: regexValidator.state.valid,
      });
      return true;
    }
    if (v == null || v === '') {
      setState({
        hasError: false,
        isValidate: false,
        message: regexValidator.state.empty,
      });
      return false;
    }
    if (regexValidator.validator(v) == null) {
      setState({
        hasError: false,
        isValidate: false,
        message: regexValidator.state.notValidFormat,
      });
      return false;
    }
    return true;
  };

  const setValue = async (v) => {
    _setValue(v);
    console.log(regexValidator.validator(v));
    const formatValid = formatValidate(v);
    if (formatValid) {
      if (additionalValidators.length > 0) {
        if (regexValidator.autoCheckAdditionalValidators) await validate(v);
        else
          setState({
            hasError: false,
            isValidate: false,
            message: regexValidator.additionalValidationRequiredMessage,
          });
      } else
        setState({
          hasError: false,
          isValidate: true,
          message: regexValidator.state.valid,
        });
    }
  };

  return {
    ...state,
    value,
    setValue,
    validate: () => validate(value),
  };
}
