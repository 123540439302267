import { Helmet } from 'react-helmet';

export default function CanZoomHeader() {
  return (
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=4"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=4, user-scalable=yes"
      />
    </Helmet>
  );
}
