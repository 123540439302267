import Link2 from '@/components/common/transition/Link2';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import { useEffect } from 'react';
import useLogin from '@/components/user/login/useLogin';

export default function JoinTypePage() {
  const navigator = useNavigate2();

  const { isLoggedIn } = useLogin();

  useEffect(() => {
    if (isLoggedIn) {
      navigator('/');
    }
  }, [isLoggedIn]);

  const next = (type) => {
    navigator(`/join/form/${type}`);
  };

  return (
    <div id="contents">
      <div className="join_cont flex justify-center items-center">
        <div className="inner_box">
          <div className="join_box">
            <h5 className="head_tit justify-center text-center mb-4">
              회원가입
            </h5>
            <p className="f14_w300 mb-2">채널W에 오신 것을 환영합니다.</p>
            <h4 className="join_tit f28_w700 mb-8">
              회원유형을
              <br />
              선택해 주세요.
            </h4>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                next(e.target.user.value);
              }}
            >
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="radio_wrap">
                  <input
                    id="user01"
                    name="user"
                    className="radio_control"
                    type="radio"
                    value="co"
                    defaultChecked="true"
                  />
                  <label className="user_Staff" htmlFor="user01">
                    <div className="flex flex-col items-center text">
                      <img
                        className="mb-4"
                        src="/images/user_ico01.png"
                        alt="우리은행 로고"
                      />
                      <p className="f12_w300">우리금융그룹</p>
                      <p className="">임직원 회원가입</p>
                    </div>
                  </label>
                </div>
                <div className="radio_wrap">
                  <input
                    id="user02"
                    name="user"
                    className="radio_control"
                    type="radio"
                    value="nor"
                  />
                  <label className="user_Staff" htmlFor="user02">
                    <div className="flex flex-col items-center">
                      <img
                        className="mb-4"
                        src="/images/user_ico02.png"
                        alt="우리은행 로고"
                      />
                      <p className="f12_w300">반가워요!</p>
                      <p className="">일반 회원가입</p>
                    </div>
                  </label>
                </div>
              </div>
              <div>
                <p className="f14_w300 co_gray999 mb-4">
                  ※ 일반회원은 관리자 승인후 서비스를 이용할 수 있습니다.
                </p>
                <button type="submit" className="pri_btn mb-4">
                  다음
                </button>
              </div>
            </form>
            <p className="f14_w300 co_gray999 text-center">
              이미 채널W 회원이신가요?
              <Link2 to="/" state={{ login: true }}>
                <span className="login f14_w300 underline">로그인하기</span>
              </Link2>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
