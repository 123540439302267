/**
 * @param {Comment} comment
 * @return {JSX.Element}
 * @constructor
 */
import { useMemo, useState } from 'react';
import classNames from 'classnames';
import useLogin from '@/components/user/login/useLogin';
import ProfileImage from '@/components/user/ProfileImage';
import CommentOption from '@/components/comment/CommentOption';
import CommentWriteItem from '@/components/comment/CommentWriteItem';
import LoginPopup from '@/components/popup/LoginPopup';
import Link2 from '@/components/common/transition/Link2';

export default function CommentItem({
  comment,
  parentId,
  onSubCommentWriteClicked,
  onCommentSubmit,
  onCommentEventEmitted,
}) {
  const { data: loginData, isLoggedIn } = useLogin();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const isResponse = useMemo(() => comment.parentId != null, [comment]);

  const [subCommentVisibleKey, setSubCommentVisibleKey] = useState(null);

  const addComment = async (parentId, content) => {
    if (!isLoggedIn) {
      setShowLoginPopup(true);
      return false;
    }
    await onCommentSubmit(content, parentId);
    return true;
  };

  const reportComment = async () => {
    if (isResponse) {
      return onCommentEventEmitted('ReportCommentResponseMutation', {
        id: comment.id,
        parentId,
      });
    } else {
      return onCommentEventEmitted('ReportCommentMutation', { id: comment.id });
    }
  };
  const deleteComment = async () => {
    if (isResponse) {
      return onCommentEventEmitted('DeleteCommentResponseMutation', {
        id: comment.id,
        parentId,
      });
    } else {
      return onCommentEventEmitted('DeleteCommentMutation', { id: comment.id });
    }
  };

  const [inputEditMode, setInputEditMode] = useState(false);

  const addResponseMode = useMemo(() => {
    return !parentId && subCommentVisibleKey;
  }, [parentId, subCommentVisibleKey]);

  return (
    <div
      className={classNames({
        'mt-4': true,
        'flex-1': true,
        'border-b': !parentId,
      })}
    >
      <div
        className={classNames({
          comment_row: true,
          relative: true,
          flex: true,
          'pb-4': true,
        })}
      >
        <ProfileImage
          className="comment_profile mr-3"
          profileUrl={comment.profileUrl}
          userNo={comment.userNo}
          nickNm={comment.author}
        />
        <div className="flex-1">
          <div>
            <p className="f14">
              <Link2 to={`/user/content?userNo=${comment.userNo}`}>
                {comment.author}
              </Link2>
            </p>
            <p className="f14_w300 mb-1 pr-16">{comment.content}</p>
          </div>

          <div className="flex items-center relative reply_text gap-4">
            <span className="f12_w400 co_gray999">{comment.organization}</span>
            <span className="f12_w400 co_gray999">{comment.timeElapsed}</span>
            <span
              className="f12_w400 cursor-pointer"
              onClick={() => {
                if (parentId == null) {
                  setSubCommentVisibleKey((prevState) => ++prevState);
                } else {
                  onSubCommentWriteClicked();
                }
              }}
            >
              답글달기
            </span>
          </div>
        </div>

        <CommentOption
          canReport={
            isLoggedIn &&
            comment.userNo !== loginData?.userNo &&
            (isResponse ? comment.resSt === 'S' : comment.replySt === 'S')
          }
          canDelete={
            comment.userNo === loginData?.userNo &&
            (isResponse ? comment.resSt === 'S' : comment.replySt === 'S')
          }
          canEdit={
            comment.userNo === loginData?.userNo &&
            (isResponse ? comment.resSt === 'S' : comment.replySt === 'S')
          }
          onEdit={(e) => setInputEditMode(true)}
          onDelete={deleteComment}
          onReport={reportComment}
        />
      </div>

      {/* FIXME: 대댓글이 없을 때 답글달기를 누르면 Collapse 애니메이션이 나와야 할까요??*/}

      {inputEditMode && (
        <div className="a comment_filed_inner mt-2 mb-4 ml-14">
          <div className="flex justify-between flex-wrap">
            <CommentWriteItem
              initialValue={comment.content}
              isEdit={true}
              onCancel={() => setInputEditMode(false)}
              useCancel={true}
              onSubmit={async (content) => {
                if (content?.trim() === '') {
                  return;
                }
                if (isResponse) {
                  await onCommentEventEmitted('EditCommentResponseMutation', {
                    id: comment.id,
                    parentId,
                    content,
                  });
                } else {
                  await onCommentEventEmitted('EditCommentMutation', {
                    id: comment.id,
                    content: content.trim(),
                  });
                }
                setInputEditMode(false);
              }}
            />
          </div>
        </div>
      )}

      {addResponseMode && (
        <div className="b comment_filed_inner mt-2 mb-4 ml-14">
          <div className="flex justify-between flex-wrap">
            <CommentWriteItem
              initialValue={''}
              isEdit={false}
              isResponsive={true}
              onCancel={() => setSubCommentVisibleKey(null)}
              useCancel={true}
              onSubmit={async (content) => {
                if (content?.trim() === '') {
                  return;
                }
                let res = await addComment(
                  parentId ?? comment.id,
                  content.trim()
                );
                if (res === true) {
                  setSubCommentVisibleKey(null);
                }
                return res;
              }}
            />
          </div>
        </div>
      )}

      {comment.subComments &&
        comment.subComments.map((subComment, idx) => (
          <div
            key={`subcomment_${comment.id}_${idx}`}
            className={classNames({
              comment_row: true,
              relative: true,
              reply_answer: true,
              flex: true,
              'pl-14': true,
              'border-b': idx !== comment.subComments.length - 1,
              'border-t': idx === 0,
            })}
          >
            <CommentItem
              comment={subComment}
              parentId={comment.id}
              onSubCommentWriteClicked={() =>
                setSubCommentVisibleKey((prevState) => ++prevState)
              }
              onCommentEventEmitted={onCommentEventEmitted}
            />
          </div>
        ))}

      <LoginPopup show={showLoginPopup} setShow={setShowLoginPopup} />
    </div>
  );
}
