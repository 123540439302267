import DefaultContentDto from '@/model/DefaultContentDto';

export default class DefaultContentDetailDto extends DefaultContentDto {
  constructor({
    cid,
    author,
    authorNo,
    contentType,
    title,
    chatYn,
    commentCount,
    viewCount,
    likeCount,
    shareCount,
    fileCount,
    tag,
    date,
    isLiked,
    cData,
    files,
    comments,
    liveYn,
    posterFile,
    nextVideo,
    isDeletable,
    isEditable,
    isReportable,
    openLvl,
    openOpt,
    profileUrl,
    profileSt,
    followerCount,

    gfUrl,
    gfKey,
    gfData,
    gfBtnText,

    eventStartDt,
    eventEndDt,
    eventStatus,
  }) {
    super({
      cid,
      author,
      title,
      commentCount,
      viewCount,
      likeCount,
      shareCount,
      fileCount,
      tag,
      date,
      isLiked,
    });
    this.cData = cData;
    this.openLvl = openLvl;
    this.chatYn = chatYn;
    this.openOpt = openOpt;
    this.contentType = contentType;
    this.files = files;
    this.profileUrl = profileUrl;
    this.profileSt = profileSt;
    this.followerCount = followerCount;
    this.authorNo = authorNo;
    this.comments = comments;
    this.liveYn = liveYn;
    this.isDeletable = isDeletable;
    this.isEditable = isEditable;
    this.isReportable = isReportable;
    this.nextVideo = nextVideo;
    this.posterFile = posterFile;

    this.gfUrl = gfUrl;
    this.gfKey = gfKey;
    this.gfData = gfData;
    this.gfBtnText = gfBtnText;

    this.eventStartDt = eventStartDt;
    this.eventEndDt = eventEndDt;
    this.eventStatus = eventStatus;
  }
}
