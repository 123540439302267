import { Outlet } from 'react-router-dom';
import BugReportPopup from '@/components/popup/bugreport/BugReportPopup';
import LoginExpiredDialogContainer from '@/components/layout/LoginExpiredDialogContainer';

export default function PortalContainer() {
  return (
    <>
      <Outlet />
      <BugReportPopup />
      <LoginExpiredDialogContainer />
      <div className="popup_portal"></div>
      <div className="app_download_popup_portal"></div>
    </>
  );
}
