import GridView from '@/components/ucms/GridView';
import { useEffect, useMemo, useState } from 'react';
import useAlertList from '@/components/user/my/useAlertList';
import PageIndicator from '@/components/content/paging/PageIndicator';
import { contentDefine } from '@/components/content/list/BoardList';
import LoadingLastWrapper from '@/components/ucms/LoadingLastWrapper';
import { useMyPagePaging } from '@/page/my/tabs/useMyPagePaging';
import usePage from '@/components/infiniteScroll/usePage';
import LoginPopup from '@/components/popup/LoginPopup';
import useLogin from '@/components/user/login/useLogin';

export default function AlertGridContainer() {
  const [historyPage, _] = usePage();
  const { isLoading, page, list, load, total, remove, unreadCount, makeRead } =
    useAlertList({ initialPage: historyPage });
  const { setPage } = useMyPagePaging({ loader: load });

  const dataList = useMemo(() => {
    return list?.map((it) => ({
      id: it.itemId,
      cid: it.cid,
      menuId: it.menuId,
      title: it.alertTitle,
      date: it.reserveDt?.replace('T', ' '),
      author: it.nickNm,
      link: it.alerturl,
      viewYn: it.viewYn,
    }));
  }, [list]);

  useEffect(() => {
    checkSessionValidity()
      .then((r) => {
        if (r) load();
        else setShowLoginPopup(true);
      })
      .catch((e) => setShowLoginPopup(true));
  }, []);

  const { checkSessionValidity } = useLogin();

  const [showLoginPopup, setShowLoginPopup] = useState(false);

  return (
    <div id="content">
      <div className="inner">
        <div className=" mb-8 md:mb-20" />
        <div className="alert_tit flex mb-7">
          <h4 className="mr-1">읽지 않은 알림</h4>
          <span className="co_pri">{unreadCount}</span>
        </div>

        <LoadingLastWrapper isLoading={isLoading} isLast={false}>
          <div className="md:min-h-[598px]">
            {!isLoading && dataList != null && dataList.length === 0 && (
              <div className="notice_none text-center h-80 flex flex-col items-center justify-center ">
                <p className="f14 mb-2">알림이 없습니다.</p>
              </div>
            )}
            <GridView
              dataList={dataList}
              rowCount={contentDefine.bbs.rowCount}
              columnCount={contentDefine.bbs.columnCount}
              parentClassName={contentDefine.bbs.list}
              itemRender={(item, i) =>
                contentDefine.bbs.item(
                  item,
                  () => {
                    remove(item.id);
                    load({ page: page + 1 });
                  },
                  () => {
                    makeRead(item.id);
                    load({ page: page + 1 });
                  }
                )
              }
            />
          </div>
        </LoadingLastWrapper>

        <LoginPopup
          show={showLoginPopup}
          setShow={() => setShowLoginPopup(false)}
        />
        {total > 0 && (
          <div className="mb-5">
            <PageIndicator
              option={{
                page: page - 1,
                totalCount: total,
                pageSize: 9,
                showingPageCount: 5,
                onPageChange: setPage,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
