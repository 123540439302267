import TermPopup from '../../components/popup/TermPopup';
import TermPopupButton from '@/page/join/TermPopupButton';

export default function JoinPrivacyOptionalTermPopup({ onAgree, onClose }) {
  return (
    <TermPopup>
      <div className="inner_box">
        <button
          className="flex flex-row-reverse join_close"
          onClick={() => onClose()}
        >
          <img src="/images/login_close.png" alt="닫기" className="close" />
        </button>
        <div className="join_box">
          <h5 className="head_tit mt-4 mb-4 justify-between"></h5>
          <h4 className="join_tit f28_w700 mb-8">
            {' '}
            [선택] 상품별 개인정보 수집·이용 동의서 <br />
            [상품/서비스명: 채널W]
          </h4>
          <div className="flex flex-col mt-4 mb-4">
            <h4 className="mt-4 mb-4">㈜우리은행 귀중</h4>※ 귀 행과의 채널W
            이용과 관련하여 귀 행이 본인의 개인정보를 수집·이용하고자 하는
            경우에는 「개인정보보호법」등 관계 법령에 따라 본인의 동의가
            필요합니다.
            <h3 className="text_box f20 mb-2 mt-10">1. 수집·이용 목적</h3>
            <p className="co_gray999 w300">
              이벤트 당첨자 대상 모바일 쿠폰 발송
            </p>
            <h3 className="text_box f20 mb-2 mt-10">2. 보유 및 이용기간</h3>
            <p className="co_gray777 f18 w600">
              경품 제공일로부터 3개월까지 보유·이용
            </p>
            <p className="co_gray999 w300">
              위 보유기간에서의 경품 제공일이란 '이벤트 당첨자 대상 모바일 쿠폰
              발송'한 날을 말합니다.
            </p>
            <p className="co_gray999 w300">
              경품 제공일 후에는 금융사고 조사, 분쟁 해결, 민원 처리, 법률상
              의무 이행을 위한 목적으로만 보유·이용합니다
            </p>
            <h3 className="text_box f20 mb-2 mt-10">3. 거부 권리 및 불이익</h3>
            <p className="co_gray999 w300">
              위 개인정보 수집·이용에 관한 동의는 거부하실 수 있으며, “이벤트
              당첨자 대상 모바일 쿠폰 발송”을 위한 선택적 사항이므로 동의하지
              않더라도 채널W 회원가입 및 이용에는 제한이 없습니다. 다만,
              동의하지 않으시는 경우 “이벤트 당첨자 대상 모바일 쿠폰 발송”의
              혜택을 받지 못할 수 있습니다.
            </p>
            <h3 className="text_box f20 mb-2 mt-10">4. 수집·이용 항목</h3>
            <p className="co_gray999 w300">
              개인정보>일반개인정보 : 성명, 닉네임, 휴대전화번호
            </p>
          </div>
        </div>
        <TermPopupButton onAgree={onAgree} onClose={onClose} />
      </div>
    </TermPopup>
  );
}
