import { useEffect } from 'react';

export default function Dim({ preventScroll = true }) {
  const handleTouchMove = (event) => {
    event.preventDefault();
  };

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
    document.querySelector('html').scrollTop = window.scrollY; // dimmed 되기 전 스크롤 위치 고정
  };

  useEffect(() => {
    if (preventScroll) {
      window.addEventListener('touchmove', handleTouchMove, { passive: false });
      window.addEventListener('scroll', disableScroll);

      return () => {
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('scroll', disableScroll);
        document.body.style.overflow = 'visible';
      };
    }
  }, [preventScroll]);
  return (
    <div className="fixed w-full h-full bg-black opacity-80 top-0 right-0 bottom-0 left-0 z-40"></div>
  );
}
