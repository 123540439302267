import useLogin from '@/components/user/login/useLogin';
import { useEffect, useMemo, useState } from 'react';
import MyProfileImage from '@/components/user/MyProfileImage';
import LoginPopup from '@/components/popup/LoginPopup';
import useMobileNavigation from '@/components/navigation/useMobileNavigation';
import { getBytes } from '@/lib/stringUtil';
import usePlatform from '@/lib/usePlatform';

export default function CommentWriteItem({
  initialValue,
  isResponsive,
  onSubmit,
  isEdit,
  useCancel,
  onCancel,
}) {
  const { setCommentFocused, setCommentBlur } = useMobileNavigation();
  const [comment, setComment] = useState(initialValue);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const { isLoggedIn } = useLogin();

  const _submit = async () => {
    try {
      let res = await onSubmit(comment);
      if (res === true) {
        setComment('');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const clearInputBuffer = (target) => {
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'text');
    hiddenInput.style.position = 'absolute';
    hiddenInput.style.width = '0px';
    hiddenInput.style.height = '0px';
    hiddenInput.style.opacity = '0';
    target?.parentElement.prepend(hiddenInput);
    hiddenInput.focus();

    window.setTimeout(() => {
      target?.focus();
      hiddenInput.remove();
    }, 0);
  };

  useEffect(() => {
    setCommentBlur();
    return () => {
      setCommentBlur();
    };
  }, []);

  const [prevInput, setPrevInput] = useState('');
  const { isChromeOnMacOs } = usePlatform();

  return (
    <>
      <div className="comment_box flex items-center flex-1 flex-grow-[10]">
        <MyProfileImage
          className="mr-6 w-[32px] h-[32px]"
          useUserContentLink={false}
        />
        {/*<span className="f14_w300 comment_filed w-full">*/}
        <input
          className="w-full bg-transparent min-w-[100px]"
          type="text"
          onFocus={() => {
            setCommentFocused();
          }}
          onBlur={() => {
            setCommentBlur();
          }}
          placeholder={`${isResponsive ? '답글' : '댓글'} 입력`}
          value={comment}
          onKeyUp={(e) => {
            if (!isChromeOnMacOs) return;
            const value = e.currentTarget.value;
            const currLastCharBytes = getBytes(
              value.substring(value.length - 1)
            );
            const prevLastCharBytes = getBytes(
              prevInput.substring(prevInput.length - 1)
            );
            // 전각 -> 반각으로 갈 때
            if (prevLastCharBytes === 2 && currLastCharBytes === 1) {
              clearInputBuffer(e.target);
            }
            setPrevInput(value);
          }}
          onChange={(e) => setComment(e.target.value)}
          onClick={() => {
            if (!isLoggedIn) {
              setShowLoginPopup(true);
            }
          }}
        />
        {/*</span>*/}
      </div>
      <div className="flex flex-1 justify-end">
        {isEdit && (
          <button className="btn_stroke_s min-w-[60px]" onClick={_submit}>
            수정
          </button>
        )}

        {!isEdit && (
          <button className="btn_stroke_s min-w-[60px]" onClick={_submit}>
            등록
          </button>
        )}
        {(isEdit || isResponsive) && useCancel && (
          <button className="btn_stroke_s min-w-[60px]" onClick={onCancel}>
            취소
          </button>
        )}
      </div>

      <LoginPopup show={showLoginPopup} setShow={setShowLoginPopup} />
    </>
  );
}
