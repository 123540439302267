import useStartViewTransitionCompat from '@/components/common/transition/useStartViewTransitionCompat';
import { useNavigate } from 'react-router-dom';

/**
 * startViewTransition 사용한 부드러운 전환을 추가합니다.
 * to 대상이 외부인 경우 사용하면 안됩니다.
 * @param className
 * @param style
 * @param children
 * @param to
 * @param replace
 * @param state
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
export default function Link2({
  className,
  style,
  children,
  to,
  replace,
  state,
  onClick,
} = {}) {
  const startViewTransition = useStartViewTransitionCompat();
  const navigator = useNavigate();

  const _onClick = (e) => {
    if (
      e.ctrlKey ||
      e.shiftKey ||
      e.metaKey || // apple
      (e.button && e.button == 1) // middle click, >IE9 + everyone else
    ) {
      // 새창 단축 동작은 막지 않음
      return;
    }
    e.preventDefault();
    if (to === -1 || to === '-1') {
      // 뒤로가기 요청시, 다르면 홈으로 이동.
      // referer가 본인 주소거나 없으면 뒤로가기
      // 외부 사이트나, url 직접 입력 접근이 해당.
      if (
        document.referrer !== '' &&
        (document.referrer == null ||
          document.referrer.indexOf(window.location.host) === -1)
      ) {
        navigator('/', { replace: true });
        return;
      } else {
        window.history.back();
        return;
      }
    }
    // http로 시작
    if (!Number(to) && to?.startsWith('http')) {
      // 같은 도메인
      if (to.startsWith(window.location.origin)) {
        // 도메인 제거 후 내부 링크로 취급
        navigator(to.replace(window.location.origin, ''), {
          replace,
          state,
        });
      } else {
        // 외부 링크
        window.location.href = to;
      }
    } else {
      // 내부 링크로 취급
      startViewTransition(
        navigator(to, {
          replace,
          state,
        })
      );
    }
  };

  return (
    <a
      onClick={onClick ?? _onClick}
      style={style}
      className={`${
        onClick != null || to != null ? 'cursor-pointer' : ''
      } ${className}`}
      href={to}
    >
      {children}
    </a>
  );
}
