import ThumbnailCard from '@/components/card/ThumbnailCard';
import { useEffect, useMemo, useRef, useState } from 'react';
import LiveList from '@/components/content/list/LiveList';
import useUcmsSearch from '@/components/ucms/search/useUcmsSearch';
import AutoBreadCrumb from '@/components/ucms/common/AutoBreadCrumb';
import SortFilter from '@/components/content/list/SortFilter';
import { SearchApi } from '@/components/search/SearchApi';
import LoadingErrorWrapper from '@/components/ucms/LoadingLastWrapper';
import GridView from '@/components/ucms/GridView';
import usePasswordPopup from '@/components/ucms/common/usePasswordPopup';
import useLogin from '@/components/user/login/useLogin';
import Config from '@/app/config/config';
import { useSearchParams } from 'react-router-dom';
import usePage from '@/components/infiniteScroll/usePage';
import useInfiniteScroll from '@/components/infiniteScroll/useInfiniteScroll';
import useSort from '@/components/infiniteScroll/useSort';
import { useSelector } from 'react-redux';

export function useInitialEffect(cb, depList) {
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
      return;
    }
    cb();
  }, [isInitial]);
  return {};
}

export default function UcmsVideoGridContainer({ menuId }) {
  const { isLoggedIn } = useLogin();
  const { popup: passwordPopup, onClick, close } = usePasswordPopup();
  const [initialPage, _] = usePage();

  const [sort, setSort] = useSort();

  const {
    dataList: liveList,
    isInitLoading: liveListLoading,
    hasError: liveListHasError,
    search: liveSearch,
    page,
  } = useUcmsSearch({
    menuId: menuId,
    isLive: true,
    isVideo: true,
    interval: Config.liveUpdateInterval,
    initialPage,
  });

  const {
    dataList: list,
    isLoading,
    isInitLoading: isVideoInitLoading,
    hasError,
    isLast,
    total,
    search,
    loadMore,
    sortChange,
    setPage,
  } = useUcmsSearch({
    menuId: menuId,
    isLive: false,
    isVideo: true,
    initialPage,
    isInfiniteLoading: true,
  });

  const _list = useMemo(() => {
    return list.map((it) => ({
      ...it,
      onClick: (e) => {
        onClick(e, it.menuId, it.cid, it.link, it.isLocked);
      },
    }));
  }, [list]);

  // const [sortSelected, setSortSelected] = useState(Object.keys(SearchApi.searchSort)[0]);

  useEffect(() => {
    sortChange(SearchApi.searchSort[sort]);
  }, [sort]);

  useInitialEffect(() => {
    liveSearch();
    search();
  }, [isLoggedIn]);

  // 화면 이동시 자동으로 닫기
  useInitialEffect(() => {
    close();
  }, [isLoading, menuId]);

  useEffect(() => {
    console.log('loadings: ', isVideoInitLoading, liveListLoading);
  }, [isVideoInitLoading, liveListLoading]);

  const [gridRef, gridBottomRef] = useInfiniteScroll({
    isLoading,
    isLast,
    loadMore,
    setPage,
    isInitLoading: isVideoInitLoading || liveListLoading,
    page,
  });

  return (
    <>
      <LiveList isLoading={liveListLoading} list={liveList} />
      <div className="cont_tit flex justify-between items-center mb-8 lg:mb-8">
        <AutoBreadCrumb menuId={menuId} showOnMobile={true} />
        <div />
        <SortFilter selected={sort} onSelect={setSort} />
      </div>

      {!isLoading && list.length === 0 && (
        <div className="notice_none text-center h-80 flex flex-col items-center justify-center ">
          <p className="f14 mb-2">게시글이 없습니다.</p>
        </div>
      )}

      <LoadingErrorWrapper
        isLoading={isLoading}
        isLast={isLast && list.length !== 0}
        lastContentMessage="마지막 영상입니다."
      >
        <div ref={gridRef}>
          <GridView
            dataList={_list ?? []}
            rowCount={4}
            columnCount={2}
            parentClassName="
                    lg:gap-y-16
                    lg:mb-16
                    gap-4
                    gap-y-12
                    mb-12
                    grid
                    lg:grid-cols-4
                    sm:grid-cols-2
                "
            itemRender={(item, i) => (
              <div
                id={`element-${item.cid}`}
                key={`video_thumbnail_card_${item.cid}_${i}`}
                className="col"
              >
                <ThumbnailCard
                  option={{
                    ...item,
                  }}
                />
              </div>
            )}
          />
        </div>
        <div ref={gridBottomRef} />
      </LoadingErrorWrapper>
      {passwordPopup}
    </>
  );
}
