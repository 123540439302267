import { useDispatch, useSelector } from 'react-redux';
import useLogin from '@/components/user/login/useLogin';
import { getAlertCount } from '@/components/user/login/LoginReducer';

export default function useAlertCount() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useLogin();
  const data = useSelector((state) => state.loginReducer.alertCount);

  const load = () => {
    if (isLoggedIn) {
      dispatch(getAlertCount({ _pageNo: 1, _pageSize: 0 }));
    }
  };

  return {
    load,
    unreadCount: isLoggedIn === true ? data?.data?.total_new ?? 0 : 0,
  };
}
