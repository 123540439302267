import React from 'react';
import ReactDOM from 'react-dom/client';
import './app/index.css';
import App from './app/App';
import reportWebVitals from './app/reportWebVitals';

import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer, { rootSaga } from './app/config/redux';
import { logger } from 'redux-logger/src';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { isDev } from '@/app/config/dev';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false, // 파일 업로드를 위해, 파일객체를 redux로 다루면서 필수로 설정해야함.
      serializableCheck: false,
    }).concat(sagaMiddleware, logger),
});
sagaMiddleware.run(rootSaga);

if (isDev()) {
} else {
  console.log = () => {};
}
const queryClient = new QueryClient({});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
