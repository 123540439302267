import { forwardRef } from 'react';

const TabPanel = forwardRef(({ children, value, index, ...other }, ref) => (
  <div
    ref={ref}
    className={`max-w-[100vw] ${other.className ?? ''}`}
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {children}
  </div>
));

export default TabPanel;
