import { Outlet, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import LoginExpiredPopup from '@/components/popup/LoginExpiredPopup';
import useQuery from '@/components/sidebar/hook/useQuery';
import { useDispatch } from 'react-redux';
import { setShowLoginExpired } from '@/components/user/login/LoginReducer';

export default function LoginExpiredDialogContainer() {
  const dispatch = useDispatch();
  const query = useQuery();
  const [_, setSearchParam] = useSearchParams();

  useEffect(() => {
    if (query?.reason === 'expired') {
      let newQuery = {
        ...query,
      };
      delete newQuery.reason;
      setSearchParam(newQuery, { replace: true });
      dispatch(setShowLoginExpired(true));
    }
  }, [query]);

  return <LoginExpiredPopup />;
}
