import { useSelector } from 'react-redux';
import WooriPlayer from '@/components/player/WooriPlayer';
import { createRef, useEffect, useRef } from 'react';
import PipVideoPortal from '@/components/player/pip/PipVideoPlayer';
import BoardVideoPortal from '@/components/player/BoardVideoContainer';

/**
 * 플레이어 PIP 상태에 따라 각 Portal에 플레이어를 렌더링한다.
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function GlobalVideoContainer({ children }) {
  const playOption = useSelector((state) => state.playerReducer.option);
  const isPip = useSelector((state) => state.playerReducer.isPip);
  const ref = useRef();
  const boardContainerRef = createRef();
  const pipContainerRef = createRef();
  const containerRef = createRef();

  const player = <WooriPlayer key="global-woori-player" ref={ref} />;

  // useEffect(() => {
  //     const tag = 'GlobalVideoContainer';
  //     console.log(`${tag} init`);
  //
  //     return () => {
  //         console.log(`${tag} dispose`);
  //     }
  // }, []);

  useEffect(() => {
    if (isPip) {
      pipContainerRef.current?.append(containerRef.current);
    } else {
      boardContainerRef.current?.append(containerRef.current);
    }
  }, [isPip]);

  return (
    <div>
      {children}

      {
        // PIP가 아닐 때 보드에 플레이어를 렌더링한다.
        playOption && (
          <BoardVideoPortal>
            <div ref={boardContainerRef} className="w-full h-full">
              <div ref={containerRef} className="w-full h-full">
                {player}
              </div>
            </div>
          </BoardVideoPortal>
        )
      }
      {
        // PIP일때 PIP에 플레이어를 렌더링한다.
        playOption && (
          <PipVideoPortal>
            <div ref={pipContainerRef} />
          </PipVideoPortal>
        )
      }
    </div>
  );
}
