import {Upload} from "@aws-sdk/lib-storage";
import {S3} from "@aws-sdk/client-s3";
import {AbortController} from "@aws-sdk/abort-controller";

// eslint-disable-next-line no-restricted-globals
let s = self;

let abortController = null;

/**
 *
 * @param {FileUploadParam} data
 */
s.onmessage = async ({ data }) => {
    const {method} = data;
    if(method === FileUploadWorkerRunMethod.upload){
        const {file: fileDto, path, bucket, config} = data.data;
        const {file, name: fileName} = fileDto;

        const {accessKey, secretKey, region, endPoint} = config;

        const s3 = new S3({
            endpoint: endPoint,
            region: region,
            forcePathStyle: true,
            credentials: {
                accessKeyId: accessKey,
                secretAccessKey: secretKey,
            }
        });

        const chunkSize = 10 * 1024 * 1024;
        const key = `${path}/${fileName}`;

        try {
            abortController = new AbortController();
            const parallelUploads3 = new Upload({
                client: s3,
                params: {
                    Bucket: bucket,
                    Key: key,
                    Body: file,
                },
                abortController: abortController,
                // queueSize: 4, // default
                partSize: chunkSize,
                // leavePartsOnError: false, // default
            });

            parallelUploads3.on("httpUploadProgress", (progress) => {
                s.postMessage({
                    method: FileUploadWorkerEventMethod.progress,
                    data: progress
                });
            });

            s.postMessage({
                method: FileUploadWorkerEventMethod.progress,
                data: {loaded: 0, total: file.size},
            });

            await parallelUploads3.done();
            s.postMessage({
                method: FileUploadWorkerEventMethod.uploaded,
                data: {
                    name: fileName,
                    url: endPoint + '/' + bucket + '/' + key,
                }
            });

        } catch (e) {
            console.error(e);

            if(e.name === "AbortError") {
                s.postMessage({
                    method: FileUploadWorkerEventMethod.canceled,
                    data: e
                });
            }
            else {
                s.postMessage({
                    method: FileUploadWorkerEventMethod.error,
                    data: e
                });
            }
            // setState(FileUploadState.error);
        }
    }
    else if(method === FileUploadWorkerRunMethod.cancel){
        // 업로드 취소
        abortController?.abort();
    }
}

export const FileUploadWorkerRunMethod = {
    upload: 'upload',
    uploadBase64: 'uploadBase64',
    cancel: 'cancel',
}

export const FileUploadWorkerEventMethod = {
    progress: 'progress',
    canceled: 'canceled',
    error: 'error',
    uploaded: 'uploaded',
}


export class FileUploadParam {
    method; // upload, cancel
    data;
}