import PCPageIndicator from './PCPageIndicator';
import MobilePageIndicator from './MobilePageIndicator';

/**
 *
 * @param {boolean} forcePage PC Pager 만 써야하는 경우 true
 * @param {PageOption} option
 * @returns {JSX.Element}
 * @constructor
 */
export default function PageIndicator({ forcePage = false, option }) {
  return (
    <>
      <PCPageIndicator
        className={forcePage ? '!flex' : ''}
        page={option?.page}
        totalCount={option?.totalCount}
        pageSize={option?.pageSize}
        showingPageCount={option?.showingPageCount}
        onPageChange={option?.onPageChange}
      />
      <MobilePageIndicator
        className={forcePage ? '!hidden' : ''}
        page={option?.page}
        totalCount={option?.totalCount}
        pageSize={option?.pageSize}
        onPageChange={option?.onPageChange}
      />
    </>
  );
}

/**
 * @typedef {Object} PageOption
 * @property {number} page
 * @property {number} totalCount
 * @property {number} pageSize
 * @property {number} showingPageCount default 5
 * @property {function} onPageChange
 */
export class PageOption {
  constructor({
    page,
    totalCount,
    pageSize,
    showingPageCount = 5,
    onPageChange,
  }) {
    this.page = page;
    this.totalCount = totalCount;
    this.pageSize = pageSize;
    this.showingPageCount = showingPageCount;
    this.onPageChange = onPageChange;
  }
}
