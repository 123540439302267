export default function VideoOptionRadio({ option, isSelected, onClick }) {
  return (
    <div className="item" onClick={onClick}>
      <div className="wetube_radio_row">
        <input
          key={option.label}
          type="radio"
          name="report"
          className="report_radio"
          id={option.label}
          checked={isSelected}
          onChange={onClick}
        />
        <label htmlFor={option.label} className="f14_w300">
          {option.label}
        </label>
      </div>
    </div>
  );
}
