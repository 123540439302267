import OutlineLabelField from '@/components/form/OutlineLabelField';
import NotateToolTip from '@/components/content/editor/NotateToolTip';
import React from 'react';
import EventFormButton from '@/components/ucms/post/EventFormButton';

export default function ContentFormUrlField({
  value,
  buttonText,
  json,
  onChange,
  onButtonTextChange,
}) {
  return (
    <div className="mt-4 file_row_wrap">
      <div className="file_col flex items-center">
        <div className="flex items-center w-full">
          <span className="flex-shrink-0 f14_w300">참여 URL</span>
          <NotateToolTip
            title={'"채녈W ID" 입력란이 있는 구글 폼 URL을 입력해 주세요.'}
          />
          <OutlineLabelField
            className="w-full max-w-[unset] ml-2 w-40 mr-2 bg-white"
            value={value}
            placeholder={'구글 폼 URL'}
            useClearAdornment={false}
            onChange={(value) => onChange?.(value)}
          />
        </div>

        <div className="flex items-center w-full">
          <span className="flex-shrink-0 f14_w300">버튼 문구</span>
          <OutlineLabelField
            className="w-[150px] ml-2 w-40 mr-2 bg-white w-full md:w-[unset]"
            value={buttonText}
            placeholder={'버튼 문구'}
            useClearAdornment={false}
            onChange={(value) => onButtonTextChange?.(value)}
          />
        </div>
      </div>
    </div>
  );
}
