import { useEffect, useMemo } from 'react';
import { parse, stringify } from 'qs';
import Axios from 'axios';

export default function OembedContainer({ children, data }) {
  const oembedList = useMemo(() => {
    let list = data.match(/<oembed.*?<\/oembed>/g);
    return list;
  }, [data]);

  const oembedParse = async () => {
    let axios = Axios.create({
      timeout: 10000,
      paramsSerializer: {
        encode: parse,
        serialize: stringify,
      },
    });
    if (oembedList == null || oembedList.length === 0) return;
    for (let tag of oembedList) {
      let url = tag.match(/url="(.*?)"/)[1];
      let isYoutube =
        url.startsWith('https://youtu.be') ||
        url.startsWith('https://youtube.com');
      if (isYoutube) {
        let res = await axios.get(`https://youtube.com/oembed?url=${url}`);
        let html = res.data.html;
        // console.log(res);
        if (html != null) {
          console.log(html);
          let target = document.querySelector(`oembed[url="${url}"]`);
          if (target != null) {
            target.innerHTML = html;
          }
        }
      }
    }
  };

  useEffect(() => {
    oembedParse();
  }, [oembedList]);

  return children;
}
