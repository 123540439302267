import { useNavigate } from 'react-router-dom';

export default function useNavigate2() {
  const navigate = useNavigate();
  return (...some) => {
    if (some[0] === -1) {
      // 뒤로가기 요청시, referer가 없거나 다르면 홈으로 이동.
      // 외부 사이트나, url 직접 입력 접근이 해당.
      if (
        document.referrer == null ||
        document.referrer.indexOf(window.location.host) === -1
      ) {
        navigate('/', { replace: true });
        return;
      } else {
        window.history.back();
        return;
      }
    }
    if (document.startViewTransition != null) {
      document.startViewTransition(() => {
        navigate(...some);
      });
    } else {
      navigate(...some);
    }
  };
}
