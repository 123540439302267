import { useEffect, useState } from 'react';
import ContentApi from '@/components/content/ContentApi';

export const RelatedContentCount = 10;
export default function useContentRelateList({ menuId, cid, contentType, pw }) {
  const [param, setParam] = useState({
    menuId,
    cid,
    pageNo: 1,
    pageSize: RelatedContentCount,
    isInitialLoading: true,
    isLoading: false,
    list: [],
    isLast: false,
  });
  const load = async () => {
    setParam((p) => ({
      ...p,
      isLoading: true,
    }));
    const res = await ContentApi.getRelateContentList({
      menuId,
      cid,
      pageNo: param.pageNo,
      pageSize: RelatedContentCount,
    });
    setParam((p) => {
      let newList = [...p.list, ...(res.data ?? [])];
      console.log(p);
      console.log(newList, newList.length);
      return {
        ...p,
        list: newList,
        isLast: newList.length >= res.total,
        isLoading: false,
        isInitialLoading: false,
      };
    });
    return res.data;
  };

  useEffect(() => {
    load();
  }, [param.pageNo]);

  const loadMore = () => {
    if (param.isLoading) return;
    if (param.isLast) return;
    setParam((p) => ({
      ...p,
      pageNo: param.pageNo + 1,
    }));
  };

  return {
    isInitialLoading: param.isInitialLoading,
    isLoading: param.isLoading,
    list: param.list,
    isLast: param.isLast,
    loadMore,
  };
}
