import OptionBase from '@/components/comment/OptionBase';

export default function CommentOption({
  canReport,
  canEdit,
  canDelete,
  onEdit,
  onDelete,
  onReport,
}) {
  return (
    <OptionBase
      canEdit={canEdit}
      canDelete={canDelete}
      canReport={canReport}
      onEdit={onEdit}
      onDelete={onDelete}
      onReport={onReport}
    >
      <img className="setting" src="/images/setting_ico.png" alt="세팅" />
    </OptionBase>
  );
}
