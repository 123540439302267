/**
 * @return {JSX.Element}
 * @constructor
 */
import RelatedVideoItem from './RelatedVideoItem';
import { forwardRef, useRef } from 'react';
import useContentRelateList from '@/components/content/list/useContentRelateList';
import { CircularProgress } from '@mui/material';
import useInfiniteScroll from '@/components/infiniteScroll/useInfiniteScroll';
import usePage from '@/components/infiniteScroll/usePage';

const RelatedVideoList = forwardRef(({ menuId, cid, contentType, pw }, ref) => {
  const { isInitialLoading, isLoading, list, isLast, loadMore } =
    useContentRelateList({ menuId, cid, contentType, pw });
  const [page, _] = usePage();
  // 모바일 탭용
  const [__, listBottomRef1] = useInfiniteScroll({
    querySelector: '#full-width-tabpanel-2',
    isLoading: isLoading,
    isLast,
    loadMore,
    page,
  });
  // PC 용
  const [___, listBottomRef2] = useInfiniteScroll({
    isLoading: isLoading,
    isLast,
    loadMore,
    page,
  });

  return (
    <div
      className="rel_box overflow-hidden flex flex-col tab_content pt-6 md:pt-0 px-4 sm:px-6 md:px-0"
      id="tab3"
      ref={ref}
    >
      <h5 className="f14 mb-4 cont_head">관련영상</h5>
      <ul>
        {!isInitialLoading && (list?.length ?? 0) === 0 && (
          <div className="w-full min-h-[100px] flex flex-col justify-center items-center">
            <span>관련 영상이 없습니다</span>
          </div>
        )}
        {list?.map((video) => (
          <RelatedVideoItem video={video} />
        ))}
        <div
          className="pb-1 mb-1"
          ref={(node) => {
            listBottomRef1.current = node;
            listBottomRef2.current = node;
          }}
        />
      </ul>
      {isLoading && (
        <div className="w-full flex items-center justify-center h-[200px]">
          <CircularProgress className="loading" />
        </div>
      )}

      {(list?.length ?? 0) > 0 && isLast && (
        <div className="w-full min-h-[100px] flex flex-col justify-center items-center">
          <span>더 이상 관련 영상이 없습니다</span>
        </div>
      )}
    </div>
  );
});

export default RelatedVideoList;
