const ACTIONS = {
  SHOW_POPUP: 'BUG_REPORT/SHOW_POPUP',
  CLOSE_POPUP: 'BUG_REPORT/CLOSE_POPUP',
};

Object.freeze(ACTIONS);

/**
 *
 * @param {BugReportPopupParam} param
 * @returns {{payload, type: string}}
 */
export const showBugReportPopup = (param) => ({
  type: ACTIONS.SHOW_POPUP,
  payload: param,
});
export const closeBugReportPopup = () => ({ type: ACTIONS.CLOSE_POPUP });

const initialState = {
  show: false,
  title: null,
  body: null,
  okButton: null,
  cancelButton: null,
  okCallback: null,
  cancelCallback: null,
};

export default function bugReportReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SHOW_POPUP: {
      return {
        ...state,
        ...action.payload,
        show: true,
      };
    }

    case ACTIONS.CLOSE_POPUP: {
      return {
        show: false,
        title: null,
        body: null,
        okButton: null,
        cancelButton: null,
        okCallback: null,
        cancelCallback: null,
      };
    }

    default:
      return state;
  }
}

export class BugReportPopupParam {
  /**
   *
   * @param {string} title
   * @param {string} body
   * @param {string} okButton
   * @param {string} cancelButton
   * @param {function} okCallback
   * @param {function} cancelCallback
   */
  constructor({
    title,
    body,
    okButton,
    cancelButton,
    okCallback,
    cancelCallback,
  }) {
    this.title = title;
    this.body = body;
    this.okButton = okButton;
    this.cancelButton = cancelButton;
    this.okCallback = okCallback;
    this.cancelCallback = cancelCallback;
  }
}
