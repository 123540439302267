import useLogin from '@/components/user/login/useLogin';
import { useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { apiInstance } from '@/app/config/network';

export default function useDebugWeb() {
  const { isLoggedIn, data } = useLogin();

  useEffect(() => {
    if (isLoggedIn === true) {
      console.log('useDebugLog', data);
      return secureLocalStorage.setItem('debugLoginId', data.userNo);
    }
  }, [isLoggedIn]);

  const sendLog = async (logData, refId = '') => {
    try {
      let userNo = data?.userNo ?? secureLocalStorage.getItem('debugLoginId');
      await apiInstance.post('/api/cms/web/log', {
        userNo,
        logData: JSON.stringify({
          ...logData,
          guid: localStorage.getItem('clientGuid'),
        }),
        refId,
      });
    } catch (e) {}
  };

  return {
    sendLog,
  };
}
