import { useDispatch, useSelector } from 'react-redux';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { useEffect, useMemo, useState } from 'react';
import { Drawer } from '@mui/material';
import {
  closeThankYouTokenSideBar,
  openThankYouTokenSideBar,
} from '@/components/sidebar/thankYouTokenReducer';
import usePathnameChange from '@/components/sidebar/hook/usePathnameChange';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ThankYouTokenIframe from '@/page/ucms/ThankyouTokenIframe';
import useMenu from '@/components/menu/useMenu';
import useWindowDimensions from '@/lib/useWindowDimensions';

export default function ThankYouTokenSideBar() {
  const dispatch = useDispatch();
  const { isMobile } = useWindowDimensions();
  const { menuList, findThankYouTokenMenu } = useMenu();
  const { state, query } = useLocation2();
  const thankYouTokenMenu = useMemo(() => {
    return findThankYouTokenMenu();
  }, [menuList]);

  // mobile 이고, state나 query에 thankyoutoken 이 있으면 자동으로 사이드바가 열림
  useEffect(() => {
    if (
      isMobile &&
      (state?.thankyoutoken !== undefined || query?.thankyoutoken !== undefined)
    ) {
      console.log('thankyoutoken sidebar open');
      open();
    }
  }, [state]);

  usePathnameChange((pathname) => {
    close();
  });

  const isOpen = useSelector((state) => state.thankYouTokenReducer.isOpen);

  const open = () => {
    if (thankYouTokenMenu != null) {
      dispatch(openThankYouTokenSideBar());
    } else {
      console.error('땡큐토큰 메뉴 없음');
    }
  };

  const iframeGoBack = () => {
    document
      .querySelector('#thankyoutokenIframe')
      ?.contentWindow?.postMessage('goBack', '*');
  };

  const close = () => {
    if (isOpen) {
      dispatch(closeThankYouTokenSideBar());
    }
  };

  return (
    <>
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={close}
        PaperProps={{
          sx: {
            width: '100%',
          },
        }}
        ModalProps={{
          sx: {
            // width: '100%',
            zIndex: 1301, // 헤더 보다 높게
          },
        }}
      >
        <div className="iframe_full flex flex-col h-full">
          <div className="flex justify-between">
            <button
              className="flex-shrink-0 flex items-center justify-start"
              onClick={iframeGoBack}
            >
              <ArrowBackIcon fontSize="large" />
            </button>
            <button
              className="flex-shrink-0 flex items-center justify-end"
              onClick={close}
            >
              <CloseIcon fontSize="large" />
            </button>
          </div>

          <ThankYouTokenIframe
            className="flex-1 overflow-hidden h-full"
            src={thankYouTokenMenu?.menuUrl}
          />
        </div>
      </Drawer>
    </>
  );
}
