import { useMemo } from 'react';
import ProfileImage from '@/components/user/ProfileImage';
import TagView from '@/components/ucms/common/Tag';
import classNames from 'classnames';
import { SelectorModal } from '@/components/modal/select/SelectorModal';
import { ChatMessageType } from '@/components/ucms/chat/live/useLiveChat';
import Link2 from '@/components/common/transition/Link2';

const chatOptions = [
  {
    label: '신고',
    value: ChatMessageType.Report,
  },
  {
    label: '차단',
    value: ChatMessageType.Ban,
  },
  {
    label: '차단해제',
    value: ChatMessageType.BanCancel,
  },
];

export class ChatItemModel {
  constructor({
    id,
    userNo,
    message,
    profileUrl,
    nickName,
    isBan,
    canReport,
    canBan,
    canBanCancel,
    mine,
    type,
    isReported,
    isNotice,
    regDt,
  }) {
    this.id = id;
    this.userNo = userNo;
    this.message = message;
    this.profileUrl = profileUrl;
    this.nickName = nickName;
    this.isBan = isBan;
    this.isReported = isReported;
    this.canReport = canReport;
    this.canBan = canBan;
    this.canBanCancel = canBanCancel;
    this.mine = mine;
    this.type = type;
    this.isNotice = isNotice;
    this.regDt = regDt;
  }
}

/**
 *
 * @param {ChatItemModel} item
 * @param onMenuSelected
 * @param menuVisible
 * @param onMenuVisibilityChanged
 * @returns {JSX.Element}
 * @constructor
 */
export const ChatItem = ({
  isAdmin,
  item,
  onMenuSelected,
  menuVisible,
  onMenuVisibilityChanged,
}) => {
  const toggleMenu = (e) => {
    e.stopPropagation();
    onMenuVisibilityChanged(!menuVisible);
  };

  const options = useMemo(() => {
    let list = [];
    if (item.canReport) {
      list.push(chatOptions[0]);
    }
    if (item.canBan) {
      list.push(chatOptions[1]);
    }
    if (item.canBanCancel) {
      list.push(chatOptions[2]);
    }
    return list;
  }, [item]);

  return (
    <>
      <li className="flex mb-3">
        <ProfileImage
          className="mr-3"
          userNo={item.userNo}
          profileUrl={item.profileUrl}
          nickNm={item.nickName}
        />
        <span className="text inline break-all">
          <Link2
            to={`/user/content?userNo=${item.userNo}`}
            className="nick w500 mr-1"
          >
            {item.nickName}
          </Link2>
          {isAdmin && item.isReported && (
            <span className="inline-block mr-1">
              <TagView tag={'reported'} />
            </span>
          )}
          {item.isNotice && (
            <span className="inline-block mr-1">
              <TagView tag={'notice'} className="clear" />
            </span>
          )}
          {item.message}
          {options.length > 0 && !item.isNotice && (
            <img
              className={classNames({
                setting: true,
                '!inline': menuVisible,
              })}
              src="/images/setting_ico.png"
              alt="세팅"
              onClick={toggleMenu}
            />
          )}
          {options.length > 0 && !item.isNotice && (
            <div className="relative">
              <SelectorModal
                items={options}
                onSelect={onMenuSelected}
                opened={menuVisible}
                setOpened={onMenuVisibilityChanged}
              />
            </div>
          )}
        </span>
      </li>
    </>
  );
};

export const SystemMessageItem = ({ item }) => {
  return (
    <>
      <li className="flex mb-3">
        <span className="text">{item.message}</span>
      </li>
    </>
  );
};

export const NoMessageItem = () => {
  return (
    <>
      <li className="flex mb-3 justify-center">
        <span className="text">채팅 내역이 없습니다.</span>
      </li>
    </>
  );
};
