export default function usePlatform() {
  const ua = window.navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf('android') > -1;
  const isIOS = /ipad|iphone|ipod/.test(ua) && !window.MSStream;
  const isMobile = isAndroid || isIOS;

  const isChrome = /chrome/.test(ua);
  const isChromeOnMacOs = /macintosh.*chrome\//.test(ua);

  return {
    ua,
    isAndroid,
    isIOS,
    isMobile,
    isChromeOnMacOs,
    isChrome,
  };
}
