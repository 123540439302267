import WriteFAB from '@/components/content/editor/WriteFAB';
import { Outlet, useParams } from 'react-router-dom';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import useMenu from '@/components/menu/useMenu';
import { useMemo, useState } from 'react';
import useLogin from '@/components/user/login/useLogin';
import MenuBanner from '@/components/banner/MenuBanner';
import usePopup from '@/components/popup/usePopup';
import Popup from '@/components/popup/Popup';
import LoginPopup from '@/components/popup/LoginPopup';
import MenuIframe from '@/page/ucms/MenuIframe';

export default function UcmsBoardContainer({}) {
  const { isLoggedIn, visibleMenu, manageMenu, data } = useLogin();
  const {
    rootMenuList,
    findMenu,
    findChildren,
    isShowWriteButton,
    isWriteable,
  } = useMenu();
  const { menuId, menuId2 } = useParams();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const navigate = useNavigate2();

  const { popup, show, hide } = usePopup();

  const menu = useMemo(() => {
    let menu = findMenu(menuId2 ?? menuId); // 권한 처리는 UcmsBoard에서 처리
    if ((menuId2 ?? menuId) == null) {
      // 메뉴 없이 접근하면, 첫번째 메뉴로 이동
      navigate(`/ucms/${rootMenuList[0].menuId}`, { replace: true });
    }
    return menu;
  }, [menuId, menuId2]);

  const hasViewPermission = useMemo(() => {
    if (!isLoggedIn) {
      return true;
    }
    return visibleMenu?.find((it) => it.menuId === menu?.menuId) != null;
  }, [isLoggedIn, visibleMenu, menuId, menuId2]);

  const hasWritePermission = useMemo(() => {
    if (!isLoggedIn) {
      return false;
    }
    return isWriteable(Number(menuId2 ?? menuId));
  }, [isLoggedIn, manageMenu, menuId, menuId2]);

  const onWrite = () => {
    if (!isLoggedIn) {
      setShowLoginPopup(true);
      return;
    }
    if (findChildren(menu.menuId).length === 0) {
      if (!hasWritePermission) {
        show({
          title: '글쓰기 불가',
          message: '글쓰기 권한이 없는 게시판입니다.',
          okCallback: hide,
        });
      } else {
        navigate(`write/${menu.menuId}`);
      }
    } else {
      if (menuId == 2) {
        navigate(`write/${menu.menuId}`);
      } else {
        show({
          title: '글쓰기 불가',
          message: '글쓰기가 불가능한 메뉴입니다.\n하위 게시한을 선택해 주세요',
          okCallback: hide,
        });
      }
    }
  };

  if (menu?.menuUrl?.startsWith('http') === true) {
    return <MenuIframe className="iframe_fill" menu={menu} />;
  }

  return (
    <div id="all_wrapper">
      <MenuBanner menuId={menuId} />
      <Outlet />

      {
        // 사용자가 로그인하여 권한 있는 경우 표시
        // 사용자가 로그인 안한경우, 누구나 쓰기가능, 직원만 쓰기 가능도 표시
        isShowWriteButton(menu?.menuId) && <WriteFAB onClick={onWrite} />
      }
      {popup != null && <Popup {...popup} />}
      <LoginPopup show={showLoginPopup} setShow={setShowLoginPopup} />
    </div>
  );
}
