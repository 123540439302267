import './SelectorModal.css';
import { Box, Collapse } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { VideoOptionMain } from '@/components/modal/videoOption/VideoOptionMain';
import VideoOptionModalHeader from '@/components/modal/videoOption/VideoOptionModalHeader';
import VideoOptionRadioList from '@/components/modal/videoOption/VideoOptionRadioList';
import {
  DefaultSpeedRates,
  WooriPlayerHelper,
} from '@/components/player/WooriPlayer';

/**
 * **⚠️ 사용시 주의점: 드롭다운/버튼의 클릭이벤트에 e.stopPropagation 으로 이벤트 전파를 막아주어야 합니다!**
 * @param {{label:string, value:string}[]} items
 * @param {(string) => void} onSelect
 * @param {string} className
 * @return {JSX.Element}
 * @constructor
 */
export const VideoOptionModal = ({
  canSkip,
  onClose,
  onSelect,
  className = '',
}) => {
  const [tab, setTab] = useState(0);

  const [qualities, setQualities] = useState([]);
  const [quality, setQuality] = useState(null);

  const [speed, setSpeed] = useState(null);
  const speedRates = useMemo(() => {
    return (canSkip ? DefaultSpeedRates : [1.0]).map((v) => ({
      label: `X ${v.toFixed(2).replace(/0$/, '')}`,
      value: v,
    }));
  }, []);

  useEffect(() => {
    let currentSpeed = WooriPlayerHelper.getPlaybackRate();
    setSpeed(
      speedRates.find((v) => v.value === currentSpeed) ?? {
        label: 'X 1.0',
        value: 1,
      }
    );

    let qualities =
      WooriPlayerHelper?.getSources()?.map((it) => ({
        ...it,
        label: it.label,
        value: it.src,
      })) ?? [];
    setQualities(qualities);

    let currentSrc = WooriPlayerHelper.getSrc();
    let quality = qualities?.find((v) => v.value === currentSrc);
    setQuality(quality);
  }, []);

  useEffect(() => {
    if (!speed) return;
    WooriPlayerHelper.setPlaybackRate(speed.value);
  }, [speed]);

  useEffect(() => {
    if (!quality) return;
    let src = WooriPlayerHelper.getSrc();
    if (src !== quality.src) {
      let time = WooriPlayerHelper.getCurrentTime();
      let loadedCallback = () => {
        WooriPlayerHelper.setCurrentTime(time);
        WooriPlayerHelper.setPlaybackRate(speed.value);
        WooriPlayerHelper.getPlayer()?.player?.off('canplay', loadedCallback);
      };
      WooriPlayerHelper.getPlayer()?.player?.on('canplay', loadedCallback);
      WooriPlayerHelper.setSrc(quality);
    }
  }, [quality]);

  return (
    <ul
      className={`select-modal video-option-modal relative active ${className}`}
      onClick={onClose}
    >
      <div
        className="sheet_inner"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="">
          <Collapse direction="left" in={tab === 0} mountOnEnter unmountOnExit>
            <Box>
              <VideoOptionMain
                quality={quality?.label}
                speed={speed?.label}
                onClose={() => onClose()}
                onQuality={() => setTab(1)}
                onSpeed={() => setTab(2)}
              />
            </Box>
          </Collapse>

          <Collapse direction="left" in={tab === 1} mountOnEnter unmountOnExit>
            <Box>
              <VideoOptionModalHeader
                onBack={(e) => setTab(0)}
                title={'해상도'}
              />

              <VideoOptionRadioList
                options={qualities}
                value={quality}
                onClick={(q) => {
                  setQuality(q);
                }}
              />
            </Box>
          </Collapse>

          <Collapse direction="left" in={tab === 2} mountOnEnter unmountOnExit>
            <Box>
              <VideoOptionModalHeader
                onBack={(e) => setTab(0)}
                title={'재생속도'}
              />

              <VideoOptionRadioList
                options={speedRates}
                value={speed}
                onClick={(v) => {
                  setSpeed(v);
                }}
              />
            </Box>
          </Collapse>
        </div>
      </div>
    </ul>
  );
};
