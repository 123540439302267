import useWindowDimensions from '@/lib/useWindowDimensions';
import { useDispatch } from 'react-redux';
import { openMenuSideBar } from '@/components/sidebar/sidebarReducer';

export default function MobileHeaderMenu() {
  const dispatch = useDispatch();
  const { isMobile } = useWindowDimensions();

  const onClick = () => {
    dispatch(openMenuSideBar());
  };

  if (!isMobile) {
    return '';
  } else {
    return (
      <div onClick={onClick}>
        <img className="w-[24px] h-[24px] mr-4" src="/images/menu_icon.svg" />
      </div>
    );
  }
}
