import LoginUtil from '@/components/user/login/LoginUtil';

const ACTIONS = {
  SET_PIN_STATE: 'SET_PIN_STATE',
  SET_PIN_SUBMIT_CALLBACK: 'SET_PIN_SUBMIT_CALLBACK',
  SET_PIN_CANCEL_CALLBACK: 'SET_PIN_CANCEL_CALLBACK',
  SET_PIN_REQUEST_CANCELED: 'SET_PIN_REQUEST_CANCELED',
};

Object.freeze(ACTIONS);

export const setPinRegisterState = (state) => ({
  type: ACTIONS.SET_PIN_STATE,
  state,
});

export const setPinSubmitCallback = (callback) => ({
  type: ACTIONS.SET_PIN_SUBMIT_CALLBACK,
  callback,
});

export const setPinCancelCallback = (callback) => ({
  type: ACTIONS.SET_PIN_CANCEL_CALLBACK,
  callback,
});

export const setPinRequestCanceled = (state) => ({
  type: ACTIONS.SET_PIN_REQUEST_CANCELED,
  state,
});

const initialState = {
  pinRegistered: false,
  pinSubmitCallback: null,
  pinCancelCallback: null,
  pinLoginCanceled: false,
};

export default function pinReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_PIN_STATE: {
      return {
        ...state,
        pinRegistered: action.state,
      };
    }
    case ACTIONS.SET_PIN_SUBMIT_CALLBACK: {
      return {
        ...state,
        pinSubmitCallback: action.callback,
      };
    }
    case ACTIONS.SET_PIN_CANCEL_CALLBACK: {
      return {
        ...state,
        pinCancelCallback: action.callback,
      };
    }
    case ACTIONS.SET_PIN_REQUEST_CANCELED: {
      return {
        ...state,
        pinLoginCanceled: action.state,
      };
    }
    default:
      return state;
  }
}
