import { useDispatch, useSelector } from 'react-redux';
import { closePIP, pausePIP, playPIP } from './AudioPipReducer';

export default function AudioPipPlayer() {
  // TODO pip 기능 구현
  const dispatch = useDispatch();
  const pipInfo = useSelector((state) => state.pipReducer.pipInfo);
  const isShow = useSelector((state) => state.pipReducer.isShow);
  const isPlaying = useSelector((state) => state.pipReducer.isPlaying);

  const close = () => {
    dispatch(closePIP());
  };

  const play = () => {
    dispatch(playPIP());
  };

  const pause = () => {
    dispatch(pausePIP());
  };

  if (isShow === false) {
    return '';
  }
  // TODO thumbnail, title, author, date, isLive, link, category

  return (
    <div className="foot_play_box">
      <div className="box_inner">
        <button type="button" className="foot_play_close" onClick={close}>
          <img src="/images/close_mini.png" alt="닫기" />
        </button>
        <div className="info_wrap">
          <div className="play_thumb flex gap-6 items-center">
            <img src="/images/play_thumb.png" alt="라디오 썸네일" />
            <div className="text">
              <p className="ch_name">우리방송</p>
              <div className="play_tit flex gap-3">
                <span>2022년 2Q 상장 중소규모기업 실적분석과 시사점</span>
                <span className="state">
                  <img src="/images/ico_wifi.png" />
                  LIVE
                </span>
              </div>
              <div className="text_info">
                <span className="ch_name">우리방송국</span>
                <span className="date">2023.3.12</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="arrow_wrap flex justify-around items-center">
        <button className="play_prev arrow_btn">PREV</button>
        <button className="play_next arrow_btn">NEXT</button>
      </div>
      {isPlaying && (
        <button
          className="play flex items-center justify-center"
          onClick={pause}
        >
          PAUSE
        </button>
      )}
      {!isPlaying && (
        <button
          className="play flex items-center justify-center"
          onClick={play}
        >
          PLAY
        </button>
      )}
    </div>
  );
}
