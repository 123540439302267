import HomeLiveList from '../../components/content/list/HomeLiveList';
import HomeRecentList from '../../components/content/list/HomeRecentList';
import Banner from '@/components/banner/Banner';
import Popup from '@/components/popup/Popup';
import useQuery from '@/components/sidebar/hook/useQuery';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openSideBar } from '@/components/sidebar/sidebarReducer';
import { useSearchParams } from 'react-router-dom';
import MainQuickGrid from '@/components/mobile/MainQuickGrid';

export default function Home({}) {
  const dispatch = useDispatch();
  const { emailAuth } = useQuery();
  const { signup } = useQuery();
  const [_, setSearchParams] = useSearchParams();
  const [showNormalSignupPopup, setShowNormalSignupPopup] = useState(false);
  const [showCoSignupPopup, setShowCoSignupPopup] = useState(false);
  const [showEmailAuthPopup, setShowEmailAuthPopup] = useState(false);

  useEffect(() => {
    setShowEmailAuthPopup(emailAuth === 'true');
    setShowNormalSignupPopup(signup === 'normal');
    setShowCoSignupPopup(signup === 'co');
    // console.log('home init')
    setSearchParams(
      (prev) => {
        let res = {
          ...Object.fromEntries(prev),
        };

        delete res.emailAuth;
        delete res.signup;

        return res;
      },
      { replace: true }
    );
  }, []);

  return (
    <div id="all_wrapper">
      <MainQuickGrid />
      <div id="mainvisual">
        <Banner />
      </div>

      <div id="contents">
        <HomeLiveList />
        <HomeRecentList />
      </div>
      {showEmailAuthPopup === true && (
        <Popup
          title="이메일 인증"
          message="이메일 인증이 완료되었습니다. 로그인 해주세요"
          okMessage="확인"
          okCallback={() => {
            dispatch(openSideBar());
            setShowEmailAuthPopup(false);
          }}
        />
      )}
      {showNormalSignupPopup === true && (
        <Popup
          title="가입 완료"
          message="가입이 완료되었습니다. 관리자 승인을 기다려주세요."
          okMessage="확인"
          okCallback={() => {
            setShowNormalSignupPopup(false);
          }}
        />
      )}
      {showCoSignupPopup === true && (
        <Popup
          title="가입 완료"
          message="가입이 완료되었습니다. 로그인 해주세요"
          okMessage="확인"
          okCallback={() => {
            dispatch(openSideBar());
            setShowCoSignupPopup(false);
          }}
        />
      )}
    </div>
  );
}
