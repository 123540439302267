import './SelectorModal.css';
import { Box, Collapse, Slide } from '@mui/material';
import { useState } from 'react';
import VideoOptionModalHeader from '@/components/modal/videoOption/VideoOptionModalHeader';

/**
 * **⚠️ 사용시 주의점: 드롭다운/버튼의 클릭이벤트에 e.stopPropagation 으로 이벤트 전파를 막아주어야 합니다!**
 * @param {{label:string, value:string}[]} items
 * @return {JSX.Element}
 * @constructor
 */
export const VideoOptionMain = ({
  quality,
  speed,
  onClose,
  onQuality,
  onSpeed,
}) => {
  return (
    <>
      <VideoOptionModalHeader onClose={onClose} title={'설정'} />

      <div className="item" onClick={(e) => onQuality()}>
        <div className="flex justify-between items-center">
          <div className="item_title">해상도</div>
          <div className="flex justify-between items-center item_value gap-3">
            {quality}
            <img src="/images/ico_right_arrow.svg" alt="더보기" />
          </div>
        </div>
      </div>

      <div className="item" onClick={(e) => onSpeed()}>
        <div className="flex justify-between items-center">
          <div className="item_title">재생속도</div>
          <div className="flex justify-between items-center item_value gap-3">
            {speed}
            <img src="/images/ico_right_arrow.svg" alt="더보기" />
          </div>
        </div>
      </div>
    </>
  );
};
