import useAppDownloadPopup from '@/components/popup/useAppUsePopup';

export default function AppDownloadContainer({ children }) {
  const { popup: appDownloadPopup } = useAppDownloadPopup();

  return (
    <div>
      {appDownloadPopup}
      {children}
    </div>
  );
}
