import { appendTimestamp, filenameCompat } from '@/lib/fileUtil';

export default class UploadFileDto {
  constructor({
    id,
    url,
    cdnPath,
    cdnFile1,
    fileId,
    file,
    name,
    size,
    deleted = false,
  }) {
    this.file = file;
    this.name = name ?? cdnFile1;
    this.size = size;
    this.deleted = deleted;
    this.id = fileId ?? id;
    this.url = url;
    this.name = name;
    this.deleted = deleted;
    this.file = file;
    this.cdnPath = cdnPath;
    this.cdnFile1 = cdnFile1;
  }

  /**
   *
   * @param {File|File[]} file
   * @returns {UploadFileDto|null}
   */
  static fromFile(file) {
    let f;
    if (Array.isArray(file)) {
      f = file[0];
    } else {
      f = file;
    }

    if (f == null) {
      return null;
    }

    return new UploadFileDto({
      file: f,
      size: f.size,
      name: appendTimestamp(filenameCompat(f.name)),
      deleted: false,
    });
  }
}
