String.prototype.isEmpty = function () {
  return this == null || this == '';
};

export const isValidName = (value) => {
  return value.match(/^[^ㄱ-ㅎㅏ-ㅣ]{2,10}$/);
};

export const isValidRankNm = (value) => {
  return value.match(/^[^ㄱ-ㅎㅏ-ㅣ]{2,10}$/);
};

export const isValidEmpNo = (value) => {
  return value.match(/^\w{8,10}$/);
};

export const isValidEmpNo8 = (value) => {
  return value.match(/^\w{8}$/);
};

export const isValidNumeric = (value) => {
  return value.match(/[0-9]/);
};

export const isValidEmail = (value) => {
  return value.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const isValidPassword = (value) => {
  return value.match(/(?=.*\d)(?=.*[A-Za-z])(?=.*[!@#$%^&*()-=+~`]).{8,}/);
};

export const isValidNumberPassword = (value) => {
  return value.match(/(012|123|234|345|456|567|678|789)/g);
};

export const isValidNickname = (value) => {
  return value.match(/^[a-zA-zㄱ-ㅎ가-힣]{3,10}$/g);
};

export const isValidTel = (value) => {
  return value.match(
    /^((\+?82)[ -]?)?0?1([0|1|6|7|8|9]{1})[ -]?\d{3,4}[ -]?\d{4}$/
  );
};

export const isValidMobileAuth = (value) => {
  return value.match(/^\d{6}$/);
};

/**
 * HTML 코드 내에서 텍스트만 추출합니다.
 * @param html
 * @returns {string}
 */
export const htmlToText = (html) => {
  if (html == null || html == '') {
    return '';
  }
  const divContainer = document.createElement('div');
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || '';
};

/**
 * 서버에서 escape한 HTML을 원래대로 복구합니다.
 */
export const parseServerHtml = (html = '') => {
  return html;
  // .replace(/&gt;/g, ">")
  // .replace(/&lt;/g, "<")
  // .replace(/&gt/g, "<")
  // .replace(/&lt/g, ">")
};

/**
 * @param hex 여러 글자 가능. ex A3
 * @returns {number[]} 0 또는 1로 구성된 array. ex [1,0,1,0,0,0,1,1]
 */
export const hexToBitArray = (hex) => {
  const bin = parseInt(hex, 16).toString(2);
  const bits = bin.split('').map((bit) => Number(bit));

  // JavaScript에서 숫자를 2진수 문자열로 변환하면 빈자리가 0으로 채워지지 않음.
  // 따라서 4개의 비트로 채우기 위해 빈자리를 0으로 채움.
  while (bits.length % 4 !== 0) {
    bits.unshift(0);
  }
  return bits;
};

/**
 *
 * @param bits 0 채움 없어도 됨. ex [1,1,1]
 * @returns {string} hex 값. ex 7
 */
export const bitArrayToHex = (bits) => {
  let bin = bits.join('');

  // JavaScript에서 숫자를 16진수 문자열로 변환하면 빈자리가 0으로 채워지지 않음.
  // 따라서 4개의 비트로 채우기 위해 빈자리를 0으로 채움.
  while (bin.length % 4 !== 0) {
    bin = '0' + bin;
  }

  let hex = parseInt(bin, 2).toString(16);
  return hex;
};
