import Footer from '../footer/Footer';
import Header from '../header/Header';
import { Outlet } from 'react-router-dom';
import MobileBottomNavigation from '../navigation/MobileBottomNavigation';
import AudioPipPlayer from '../player/pip/AudioPipPlayer';
import GlobalVideoContainer from '@/components/layout/GlobalVideoContainer';
import { useEffect, useState } from 'react';
import { AxiosWrapper } from '@/app/config/network';
import useQuery from '@/components/sidebar/hook/useQuery';
import { ErrorBoundary } from '@/components/common/ErrorBoundary';
import LoginPopup from '@/components/popup/LoginPopup';
import NoZoomHeader from '@/components/common/ux/NoZoomHeader';
import Maintenance from '@/page/etc/Maintenance';
import { useDispatch, useSelector } from 'react-redux';
import useMenu from '@/components/menu/useMenu';
import useLogin from '@/components/user/login/useLogin';
import useSubscribe from '@/components/user/my/useSubscribe';
import { CircularProgress } from '@mui/material';
import Popup from '@/components/popup/Popup';
import { isMaintenance } from '@/app/config/dev';
import AppDownloadContainer from '@/components/layout/AppDownloadContainer';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import useWindowDimensions from '@/lib/useWindowDimensions';
import useMobileNavigation from '@/components/navigation/useMobileNavigation';
import { getCompanyList } from '@/components/user/login/LoginReducer';
import useDebugLog from '@/components/debug/useDebugLog';
import LoginUtil from '@/components/user/login/LoginUtil';

export default function Container() {
  const { isShow } = useMobileNavigation();
  const { pathname } = useLocation2();
  const query = useQuery();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const { isWebView, isMobile, width } = useWindowDimensions();
  const { sendLog } = useDebugLog();
  const dispatch = useDispatch();
  const { isLoading } = useMenu();
  const {
    isLoggedIn,
    tryLogin,
    isLoading: isLoginLoading,
    isSessionLogin,
    code,
    data,
    isSessionLoading,
  } = useLogin();
  const companyList = useSelector((state) => state.loginReducer.companyList);
  const [popup, setPopup] = useState(null);

  useSubscribe();

  useEffect(() => {
    dispatch(getCompanyList());
    _tryLogin();
  }, []);

  const _tryLogin = async () => {
    if (!isLoggedIn) {
      let logData = {
        location: 'container.mount > tryLogin',
        ...(await LoginUtil.getDebugInfo()),
        previousLoginCode: code,
        previousIsLoggedIn: isLoggedIn,
        previousIsSessionLogin: isSessionLogin,
        isWebView,
        isMobile,
        screenWidth: width,
        userAgent: window.navigator.userAgent,
      };
      sendLog(logData);
      await tryLogin();
    }
  };

  useEffect(() => {
    console.log('isWebView', isWebView);
  }, [isWebView]);

  useEffect(() => {
    if (query?.isDev === 'true') {
      localStorage.setItem('isDev', 'true');
    }
  }, [query]);

  let isDev = localStorage.getItem('isDev');

  if (isMaintenance && isDev !== 'true') {
    return <Maintenance />;
  }

  if (isSessionLoading !== false || isLoading || isLoginLoading) {
    return (
      <div className="h-[100vh] w-full flex items-center justify-center">
        <CircularProgress className="loading" />
      </div>
    );
  }

  // if (true) return <></>;
  return (
    <>
      <AppDownloadContainer>
        <GlobalVideoContainer>
          {/*ErrorBoundary 는 페이지 path마다 변경되므로, 내부에 global dom 금지*/}
          <ErrorBoundary key={`global_container_error_boundary_${pathname}`}>
            <NoZoomHeader />
            <AxiosWrapper>
              <div>
                <div id="mobile_head" />
                <Header />
                <main>
                  <ErrorBoundary>
                    <Outlet />
                  </ErrorBoundary>
                  {/*모바일일때 fix로 가려지지 않도록 영역 부여*/}
                  {isShow && <div className="mobile_nav !relative" />}
                </main>
                <Footer />
                <AudioPipPlayer />
                {isShow && <MobileBottomNavigation />}
                <div className="mobile_del_nav"></div>
              </div>
              <LoginPopup show={showLoginPopup} setShow={setShowLoginPopup} />
            </AxiosWrapper>
          </ErrorBoundary>
          <div className="pip_player_portal"></div>
        </GlobalVideoContainer>
      </AppDownloadContainer>
      {popup != null && <Popup {...popup} />}
    </>
  );
}
