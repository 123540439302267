// env-cmd를 사용하여 NODE_ENV production에서 REACT_APP_MODE으로 production, development를 구분한다.

export function isDev() {
  return process.env.REACT_APP_MODE === 'development';
}

export const isMaintenance = false;

export let isLanding = null;

export const getIsLanding = () => {
  // return localStorage.getItem('isLanding');
  return isLanding;
};

export const setIsLanding = async (value) => {
  // await localStorage.setItem('isLanding', value);
  isLanding = value;
};
