import useBio from '@/components/easyLogin/bio/useBio';
import usePin from '@/components/easyLogin/pin/usePin';
import { useMemo } from 'react';
import useWindowDimensions from '@/lib/useWindowDimensions';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupShowed } from '@/components/easyLogin/EasyLoginReducer';

export default function useEasyLogin() {
  const dispatch = useDispatch();
  const easyLoginRegisterSuggestShowed = useSelector(
    (state) => state.easyLoginReducer.registerPopupShowed
  );
  const { isMobile, isWebView } = useWindowDimensions();
  const { bioRegistered, requestBioLogin, deviceSupport, deviceBioRegistered } =
    useBio();
  const {
    pinRegistered,
    canRegister: pinCanRegister,
    requestPinLogin,
  } = usePin();
  const isRegistered = useMemo(() => {
    return bioRegistered || pinRegistered;
  }, [bioRegistered, pinRegistered]);

  const canRegister = useMemo(() => {
    return (
      isWebView && ((deviceBioRegistered && deviceSupport) || pinCanRegister)
    );
  }, [isMobile, deviceBioRegistered, deviceSupport]);

  const tryEasyLogin = async ({ ignoreCancel = false } = {}) => {
    if (bioRegistered) {
      return await requestBioLogin({ ignoreCancel });
    } else if (pinRegistered) {
      return await requestPinLogin({ ignoreCancel });
    } else {
      return false;
    }
  };

  const setEasyLoginRegisterSuggestShowed = (bool) => {
    dispatch(setPopupShowed(bool));
  };

  return {
    canRegister,
    isRegistered,
    tryEasyLogin,
    easyLoginRegisterSuggestShowed,
    setEasyLoginRegisterSuggestShowed,
  };
}
