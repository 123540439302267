import { useEffect, useMemo, useState } from 'react';
import { SearchApi } from '@/components/search/SearchApi';
import useLogin from '@/components/user/login/useLogin';
import Config from '@/app/config/config';

export default function useHomeLiveList() {
  const { isLoggedIn } = useLogin();

  const [param, setParam] = useState({
    page: 0,
    isLive: true,
    size: 999,
    // size: 8,
    sort: SearchApi.searchSort.recent,
  });

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);
  const [hasError, setHasError] = useState(false);
  const isLast = useMemo(() => {
    if (total == null) return false;
    return param.size * param.page >= total;
  }, [total, param]);

  useEffect(() => {
    _loadList();
    let interval = setInterval(() => {
      _loadList();
    }, Config.liveUpdateInterval);
    return () => {
      if (interval != null) {
        clearInterval(interval);
      }
    };
  }, [param, isLoggedIn]);

  const _loadList = async () => {
    if (isLoading) {
      // console.warn('로딩중입니다.');
      return;
    }
    setIsLoading(true);
    try {
      const res = await SearchApi.liveSearch(param);
      setList((l) => res.data);
      setTotal(res.total ?? 0);
    } catch (e) {
      console.error(e);
      setHasError(true);
    }
    setIsInitialLoading(false);
    setIsLoading(false);
  };

  const loadMore = () => {
    setParam((p) => ({ ...p, page: p.page + 1 }));
  };

  const sortChange = (sort) => {
    setList([]);
    setParam((p) => ({ ...p, sort: sort, page: 0 }));
  };

  return {
    isInitialLoading,
    isLast,
    hasError,
    isLoading,
    list,
    param,
    loadMore,
    sortChange,
  };
}
