import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import usePage from '@/components/infiniteScroll/usePage';
import { useIntersectionObserver } from '@/components/infiniteScroll/useIntersectionObserver';
import { useSelector } from 'react-redux';

/**
 * 특정 카드로 스크롤 복원을 하고 싶으면 카드의 id를 'element-${key}'로 지정해주세요!
 * @param querySelector
 * @param isLoading
 * @param isLast
 * @param loadMore
 * @param isInitLoading
 * @param page
 * @param defaultPage
 * @param pushPageOnPagePropChange 데이터 훅에서 넘겨받은 `page` 프롭이 바뀔 때 히스토리 상 페이지를 변경할지?
 * @return {[React.MutableRefObject<null>, React.MutableRefObject<null>]}
 */
export default function useInfiniteScroll({
  querySelector,
  isLoading = true,
  isLast,
  loadMore,
  isInitLoading,
  page,
  defaultPage,
  pushPageOnPagePropChange = false,
  recoverScroll = true,
}) {
  const [historyPage, setPage] = usePage(defaultPage);

  const isLoadingRef = useRef(isLoading);
  const isLastRef = useRef(isLast);
  const target = useMemo(
    () => document.querySelector(querySelector),
    [querySelector]
  );
  const gridRef = useRef(null);
  const gridBottomRef = useRef(null);

  const cb = () => {
    if (isLastRef.current || isLoadingRef.current) return;
    pushToHistoryAndLoadMore();
  };

  const pushToHistoryAndLoadMore = () => {
    console.log(isLoading, isLast, isInitLoading);
    if (isLoading || isLast || isInitLoading) return;
    if (!pushPageOnPagePropChange) setPage((prev) => prev + 1);
    loadMore();
  };

  const { observe, unobserve, disconnect } = useIntersectionObserver(
    cb,
    target,
    [historyPage]
  );

  const prevPage = useSelector((state) => state.detailHistoryReducer.prevPage);

  useEffect(() => {
    if (!recoverScroll) {
      return;
    }
    if (!isInitLoading && page !== 0) {
      if (prevPage != null) {
        const target = gridRef.current?.querySelector(
          `#element-${prevPage.split('/').reverse()[0]}`
        );
        if (target != null)
          target.scrollIntoView({
            behavior: 'auto',
            block: 'end',
          });
      } else {
        gridRef.current?.scrollIntoView({ behavior: 'auto', block: 'end' });
      }
    }
  }, [isInitLoading, prevPage]);

  useEffect(() => {
    isLastRef.current = isLast;
  }, [isLast]);

  useEffect(() => {
    if (!page) return setPage(0);
    else if (pushPageOnPagePropChange) setPage(page);
  }, [page]);

  // isLoading에 대한 effect는 최초 mount 시에도 수행되므로 중복 call 제거
  useEffect(() => {
    isLoadingRef.current = isLoading;

    // gridBottomRef가 div일 때만 처리
    if (!isLoading && gridBottomRef.current instanceof HTMLDivElement) {
      unobserve(gridBottomRef.current);
      observe(gridBottomRef.current);
    }
    return () => {
      disconnect();
    };
  }, [isLoading, target]);

  return [gridRef, gridBottomRef];
}
