import { useMemo, useState } from 'react';
import CryptoJS from 'crypto-js';
import useAESCrypto from '@/components/user/useAESCrypto';

export default function CryptoTestPage() {
  // const secret = 'aes256-unpl-key!'
  // const [text, setText] = useState('Password123$%^');
  // const [iv, setIv] = useState('aes256-unpl-key!');
  // console.log(CryptoJS.enc.Utf8.parse(iv))
  // // const iv = { words: [ 0, 0, 0, 0 ], sigBytes: 16 }
  // // const ivRaw = useMemo(() => {
  // //     return CryptoJS.enc.Hex.parse(iv)
  // // }, [iv])
  //
  // const encoded = useMemo(() => {
  //     let data =  CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(secret), {
  //         // iv: iv,
  //         iv: CryptoJS.enc.Utf8.parse(iv),
  //         // padding: CryptoJS.pad.Pkcs7,
  //         mode: CryptoJS.mode.CBC,
  //         // salt: '',
  //     })
  //     console.log(data);
  //     return data.toString();
  // }, [text])
  //
  // const decoded = useMemo(() => {
  //     return CryptoJS.AES.decrypt(encoded, secret).toString(CryptoJS.enc.Utf8);
  // }, [encoded]);

  // const text = 'aes256-unpl-key!';
  // // const text = 'abc가나다라1234!@#$';
  // const secret = 'aes256-unpl-key!'
  // const iv = 'aes256-unpl-key!'
  //
  // const _text = CryptoJS.enc.Utf8.parse(text);
  // const _secret = CryptoJS.enc.Utf8.parse(secret.padEnd(32, "\0"))
  // const _iv = CryptoJS.enc.Utf8.parse(iv.padEnd(16, "\0"))
  //
  // const enc = CryptoJS.AES.encrypt(
  //     _text,
  //     _secret,
  //     {
  //         // iv: iv,
  //         iv: _iv,
  //         padding: CryptoJS.pad.Pkcs7,
  //         mode: CryptoJS.mode.CBC
  //     },
  // );
  //
  // const des = CryptoJS.AES.decrypt(
  //     enc,
  //     _secret,
  //     {
  //         // iv: iv,
  //         iv: _iv,
  //         padding: CryptoJS.pad.Pkcs7,
  //         mode: CryptoJS.mode.CBC
  //     },
  // );
  //
  // console.log(
  //     text,
  //     // enc,
  //     enc.toString(),
  //     // des,
  //     des.toString(CryptoJS.enc.Utf8),
  // )
  const { encrypt, decrypt } = useAESCrypto();
  const [text, setText] = useState('');
  const encoded = useMemo(() => {
    return encrypt(text);
  }, [text]);
  const decoded = useMemo(() => {
    return decrypt(encoded);
  }, [text]);

  return (
    <div className="flex flex-col">
      <table className="border-2">
        <tr>
          <td>입력값</td>
          <td>
            <input
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </td>
        </tr>
        {/*<tr>*/}
        {/*    <td>iv</td>*/}
        {/*    <td><input type="text" value={iv} onChange={e => setIv(e.target.value)}/></td>*/}
        {/*</tr>*/}
        <tr>
          <td>encode</td>
          <td>{encoded}</td>
        </tr>
        <tr>
          <td>decode</td>
          <td>{decoded}</td>
        </tr>
      </table>
    </div>
  );
}
