import { SearchApi } from '@/components/search/SearchApi';
import { useSearchParams } from 'react-router-dom';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { useMemo } from 'react';

export default function useSort(
  defaultSort = Object.keys(SearchApi.searchSort)[0]
) {
  const [_, setSearchParams] = useSearchParams();
  const { pathname, query } = useLocation2();
  const querySort = useMemo(() => {
    return query.sort;
  }, [query]);
  const sort = useMemo(() => {
    const s = querySort;
    if (s == null || s == '') {
      setSearchParams(
        (prev) => {
          console.log('setSearchParams', prev, defaultSort);
          return { ...Object.fromEntries(prev), sort: `${defaultSort}` };
        },
        { replace: true }
      );
      return defaultSort;
    }
    return s;
  }, [querySort, pathname]);
  const setSort = (p, options = { replace: false }) => {
    setSearchParams(
      (prev) => ({
        ...Object.fromEntries(prev),
        sort: typeof p == 'function' ? p(sort) : p,
      }),
      options
    );
  };

  return [sort, setSort];
}
