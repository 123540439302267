import _ from 'lodash';
import ThumbnailCard from '@/components/card/ThumbnailCard';
import PageIndicator from '@/components/content/paging/PageIndicator';
import classNames from 'classnames';
import BoardCard from '@/components/ucms/common/BoardCard';
import Link2 from '@/components/common/transition/Link2';
import Thumbnail, {
  ThumbnailOption,
} from '@/components/card/thumbnail/Thumbnail';
import OptionalLinkWrapper from '@/components/card/OptionalLinkWrapper';
import { ContentType } from '@/app/config/ContentType';

export function BigThumbnailCard({ id, option }) {
  return (
    <div id={id} className="col">
      <OptionalLinkWrapper
        to={option?.isSelectMode === true ? null : option?.link}
      >
        <div onClick={option?.onClick}>
          <Thumbnail
            className={'big_thumbnail'}
            option={new ThumbnailOption({ ...option })}
          />
          <h4 className="info_tit mb-0">{option.title}</h4>
          <div className="text_info">
            <span className="date">{option.date}</span>
          </div>
        </div>
      </OptionalLinkWrapper>
    </div>
  );
}

export const contentDefine = {
  event: {
    list: 'event_wrap mb-16 grid sm:gap-4 gap-12 grid-cols-1 xl:grid-cols-3 md:grid-cols-2',
    item: (option) => <BigThumbnailCard option={option} />,
    rowCount: 1,
    columnCount: 3,
  },

  img_bbs: {
    list: 'event_wrap mb-16 grid sm:gap-4 gap-12 grid-cols-1 xl:grid-cols-3 md:grid-cols-2',
    item: (option) => <BigThumbnailCard option={option} />,
    rowCount: 1,
    columnCount: 3,
  },
  // img_bbs: {
  //   list: 'box_wrap grid sm:gap-4 gap-12 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1',
  //   item: (option) => (
  //     <div className="col">
  //       <ThumbnailCard option={{ ...option, type: 'image' }} />
  //     </div>
  //   ),
  //   rowCount: 2,
  //   columnCount: 4,
  // },
  vod: {
    list: 'box_wrap grid sm:gap-4 gap-12 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1',
    item: (option) => (
      <div className="col">
        <ThumbnailCard option={{ ...option, type: 'video' }} />
      </div>
    ),
    rowCount: 4,
    columnCount: 4,
  },
  bbs: {
    list: 'flex grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 row mb-12',
    item: (option, onDelete, onClick) => (
      <BoardCard content={option} onDelete={onDelete} onClick={onClick} />
    ),
    rowCount: 4,
    columnCount: 3,
  },
};

export const sizeClassMap = {
  [ContentType.EVENT]: contentDefine.event,
  [ContentType.IMG_BBS]: contentDefine.img_bbs,
  [ContentType.VOD]: contentDefine.vod,
  [ContentType.AL]: contentDefine.vod,
  [ContentType.VL]: contentDefine.vod,
  [ContentType.VOD_USER]: contentDefine.vod,
  [ContentType.BBS]: contentDefine.bbs,
  [ContentType.NOTICE]: contentDefine.bbs,
  [ContentType.OOO]: contentDefine.bbs,
  [ContentType.OOOR]: contentDefine.bbs,
};

/**
 *
 * @param {BoardOption} option
 * @returns {JSX.Element}
 * @constructor
 */
export default function BoardList({ option }) {
  return (
    <>
      {option?.title && (
        <div className="head_tit flex justify-between w-100 mb-12 border-b ">
          <div className="flex items-center">
            <h3 className="cont_tit mr-2">{option?.title ?? ''}</h3>
            <span
              className={classNames({
                tit_num: option?.pageOption?.totalCount > 0,
                tit_num_dis: option?.pageOption?.totalCount === 0,
                mb: true,
              })}
            >
              {option?.pageOption?.totalCount ?? 0}
            </span>
          </div>
          {option?.onClickMore != null && (
            <button
              onClick={option.onClickMore}
              className="cursor-pointer head_more f20"
            >
              더보기
            </button>
          )}
          {option?.moreLink != null && option?.onClickMore == null && (
            <Link2 to={option.moreLink} className="head_more f20">
              더보기
            </Link2>
          )}
        </div>
      )}

      {(option?.thumbnailCardOptions == null ||
        option?.thumbnailCardOptions?.length === 0) && (
        <div className="notice_none text-center h-80 flex flex-col items-center justify-center ">
          <p className="f14 mb-2">게시글이 없습니다.</p>
          {/*<p className="f12_w300 co_gray999">어려워 마시고 편안하게 작성해 보세요.</p>*/}
        </div>
      )}
      {_.chunk(
        option?.thumbnailCardOptions ?? [],
        option?.columnCount ?? sizeClassMap[option.contentType].columnCount
      ).map((chunk, idx) => (
        <div className={sizeClassMap[option.contentType].list}>
          {chunk.map((it) => sizeClassMap[option.contentType].item(it))}
          {process.env.NODE_ENV === 'development' && idx % 2 == 1
            ? `${Math.floor(idx / 2)} page`
            : null}
        </div>
      ))}

      {option?.showPage === true && option?.pageOption != null && (
        <>
          <div className="border-t" />
          <PageIndicator option={option?.pageOption} />
        </>
      )}
    </>
  );
}

/**
 * @typedef {Object} BoardOption
 * @property {string} title
 // * @property {string} moreLink
 * @property {boolean} showPage
 * @property {ThumbnailCardOption} thumbnailCardOptions
 * @property {number} columnCount default 4
 * @property {number} rowCount default 2
 * @property {PageOption} pageOption
 */
export class BoardOption {
  constructor({
    title,
    onClickMore,
    moreLink,
    showPage,
    thumbnailCardOptions,
    rowCount = 2,
    columnCount = 4,
    pageOption,
    contentType = ContentType.BBS,
  }) {
    this.title = title;
    this.onClickMore = onClickMore;
    this.moreLink = moreLink;
    this.showPage = showPage;
    this.thumbnailCardOptions = thumbnailCardOptions;
    this.columnCount = columnCount;
    this.rowCount = rowCount;
    this.pageOption = pageOption;
    this.contentType = contentType;
  }
}
