function formatBytes(a, b = 2) {
  if (!+a) return '0 Bytes';
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  }`;
}

/**
 * @param {FileDto[]} files
 * @param isAuthor
 * @param {(number) => void} onDelete
 * @return {JSX.Element}
 * @constructor
 */
export default function FileView({ files, isAuthor, onDelete }) {
  return (
    <div className="upload_file border-t border-dashed pt-6 pb-10">
      <p className="f12 mr-1 mb-2">
        첨부파일<span className="ml-1 f12 co_orange">{files.length}</span>
      </p>
      <p className="gap-6 flex items-center flex-wrap">
        {files.map((file, idx) => (
          <span
            key={`${file.name}_${idx}`}
            className="f14_w300 underline flex items-center gap-1"
          >
            <a href={file.fileLink} target="_blank" rel="noreferrer">
              {file.fileName}
            </a>
            {isAuthor && (
              <a
                href="src/page/ucms/component_NEEDTOMOVE"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onDelete(file.id);
                }}
              >
                <img
                  className="file_item"
                  src="/images/file_close.png"
                  alt="파일 삭제"
                />
              </a>
            )}
          </span>
        ))}
      </p>
    </div>
  );
}
