export function toShortCount(number) {
  const n = Number(number);
  if (isNaN(n)) {
    return number;
  } else if (n <= 99) {
    return n;
  }
  // 00.0K
  else if (n <= 99999) {
    return `${Math.floor(n / 100) / 10}K`;
  }
  // 00.0M
  else if (n <= 99999999) {
    return `${Math.floor(n / 100000) / 10}M`;
  } else {
    // 00.0B
    return `${Math.floor(n / 100000000) / 10}B`;
  }
}

export function safeParseInt(number) {
  const n = Number(number);
  if (isNaN(n)) return undefined;
  else return n;
}
