import ContentEditor, {
  EditorData,
} from '@/components/content/editor/ContentEditor';
import useLogin from '@/components/user/login/useLogin';
import { useEffect, useMemo } from 'react';
import useMenu from '@/components/menu/useMenu';
import { useParams } from 'react-router-dom';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import useContentInfo from '@/components/content/useContentInfo';
import { CircularProgress } from '@mui/material';
import useQuery from '@/components/sidebar/hook/useQuery';
import { ContentType } from '@/app/config/ContentType';
import UploadFileDto from '@/components/content/editor/upload/UploadFileDto';
import dayjs from 'dayjs';

export default function ContentWrite() {
  const { menuList, findMenu, findChildren, isWriteable, getContentType } =
    useMenu();
  const { isLoggedIn, data, manageMenu, visibleMenu } = useLogin();
  const { menuId, id } = useParams();
  const { password } = useQuery();
  const navigate = useNavigate2();
  const props = useContentInfo();
  const contentType = useMemo(() => getContentType(menuId), [menuId]);

  useEffect(() => {
    let targetMenu = findMenu(menuId);
    if (
      targetMenu == null ||
      (findChildren(menuId).length > 0 && menuId !== '2')
    ) {
      // TODO 글쓰기 불가 메뉴
      alert('글쓰기가 불가능한 메뉴입니다.');
      navigate(-1);
      return;
    }

    if (!isLoggedIn) {
      // TODO 로그인 필요
      alert('로그인이 필요합니다.');
      navigate(-1);
      return;
    }

    if (!isWriteable(Number(menuId))) {
      // TODO 권한 없음
      alert('글쓰기 권한이 없습니다.' + menuId);
      navigate(-1);
      return;
    }

    if (id != null) {
      props.load(menuId, id, contentType, password);
    }
  }, [isLoggedIn, menuId, id]);

  const menu = useMemo(() => {
    return findMenu(menuId);
  }, [menuId]);

  const editorInitData = useMemo(() => {
    if (menu == null) {
      return;
    }
    const isVod = contentType?.startsWith('VOD');
    const isEvent = contentType === ContentType.EVENT;
    const isImageBBS = contentType === ContentType.IMG_BBS;
    const needThumbnail =
      contentType === ContentType.VOD || isEvent || isImageBBS; // USER_VOD는 필수아님

    if (id == null || props.content == null) {
      return new EditorData({
        menuId: menu.menuId,
        cid: id,
        title: '',
        body: '',
        useVideo: isVod,
        needThumbnail: needThumbnail,
        useThumbnail: isVod || isEvent || isImageBBS,
        isSquareThumbnail: isEvent || isImageBBS,
        // useReserve: isVod,
        useReserve: false,
        // useImageList: !isVod,
        useImageList: false,
        useAttachFile: true,
        hasPassword: false,
        password: '',
        useGfForm: isEvent,
        gfUrl: '',
        gfKey: '',
        gfData: '',
        gfBtnText: '참여하기',

        useEventConfig: isEvent,
        eventStatus: getInitialEventStatus(null),
        eventStartDt: getInitialEventStartDt(null),
        eventEndDt: getInitialEventEndDt(null),
      });
    } else {
      const attachFileList = props.attachments.map((attach) => {
        return new UploadFileDto({
          id: attach.fileId,
          name: attach.fileName,
        });
      });
      return new EditorData({
        menuId: menu.menuId,
        cid: id,
        title: props.content.title,
        body: props.content.cData,
        useVideo: isVod,
        needThumbnail: needThumbnail,
        useThumbnail: isVod || isEvent || isImageBBS,
        isSquareThumbnail: isEvent || isImageBBS,
        // useReserve: isVod,
        useReserve: false,
        openLvl: props.content.openLvl,
        openOpt: props.content.openOpt,
        reserveAt: props.content.reserveAt,
        useImageList: false,
        useAttachFile: true,
        attachFileList: attachFileList,
        hasPassword: password != null && password !== '',
        password: password,
        files: props.files,
        thumbnailFile: props.userThumbnails?.[0],
        videoFile: props.videoFile,
        imageFileList: props.images,

        useGfForm: isEvent,
        gfUrl: props.content.gfUrl,
        gfKey: props.content.gfKey,
        gfData: props.content.gfData,
        gfBtnText:
          props.content.gfBtnText == null || props.content.gfBtnText === ''
            ? '참여하기'
            : props.content.gfBtnText,

        useEventConfig: isEvent,
        eventStatus: getInitialEventStatus(props.content.eventStatus),
        eventStartDt: getInitialEventStartDt(props.content.eventStartDt),
        eventEndDt: getInitialEventEndDt(props.content.eventEndDt),
      });
    }
  }, [id, props, menu]);

  // hook 대기
  if (!isLoggedIn || !isWriteable(Number(menuId))) {
    return <div className="h-[80vh] w-full flex items-center justify-center" />;
  }

  // 신규등록이 아니면 컨텐츠 로딩
  if (id != null) {
    if (props.isInitLoading || menu == null) {
      return (
        <div className="h-[80vh] w-full flex items-center justify-center">
          <CircularProgress className="loading" />
        </div>
      );
    }
  }

  return (
    <>
      <ContentEditor data={editorInitData} />
    </>
  );
}

function getInitialEventStatus(eventStatus) {
  if (eventStatus === 'E' || eventStatus === 'I') {
    return eventStatus;
  } else {
    return 'R';
  }
}

function getInitialEventStartDt(eventStartDt) {
  console.log('getInitialEventStartDt', eventStartDt);
  if (eventStartDt == null || eventStartDt === '') {
    return dayjs();
  }
  return dayjs(eventStartDt);
}

function getInitialEventEndDt(eventStartDt) {
  if (eventStartDt == null || eventStartDt === '') {
    return null;
  }
  return dayjs(eventStartDt);
}
