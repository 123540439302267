import { useRef, useState } from 'react';
import classNames from 'classnames';
import OutlineLabelField from '../../form/OutlineLabelField';
import { IconButton } from '@mui/material';
import useMenuList from '@/components/content/editor/useMenuList';

export default function ContentEditorMenuInput({
  id = 'menu_input',
  name,
  value,
  onChange,
  className,
  message,
  onKeyDown,
  disabled,
  children: rightElement,
} = {}) {
  const menuList = useMenuList({ excludeNormal: false });
  const [isOpen, setOpen] = useState(false);
  const inputRef = useRef();
  const triesRef = useRef(0);
  const selectMenu = (menuId, menuNm) => {
    // 직접입력
    if (menuNm === '') {
    } else {
      onChange?.(`${menuId}`, `${menuNm}`);
    }
    setOpen(false);
    inputRef.current?.focus();
    // setIsRunning(true);
  };

  return (
    <div className="input_box single">
      <OutlineLabelField
        id={id}
        name={name}
        autoComplete="off"
        label="카테고리 선택"
        inputRef={inputRef}
        className={`${className}`}
        value={value}
        message={message}
        onKeyDown={onKeyDown}
        disabled={true}
        type="menu"
        customButton={
          <>
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => setOpen(!isOpen)}
              size="small"
              edge="end"
            >
              <img
                className="input_icon input_arrow_down"
                src="/images/input_arrow_down.png"
                alt="메뉴목록"
              />
            </IconButton>
            {rightElement}
          </>
        }
      />
      <ul
        className={classNames({
          input_select_box: true,
          active: isOpen,
          editor_select_box: true,
        })}
      >
        {
          // 0번 일반사용자 제외
          menuList
            ?.sort((a, b) => a.menuSeq - b.menuSeq)
            ?.map(
              (it) =>
                it.useYN === 'Y' && (
                  <li
                    key={it.menuId}
                    className="menuNm_li"
                    onClick={() => selectMenu(`${it.menuId}`, `${it.menuNm}`)}
                  >
                    <span className="menuNm">{it.menuNm}</span>
                  </li>
                )
            )
        }
      </ul>
    </div>
  );
}
