import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function PathChangeDetect({ callback }) {
  const { pathname } = useLocation();

  useEffect(() => {
    callback?.();
  }, [pathname]);

  return null;
}
