export default function TwitterShareButton({ title, link, onClick }) {
  return (
    <a
      href={`https://twitter.com/intent/tweet?text=${title}&url=${encodeURIComponent(
        link
      )}`}
      target={'_blank'}
      onClick={onClick}
      rel="noreferrer"
    >
      <img src="/images/sns_ico02.png" alt="트위터 공유" />
    </a>
  );
}
