import MoreVertIcon from '@mui/icons-material/MoreVert';
import OptionBase from '@/components/comment/OptionBase';

export default function PostOption({
  canReport,
  canEdit,
  canDelete,
  onEdit,
  onDelete,
  onReport,
}) {
  return (
    <OptionBase
      canEdit={canEdit}
      canDelete={canDelete}
      canReport={canReport}
      onEdit={onEdit}
      onDelete={onDelete}
      onReport={onReport}
    >
      <MoreVertIcon />
    </OptionBase>
  );
}
