import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { ContentType, isVideoType } from '@/app/config/ContentType';
import useLogin from '@/components/user/login/useLogin';

export const menuType = {
  media: 0,
  my: 1,
  community: 2,
  managedCommunity: 3,
};

// 검색 및 카드 구성 정의
export const menuTypeData = {
  wbn: {
    menuId: 1,
    tabIndex: 2,
    title: '우리방송',
    isVideo: true,
  },
  radio: {
    menuId: 3,
    tabIndex: 4,
    title: 'Radio',
    isVideo: true,
  },
  tube: {
    menuId: 2,
    tabIndex: 3,
    title: '우리튜브',
    isVideo: true,
  },
  bbs: {
    menuId: 5,
    tabIndex: 5,
    title: '게시판',
    isVideo: false,
  },
};

export default function useMenu() {
  const { data, visibleMenu, manageMenu, menuAdmin } = useLogin();
  const dispatch = useDispatch();
  const _menuList = useSelector((state) => state.menuReducer.menuList);

  const parseCompGroupMenuData = (data) => {
    try {
      // console.log('parseCompGroupMenuData', data);
      let json;
      try {
        json = JSON.parse(data.compGroupMenuData);
      } catch (e) {
        // FIXME API에서 json인데  "가 아니라 '로 감싸져있음
        json = JSON.parse(data.compGroupMenuData.replace(/'/g, '"'));
      }
      let compIds = json.filter((it) => it.rights === 1).map((it) => it.compId);
      return {
        ...data,
        compGroupMenuData: compIds,
      };
    } catch (e) {
      console.error(e);
      return data;
    }
  };

  /**
   * @type {[MenuModel]}
   */
  const menuList = useMemo(() => {
    // menuLevel 0: 게시판, 1: 설정(마이페이지)
    return (
      _menuList.data?.data
        ?.filter((it) => it.useYN === 'Y')
        .map((it) => parseCompGroupMenuData(it)) ?? []
    );
  }, [_menuList.data]);

  /**
   * @type {[MenuModel]}
   */
  const rootMenuList = useMemo(() => {
    // menuLevel 0: 게시판, 1: 설정(마이페이지)
    return (
      menuList?.filter(
        (it) => it.menuLevel === 1 && it.menuType !== menuType.my
      ) ?? []
    );
  }, [menuList]);

  /**
   * @type {[MenuModel]}
   */
  const videoRootMenuList = useMemo(() => {
    return rootMenuList?.filter((it) => it.menuType === menuType.media) ?? [];
  }, [rootMenuList]);

  /**
   * @type {[MenuModel]}
   */
  const boardRootMenuList = useMemo(() => {
    return (
      rootMenuList?.filter((it) => it.menuType === menuType.community) ?? []
    );
  }, [rootMenuList]);

  /**
   *
   * @param {number|string} menuId
   * @returns {MenuModel}
   */
  const findMenu = (menuId) => {
    return menuList?.find((it) => it.menuId == menuId);
  };

  /**
   *
   * @param {number|string|MenuModel} menu
   * @returns {MenuModel[]}
   */
  const findChildren = (menu) => {
    let menuId;
    if (isNaN(Number(menu))) {
      menuId = menu.menuId;
    } else {
      menuId = menu;
    }
    const compId = data?.compId ?? 0;
    let res =
      menuList?.filter(
        (it) =>
          it.parentId == menuId &&
          (it.compGroupMenuData == null ||
            it.compGroupMenuData?.find((it) => it == compId)) != null
      ) ?? [];

    // console.log('findChildren', menuId, menuList, res);
    return res;
  };

  /**
   *
   * @param {number|string|MenuModel} menu
   * @returns {MenuModel[]}
   */
  const findAllChildren = (menu) => {
    return findChildren(menu).reduce((acc, it) => {
      return acc.concat(it, findAllChildren(it.menuId));
    }, []);
  };

  /**
   * 하위 메뉴중, 하위 메뉴가 없는 메뉴만 반환
   * @param {number|string|MenuModel} menu
   * @returns {MenuModel[]}
   */
  const findChildrenWithoutChild = (menu) => {
    console.log('????', menu);
    return findChildren(menu).filter(
      (it) => findChildren(it.menuId).length === 0
    );
  };

  /**
   * 하위 메뉴중, 하위 메뉴가 있는 메뉴만 반환
   * @param {number|string|MenuModel} menu
   * @returns {MenuModel[]}
   */
  const findChildrenWithChild = (menu) => {
    return findChildren(menu).filter(
      (it) => findChildren(it.menuId).length > 0
    );
  };

  /**
   * 부모
   * @param {number|string} menuId
   * @returns {MenuModel[]}
   */
  const findRootMenu = (menuId) => {
    let menu = findMenu(menuId);
    if (menu == null) {
      return null;
    }
    while (menu.menuLevel > 1) {
      menu = findMenu(menu.parentId);
    }
    return menu;
  };

  /**
   * 글쓰기 버튼이 보일 조건
   * 1. 자식 메뉴가 없어야 함(우리튜브는 제외)
   * 2. userWriteLvl 이 0 이면 로그인 안해도 표시
   * 3. 사용자의 관리 권한에 메누가 있으면 표시
   * 4. userWriteLvl 이 1 이고, userWriteOpt 에 현재 로그인한 사용자의 회사가 있으면 표시
   * 5. userWriteLvl 이 2 이고, 관리자이면 표시
   * 6. 나머지 권한 없음
   * @param menuId
   * @returns {boolean}
   */
  const isShowWriteButton = (menuId) => {
    let children = findChildren(menuId);
    if (children.length > 0 && menuId != 2) {
      return false; // 자식 메뉴가 있으면 글 못씀
    }
    let menu = findMenu(menuId);
    if (menu == null) {
      return false;
    }
    const userWriteLvl = menu.userWriteLvl;
    const userWriteOpt = `,${menu.userWriteOpt},`;
    console.log(
      `$$$$$$$$$$$$$ ${menuId} ${menu.userWriteLvl} ${menu.userWriteOpt}`
    );

    console.log(`isShowWriteButton ${menuId} ${userWriteLvl} ${userWriteOpt}`);
    // public
    if (userWriteLvl === 0) {
      console.log('isShowWriteButton public');
      return true;
    }
    // 로그인 안한 사용자
    if (data == null) {
      return userWriteLvl !== 2;
      // console.log('isShowWriteButton not public no login')
      // return false;
    }
    const compId = data.compId;

    let hasManagePermission =
      manageMenu.find((it) => it == menuId) != null ||
      menuAdmin.find((it) => it == menuId) != null;
    if (hasManagePermission) {
      console.log('isShowWriteButton admin');
      return true;
    }
    // let hasManagePermission = userData.manageMenu.find(it => it.menuId === menuId) != null;
    // if(hasManagePermission){
    //     return true;
    // }

    if (userWriteLvl === 1 && userWriteOpt.includes(`,${compId},`)) {
      console.log('isShowWriteButton company');
      return true;
    } else if (userWriteLvl === 2 && data.adminGroupId === 1) {
      console.log('isShowWriteButton admin account');
      return true; // 관리자 계정만
    } else {
      console.log('isShowWriteButton no permission' + menu.userWriteOpt);
      return false;
    }
  };

  /**
   * 글쓰기 권한 확인
   * 1. 자식 메뉴가 없어야 함(우리튜브는 제외)
   * 2. userWriteLvl 이 0 이고, 로그인만 하면 권한 있음
   * 3. 사용자의 권한에 메뉴가 있으면 권한 있음
   * 4. userWriteLvl 이 1 이고, userWriteOpt 에 현재 로그인한 사용자의 회사가 있으면 권한 있음
   * 5. userWriteLvl 이 2 이고, 관리자이면 권한 있음
   * 6. 나머지 권한 없음
   * @param menuId
   * @returns {boolean}
   */
  const isWriteable = (menuId) => {
    let children = findChildren(menuId);
    if (children.length > 0 && menuId != 2) {
      return false; // 자식 메뉴가 있으면 글 못씀
    }
    let menu = findMenu(menuId);
    if (menu == null) {
      return false;
    }
    const userWriteLvl = menu.userWriteLvl;
    const userWriteOpt = `,${menu.userWriteOpt},`;
    console.log(`hasWritePermission ${menuId} ${userWriteLvl} ${userWriteOpt}`);
    // public
    if (userWriteLvl === 0) {
      console.log('hasWritePermission public');
      return true;
    }
    // 로그인 안한 사용자
    if (data == null) {
      console.log('hasWritePermission not public no login');
      throw new Error('need login');
      // return false;
    }
    const compId = data.compId;

    let hasManagePermission =
      manageMenu.find((it) => it == menuId) != null ||
      menuAdmin.find((it) => it == menuId) != null;
    if (hasManagePermission) {
      console.log('hasWritePermission admin');
      return true;
    }
    // let hasManagePermission = userData.manageMenu.find(it => it.menuId === menuId) != null;
    // if(hasManagePermission){
    //     return true;
    // }

    if (userWriteLvl === 1 && userWriteOpt.includes(`,${compId},`)) {
      console.log('hasWritePermission company');
      return true;
    } else if (userWriteLvl === 2 && data.adminGroupId === 1) {
      console.log('hasWritePermission admin account');
      return true; // 관리자 계정만
    } else {
      console.log('hasWritePermission no permission');
      return false;
    }
  };

  /**
   * 하위 게시판 메뉴에서 전체 탭을 표시할지 여부를 결정
   * @param menuId
   * @returns {boolean}
   */
  const shouldShowAllMenu = (menuId) => {
    let menu = findMenu(menuId);
    // 자식 메뉴까지 모두 VOD 계열인 경우 전체 버튼 표시
    // 우리튜브 개편
    if (menuId === 2) {
      return true;
    } else {
      if (isVideoType(menu?.contentType)) {
        let allChildren = findAllChildren(menuId);
        if (allChildren.length <= 1) {
          return false;
          let notVod = allChildren.find(
            (it) => !isVideoType(menu?.contentType)
          );
          return notVod == null;
        } else return true;
      } else {
        return false;
      }
    }
  };

  const getContentType = (menuId) => {
    return findMenu(menuId)?.contentType ?? ContentType.BBS;
  };

  const isMediaContentType = (menuId) => {
    const contentType = getContentType(menuId);
    return (
      contentType === ContentType.VOD ||
      contentType === ContentType.VOD_USER ||
      contentType === ContentType.VL ||
      contentType === ContentType.AL
    );
  };

  /**
   *
   * @param {string|number|MenuModel}menu
   * @returns {MenuModel|null}
   */
  const findVisibleMenu = (menu) => {
    let _menu;
    if (isNaN(Number(menu))) {
      _menu = menu;
    } else {
      _menu = findMenu(menu);
    }
    if (_menu == null) {
      return null;
    }
    const compId = data?.compId ?? 0;
    let hasPermission =
      _menu.compGroupMenuData == null ||
      _menu.compGroupMenuData?.find((it) => it == compId) != null;
    if (hasPermission) {
      return _menu;
    } else {
      return null;
    }
  };

  const isLoading = useMemo(() => {
    return _menuList.loading || _menuList.data == null;
  }, [_menuList]);

  const findLMSMenu = () => {
    return menuList.find((it) => it.menuUrl === 'lms');
  };

  /**
   *
   * @param {MenuModel|number} menu
   * @returns {boolean}
   */
  const isThankYouTokenMenu = (menu) => {
    let m = menu;
    if (!isNaN(Number(menu))) {
      let id = Number(menu);
      m = findMenu(id);
    }
    return m?.menuNm === '땡큐토큰';
  };

  /**
   *
   * @returns {MenuModel|null}
   */
  const findThankYouTokenMenu = () => {
    return menuList.find((it) => it.menuNm === '땡큐토큰');
  };

  /**
   *
   * @param {MenuModel|number} menu
   * @returns {boolean}
   */
  const isWonderingMenu = (menu) => {
    let m = menu;
    if (!isNaN(Number(menu))) {
      let id = Number(menu);
      m = findMenu(id);
    }
    return m?.menuNm === '원더링';
  };
  /**
   *
   * @returns {MenuModel|null}
   */
  const findWonderingMenu = () => {
    return menuList.find((it) => it.menuNm === '원더링');
  };

  /**
   *
   * @param {MenuModel|number} menu
   * @returns {string}
   */
  const getFullPath = (menu) => {
    let m = menu;
    if (!isNaN(Number(menu))) {
      let id = Number(menu);
      m = findMenu(id);
    }
    let parentList = [m];
    let parent = m;
    let p = Number(parent.parentId);
    while (p != null && !isNaN(p) && p > 0) {
      parent = findMenu(p);
      parentList = [parent, ...parentList];
      p = parent.parentId;
    }

    return `/${parentList.map((it) => m.menuId).join('/')}`;
  };

  return {
    isLoading,
    // isLoading: _menuList.loading || _menuList.data == null,
    menuList,
    rootMenuList,
    videoRootMenuList,
    boardRootMenuList,
    findMenu,
    findVisibleMenu,
    findRootMenu,
    findChildren,
    findChildrenWithoutChild,
    findChildrenWithChild,
    shouldShowAllMenu,
    getContentType,
    isMediaContentType,
    isShowWriteButton,
    isWriteable,
    findLMSMenu,
    isThankYouTokenMenu,
    findThankYouTokenMenu,
    getFullPath,
    isWonderingMenu,
    findWonderingMenu,
  };
}

/**
 * @typedef {Object} MenuModel
 * @property {number} KEY01 level1 key
 * @property {number} KEY02 level2 key
 * @property {number} KEY03 level3 key
 * @property {number} ORDER01 level1 order
 * @property {number} ORDER02 level2 order
 * @property {number} ORDER03 level3 order
 * @property {string} PATH01
 * @property {string} PATH02
 * @property {string} PATH03
 * @property {string} boardYn Y|N
 * @property {string} mediaYn Y|N
 * @property {number} menuId
 * @property {number} menuLevel
 * @property {string} menuNm
 * @property {string} menuPath
 * @property {number} menuSeq
 * @property {number} menuType 0: 게시판, 1: 설정(마이페이지)
 * @property {string} menuUrl
 * @property {string} parentId
 * @property {string} searchYn Y|N
 * @property {string} useYN Y|N
 * @property {string} contentType {@link src/app/config/ContentType}
 * @property {dragLockYn} dragLockYn Y|N
 */
export class MenuModel {
  KEY01;
  KEY02;
  KEY03;
  ORDER01;
  ORDER02;
  ORDER03;
  PATH01;
  PATH02;
  PATH03;
  boardYn;
  mediaYn;
  menuId;
  menuLevel;
  menuNm;
  menuPath;
  menuSeq;
  menuType;
  menuUrl;
  parentId;
  searchYn;
  useYN;
  contentType;
  dragLockYn;
  userWriteLvl; // 0 public, 1 employee, 2 admin
  userWriteOpt; // compId csv
  compGroupMenuData;
}
