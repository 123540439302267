import { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import Link2 from '@/components/common/transition/Link2';

const defaultProfileCount = 16; // 1~16

/**
 * 기본 이미지를 포함한 프로필 이미지
 * 파일 정보가 없으면, userNo기반으로 기본 프로필 이미지를 보여준다.
 * @param className
 * @param userNo
 * @param profileSt
 * @param profileUrl
 * @param nickNm - 사용자 콘텐츠 조회 페이지에서 보여줄 닉네임
 * @returns {JSX.Element}
 * @constructor
 */

export default function ProfileImage({
  className = '',
  profileUrl,
  profileSt,
  userNo,
  nickNm,
  useUserContentLink = true,
}) {
  const src = useMemo(() => {
    if (profileUrl != null) {
      return profileUrl;
    } else {
      return getDefaultProfileImage(userNo);
    }
  }, [profileUrl, profileSt, userNo]);

  if (profileSt === 'R' || profileSt === 'W') {
    return (
      <div className={`profile ${className}`}>
        <div className="w-full h-full flex items-center justify-center">
          <CircularProgress className="loading !p-0 !bg-[unset] !h-[unset]" />
        </div>
      </div>
    );
  }

  return (
    <Link2 to={useUserContentLink ? `/user/content?userNo=${userNo}` : null}>
      <img
        className={`profile ${className}`}
        src={src}
        alt="프로필 이미지"
        onError={(e) => {
          e.target.src = getDefaultProfileImage(userNo);
        }}
      />
    </Link2>
  );
}

export const getDefaultProfileImage = (
  userNo,
  containOrigin = false,
  useSvg = true
) => {
  let uid = userNo;
  if (uid == null) {
    uid = Math.floor(Math.random() * 15) + 1;
  } else {
    uid = (uid % defaultProfileCount) + 1;
  }
  return `${
    containOrigin ? window.location.origin : ''
  }/images/default_profile_${uid}.${useSvg ? 'svg' : 'png'}`;
};
