import Popup from '@/components/popup/Popup';
import usePin, { pinLength } from '@/components/easyLogin/pin/usePin';
import useBio from '@/components/easyLogin/bio/useBio';
import usePopup from '@/components/popup/usePopup';
import { useState } from 'react';
import { setPopupShowed } from '@/components/easyLogin/EasyLoginReducer';
import { useDispatch } from 'react-redux';
import PinDialog from '@/components/easyLogin/pin/PinDialog';

export default function PinLoginConfig() {
  const dispatch = useDispatch();
  const { bioRegistered, removeBio } = useBio();
  const { canRegister, pinRegistered, removePin, registerPin } = usePin();
  const { popup, show, hide } = usePopup();
  const [requestPin1, setRequestPin1] = useState(false);
  const [requestPin2, setRequestPin2] = useState(false);
  const [pin1, setPin1] = useState('');

  const toggle = async () => {
    if (pinRegistered) {
      removePin();
    } else {
      if (bioRegistered) {
        show({
          title: '간편비밀번호',
          message: '간편비밀번호 등록시 생체인증 로그인은 해제됩니다.',
          okCallback: () => {
            hide();
            dispatch(setPopupShowed(true));
            requestPin();
          },
          cancelCallback: () => {
            hide();
          },
        });
      } else {
        requestPin();
      }
    }
  };

  const cancel = () => {
    setPin1('');
    setRequestPin1(false);
    setRequestPin2(false);
  };

  const requestPin = () => {
    setPin1('');
    setRequestPin1(true);
    setRequestPin2(false);
  };

  const _submitPin1 = (pin) => {
    if (pin.length >= pinLength) {
      setPin1(pin);
      setRequestPin1(false);
      setRequestPin2(true);
    }
  };
  const _submitPin2 = async (pin) => {
    if (pin.length >= pinLength) {
      if (pin1 !== pin) {
        show({
          title: '비밀번호가 다릅니다',
          okCallback: () => {
            requestPin();
            hide();
          },
        });
        return;
      } else {
        setRequestPin1(false);
        setRequestPin2(false);
      }
      removeBio();
      try {
        await registerPin({ pin: pin1 });
        show({
          title: '간편비밀번호가 등록 되었습니다.',
          okCallback: () => {
            hide();
          },
        });
      } catch (e) {
        show({
          title: '오류가 발생하였습니다.',
          okCallback: () => {
            hide();
          },
        });
      }
    }
  };

  if (!canRegister) return '';

  return (
    <>
      <div className="row flex items-center justify-between mb-10">
        <h5>간편비밀번호 사용</h5>
        <div>
          <input
            className="toggle"
            type="checkbox"
            id="pin-toggle"
            hidden=""
            checked={pinRegistered}
            onChange={toggle}
          />
          <label htmlFor="pin-toggle" className="toggleSwitch">
            <span className="toggleButton"></span>
          </label>
        </div>
      </div>
      {popup != null && <Popup {...popup} className="z-[2002]" />}
      <PinDialog open={requestPin1} onSubmit={_submitPin1} onCancel={cancel} />
      <PinDialog
        title="간편비밀번호 확인"
        open={requestPin2}
        onSubmit={_submitPin2}
        onCancel={cancel}
      />
    </>
  );
}
