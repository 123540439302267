import FileSelector from '@/components/content/editor/FileSelector';
import React, { useEffect, useMemo, useState } from 'react';
import NotateToolTip from '@/components/content/editor/NotateToolTip';

/**
 *
 * @param {ContentEditorVideoFieldOption} option
 * @returns {JSX.Element}
 * @constructor
 */

// const videoStateTooltip =
//     '등록  : 관리자 승인완료 후 정상적으로 노출상태입니다.\n' +
//     '대기 : 영상 업로드 완료 상태이고 관리자 승인하기전입니다.\n' +
//     '승인거부 : 관리자에서 승인거부된 상태입니다.\n' +
//     '비공개 : 사용자가 비공개로 전환한 상태입니다'

const videoStateTooltip = (
  <div>
    <span className="tooltip-text block">
      - 업로드 전 : 영상 업로드 전입니다.
    </span>
    <span className="tooltip-text block">
      - 대기 : 업로드 완료 상태이고 변환 대기중입니다.
    </span>
    <span className="tooltip-text block">- 변환중 : 영상을 변환중입니다.</span>
    <span className="tooltip-text block">
      - 오류 : 영상이 정상적으로 처리되지 않았습니다.
    </span>
    <span className="tooltip-text block">
      - 완료 : 영상 변환이 정상적으로 완료된 상태입니다
    </span>
  </div>
);

export default function ContentEditorVideoField({ option }) {
  const [file, setFile] = useState();
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (file != null) {
      option?.onFile?.(file);
      setPreview(URL.createObjectURL(file));

      for (var key in file) {
        console.log('선택된 파일 : ' + key + ' / ' + file[key]);
      }
    } else {
      option?.onFile?.(file);
      setPreview(null);
    }
  }, [file]);

  useEffect(() => {
    console.log('로딩된 파일 : ' + preview);
  }, [preview]);

  const videoState = useMemo(() => {
    const existVideoFile = option?.videoFile;
    if (existVideoFile != null && file == null) {
      if (existVideoFile.cdnSt === 'S') {
        return '완료';
      } else if (existVideoFile.cdnSt === 'W') {
        return '변환중';
      } else if (existVideoFile.cdnSt === 'R') {
        return '변환중';
      } else if (existVideoFile.uploadSt === 'F') {
        return '오류';
      } else if (existVideoFile.orgSt === 'F') {
        return '오류';
      } else if (existVideoFile.cdnSt === 'F') {
        return '오류';
      } else if (existVideoFile.uploadSt === 'S') {
        return '대기';
      }
    }
    if (existVideoFile != null && file != null) {
      return '업로드 전 (변경)';
    }
    return '업로드 전';
  }, [option, file]);

  return (
    <div className="file_col flex md:col-span-2 gap-4">
      <div className="flex gap-4">
        <FileSelector
          option={{
            className: 'gap-y-1 flex-none',
            title: '동영상',
            fileType: 'video/mp4,video/quicktime',
            onFile: async (file) => {
              const item = file?.[0];
              const buffer = await item.arrayBuffer();
              const clone = new File([buffer], item.name, { type: item.type });
              if (clone) {
                setFile(clone);
              }
            },
          }}
        />
        <div className="thumb_box">
          {preview == null && option?.videoFile != null && (
            <>
              <video src={option?.videofile} controls />
              <button
                type="button"
                className="remove"
                onClick={() => setFile(null)}
              />
            </>
          )}
          {preview != null && (
            <>
              <video src={preview} controls />
              <button
                type="button"
                className="remove"
                onClick={() => setFile(null)}
              />
            </>
          )}
        </div>
      </div>

      <div className="flex video_state items-center gap-8 ">
        <span className="flex items-center f14_w300">
          영상상태
          <NotateToolTip title={videoStateTooltip} />
        </span>
        <span className="f14">{videoState}</span>
      </div>
    </div>
  );
}

/**
 * @typedef {Object} ContentEditorThumbnailFieldOption
 * @property {string} video 첨부 video
 * @property {string} videoFile 업로드 된 영상
 * @property {(files: File[]) => void} onFile 새 썸네일
 */
export class ContentEditorVideoFieldOption {
  constructor({ videoFile, video, onFile }) {
    this.videoFile = videoFile;
    this.video = video;
    this.onFile = onFile;
  }
}
