const ACTIONS = {
  POP: 'POP',
};

Object.freeze(ACTIONS);

export const pop = (page) => ({ type: ACTIONS.POP, payload: page });

const initialState = {
  prevPage: null,
};

export default function detailHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.POP: {
      return {
        ...state,
        prevPage: action.payload,
      };
    }

    default:
      return state;
  }
}
