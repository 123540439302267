import Link2 from '@/components/common/transition/Link2';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import classNames from 'classnames';

export default function Footer() {
  const { pathname } = useLocation2();

  return (
    <footer id="footer" className="border-t">
      <div className="inner  items-center">
        <div className="flex  flex-wrap justify-between">
          <div className="flex items-center">
            <h1 className="sm:flex foot_logo mr-6 hidden">
              <img src="/images/foot_logo.png" alt="푸터로고" />
            </h1>
            <span className="copyright">
              COPYRIGHT (C) 2023 WOORI BANK WBN. AII RIGHTS RESERVED.
            </span>
          </div>
          <div>
            <Link2 to="/terms/privacy">개인정보처리방침</Link2>
          </div>
          <div className="flex sm:flex-wrap gap-6 address items-center">
            <span>04632</span>
            <span>서울특별시 중구 소공로 51(회현동 1가)</span>
            <span>Tel. (02) 2002-4005</span>
          </div>
        </div>
      </div>
    </footer>
  );
}
