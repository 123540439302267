import { useEffect, useMemo, useState } from 'react';
import { SearchApi } from '@/components/search/SearchApi';

export default function useThankyouRegList() {
  const [list, setList] = useState([]);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    _loadList();
  }, []);

  const _loadList = async () => {
    try {
      const res = await SearchApi.ttRegSearch();
      setList((l) => res.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    }
  };

  return {
    hasError,
    list,
  };
}
