/**
 * 객체로 자체적으로 API 요청 및 표시
 * @constructor
 */

import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useBanner, { BannerType } from '@/components/banner/useBanner';
import { CircularProgress } from '@mui/material';

export default function Banner() {
  const { list, hasError, isLoading } = useBanner({
    bannerType: BannerType.main,
  });
  const dispatch = useDispatch();
  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    if (isLoading || hasError) {
      return;
    }
    const $ = window.$;
    $(document).ready(function () {
      const slider = $('.slider');
      try {
        slider?.slick('unslick');
      } catch (e) {
        //ignore
      }
      slider?.on('init', function (e, s) {
        // 다른 페이지에서 초기화 되어 넘어온 경우, slick 위치가 이상한 문제가 있음.
        // 초기화 후, 100ms * 5 회 반복하여 0페이지로 이동하며, 동시에 500ms간 opacity 애니메이션으로 보이게함
        let fixCount = 0;
        s?.goTo(0);
        let fix = setInterval(() => {
          s?.goTo(0);
          fixCount++;
          // console.log('slick fix');
          if (fixCount > 5) {
            clearInterval(fix);
            setInitiated(true);
          }
        }, 100);
      });
      slider?.slick({
        dots: true,
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '16px',
        slidesToScroll: 1,
        variableWidth: true,
        infinite: true,
        autoplay: true,
        swipeToSlide: true,
        speed: 200,
        autoplaySpeed: 5000,
        waitForAnimate: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              variableWidth: false,
            },
          },
          {
            breakpoint: 768,
            settings: {
              variableWidth: false,
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              variableWidth: false,
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1,
            },
          },
        ],
      });
    });
  }, [list, isLoading]);

  return (
    <>
      {isLoading && (
        <div className="h-72 w-full flex items-center justify-center">
          <CircularProgress className="loading" />
        </div>
      )}
      <div
        className="slider"
        style={{
          transition: 'opacity 0.5s',
          opacity: initiated ? 1 : 0,
        }}
      >
        {list.map((it) => (
          <div key={`banner_${it.itemId}`}>
            <Link to={it.bannerLink}>
              <img className="pc" src={it.bannerPath1} alt={it.bannerTitle} />
              <img className="m" src={it.bannerPath2} alt={it.bannerTitle} />
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}
