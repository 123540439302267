import Popup from '@/components/popup/Popup';
import { useDispatch } from 'react-redux';
import { openSideBar } from '@/components/sidebar/sidebarReducer';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import { userLogout } from '@/components/user/login/LoginReducer';

export default function EmployInfoEditRequiredPopup({
  show = false,
  setShow,
} = {}) {
  const navigate = useNavigate2();
  const dispatch = useDispatch();
  return (
    <>
      {show && (
        <Popup
          useDim={true}
          title="임직원 정보 필요"
          message="서비스 이용을 위해 임직원 정보 입력이 필요합니다."
          okMessage="입력하기"
          cancelMessage="로그아웃"
          cancelCallback={() => {
            dispatch(userLogout());
          }}
          okCallback={() => {
            navigate('/myinfo/info');
          }}
        />
      )}
    </>
  );
}
