import { apiInstance } from '@/app/config/network';
import AuthUtil from '@/components/auth/AuthUtil';

export default class MenuApi {
  static getMenuList = async (isLoggedIn) => {
    return apiInstance.get('/api/cms/menu/menuListAll').then((res) => {
      return res.data;
    });
  };

  static async getMenuListDepth() {
    return apiInstance
      .get('/api/cms/menu/menuListAll?depth=2&menuid=2')
      .then((res) => {
        return res.data;
      });
  }
}
