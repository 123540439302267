
export const extLowercase = (filename = '') => {
    let s = filename.split('.');
    let ext = s.pop().toLowerCase();
    return [...s, ext].join('.');
}

export const jpegToJpg = (filename = '') => {
    let s = filename.split('.');
    let ext = s.pop().replace("jpeg", "jpg");
    return [...s, ext].join('.');
}

export const normalizeNFC = (filename = '') => {
    return filename.normalize('NFC');
}

export const filenameCompat = (filename = '') => {
    return normalizeNFC(extLowercase(jpegToJpg(filename)));
}

export const appendTimestamp = (filename, timestamp = `_${new Date().getTime()}`) => {
    return appendFileName(filename, timestamp);
}

export const appendFileName = (filename, append) => {
    let s = filename.split('.');
    let ext = s.pop();
    let fileName = [...s].join('.');
    return [`${fileName}${append}`, ext].join('.');
}