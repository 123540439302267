import { useState } from 'react';

export default function usePopup() {
  const [popup, setPopup] = useState(null);
  /**
   * @param {PopupOption} option
   */
  const show = (option) => {
    setPopup(option);
  };
  const hide = () => {
    setPopup(null);
  };
  return {
    popup,
    show,
    hide,
  };
}
