import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import {FileUploadWorkerEventMethod, FileUploadWorkerRunMethod} from "./s3FileUpload.worker";
import {appendTimestamp, filenameCompat} from "./lib/fileUtil";
// import imageIcon from "@ckeditor/ckeditor5-core/theme/icons/image.svg";
// import FileDialogButtonView from "@ckeditor/ckeditor5-upload/src/ui/filedialogbuttonview";
// import FileRepository from "@ckeditor/ckeditor5-upload/src/filerepository";
// import Notification from "@ckeditor/ckeditor5-ui/src/notification/notification";
// import Command from "@ckeditor/ckeditor5-core/src/command";
// import {findOptimalInsertionPosition} from '@ckeditor/ckeditor5-widget/src/utils';
//
// const _UPLOAD_FILE_LIMIT_TEXT = "50MB"
// const _UPLOAD_FILE_LIMIT = 50000000; // 50mb
//
// // https://bloodseeker.tistory.com/3
// const createImageTypeRegExp = (types) => {
//     // Sanitize the MIME type name which may include: "+", "-" or ".".
//     const regExpSafeNames = types.map((type) => type.replace("+", "\\+"));
//
//     return new RegExp(`^image\\/(${regExpSafeNames.join("|")})$`);
// };
//
// class FileUploadCommand extends Command {
//     /**
//      * Executes the command.
//      *
//      * @fires execute
//      * @param {Object} options Options for the executed command.
//      * @param {File|Array.<File>} options.file The image file or an array of image files to upload.
//      */
//     //fileUpload command를 받으면 execute함수가 실행 됨.
//     execute(options) {
//         const editor = this.editor;
//         const model = editor.model;
//
//         //File Repository 가져옮
//         const fileRepository = editor.plugins.get(FileRepository);
//         const notification = editor.plugins.get(Notification);
//
//         model.change((writer) => {
//             const filesToUpload = Array.isArray(options.file)
//                 ? options.file
//                 : [options.file];
//
//             for (const file of filesToUpload) {
//                 console.log(file);
//                 if (file.size > _UPLOAD_FILE_LIMIT) {
//                     notification.showWarning(`첨부 파일 용량 제한을 넘었습니다 : ${_UPLOAD_FILE_LIMIT_TEXT}`);
//                     return;
//                 }
//                 uploadFile(writer, model, fileRepository, file);
//             }
//         });
//     }
// }
//
// // Handles uploading single file.
// //
// // @param {module:engine/model/writer~writer} writer
// // @param {module:engine/model/model~Model} model
// // @param {File} file
// function uploadFile(writer, model, fileRepository, file) {
//     //파일 별 loader instance를 생성 함.
//     const loader = fileRepository.createLoader(file);
//
//     if (!loader) {
//         return;
//     }
//
//     //loader가 file를 disk에서 read() 후 upload()로 server에 전송 함.
//     loader
//         .read()
//         .then(() => loader.upload())
//         .then((data) => {
//             const attributes = {
//                 linkHref: data.default,
//                 titleTarget: data.editor ? data.editor : ""
//             }
//
//             console.log(data.default);
//
//             //server에 return된 정보로 <a> tag로 되어 있는 file element를 생성 함.
//             const fileElement = writer.createText(file.name, attributes);
//
//             const insertAtSelection = findOptimalInsertionPosition(
//                 model.document.selection,
//                 model
//             );
//
//             //editor에 <a> tag로 되어 있는 file element를 삽입 함.
//             model.insertContent(fileElement, insertAtSelection);
//         });
// }

// https://simsimjae.tistory.com/340
class MyUploadAdapter {
    constructor({
                    loader,
                    uploadConfig,
                } ) {
        this.loader = loader;
        this.uploadConfig = uploadConfig;
    }
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {
                this._initRequest();
                this._initListeners( resolve, reject );
                this._sendRequest( file );
            } ) );
    }
    abort() {
        if ( this.worker ) {
            this.worker.postMessage({
                method: FileUploadWorkerRunMethod.cancel,
            });
        }
    }
    _initRequest() {
        this.worker = new Worker(new URL('./s3FileUpload.worker.js', import.meta.url));

        // const xhr = this.xhr = new XMLHttpRequest();
        // 여기서는 POST 요청과 json으로 응답을 받지만 어떤 포맷으로 하든 너의 선택이다.
        // xhr.open( 'POST', 'http://example.com/image/upload/path', true );
        // xhr.responseType = 'json';
    }
    //XHR 리스너 초기화 하기
    _initListeners( resolve, reject, file ) {
        const worker = this.worker;
        const loader = this.loader;


        // setUploadPromise({
        //     resolve,
        //     reject,
        // })
        worker.onmessage = ({data}) => {
            const method = data.method;
            if(method === FileUploadWorkerEventMethod.progress) {
                const {loaded, total} = data.data;
                loader.uploadTotal = total;
                loader.uploaded = loaded;
            }
            else if(method === FileUploadWorkerEventMethod.canceled) {
                // setState(FileUploadState.canceled);
                // console.log('canceled');
                // const e = new UploadAbortError();
                // uploadPromise?.reject?.(e);
                // setUploadPromise(null);
                reject();
            }
            else if(method === FileUploadWorkerEventMethod.error) {
                reject();
                // setState(FileUploadState.error);
                // console.error(data.data);
                // uploadPromise?.reject?.(data.data);
                // setUploadPromise(null);
            }
            else if(method === FileUploadWorkerEventMethod.uploaded) {
                // setState(FileUploadState.uploaded);
                // uploadPromise?.resolve?.();
                // setUploadPromise(null);
                resolve({
                    default: data.data.url,
                })
            }
            else {
                console.warn('unhandled message', data);
            }
        }


        // setData(data);

        // const genericErrorText = `Couldn't upload file: ${ file.name }.`;
        // xhr.addEventListener( 'error', () => reject( genericErrorText ) );
        // xhr.addEventListener( 'abort', () => reject() );
        // xhr.addEventListener( 'load', () => {
        //     const response = xhr.response;
        //     // 이 예제에서는 XHR서버에서의 response 객체가 error와 함께 올 수 있다고 가정한다. 이 에러는
        //     // 메세지를 가지며 이 메세지는 업로드 프로미스의 매개변수로 넘어갈 수 있다.
        //
        //     if ( !response || response.error ) {
        //         return reject( response && response.error ? response.error.message : genericErrorText );
        //     }
        //
        //     // 만약 업로드가 성공했다면, 업로드 프로미스를 적어도 default URL을 담은 객체와 함께 resolve하라.
        //     // 이 URL은 서버에 업로드된 이미지를 가리키며, 컨텐츠에 이미지를 표시하기 위해 사용된다.
        //     resolve( {
        //         default: response.url
        //     } );
        // } );
        //
        // // 파일로더는 uploadTotal과 upload properties라는 속성 두개를 갖는다.
        // // 이 두개의 속성으로 에디터에서 업로드 진행상황을 표시 할 수 있다.
        // if ( xhr.upload ) {
        //     xhr.upload.addEventListener( 'progress', evt => {
        //         if ( evt.lengthComputable ) {
        //             loader.uploadTotal = evt.total;
        //             loader.uploaded = evt.loaded;
        //         }
        //     } );
        // }
    }
    _sendRequest( file ) {

        const worker = this.worker;
        let data = {
            config: this.uploadConfig,
            path: `bbs/${getTodayPath()}`,
            file: {
                name: appendTimestamp(filenameCompat(file.name)),
                file: file,
            },
            bucket: 'banner',
        }


        worker.postMessage({
            method: FileUploadWorkerRunMethod.upload,
            data: data,
        });
        // 폼 데이터 준비
        // const data = new FormData();
        // data.append( 'upload', file );

        // 여기가 인증이나 CSRF 방어와 같은 방어 로직을 작성하기 좋은 곳이다.
        // 예를들어, XHR.setREquestHeader()를 사용해 요청 헤더에 CSRF 토큰을 넣을 수 있다.

        // this.worker.send( data );
    }
}

const getTodayPath = () => {
    let now = new Date();
    return dateFormat({
        value: now,
        format: 'yyyy/MM/dd',
    });
}

/**
 * Date를 format형식에 맞추어 텍스트 치환합니다.
 * @param {Date|string} value
 * @param {string} format
 * @param {[string]} apText 오전/오후 텍스트. 기본값 ['오전', '오후']
 * @param {[string]} weekdayText 요일 텍스트. 기본값 ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"]
 * @returns {string}
 */
export const dateFormat = ({value, format, apText, weekdayText}) => {
    if (value == null || !value.valueOf()) return " ";

    let _apText = apText ?? ['오전', '오후'];
    let _weekdayText = weekdayText ?? ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
    let d;
    if(value instanceof Date){
        d = value;
    }
    else {
        d = new Date(value);
    }
    let h;

    return format.replace(/(yyyy|yy|MM|M|dd|d|E|hh|mm|ss|a\/p)/gi, function ($1) {
        switch ($1) {
            case "yyyy":
                return d.getFullYear();
            case "yy":
                return (d.getFullYear() % 100);
            case "M":
                return  d.getMonth() + 1;
            case "MM":
                return  ('0' + (d.getMonth() + 1)).slice(-2);
            case "dd":
                return d.getDate().toString().padStart(2, '0');
            case "d":
                return d.getDate().toString();
            case "E":
                return _weekdayText[d.getDay()];
            case "HH":
                return ('0'+ d.getHours()).slice(-2);
            case "hh":
                return ((h = d.getHours() % 12) ? h : 12);
            case "mm":
                return ('0'+ d.getMinutes()).slice(-2);
            case "ss":
                return d.getSeconds().toString().padStart(2, '0');
            case "a/p":
                return d.getHours() < 12 ? _apText[0] : _apText[1];
            default:
                return $1;
        }
    });
};


class Uploader extends Plugin {
    init() {
        const editor = this.editor;
        const uploadConfig = editor.config.get( 'uploadConfig' );
        editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
            return new MyUploadAdapter({
                loader: loader,
                uploadConfig: uploadConfig,
            } );
        };
    }
}

export default Uploader;
