import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function usePathnameChange(callback) {
  const { pathname } = useLocation();
  const [oldPathname, setOldPathname] = useState();
  useEffect(() => {
    if (pathname !== oldPathname) {
      callback?.(pathname);
    }
    return () => {
      setOldPathname(pathname);
    };
  }, [pathname]);
}
