import { useQuery } from 'react-query';
import CompanyApi from '@/components/user/company/CompanyApi';

const QueryKey = {
  all: ['positionSearch'],
  filtered: (compCd) => [...QueryKey.all, compCd],
};

export const ShowPositionCompanyCode = ['20'];

export default function (compCd) {
  return {
    positionQuery: useQuery(QueryKey.filtered(compCd), {
      queryFn: async ({ queryKey: [_, _compCd] }) =>
        (await CompanyApi.getPositionList(_compCd)).data.map((data) => ({
          ...data,
          positionCd: data.positionCd.trim(),
        })),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }),
    get useRankTypeCd() {
      return ShowPositionCompanyCode.includes(compCd);
    },
  };
}
