import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  Autocomplete,
  CircularProgress,
  Collapse,
  createFilterOptions,
  FormControl,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';

export default function SearchableDropdown({
  id = 'dept_input',
  searchQuery,
  selectedItem,
  onSelectItem,
  noOptionsText,
  openOnlyOnText = true,
  ...inputProps
} = {}) {
  const inputRef = useRef();

  /**
   * 하단 메세지 표시 여부 계산
   * @type {boolean}
   */
  const showMessage = useMemo(() => {
    if (inputProps.message != null && inputProps.message !== '') {
      return true;
    } else {
      return false;
    }
  }, [inputProps.message]);
  const filterOptions = createFilterOptions({
    stringify: (option) => option.name,
  });

  const [searchKeyword, setSearchKeyword] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (openOnlyOnText) {
      if (searchKeyword.length > 0) {
        setOpen(true);
      }
    } else {
      setOpen(true);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setSearchKeyword(newInputValue);
    if (event == null) return;
    if (openOnlyOnText) {
      if (newInputValue.length > 0) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    setSearchKeyword('');
    onSelectItem(null);
  }, [id]);

  return (
    <div className={`outline_field ${inputProps.className}`}>
      <FormControl
        variant="outlined"
        className={`w-full ${inputProps.className}`}
      >
        <Autocomplete
          filterOptions={filterOptions}
          disablePortal
          openOnFocus={false}
          autoHighlight={true}
          open={open}
          onOpen={handleOpen}
          onInputChange={handleInputChange}
          onClose={() => setOpen(false)}
          noOptionsText={noOptionsText}
          id={`${id}_autocomplete`}
          key={`${id}_autocomplete`}
          options={searchQuery.data ?? []}
          loading={searchQuery.isLoading}
          loadingText="불러오는중.."
          value={selectedItem}
          getOptionLabel={(option) => option.name}
          onChange={(e, newValue) => onSelectItem(newValue)}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                {option.name}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...inputProps}
              {...params}
              // value={searchKeyword}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {searchQuery.isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              // onChange={(e) => setSearchKeyword(e.target.value)}
              inputRef={inputRef}
              id={id}
            />
          )}
        />
      </FormControl>
      {/*메세지 영역*/}
      <Collapse in={showMessage}>
        <div
          className={`outline_field_message ${inputProps.className}`}
          style={{ height: '100%', padding: '10px 16px' }}
        >
          {inputProps.message}
        </div>
      </Collapse>
    </div>
  );
}
