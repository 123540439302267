import MyProfileImage from '@/components/user/MyProfileImage';
import { useEffect, useRef, useState } from 'react';
import useMobileNavigation from '@/components/navigation/useMobileNavigation';
import { getBytes } from '@/lib/stringUtil';
import usePlatform from '@/lib/usePlatform';

export default function LiveChatInput({ nickNm, onSend, isAdmin }) {
  const { setChatBlur, setChatFocused } = useMobileNavigation();
  const inputRef = useRef(null);
  const [chat, setChat] = useState('');
  const [isAnnounce, setIsAnnounce] = useState(false);
  const { isChromeOnMacOs, isMobile } = usePlatform();
  const [prevInput, setPrevInput] = useState('');
  const otherArea = useRef();

  const sendChat = (message) => {
    if (message == null || message.trim().length === 0) {
      return;
    }
    onSend(message, isAnnounce);
    setChat('');
    setIsAnnounce(false);
    clearInputBuffer();
    if (isMobile) {
      inputRef.current?.blur();
    }
  };

  const clearInputBuffer = () => {
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'text');
    hiddenInput.style.position = 'absolute';
    hiddenInput.style.width = '0px';
    hiddenInput.style.height = '0px';
    hiddenInput.style.opacity = '0';
    inputRef.current?.parentElement.prepend(hiddenInput);
    hiddenInput.focus();

    inputRef.current?.focus();
    hiddenInput.remove();
  };

  useEffect(() => {
    setChatBlur();
    return () => {
      setChatBlur();
    };
  }, []);

  return (
    <div className="chat_filed !relative">
      <ul className="flex flex-col">
        <li className="flex">
          <MyProfileImage className="mr-3" useUserContentLink={false} />
          <span className="text">
            <strong className="nick w500 mr-1">{nickNm ?? ''}</strong>
          </span>
        </li>
      </ul>
      <input
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
            sendChat(e.target.value);
          }
        }}
        onKeyUp={(e) => {
          if (!isChromeOnMacOs) return;
          const value = e.currentTarget.value;
          const currLastCharBytes = getBytes(value.substring(value.length - 1));
          const prevLastCharBytes = getBytes(
            prevInput.substring(prevInput.length - 1)
          );
          // 전각 -> 반각으로 갈 때
          if (
            prevLastCharBytes === 2 &&
            currLastCharBytes === 1 &&
            e.key !== 'Enter'
          ) {
            clearInputBuffer();
          }
          setPrevInput(value);
        }}
        className="my-2"
        type="text"
        placeholder="대화를 입력하세요"
        onFocus={() => {
          setChatFocused();
        }}
        onBlur={() => {
          setChatBlur();
        }}
        value={chat}
        onChange={({ target }) => setChat(target.value?.slice(0, 200))}
      />
      <div className="flex items-center justify-between" ref={otherArea}>
        <div className="flex all_agree_wrap">
          {isAdmin && (
            <>
              <input
                id="notice_check"
                type="checkbox"
                checked={isAnnounce}
                onChange={({ target }) => setIsAnnounce(target.checked)}
              />
              <label htmlFor="notice_check">공지사항</label>
            </>
          )}
        </div>
        <div className="flex items-center">
          <span className="text_count mr-3">{chat.length}/200</span>
          <button
            type="button"
            className="submit_btn"
            disabled={chat.length < 1}
            onClick={() => sendChat(chat)}
          >
            전송
          </button>
        </div>
      </div>
    </div>
  );
}
