import SearchForm from './SearchForm';
import { Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closeSearchSideBar } from '../sidebarReducer';
import usePathnameChange from '../hook/usePathnameChange';

export default function SearchSideBar({}) {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.sidebarReducer.isSearchOpen);

  usePathnameChange(() => {
    close();
  });

  const close = () => {
    dispatch(closeSearchSideBar());
  };

  return (
    <>
      <Drawer
        anchor={'top'}
        open={isOpen}
        onClose={close}
        ModalProps={{
          BackdropProps: {
            sx: {
              backgroundClip: 'content-box',
              paddingTop: '96px',
            },
          },
          sx: {
            '& .MuiPaper-root': {
              position: 'absolute',
              top: '96px',
            },
          },
        }}
        PaperProps={{
          sx: {
            boxShadow: 'unset',
          },
        }}
      >
        <SearchForm />
      </Drawer>
    </>
  );
}
