import { useEffect, useMemo, useState } from 'react';
import BannerApi from '@/components/banner/BannerApi';

export default function useLiveBanner({ cid }) {
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);

  // TODO 배포 url 문제로, 배너 origin 임시 치환
  const bannerLinkFix = (link) => {
    return link
      .replace('https://wooriwbn.com', window.location.origin)
      .replace('http://wooriwbn.com', window.location.origin)
      .replace('http://wbn.castware.co.kr', window.location.origin)
      .replace('https://wbn.castware.co.kr', window.location.origin);
  };

  const load = async () => {
    setIsLoading(true);
    try {
      const res = await BannerApi.getLiveBanner({ cid });
      // const res = await BannerApi.getLiveBanner({cid: 10004838}); // test
      if (res?.code === '00') {
        setList(
          res.data?.map((it) => ({
            ...it,
            bannerLink: bannerLinkFix(it.bannerLink),
          }))
        );
      }
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  const noticeEvent = useMemo(() => {
    return {
      use: list.length > 0,
      active: list.length > 0,
      list: list.map((it, index) => ({
        src: it.bannerPath1,
        index: index,
      })),
      clickCallback: function (e, index) {
        window.open(list[index].bannerLink, '_blank');
      },
    };
  }, [list]);

  useEffect(() => {
    if (cid != null) {
      load();
    }
  }, [cid]);

  return {
    isLoading,
    list,
    noticeEvent,
  };
}
