import { CircularProgress } from '@mui/material';

/**
 * @template T
 * @param {T[]} dataList
 * @param chunkSize 레이아웃에 보여줄 한 그룹의 사이즈
 * @param parentClassName 부모 스타일
 * @param {(item:T, idx: number) => void} itemRender
 * @param isLoading
 * @param isLast
 * @param lastContentMessage
 * @param {React.ReactNode} children
 * @return {React.ReactNode}
 */
export default function LoadingLastWrapper({
  isLoading,
  isLast,
  lastContentMessage,
  children,
}) {
  return (
    <>
      {children}
      {isLoading && (
        <div className="w-full flex items-center justify-center">
          <CircularProgress className="loading" />
        </div>
      )}
      <div className="w-full flex items-center justify-center">
        {!isLoading && isLast && lastContentMessage && (
          <span className="pt-9 justify-center co_gray_aaa f14_w300 mb-10">
            {lastContentMessage}
          </span>
        )}
      </div>
    </>
  );
}
