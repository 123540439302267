import PageIndicator from '@/components/content/paging/PageIndicator';
import ProfileImage from '@/components/user/ProfileImage';
import useFollowing from '@/components/user/my/useFollowing';
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import MyPageHeader from '@/page/my/MypageHeader';
import { useMyPagePaging } from '@/page/my/tabs/useMyPagePaging';
import usePage from '@/components/infiniteScroll/usePage';
import { NotLoggedInError } from '@/components/user/login/LoginApi';
import Popup from '@/components/popup/Popup';

export default function Following() {
  const [isSelectMode, setSelectMode] = useState(false);
  const [checked, setChecked] = useState(new Set());
  const [popup, setPopup] = useState(null);
  const [historyPage, _] = usePage();
  const { isLoading, page, list, load, unsubscribeList, total } = useFollowing({
    initialPage: historyPage,
  });
  const { setPage } = useMyPagePaging({
    loader: load,
    page,
    isStartFromOne: false,
  });

  const onCheck = (e, userNo) => {
    if (e.target.checked) {
      setChecked(new Set([...checked, userNo]));
    } else {
      setChecked(new Set([...checked].filter((it) => it !== userNo)));
    }
  };

  const selectAll = () => {
    setChecked(new Set(list.map((it) => it.userNo)));
  };

  const onDelete = async () => {
    try {
      let list = [...checked];
      if (list.length === 0) {
        return;
      }
      await unsubscribeList(list);
    } catch (e) {
      console.error(e);
      setPopup({
        title: '구독 알림',
        message: e.message,
        okCallback: () => {
          setPopup(null);
        },
      });
    } finally {
      setChecked(new Set());
      await load();
      setSelectMode(false);
    }
  };

  return (
    <div>
      <MyPageHeader
        option={{
          title: '구독중',
          count: total,
          selectCount: checked.size,
          isDeleteMode: isSelectMode,
          onEnterDeleteMode: () => setSelectMode(true),
          onCancel: () => {
            setSelectMode(false);
            setChecked(new Set());
          },
          onAllSelect: () => selectAll(),
          onDelete: () => {
            onDelete();
          },
        }}
      />
      <div className="box_wrap grid sm:gap-3 sm:gap-y-2">
        <div className="lg:min-h-[400px]">
          {isLoading && (
            <div className="h-72 w-full flex items-center justify-center">
              <CircularProgress className="loading" />
            </div>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-4 ">
            {!isLoading &&
              list?.map((it) => (
                <label
                  key={it.userNo}
                  htmlFor={it.userNo}
                  className="flex following_box items-center"
                >
                  <ProfileImage
                    className="profile_img mr-6"
                    profileUrl={it.profileUrl}
                    profileSt={it.profileSt}
                    userNo={it?.userNo}
                  />
                  <div>
                    <p>{it.nickNm}</p>
                    <p className="f14_w300">우리은행</p>
                  </div>
                  {isSelectMode && (
                    <div className="all_agree_wrap2 absolute right-4 top-4 w-6 h-6 ">
                      <input
                        id={it.userNo}
                        type="checkbox"
                        checked={checked.has(it.userNo)}
                        onChange={(e) => onCheck(e, it.userNo)}
                      />
                      <label htmlFor={it.userNo} className="h-full"></label>
                    </div>
                  )}
                </label>
              ))}
          </div>
        </div>

        <PageIndicator
          forcePage={true}
          option={{
            page,
            totalCount: total,
            pageSize: 9,
            showingPageCount: 5,
            onPageChange: setPage,
          }}
        />
      </div>
      {popup != null && <Popup {...popup} />}
    </div>
  );
}
