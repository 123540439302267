import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import { SearchApi } from '@/components/search/SearchApi';

/**
 * 데이터를 조회하는 함수의 매개변수 타입
 *
 * @typedef {Object} Param
 * @property {number} page - 페이지 번호
 * @property {number} size - 한 페이지에 표시될 아이템 수
 * @property {any} [otherFields] - 기타 다이나믹한 타입의 필드
 */

/**
 * @typedef {Object} Page
 * @property {any[]} res - 페이지의 요소들
 * @property {number} nextPage
 * @property {number} prevPage
 */

/**
 * @typedef {Object} InfiniteQueryResult
 * @property {boolean} isLoading
 * @property {boolean} initLoadCompleted
 * @property {any[]} list
 * @property {boolean} hasError
 * @property {any} error
 * @property {boolean} isLast
 * @property {function} refetch
 * @property {function} loadMore
 * @property {number} total
 * @property {number} page
 */

const InfiniteQueryKey = {
  all: ['infiniteSearch'],
  firstData: (filters) => [...InfiniteQueryKey.all, 'firstData', filters],
  filtered: (filters) => [...InfiniteQueryKey.all, 'filtered', filters],
};

function isLastPage(list, pageSize) {
  if (list == null) return false;
  return (list.length ?? 0) < pageSize;
}

/**
 *
 * @param {(params: Param) => Page} fn - 데이터를 요청하는 함수.
 * @param {number} pageParam - useInfiniteQuery에서 주입하는 pageParam 혹은 page 자체.
 * @param {Param} param - 데이터를 요청할 때 이용할 파라미터. page 필드는 없어도 됩니다.
 * @param {function} ParamClass - 인터페이스를 맞추기 위해 파라미터 클래스의 생성자를 넘겨 받을 수 있습니다.
 * @return {Promise<Page>}
 * @constructor
 */
const PageWrapper = async (fn, { pageParam }, param, ParamClass) => {
  return {
    res: await fn(
      convertToClassIfExist({ ...param, page: pageParam }, ParamClass)
    ),
    nextPage: pageParam + 1,
    prevPage: pageParam - 1,
  };
};

const convertToClassIfExist = (object, constructor) => {
  return constructor ? new constructor(object) : object;
};

export const makeFlatPages = (pages) => {
  return pages.map((page) => page?.res?.data ?? []).flat();
};
/**
 * @param {Object} options
 * @param {{all: string[], firstData:(filters:any) => string[], filtered: (filters:any) => string[], }} options.QueryKeyMap - 쿼리키들을 담은 오브젝트
 * @param {(params: Param) => Page} options.dataFn - 파라미터를 받아 단일 페이지를 뱉는 함수. async-like 입니다.
 * @param {Param} options.initialParam - 최초 파라미터. 기본값으로, 첫 페이지나 검색타입 등을 정의해둘 수 있습니다.
 * @param {function} options.ParamClass - 인터페이스를 맞추기 위해 파라미터 클래스의 생성자를 넘겨 받을 수 있습니다.
 * @param {} options.enabled
 */
export default function useInfiniteQueryWithInitialData({
  QueryKeyMap,
  dataFn,
  initialParam,
  ParamClass,
  enabled = true,
}) {
  const queryClient = useQueryClient();

  const [param, setParam] = useState(initialParam);
  const [initLoadCompleted, setInitLoadCompleted] = useState(false);

  const setParamAndReload = (fieldName, value) => {
    setParam((prev) => ({ ...prev, [fieldName]: value }));
    setInitLoadCompleted(false);
    queryClient.removeQueries(QueryKeyMap.all);
  };

  const initialDataQuery = useQuery(
    QueryKeyMap.firstData({
      ...param,
      page: undefined,
    }),
    async ({ queryKey: [_, __, params] }) => {
      console.log(param);
      const result = await dataFn(
        convertToClassIfExist(
          {
            ...params,
            page: 0,
            size:
              param.size * (initialParam.page === 0 ? 1 : initialParam.page),
          },
          ParamClass
        )
      );
      return { ...result, list: result.data, total: result.total };
    },
    {
      enabled: !initLoadCompleted && enabled,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    }
  );

  // console.log("에러: ", initialDataQuery.isError, initialDataQuery.error);
  const infiniteLoadingRequired = useMemo(
    () => param.size < (initialDataQuery?.data?.total ?? 0),
    [param.size, initialDataQuery.data?.total]
  );

  // console.log("initialQuery data:", initialDataQuery.data, initialParam);

  const infiniteQuery = useInfiniteQuery(
    QueryKeyMap.filtered({ ...param, page: undefined }),
    async ({ pageParam = initialParam.page + 1, queryKey: [_, __, param] }) => {
      return await PageWrapper(
        dataFn,
        { pageParam },
        {
          ...param,
          page: pageParam,
        },
        ParamClass
      );
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return isLastPage(lastPage?.res?.data ?? [], param.size)
          ? undefined
          : lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage, allPages) => {
        return (firstPage?.prevPage ?? -1) === -1
          ? undefined
          : firstPage.prevPage;
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled:
        !initLoadCompleted &&
        infiniteLoadingRequired &&
        initialDataQuery.isSuccess,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    }
  );

  const {
    isLoading,
    data,
    error,
    isError,
    refetch,
    hasNextPage,
    fetchNextPage,
  } = infiniteQuery;

  useEffect(() => {
    if (initialDataQuery.isSuccess && infiniteQuery.isSuccess) {
      setInitLoadCompleted(true);
    }
  }, [
    initialDataQuery.status,
    infiniteQuery.status,
    infiniteQuery.data,
    initialDataQuery.data,
  ]);

  const initialList = useMemo(
    () => initialDataQuery?.data?.list ?? [],
    [initialDataQuery?.data?.list]
  );

  const list = useMemo(() => {
    return [
      ...initialList,
      ...(makeFlatPages(infiniteQuery.data?.pages ?? []) ?? []),
    ];
  }, [initialList, infiniteQuery.data?.pages]);

  const newPage =
    infiniteQuery.data?.pageParams?.[
      infiniteQuery.data.pageParams.length - 1
    ] ?? 0;
  const returnValue = {
    isLoading: isLoading || infiniteQuery.isFetching,
    initLoadCompleted:
      initLoadCompleted ||
      (!infiniteLoadingRequired && initialDataQuery.isSuccess),
    list,
    hasError: isError,
    isLast: !hasNextPage,
    refetch,
    loadMore: () => {
      if (
        !infiniteQuery.isFetching &&
        !infiniteQuery.isLoading &&
        initLoadCompleted &&
        hasNextPage
      )
        fetchNextPage();
    },
    total: initialDataQuery.data?.total ?? 0,
    page: newPage - 1 < 0 ? initialParam.page : newPage - 1,
  };

  useEffect(() => {
    console.log('[useInfiniteFakeQuery]', returnValue);
  }, [infiniteQuery.data, isLoading]);

  return {
    initLoadCompleted,
    setParamAndReload,
    ...returnValue,
    initialDataQuery,
    infiniteQuery,
  };
}
