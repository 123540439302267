import { forwardRef } from 'react';
import useLogin from '@/components/user/login/useLogin';
import { LiveChat } from '@/components/ucms/chat/live/LiveChat';
import { ChatLogList } from '@/components/ucms/chat/log/ChatLogList';

export const VideoChat = forwardRef(({ cid, isLive }, tabContentRef) => {
  const { isLoggedIn } = useLogin();

  return (
    <>
      {isLive && isLoggedIn && (
        <LiveChat cid={cid} isLive={isLive} ref={tabContentRef} />
      )}
      {!isLive && isLoggedIn && <ChatLogList cid={cid} ref={tabContentRef} />}
    </>
  );
});
