import useWindowDimensions from '@/lib/useWindowDimensions';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import classNames from 'classnames';

export default function LikeBBSTableRow({
  id,
  link,
  isSelected,
  onSelectChange,
  category,
  title,
} = {}) {
  const navigate = useNavigate2();

  return (
    <tr
      className={classNames({
        'tr_wrap grid-cols-12 grid-rows-full': true,
        'cursor-pointer': link != null,
      })}
      onClick={(e) => {
        console.log(e.target);
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'LABEL') {
          return true;
        }
        if (link != null) {
          navigate(link);
        }
      }}
    >
      <td className="sm:col-span-2 col-span-1  row-span-full row-start-1 check_td">
        <div className="thumb_check">
          <input
            id={`${id}_check`}
            className="id_save"
            type="checkbox"
            checked={isSelected === true}
            onChange={(e) => {
              // e.preventDefault();
              e.stopPropagation();
              onSelectChange(e.target.checked);
            }}
          />
          <label htmlFor={`${id}_check`} className="check_text"></label>
        </div>
      </td>

      {category != null && (
        <td className="td_sub_tit  col-span-full col-start-1 row-start-1 mb-1">
          {category}
        </td>
      )}
      {title != null && (
        <td className="td_tit col-start-1 col-span-12 row-start-2">{title}</td>
      )}
    </tr>
  );
}
