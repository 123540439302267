import useContentInfo, {
  ContentState,
} from '@/components/content/useContentInfo';
import { CircularProgress } from '@mui/material';
import UcmsVideoDetailPage from '@/page/ucms/UcmsVideoDetailPage';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import UcmsPostDetailPage from '@/page/ucms/UcmsPostDetailPage';
import useMenu from '@/components/menu/useMenu';
import ContentPassword from '@/components/content/password/ContentPassword';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { ContentType } from '@/app/config/ContentType';
import useLogin from '@/components/user/login/useLogin';
import { openSideBar } from '@/components/sidebar/sidebarReducer';
import { useDispatch } from 'react-redux';
import { PopupContainer } from '@/components/popup/Popup';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import useNoContextMenu from '@/components/common/ux/NoContextMenu';
import useNoImageDrag from '@/components/common/ux/NoImageDrag';
import { history } from '@/app/App';
import { pop } from '@/components/ucms/detailHistoryReducer';
import UcmsLMSVideoDetailPage from '@/page/ucms/UcmsLMSVideoDetailPage';

export default function UcmsDetailContainer() {
  useNoImageDrag();
  useNoContextMenu();

  const { pathname } = useLocation2();
  const navigate = useNavigate2();
  const dispatch = useDispatch();
  const { isLoggedIn } = useLogin();
  const { state } = useLocation2();
  const { menuList, findMenu } = useMenu();
  const props = useContentInfo();
  const { isInitLoading, data, replies, load } = props;

  const { id: cid, menuId } = useParams();
  const menu = useMemo(() => {
    return findMenu(menuId); // 권한 없는 메뉴도 비공개 글인 경우 접근 가능
  }, [props]);

  const contentType = useMemo(() => {
    if (menu == null) {
      return null;
    }
    return menu.contentType;
  }, [menu]);

  const reload = () => {
    load(menuId, cid, contentType, state?.password, true);
  };
  const onPasswordSubmit = (password) => {
    load(menuId, cid, contentType, password, true);
  };

  useEffect(() => {
    reload();
  }, [menu, isLoggedIn, cid]);

  useEffect(() => {
    return history.listen(({ action }) => {
      if (action === 'POP') dispatch(pop(pathname));
    });
  }, []);

  if (isInitLoading || props.contentState === ContentState.loading) {
    return (
      <div className="w-full h-[80vh] flex items-center justify-center">
        <CircularProgress className="loading" />
      </div>
    );
  }

  if (props.contentState !== ContentState.success) {
    let option = {
      okMessage: '홈으로 가기',
      okCallback: () => {
        navigate('/', { replace: true });
      },
    };

    if (props.contentState === ContentState.needPassword) {
      return <ContentPassword onSubmit={onPasswordSubmit} />;
    } else if (props.contentState === ContentState.needPermission) {
      if (isLoggedIn) {
        option = {
          ...option,
          title: '조회 권한이 없습니다',
        };
      } else {
        option = {
          ...option,
          title: '조회 권한이 없습니다',
          okMessage: '로그인 하기',
          okCallback: () => {
            dispatch(openSideBar());
          },
        };
      }
    } else if (props.contentState === ContentState.hided) {
      option = {
        ...option,
        title: '숨겨진 항목입니다.',
      };
    } else if (props.contentState === ContentState.hidden) {
      option = {
        ...option,
        title: '비공개 항목입니다.',
      };
    } else if (props.contentState === ContentState.notFound) {
      option = {
        ...option,
        title: '찾을 수 없는 항목입니다.',
      };
    } else if (props.contentState === ContentState.deleted) {
      option = {
        ...option,
        title: '삭제된 항목입니다.',
      };
    } else if (props.contentState === ContentState.needLogin) {
      option = {
        ...option,
        title: '로그인이 필요합니다.',
        okMessage: '로그인 하기',
        okCallback: () => {
          dispatch(openSideBar());
        },
      };
    } else if (props.contentState === ContentState.error) {
      if (menu == null) {
        option = {
          ...option,
          title: '잘못된 접근입니다.',
        };
      } else {
        throw Error('컨텐츠 로드 오류'); // ErrorBoundary로 처리
      }
    }

    return (
      <div>
        <div className="w-full h-[80vh] flex items-center justify-center"></div>
        <PopupContainer className="!z-0" {...option} />
      </div>
    );
  }

  if (contentType === ContentType.VOD || contentType === ContentType.VOD_USER) {
    if (isInitLoading) {
      return (
        <div className="w-full flex items-center justify-center">
          <CircularProgress className="loading" />
        </div>
      );
    }
    return (
      <UcmsVideoDetailPage
        {...props}
        dragLockYn={menu.dragLockYn}
        onWriteComment={async (...args) =>
          await props.onWriteComment(menuId, ...args)
        }
      />
    );
  } else if (contentType === ContentType.EDU_VOD) {
    if (isInitLoading) {
      return (
        <div className="w-full flex items-center justify-center">
          <CircularProgress className="loading" />
        </div>
      );
    }
    return (
      <UcmsLMSVideoDetailPage
        {...props}
        dragLockYn={menu.dragLockYn}
        onWriteComment={async (...args) =>
          await props.onWriteComment(menuId, ...args)
        }
      />
    );
  } else {
    if (isInitLoading) {
      return (
        <div className="w-full flex items-center justify-center">
          <CircularProgress className="loading" />
        </div>
      );
    }
    return (
      <UcmsPostDetailPage
        {...props}
        dragLockYn={menu.dragLockYn}
        onWriteComment={async (...args) =>
          await props.onWriteComment(menuId, ...args)
        }
      />
    );
  }
}
