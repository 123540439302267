import { useDispatch, useSelector } from 'react-redux';
import LoginUtil from './LoginUtil';
import LoginApi from '@/components/user/login/LoginApi';
import {
  setSessionLoginFail,
  setShowLoginExpired,
  setUserLogin,
} from '@/components/user/login/LoginReducer';
import AuthUtil from '@/components/auth/AuthUtil';
import { RequiredFields } from '@/page/my/MyTermUpdate';
import { UserType } from '@/components/join/JoinApi';
import useEasyLogin from '@/components/easyLogin/useEasyLogin';
import useDebugLog from '@/components/debug/useDebugLog';
import useBio from '@/components/easyLogin/bio/useBio';
import usePin from '@/components/easyLogin/pin/usePin';
import useWindowDimensions from '@/lib/useWindowDimensions';
import uuid from 'react-uuid';
import { setIsTryBioLogin } from '@/components/easyLogin/EasyLoginReducer';

export default function useLogin() {
  const { isWebview, isMobile, width } = useWindowDimensions();
  const dispatch = useDispatch();
  const loginResult = useSelector((state) => state.loginReducer.loginResult);
  const isSessionLoading = useSelector(
    (state) => state.loginReducer.sessionLoading
  );

  const { bioRegistered } = useBio();
  const { pinRegistered } = usePin();
  const { isRegistered, tryEasyLogin } = useEasyLogin();

  const { sendLog } = useDebugLog();

  const isSessionLogin = useSelector(
    (state) => state.loginReducer.isSessionLogin
  );

  /**
   *
   * @returns {Promise<{result: boolean, type: string}|{result: boolean, type: string, error}|{result: (Promise<boolean|undefined|void>|*), type: string}>}
   */
  const tryLogin = async () => {
    let success = false;
    let type = null;
    let code = null;
    let error = null;
    let logData = {
      location: 'useLogin.tryLogin start',
      ...(await LoginUtil.getDebugInfo()),
      previousLoginCode: LoginUtil.loginCode(loginResult),
      previousIsLoggedIn: LoginUtil.loginCode(loginResult) === '00',
      previousIsSessionLogin: isSessionLogin,
      easyLoginRegistered: isRegistered,
      bioRegistered,
      pinRegistered,
      isWebview,
      isMobile,
      screenWidth: width,
      userAgent: window.navigator.userAgent,
      code,
      type,
      success,
      error,
      transactionId: uuid(),
    };
    sendLog(logData);

    try {
      type = 'session';
      let sessionResult = await fetchSession();
      code = sessionResult?.data?.code;
      if (sessionResult?.data?.code === '00') {
        success = true;
        if (loginResult?.data == null) {
          dispatch(setUserLogin(sessionResult));
          dispatch({
            type: 'SESSION_CHECK_SUCCESS',
          });
        }

        logData = {
          ...logData,
          location: 'useLogin.tryLogin.session.alive',
          code,
          type,
          success,
          error,
        };
        sendLog(logData);

        return { type: type, result: success };
      } else {
        logData = {
          ...logData,
          location: 'useLogin.tryLogin.session.expired',
          code,
          type,
          success,
          error,
        };
        sendLog(logData);
      }

      if (isRegistered) {
        dispatch({
          type: 'SESSION_CHECK',
        });
        try {
          type = 'easylogin';
          try {
            type = 'easylogin';

            dispatch(setIsTryBioLogin(true));
            success = await tryEasyLogin();
          } catch (e) {
            error = e;
            success = false;
          } finally {
            dispatch(setIsTryBioLogin(false));
          }
        } catch (e) {
          error = e;
          success = false;
        }
      }
    } catch (e) {
      error = e;
    }

    dispatch({
      type: success ? 'SESSION_CHECK_SUCCESS' : 'SESSION_CHECK_ERROR',
    });

    if (!success) {
      dispatch(setSessionLoginFail());
      if (loginResult?.data != null) {
        dispatch(setShowLoginExpired(true));
      }
    } else {
      dispatch(setShowLoginExpired(false));
    }

    logData = {
      ...logData,
      location: 'useLogin.tryLogin.end',
      ...(await LoginUtil.getDebugInfo()),
      easyLoginRegistered: isRegistered,
      bioRegistered,
      pinRegistered,
      isWebview,
      isMobile,
      screenWidth: width,
      userAgent: window.navigator.userAgent,
      code,
      type,
      success,
      error,
    };
    sendLog(logData);

    return { type: type, result: success, error };
  };

  /**
   * @deprecated
   * @returns {Promise<boolean>}
   */
  const checkSession = async () => {
    let isSessionOk = false;
    const res = await fetchSession();
    isSessionOk = res?.data?.code === '00';
    console.log('checksession', isSessionOk, loginResult);
    if (isSessionOk && loginResult?.data == null) {
      console.log('checksession', loginResult?.data);
      dispatch(setUserLogin(res));
    }
    return isSessionOk;
  };

  const fetchSession = async () => {
    const { userId, sessionKey, apiAuthKey } =
      await AuthUtil.getOneTimeSessionKey();
    return await LoginApi.sessionLogin({
      userId,
      sessionKey,
      apiAuthKey,
    });
  };

  /**
   * @deprecated
   * 사이트가 최초 로딩될 때 전역 Container 에서 세션 상태를 확인하는 용도에요.
   * ⚠️이 함수를 오용할 경우 라우트 동작이 이상해질 수 있습니다!!
   */
  const sessionLogin = async () => {
    dispatch({ type: 'SESSION_CHECK' });
    const isSessionOk = await checkSession();
    dispatch({
      type: isSessionOk ? 'SESSION_CHECK_SUCCESS' : 'SESSION_CHECK_ERROR',
    });
    return isSessionOk;
  };

  /**
   * @deprecated
   * 완전히 로드 되고 작동중일 때 사용하는 용도에요.
   */
  const checkSessionValidity = async () => {
    return await checkSession();
  };

  const shouldGoTermPage = () => {
    let data = LoginUtil.getLoginInfo()?.data?.[0];
    if (data == null) {
      return false;
    }
    for (let i in RequiredFields) {
      let key = RequiredFields[i];
      if (data[key] !== 'Y') {
        return true;
      }
    }
  };

  const shouldEditEmployInfo = () => {
    let data = LoginUtil.getLoginInfo()?.data?.[0];
    if (data == null) {
      return false;
    }
    if (data.userType == UserType.normal) {
      return false;
    }
    return (
      data.wbnEmpNo == null ||
      data.wbnEmpNo === '' ||
      data.wbnDeptCd == null ||
      data.wbnDeptCd === '' ||
      data.wbnRankTypeCd == null ||
      data.wbnRankTypeCd === '' ||
      data.wbnPositionCd == null ||
      data.wbnPositionCd === ''
    );
  };

  const shouldEditMyInfo = () => {
    let data = LoginUtil.getLoginInfo()?.data?.[0];
    if (data == null) {
      return false;
    }
    if (data.userType == UserType.normal) {
      return false;
    }
    if ('Y' !== data['flag_update']) {
      return true;
    }
  };

  return {
    isLoggedIn: LoginUtil.loginCode(loginResult) === '00',
    isLoading: (loginResult?.data?.isLoading ?? false) || isSessionLoading,
    errorMessage: loginResult?.data?.data?.message,
    code: loginResult?.data?.data?.code,
    get data() {
      return LoginUtil.getLoginInfo()?.data?.[0];
    },
    get visibleMenu() {
      return LoginUtil.getLoginInfo()?.compmenu ?? [];
    },
    get manageMenu() {
      return LoginUtil.getLoginInfo()?.adminmenu ?? [];
    },
    get menuAdmin() {
      return LoginUtil.getLoginInfo()?.menuAdmin?.map((it) => it.menuId) ?? [];
    },

    // getData,
    tryLogin,
    sessionLogin,
    isSessionLoading,
    isSessionLogin,
    checkSessionValidity,
    shouldGoTermPage,
    shouldEditEmployInfo,
    shouldEditMyInfo,
  };
}
