import { Collapse, Drawer } from '@mui/material';
import useMenu from '@/components/menu/useMenu';
import { useDispatch, useSelector } from 'react-redux';
import { closeMenuSideBar } from '@/components/sidebar/sidebarReducer';
import { useState } from 'react';
import { openThankYouTokenSideBar } from '@/components/sidebar/thankYouTokenReducer';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import Link2 from '@/components/common/transition/Link2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import { openWonderingSideBar } from '@/components/sidebar/wonderingReducer';

export default function MobileSidebarSiteMap() {
  const dispatch = useDispatch();
  const navigate = useNavigate2();
  const isOpen = useSelector((state) => state.sidebarReducer.isMenuOpen);
  const [collapseOpenMenu, setCollapseOpenMenu] = useState();

  const {
    rootMenuList,
    isThankYouTokenMenu,
    findMenu,
    findChildrenWithChild,
    findChildrenWithoutChild,
    isWonderingMenu,
  } = useMenu();

  const onClose = () => {
    dispatch(closeMenuSideBar());
  };

  const onMenuClick = (menu, collapse = false) => {
    if (isThankYouTokenMenu(menu)) {
      onClose();
      dispatch(openThankYouTokenSideBar());
    } else if (isWonderingMenu(menu)) {
      onClose();
      dispatch(openWonderingSideBar());
    } else if (collapse) {
      if (collapseOpenMenu === menu) {
        setCollapseOpenMenu(null);
      } else {
        setCollapseOpenMenu(menu);
      }
    } else {
      let parent = findMenu(menu.parentId);
      parent = findMenu(parent?.parentId) ?? parent;

      if (
        parent?.menuId == null ||
        parent?.menuId === '' ||
        parent?.menuId === '0'
      ) {
        navigate(`/ucms/${menu.menuId}`);
      } else {
        navigate(`/ucms/${parent.menuId}/${menu.menuId}`);
      }
      onClose();
    }
  };

  return (
    <div>
      <Drawer
        anchor={'left'}
        open={isOpen}
        onClose={onClose}
        ModalProps={{
          sx: {
            zIndex: 1301, // 헤더 보다 높게
          },
        }}
      >
        <div className="bg-white w-[100vw] h-[100vh]">
          <div className="flex justify-between">
            <span
              className="pl-8 p-4"
              style={{
                fontSize: '24px',
                fontWeight: '800',
              }}
            >
              전체메뉴
            </span>
            <button className="p-4" onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
          {rootMenuList.map((it, index) => (
            <div key={`mobile_sidebar_root_container_${index}`}>
              <Link2
                key={`mobile_sidebar_root_${index}`}
                className="pl-8 p-4 block"
                style={{
                  fontSize: '16px',
                  fontWeight: '400',
                }}
                onClick={(e) => onMenuClick(it)}
              >
                {it.menuNm}
              </Link2>
              {findChildrenWithoutChild(it).map((it, index2) => (
                <Link2
                  key={`mobile_sidebar_${index}_${index2}`}
                  className="pl-14 p-2 block"
                  style={{
                    fontSize: '15px',
                    fontWeight: '300',
                  }}
                  onClick={(e) => onMenuClick(it)}
                >
                  {it.menuNm}
                </Link2>
              ))}
              {findChildrenWithChild(it).map((it, index2) => (
                <div key={`mobile_sidebar_${index}_${index2}_parent_container`}>
                  <Link2
                    key={`mobile_sidebar_${index}_${index2}_parent`}
                    className="pl-14 p-2 pr-8 block"
                    onClick={(e) => onMenuClick(it, true)}
                  >
                    <div className="flex">
                      <span
                        className="flex-1"
                        style={{
                          fontSize: '15px',
                          fontWeight: '300',
                        }}
                      >
                        {it.menuNm}
                      </span>
                      <ExpandMoreIcon
                        className={classNames({
                          'rotate-180': collapseOpenMenu === it,
                        })}
                        style={{
                          transition: '0.4s',
                        }}
                      />
                    </div>
                  </Link2>
                  <Collapse in={collapseOpenMenu === it}>
                    {findChildrenWithoutChild(it).map((it, index3) => (
                      <Link2
                        key={`mobile_sidebar_${index}_${index2}_${index3}`}
                        className="pl-20 p-2 block"
                        style={{
                          fontSize: '15px',
                          fontWeight: '300',
                        }}
                        onClick={(e) => onMenuClick(it)}
                      >
                        {it.menuNm}
                      </Link2>
                    ))}
                  </Collapse>
                </div>
              ))}
              <div
                key={`mobile_sidebar_root_divider_${index}`}
                style={{
                  height: '1px',
                  backgroundColor: '#ddd',
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  marginLeft: '2rem',
                  marginRight: '2rem',
                }}
              />
            </div>
          ))}
        </div>
      </Drawer>
    </div>
  );
}
