import { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import BoardList from '@/components/content/list/BoardList';
import { SearchApi } from '@/components/search/SearchApi';
import { searchTypeData } from '@/page/search/SearchPage';
import useInfiniteScroll from '@/components/infiniteScroll/useInfiniteScroll';
import { safeParseInt } from '@/lib/mathUtil';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import useCategorySearch from '@/components/search/useCategorySearch';
import SortFilter from '@/components/content/list/SortFilter';
import useSort from '@/components/infiniteScroll/useSort';

export default function CommonSearchTab({
  keyword,
  isVideo,
  code,
  contentType,
}) {
  const { query } = useLocation2();
  const [sort, setSort] = useSort();

  const { list, isLoading, initLoadCompleted, isLast, loadMore, page, total } =
    useCategorySearch({
      initialPage: (safeParseInt(query.page) ?? 0) + 1,
      searchType: searchTypeData[code],
      keyword,
      sort,
    });

  const dataList = useMemo(() => {
    return list?.map((it) => SearchApi.infoToThumbnailOption(it, isVideo)); // search tab
  }, [list]);

  const [gridViewRef, gridViewBottomRef] = useInfiniteScroll({
    isLoading,
    isInitLoading: !initLoadCompleted,
    isLast,
    loadMore,
    page,
    defaultPage: 0,
    pushPageOnPagePropChange: true,
  });

  return (
    <>
      <div className="cont_tit float-right items-center ">
        <SortFilter selected={sort} onSelect={setSort} />
      </div>
      <div ref={gridViewRef} style={{ marginBottom: 30 }}>
        {initLoadCompleted ? (
          <BoardList
            option={{
              title: searchTypeData[code]?.title ?? code,
              contentType: contentType,
              pageOption: {
                totalCount: total,
              },
              showPage: false,
              thumbnailCardOptions: dataList,
            }}
          />
        ) : (
          <div className="w-full flex items-center justify-center">
            <CircularProgress className="loading" />
          </div>
        )}
      </div>

      <div ref={gridViewBottomRef} />

      {initLoadCompleted && isLoading && (
        <div className="w-full flex items-center justify-center">
          <CircularProgress className="loading" />
        </div>
      )}
    </>
  );
}
