import { Box } from '@mui/material';

/**
 * @param renderItem
 * @param items
 * @param {React.ReactNode} children 추가로 덧붙일 element
 * @return {JSX.Element}
 * @constructor
 */
export default function HorizontalCarousel({ renderItem, items, children }) {
  return (
    <Box
      sx={{
        padding: '0 24px',
        display: '-webkit-box',
        gap: 1,
        py: 1,
        overflow: 'auto',
        scrollSnapType: 'x mandatory',
        '& > *': {
          scrollSnapAlign: 'center',
        },
        '::-webkit-scrollbar': { display: 'none' },
      }}
    >
      <>{[...items.map(renderItem), children]}</>
    </Box>
  );
}
