import { userLogout } from '../login/LoginReducer';
import { useDispatch } from 'react-redux';
import useLogin from '../login/useLogin';
import useStartViewTransitionCompat from '@/components/common/transition/useStartViewTransitionCompat';
import Link2 from '@/components/common/transition/Link2';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import MyInfoProfileImage from '@/components/user/my/MyInfoProfileImage';
import useSubscribe from '@/components/user/my/useSubscribe';
import useUserPointView from '@/components/user/useUserPointView';
import LoginUtil from '@/components/user/login/LoginUtil';

export default function MyInfoCard({}) {
  const { followingCount, followerCount } = useSubscribe();
  const startViewTransition = useStartViewTransitionCompat();
  const { data } = useLogin();
  const { load, data: pointData, isLoading } = useUserPointView();

  const dispatch = useDispatch();
  const navigator = useNavigate2();

  const logout = async () => {
    dispatch(userLogout());
    startViewTransition(() => {
      navigator('/');
    });
  };

  return (
    <div className="grid-cols-1 w-full lg:col-span-1 col-span-4">
      <div className="left_box flex flex-col">
        <span className="underline text-right mb-6 my_logout" onClick={logout}>
          로그아웃
        </span>

        <div className="profile_wrap w-full flex flex-col items-center">
          <div className="profile_box">
            <MyInfoProfileImage />
          </div>
          <div className="profile_text text-center">
            <p className="f20 mt-3">{data?.nickNm}</p>
            <span className="f12_w400 mt-1 block">
              {data?.compNm} {data?.userSt !== 'S' && data?.userStNm}
            </span>
          </div>
          <Link2 to="/myfollow">
            <div className="follow_box flex mt-4">
              <span className="f14_w300">구독중 {followingCount}</span>
              <span className="f14_w300">구독자 {followerCount}</span>
            </div>
          </Link2>
          <Link2 to="/myinfo">
            <div className="m_ifo_edit f14_w300 mt-6 mb-6">회원정보 수정</div>
          </Link2>
          <Link2 to="/mypoint" className="w-full">
            <div className="point_wrap w-full gap-y-4">
              {pointData?.pointRank != null && (
                <div className="flex justify-between">
                  <span>포인트 순위</span>
                  <span>{pointData?.pointRank}</span>
                </div>
              )}
              <div className="flex justify-between">
                <span>적립 포인트</span>
                <span>{(pointData?.pointPlus ?? 0).toLocaleString()}</span>
              </div>
              <div className="flex justify-between">
                <span>사용 포인트</span>
                <span>{(pointData?.pointMinus ?? 0).toLocaleString()}</span>
              </div>
              <div className="flex justify-between">
                <span>사용가능 포인트</span>
                <span>{(pointData?.userPoint ?? 0).toLocaleString()}</span>
              </div>
            </div>
          </Link2>
        </div>
      </div>
    </div>
  );
}
