import { useEventCallback } from '@mui/material';
import { useEffect, useRef } from 'react';

/**
 * IntersectionObserver 를 ref로 wrapping 했어요.
 * @param {(IntersectionObserverEntry) => void} cb 요소가 ViewPort에 들어왔을 때 호출할 콜백함수
 * @param {HTMLElement} target
 * @param deps
 * @return {{disconnect: (function(): void), unobserve: (function(*): void), observe: (function(*): void)}}
 */
export function useIntersectionObserver(cb, target = null, deps) {
  const callback = useEventCallback(() => {
    cb();
  });

  const observer = useRef(
    new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) callback();
        });
      },
      { threshold: 1, root: target }
    )
  );

  useEffect(() => {
    return () => {
      observer.current.disconnect();
    };
  }, []);

  const observe = (el) => observer.current.observe(el);
  const unobserve = (el) => observer.current.unobserve(el);
  const disconnect = () => observer.current.disconnect();

  return { observe, unobserve, disconnect };
}
