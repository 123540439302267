import { useState } from 'react';
import MyPageApi from '@/components/user/my/MyPageApi';

export default function useThankyouRegList() {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const load = async () => {
    setIsLoading(true);
    let res = await MyPageApi.userThankyouRegList();
    setList(res.data);
    setIsLoading(false);
    return res.data;
  };

  const update = async (param) => {
    setIsLoading(true);
    let res = await MyPageApi.updateThankyouRegList(param);
    setIsLoading(false);
    return res;
  };

  return {
    isLoading,
    list,
    load,
    update,
  };
}
