const ACTIONS = {
  OPEN_LOGIN_SIDE_BAR: 'OPEN_LOGIN_SIDE_BAR',
  CLOSE_LOGIN_SIDE_BAR: 'CLOSE_LOGIN_SIDE_BAR',
  OPEN_MENU_SIDE_BAR: 'OPEN_MENU_SIDE_BAR',
  CLOSE_MENU_SIDE_BAR: 'CLOSE_MENU_SIDE_BAR',

  OPEN_SEARCH_SIDE_BAR: 'OPEN_SEARCH_SIDE_BAR',
  CLOSE_SEARCH_SIDE_BAR: 'CLOSE_SEARCH_SIDE_BAR',
};

Object.freeze(ACTIONS);
export const openSideBar = () => ({ type: ACTIONS.OPEN_LOGIN_SIDE_BAR });
export const closeSideBar = () => ({ type: ACTIONS.CLOSE_LOGIN_SIDE_BAR });

export const openMenuSideBar = () => ({ type: ACTIONS.OPEN_MENU_SIDE_BAR });
export const closeMenuSideBar = () => ({ type: ACTIONS.CLOSE_MENU_SIDE_BAR });

export const openSearchSideBar = () => ({ type: ACTIONS.OPEN_SEARCH_SIDE_BAR });
export const closeSearchSideBar = () => ({
  type: ACTIONS.CLOSE_SEARCH_SIDE_BAR,
});

const initialState = {
  isLoginOpen: false,
  isSearchOpen: false,
  isMenuOpen: false,
};

export default function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.OPEN_LOGIN_SIDE_BAR: {
      return {
        ...state,
        isLoginOpen: true,
      };
    }
    case ACTIONS.CLOSE_LOGIN_SIDE_BAR: {
      return {
        ...state,
        isLoginOpen: false,
      };
    }
    case ACTIONS.OPEN_MENU_SIDE_BAR: {
      return {
        ...state,
        isMenuOpen: true,
      };
    }
    case ACTIONS.CLOSE_MENU_SIDE_BAR: {
      return {
        ...state,
        isMenuOpen: false,
      };
    }
    case ACTIONS.OPEN_SEARCH_SIDE_BAR: {
      return {
        ...state,
        isSearchOpen: true,
      };
    }
    case ACTIONS.CLOSE_SEARCH_SIDE_BAR: {
      return {
        ...state,
        isSearchOpen: false,
      };
    }
    default:
      return state;
  }
}
