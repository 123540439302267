import {
  createPromiseThunk,
  handleAsyncActions,
  reducerUtils,
} from '@/lib/asyncUtils';
import BioApi from './BioApi';
import LoginUtil from '@/components/user/login/LoginUtil';

const API_ACTION = {
  BIO_CAN_AUTH: 'BIO_CAN_AUTH',
  BIO_CAN_AUTH_ERROR: 'BIO_CAN_AUTH_ERROR',
  BIO_CAN_AUTH_SUCCESS: 'BIO_CAN_AUTH_SUCCESS',

  BIO_REQUEST_AUTH: 'BIO_REQUEST_AUTH',
  BIO_REQUEST_AUTH_ERROR: 'BIO_REQUEST_AUTH_ERROR',
  BIO_REQUEST_AUTH_SUCCESS: 'BIO_REQUEST_AUTH_SUCCESS',
};

const ACTIONS = {
  SET_BIO_STATE: 'SET_BIO_STATE',
  SET_BIO_REQUEST_CANCELED: 'SET_BIO_REQUEST_CANCELED',
};

Object.freeze(API_ACTION);

/**
 * @deprecated 2023.07.13, version < 2.0.111
 * @type {(function(...[*]): function(*): Promise<void>)|*}
 */
export const bioCanAuth = createPromiseThunk(
  API_ACTION.BIO_CAN_AUTH,
  BioApi.canBioAuth
);
export const bioRequestAuth = createPromiseThunk(
  API_ACTION.BIO_REQUEST_AUTH,
  BioApi.requestBioAuth
);

export const setBioState = (state) => ({ type: ACTIONS.SET_BIO_STATE, state });

export const setBioRequestCanceled = (state) => ({
  type: ACTIONS.SET_BIO_REQUEST_CANCELED,
  state,
});

const initialState = {
  bioCanAuth: reducerUtils.initial(),
  bioRequest: reducerUtils.initial(),
  bioRegistered: false,
  bioLoginCanceled: false,
};

export default function bioReducer(state = initialState, action) {
  switch (action.type) {
    case API_ACTION.BIO_CAN_AUTH:
    case API_ACTION.BIO_CAN_AUTH_ERROR:
    case API_ACTION.BIO_CAN_AUTH_SUCCESS:
      return handleAsyncActions(API_ACTION.BIO_CAN_AUTH, 'bioCanAuth')(
        state,
        action
      );

    case API_ACTION.BIO_REQUEST_AUTH:
    case API_ACTION.BIO_REQUEST_AUTH_ERROR:
    case API_ACTION.BIO_REQUEST_AUTH_SUCCESS:
      return handleAsyncActions(API_ACTION.BIO_REQUEST_AUTH, 'bioRequest')(
        state,
        action
      );

    case ACTIONS.SET_BIO_STATE: {
      return {
        ...state,
        bioRegistered: action.state,
      };
    }
    case ACTIONS.SET_BIO_REQUEST_CANCELED: {
      return {
        ...state,
        bioLoginCanceled: action.state,
      };
    }

    default:
      return state;
  }
}
