import { SearchApi } from '@/components/search/SearchApi';
import { useQuery } from 'react-query';

/**
 *
 * @param {string} keyword
 * @return {{isLoadingError: false | true, errorUpdateCount: number, data: *, isRefetching: boolean, isRefetchError: false | true, isFetching: boolean, isPlaceholderData: boolean, refetch: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>)) => Promise<QueryObserverResult<*, unknown>>, error: unknown, remove: () => void, isFetchedAfterMount: boolean, isLoading: false | true, errorUpdatedAt: number, dataUpdatedAt: number, search: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>)) => Promise<QueryObserverResult<*, unknown>>, isError: false | true, isPreviousData: boolean, isFetched: boolean, isIdle: true | false, hasError: boolean, isStale: boolean, failureCount: number, isSuccess: false | true, status: "idle" | "error" | "loading" | "success"}}
 */
export default function useTotalSearch(keyword) {
  const totalSearch = useQuery(['totalSearch', keyword], {
    queryFn: ({ queryKey: [_, keyword] }) =>
      SearchApi.totalSearch({
        keyword: keyword,
      }),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
  });
  return {
    ...totalSearch,
    hasError: totalSearch.isError,
    search: totalSearch.refetch,
  };
}
