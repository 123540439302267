import { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import ContentApi from '@/components/content/ContentApi';
import { isLastPage } from '@/page/ucms/user-content/useUserContent';
import Comment from '@/components/comment/model/Comment';
import { dateFormat } from '@/lib/timeUtil';
import useLogin from '@/components/user/login/useLogin';

const PrimitiveQueryKey = {
  CommentInfiniteQuery: 'CommentInfiniteQuery',
  ReportCommentMutation: 'ReportCommentMutation',
  EditCommentMutation: 'EditCommentMutation',
  DeleteCommentMutation: 'DeleteCommentMutation',
  ReportCommentResponseMutation: 'ReportCommentResponseMutation',
  EditCommentResponseMutation: 'EditCommentResponseMutation',
  DeleteCommentResponseMutation: 'DeleteCommentResponseMutation',
};

const QueryKey = {
  CommentInfiniteQuery: {
    all: [PrimitiveQueryKey.CommentInfiniteQuery],
    filtered: (filter) => [
      ...QueryKey.CommentInfiniteQuery.all,
      'filtered',
      filter,
    ],
  },
  ReportCommentMutation: ({ id }) => [
    PrimitiveQueryKey.ReportCommentResponseMutation,
    { id },
  ],
  EditCommentMutation: ({ id, content }) => [
    PrimitiveQueryKey.EditCommentMutation,
    { id, content },
  ],
  DeleteCommentMutation: ({ id }) => [
    PrimitiveQueryKey.DeleteCommentMutation,
    { id },
  ],
  ReportCommentResponseMutation: ({ id }) => [
    PrimitiveQueryKey.ReportCommentResponseMutation,
    { id },
  ],
  EditCommentResponseMutation: ({ id, content }) => [
    PrimitiveQueryKey.EditCommentResponseMutation,
    { id, content },
  ],
  DeleteCommentResponseMutation: ({ id }) => [
    PrimitiveQueryKey.DeleteCommentResponseMutation,
    { id },
  ],
};

const actionToDataFn = (action) => {
  switch (action) {
    case PrimitiveQueryKey.ReportCommentMutation:
      return ContentApi.reportComment;
    case PrimitiveQueryKey.EditCommentMutation:
      return ContentApi.editComment;
    case PrimitiveQueryKey.DeleteCommentMutation:
      return ContentApi.deleteComment;
    case PrimitiveQueryKey.ReportCommentResponseMutation:
      return ContentApi.reportCommentResponse;
    case PrimitiveQueryKey.EditCommentResponseMutation:
      return ContentApi.editCommentResponse;
    case PrimitiveQueryKey.DeleteCommentResponseMutation:
      return ContentApi.deleteCommentResponse;
  }
};

const fetchReplies = async ({ cid, page, size }) => {
  const replies = (await ContentApi.getReplyList2(cid, page, size)).data;
  return Promise.all(
    replies.map(async (reply) => {
      // 대댓글만 시간순 정렬
      const rereplies = (await ContentApi.getReReply(reply.replyId)).data?.sort(
        (a, b) => a.resId - b.resId
      );
      return { ...reply, rereply: rereplies };
    })
  );
};

/**
 *
 * @param action
 * @param pages
 * @param pageIndex
 * @param objectIndex
 * @param {Object} param
 */
const actionToOptimisticUpdate = (
  action,
  pages,
  pageIndex,
  objectIndex,
  params
) => {
  const { id, parentId, content } = params;

  const newPages = [...pages];
  newPages[pageIndex] = { ...newPages[pageIndex] };
  const isResponse = action.includes('Response') && parentId;

  if (action.includes('Report')) {
    newPages[pageIndex].res = newPages[pageIndex].res.map((reply) =>
      reply.replyId === (isResponse ? parentId : id)
        ? {
            ...reply,
            ...(isResponse
              ? {
                  rereply: reply.rereply.map((rereply) =>
                    rereply.resId === id
                      ? {
                          ...rereply,
                          resData: '(신고된 댓글입니다.)',
                        }
                      : rereply
                  ),
                }
              : {
                  replyData: '(신고된 댓글입니다.)',
                }),
          }
        : reply
    );
  } else if (action.includes('Edit')) {
    newPages[pageIndex].res = [
      ...newPages[pageIndex].res.map((reply) =>
        reply.replyId === (isResponse ? parentId : id)
          ? {
              ...reply,
              ...(isResponse
                ? {
                    rereply: reply.rereply.map((rereply) =>
                      rereply.resId === id
                        ? {
                            ...rereply,
                            resData: content,
                          }
                        : rereply
                    ),
                  }
                : {
                    replyData: content,
                  }),
            }
          : reply
      ),
    ];
  } else if (action.includes('Delete')) {
    newPages[pageIndex].res = newPages[pageIndex].res.map((reply) =>
      reply.replyId === (isResponse ? parentId : id)
        ? {
            ...reply,
            ...(isResponse
              ? {
                  rereply: reply.rereply.map((rereply) =>
                    rereply.resId === id
                      ? {
                          ...rereply,
                          resData: '(삭제된 댓글입니다.)',
                          resSt: 'D',
                        }
                      : rereply
                  ),
                }
              : {
                  replyData: '(삭제된 댓글입니다.)',
                  replySt: 'D',
                }),
          }
        : reply
    );
  }
  console.log(newPages);
  return newPages;
};

function findObjectById(pages, targetId) {
  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];
    for (let j = 0; j < page.res.length; j++) {
      const object = page.res[j];
      if (object.replyId === targetId) {
        return { object, pageIndex: i, objectIndex: j };
      }
      for (let k = 0; k < object.rereply?.length ?? 0; k++) {
        const rereply = object.rereply[k];
        if (rereply.resId === targetId)
          return {
            rereply,
            pageIndex: i,
            objectIndex: j,
            rereplyIndex: k,
            isResponse: true,
          };
      }
    }
  }
  return null; // 해당 id를 갖는 객체를 찾지 못한 경우 null 반환
}

export default function useComment({ cid, onCommentSubmit, totalReply }) {
  const [param, setParam] = useState({
    size: 50,
    cid,
  });

  const { isLoggedIn, data: loginData } = useLogin();

  const commentQuery = useInfiniteQuery(
    QueryKey.CommentInfiniteQuery.filtered(param),
    async ({ pageParam = 1, queryKey: [_, __, param] }) => {
      const result = await fetchReplies({
        cid: param.cid,
        page: pageParam,
        size: param.size,
      });
      console.log(result);
      const queryResult = {
        res: result,
        nextPage: pageParam + 1,
        total: totalReply,
      };
      console.log('queryResult: ', queryResult);
      return queryResult;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      getNextPageParam: (lastPage, allPages) => {
        return isLastPage(lastPage.res, param.size)
          ? undefined
          : lastPage.nextPage;
      },
    }
  );
  const [addedReplies, setAddedReplies] = useState(0);
  const [maxReplyId, setMaxReplyId] = useState(0);
  const [maxRereplyId, setMaxRereplyId] = useState(0);

  const list = useMemo(() => {
    console.log('이 데이터를 기반으로 리스트를 렌더링합니다: ', commentQuery);
    if (!commentQuery.data || !commentQuery.isSuccess) return [];
    setMaxReplyId(0);
    setMaxRereplyId(0);
    return commentQuery.data.pages
      .map((page) => page.res)
      .flat()
      .map((it) => {
        setMaxReplyId((prev) => (prev < it.replyId ? it.replyId : prev));
        return new Comment({
          id: it.replyId,
          resId: it.resId,
          userNo: it.userNo,
          replySt: it.replySt,
          profileUrl: it.profileUrl,
          organization: it.compNm,
          author: it.nickNm,
          content: it.replyData,
          timeElapsed: dateFormat({
            value: it.regDt,
            format: 'yyyy.MM.dd HH:mm',
          }), // 날짜를 텍스트로
          subComments: it.rereply?.map((sub) => {
            setMaxRereplyId((prev) => (prev < it.id ? it.id : prev));
            return new Comment({
              id: sub.resId,
              parentId: it.replyId,
              organization: it.compNm,
              resSt: sub.resSt,
              author: sub.nickNm,
              content: sub.resData,
              userNo: sub.userNo,
              profileUrl: sub.profileUrl,
              timeElapsed: dateFormat({
                value: sub.regDt,
                format: 'yyyy.MM.dd HH:mm',
              }), // 날짜를 텍스트로
            });
          }),
        });
      });
  }, [commentQuery.data, commentQuery.status]);

  const { isLast, total } = useMemo(() => {
    if (!commentQuery.isSuccess && commentQuery.isLoading)
      return { isLast: false, total: 0 };
    console.log(commentQuery);
    return {
      isLast: !commentQuery.hasNextPage,
      total: commentQuery.data?.pages?.[0]?.total ?? 0,
    };
  }, [commentQuery.data]);

  // 추가 Optimistic Update 후 댓글 추가 요청
  const _onCommentSubmit = async (value, parentReplyId) => {
    if (!isLoggedIn) return;
    const oldData = queryClient.getQueriesData(
      QueryKey.CommentInfiniteQuery.filtered(param)
    )[0][1];
    // update 오버라이드 방지를 위해 쿼리 취소
    console.log(oldData);
    const commentId = await onCommentSubmit(value, parentReplyId);

    await queryClient.cancelQueries(
      QueryKey.CommentInfiniteQuery.filtered(param)
    );

    // Optimistic Update
    queryClient.setQueryData(
      QueryKey.CommentInfiniteQuery.filtered(param),
      () => {
        if (!parentReplyId) {
          const newPage = {
            ...oldData.pages[0],
            res: [
              {
                _itemNo: 1,
                replyId: commentId,
                cid,
                replyData: value,
                regDt: new Date().toISOString(),
                replySt: 'S',
                ...loginData,
                cnt: null,
                rereply: [],
              },
              ...oldData.pages[0].res,
            ],
          };
          setAddedReplies((prev) => prev + 1);
          const newPages = [newPage, ...oldData.pages.slice(1)];
          return { pages: newPages, pageParams: oldData.pageParams };
        } else {
          // Todo: 부모 댓글 ID로 찾아서 rereply에 추가하기
          const newPages = oldData.pages.map((page) => ({
            ...page,
            res: page.res.map((reply) =>
              reply.replyId === parentReplyId
                ? {
                    ...reply,
                    rereply: [
                      ...(reply.rereply ?? []),
                      {
                        _itemNo: 1,
                        resId: commentId,
                        replyId: reply.replyId,
                        resSt: 'S',
                        cid,
                        resData: value,
                        regDt: new Date().toISOString(),
                        ...loginData,
                      },
                    ],
                  }
                : reply
            ),
          }));
          setAddedReplies((prev) => prev + 1);
          return { pages: newPages, pageParams: oldData.pageParams };
        }
      }
    );
  };

  const queryClient = useQueryClient();
  const commentMutation = useMutation({
    mutationFn: ({ event, params }) => {
      return actionToDataFn(event)({
        replyId: params.id,
        resId: params.id,
        resData: params.content,
        replyData: params.content,
      });
    },
    onMutate: async ({ event, params: mutationParams }) => {
      const oldData = queryClient.getQueriesData(
        QueryKey.CommentInfiniteQuery.filtered(param)
      )[0][1];
      // update 오버라이드 방지를 위해 쿼리 취소
      console.log(oldData);
      await queryClient.cancelQueries(
        QueryKey.CommentInfiniteQuery.filtered(param)
      );

      // Optimistic Update
      queryClient.setQueryData(
        QueryKey.CommentInfiniteQuery.filtered(param),
        () => {
          const obj = findObjectById(oldData.pages, mutationParams.id);
          if (!obj) return oldData;
          const { pageIndex, objectIndex } = obj;
          const pages = actionToOptimisticUpdate(
            event,
            oldData.pages,
            pageIndex,
            objectIndex,
            mutationParams
          );
          return { pages: [...pages], pageParams: oldData.pageParams };
        }
      );
    },
  });

  const fetchReReplies = async (replies) => {
    let r = replies;
    if (r != null && r.length > 0) {
      for (let i = 0; i < r.length; i++) {
        const reply = r[i];
        try {
          const rereply = await ContentApi.getReReply(reply.replyId);
          const oldData = queryClient.getQueriesData(
            QueryKey.CommentInfiniteQuery.filtered(param)
          )[0][1];
          await queryClient.cancelQueries(
            QueryKey.CommentInfiniteQuery.filtered(param)
          );

          queryClient.setQueryData(
            QueryKey.CommentInfiniteQuery.filtered(param),
            () => {
              const { pageIndex, objectIndex } = findObjectById(
                oldData.pages,
                reply.replyId
              );
              const newPages = [...oldData.pages];
              newPages[pageIndex].res[objectIndex] = {
                ...newPages[pageIndex].res[objectIndex],
                rereply: rereply.data,
              };
              return { pages: newPages, pageParams: oldData.pageParams };
            }
          );
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  console.log('hasNextPage: ', commentQuery.hasNextPage);
  return {
    fetchReReplies,
    commentMutation,
    _onCommentSubmit,
    isLast,
    total,
    list,
    commentQuery,
    addedReplies,
  };
}
