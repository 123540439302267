import { apiInstance } from '@/app/config/network';
import { normalizeNFC } from '@/lib/stringUtil';
import AuthUtil from '@/components/auth/AuthUtil';

export default class JoinApi {
  static async checkNickUnique(nick) {
    return apiInstance.post('/api/cms/user/checkUserNick', {
      nickNm: normalizeNFC(nick),
    });
  }

  static async checkEmailUnique(userId) {
    return apiInstance.post('/api/cms/user/checkUserId', {
      userId: userId,
    });
  }

  /**
   * @deprecated use signup instead
   * @param {JoinBody} body
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async regist(body) {
    return apiInstance.post('/api/cms/user/regist', body);
  }

  /**
   *
   * @param {JoinBody} body
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  static async signup(body) {
    return apiInstance.post('/api/cms/signup/signUp', body);
  }

  static async emailAuth(userId, code) {
    return apiInstance.post('/api/cms/user/emailAuth', {
      userId: userId,
      authNo: code,
    });
  }

  static async resendEmailAuth(userId) {
    return apiInstance
      .post('/api/cms/user/sendAuthNo', {
        userId: userId,
      })
      .then((res) => {
        return res.data;
      });
  }

  static async joinEmailCodeReq({
    refId,
    emailId,
    emailDomain,
    userType,
    compId,
  }) {
    return apiInstance
      .post('/api/cms/signup/emailReq', {
        refId: refId,
        emailId: emailId,
        emailDomain: emailDomain,
        userType: userType,
        compId: compId,
      })
      .then((res) => {
        return res.data;
      });
  }

  static async joinEmailCodeAuth({ refId, authNo }) {
    return apiInstance
      .post('/api/cms/signup/emailAuth', {
        refId: refId,
        authNo: authNo,
      })
      .then((res) => {
        return res.data;
      });
  }

  static async joinMobileCodeReq({ refId, userMobile }) {
    return apiInstance
      .post('/api/cms/signup/smsReq', {
        refId: refId,
        userMobile: userMobile,
      })
      .then((res) => {
        return res.data;
      });
  }
  static async joinMobileCodeAuth({ refId, authNo }) {
    return apiInstance
      .post('/api/cms/signup/smsAuth', {
        refId: refId,
        auth2No: authNo,
      })
      .then((res) => {
        return res.data;
      });
  }
}

export const UserType = {
  employee: 'UT01',
  normal: 'UT02',
};

export class JoinBody {
  constructor({
    compId,
    userId,
    userPwd,
    nickNm,
    userNm,
    userComp = '',
    userMobile = '',
  }) {
    this.compId = compId;
    this.adminGroupId = 0; // 일반사용자 0
    this.userId = userId;
    this.emailId = userId.split('@')?.[0];
    this.emailDomain = userId.split('@')?.[1];
    this.userPwd = userPwd;
    this.userType = compId === 0 ? UserType.normal : UserType.employee;
    this.nickNm = normalizeNFC(nickNm);
    this.userNm = normalizeNFC(userNm);
    this.userRank = '';
    this.userComp = userComp;
    this.userMobile = userMobile;
  }
}
