import { useParams } from 'react-router-dom';
import useContentInfo, {
  ContentState,
} from '@/components/content/useContentInfo';
import { useEffect, useMemo } from 'react';
import WooriPlayer, {
  WooriPlayerOption,
} from '@/components/player/WooriPlayer';
import { play } from '@/components/player/playerReducer';
import { useDispatch, useSelector } from 'react-redux';
import { ContentFileType } from '@/components/content/ContentApi';
import useMenu from '@/components/menu/useMenu';
import { CircularProgress } from '@mui/material';
import { getMenuList } from '@/components/menu/MenuReducer';

export default function VideoEmbedPage({}) {
  const { menuId, id } = useParams();
  const props = useContentInfo();
  const dispatch = useDispatch();
  const playOption = useSelector((state) => state.playerReducer.option);
  const {
    data,
    isInitLoading,
    isLoading,
    content,
    videoFile,
    userThumbnails,
    thumbnails,
    hasError,
    contentState,
  } = props;

  const { menuList, findMenu } = useMenu();

  // 상위 컨테이너에서 호출
  // useEffect(() => {
  //     dispatch(getMenuList());
  // }, [])
  //

  const menu = useMemo(() => {
    return findMenu(menuId);
  }, [menuList, menuId]);

  useEffect(() => {
    if (menu != null) {
      props.load(menuId, id, menu.contentType, '');
    }
  }, [menu]);

  useEffect(() => {
    if (!isInitLoading && content != null && videoFile != null) {
      const option = new WooriPlayerOption({
        cid: content.cid,
        cdnSt: videoFile?.cdnSt,
        menuId: data.menuId,
        audioPosterMode: videoFile?.fileType === ContentFileType.audio,
        qualities: videoFile?.qualities,
        qualityDefault: videoFile?.qualities?.[0]?.label,
        poster: thumbnails?.[0]?.poasterUrl ?? userThumbnails?.[0]?.poasterUrl,
        videoTitle: {
          use: true,
          title: content.title,
        },
        spriteThumbnail: videoFile?.spriteThumbnail,
      });
      dispatch(play(option));
    }
  }, [isInitLoading]);

  if (hasError) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center bg-black">
        <span className="text-white">오류</span>
      </div>
    );
  }

  if (isInitLoading || isLoading || contentState === ContentState.loading) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center bg-black">
        <CircularProgress className="loading" />
      </div>
    );
  }

  if (contentState === ContentState.needPassword) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center bg-black">
        <span className="text-white">비공개 영상입니다.</span>
      </div>
    );
  }

  if (data == null || menu == null) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center bg-black">
        <span className="text-white">영상 없음</span>
      </div>
    );
  }

  if (contentState !== ContentState.success || videoFile == null) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center bg-black">
        <span className="text-white">영상 조회 불가</span>
      </div>
    );
  }

  return (
    <div className="overflow-hidden aspect-video w-[100vw] h-[100vh]">
      {playOption != null && (
        <div className="embed">
          <WooriPlayer key="global-woori-player" embed />
        </div>
      )}
    </div>
  );
}
