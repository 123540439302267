import { useParams } from 'react-router-dom';
import { PinField } from 'react-pin-field';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import Config from '@/app/config/config';
import JoinApi from '@/components/join/JoinApi';
import { CircularProgress } from '@mui/material';
import Link2 from '@/components/common/transition/Link2';
import Popup from '@/components/popup/Popup';
import useNavigate2 from '@/components/common/transition/useNavigate2';

const authCodeLength = Config.emailAuthCodeLength;

export default function JoinEmailAuthPage() {
  const [code, setCode] = useState('');
  const { email } = useParams();
  const navigator = useNavigate2();
  const pinRef = useRef();
  const [popup, setPopup] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    if (code.length !== authCodeLength) {
      return;
    }
    const res = await JoinApi.emailAuth(email, `${code}`);
    console.log(res);
    if (res.data.code === '00') {
      // TODO 이메일 인증은 완료이나, 심사 대기인 경우 다른 문구와 동작이 되도록
      navigator('/?emailAuth=true');
    }
  };

  const resend = async () => {
    setIsLoading(true);
    const res = await JoinApi.resendEmailAuth(email);
    if (res.code === '00') {
      setPopup({
        title: '인증번호 재발송',
        message: '인증번호가 재발송 되었습니다.',
        okCallback: () => setPopup(null),
      });
      pinRef.current?.forEach((input) => (input.value = ''));
      setCode('');
    } else {
      setPopup({
        title: '인증번호 재발송 실패',
        message:
          '인증번호를 재발송 할 수 없습니다.\n잠시 후 다시 시도해 주세요',
        okCallback: () => setPopup(null),
      });
    }
    setIsLoading(false);
  };

  return (
    <div id="contents">
      <div className="join_cont flex justify-center items-center">
        <div className="inner_box">
          <div className="join_box">
            <h5 className="head_tit mb-4 justify-between">
              <Link2 to={-1}>
                <img
                  className="arrow_left"
                  src="/images/arrow_left.png"
                  alt="뒤로가기"
                />
              </Link2>
              <span>이메일 인증</span>
              <span></span>
            </h5>

            <h4 className="join_tit f28_w700 mb-2">
              {' '}
              인증코드 {authCodeLength}자리
            </h4>
            <p className="f14_w300">{email}</p>
            <p className="f14_w300 mb-10">
              가입시 적어주신 메일의 메일함을 확인해 보세요.
            </p>
            <div className="input_box_one flex flex-col mb-4">
              <div className="one_col_wrap flex flex-row gap-2 justify-center pin_number">
                <PinField
                  ref={pinRef}
                  type={'number'}
                  className="one_col_pw"
                  onChange={(v) => setCode(v)}
                  pattern={'\\d*'}
                  inputMode={'decimal'}
                  length={authCodeLength}
                  validate={/^[0-9]$/}
                />
              </div>
            </div>
            <div className="text-center">
              {isLoading && (
                <div className="h-12 w-full flex items-center justify-center">
                  <CircularProgress className="loading" />
                </div>
              )}
              {!isLoading && (
                <button
                  type="submit"
                  className="underline w300 mb-6"
                  onClick={resend}
                >
                  인증코드 재전송
                </button>
              )}

              <p className="w300 text-center co_gray999 mb-10">
                인증코드가 포함된 메일을 받지 못하셨나요?
                <br />
                스팸메일함도 확인바랍니다.
              </p>
              <button
                className={classNames({
                  pri_btn: code.length === authCodeLength,
                  pri_btn_dis: code.length !== authCodeLength,
                })}
                onClick={submit}
              >
                가입완료
              </button>
            </div>
          </div>
        </div>
      </div>
      {popup != null && <Popup {...popup} />}
    </div>
  );
}
