import OutlineLabelField from '@/components/form/OutlineLabelField';
import { useState } from 'react';
import classNames from 'classnames';

export default function ContentPasswordPopup({
  showClose = false,
  onSubmit,
  isPasswordError,
}) {
  const [password, setPassword] = useState('');

  return (
    <div className="popup_join2 mb-1">
      <h5>비밀게시글 보기</h5>
      <img
        src="/images/login_close.png"
        alt="닫기"
        className="close"
        onClick={() => onSubmit?.('')}
      />
      <p className="text-center s_tit f14_w300 mb-6 co_gray999 mt-2">
        서비스 이용을 위해
        <br />
        비밀번호를 입력해주세요.
      </p>
      <div className="pop_input_box w-full">
        <OutlineLabelField
          label={'비밀번호'}
          type="password"
          value={password}
          autoFocus={true}
          className={isPasswordError ? 'error' : ''}
          message={isPasswordError ? '비밀번호가 일치하지 않습니다' : ''}
          onChange={(value) => setPassword(value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSubmit?.(password);
            }
          }}
          useClearAdornment={false}
          usePasswordAdornment={false}
          customButton={
            <button
              type="button"
              className={classNames({
                'secrit_btn !w-[80px]': true,
                btn_stroke_m: password.length > 0,
                btn_stroke_m_dis: password.length === 0,
              })}
              onClick={() => onSubmit?.(password)}
            >
              확인
            </button>
          }
        />
      </div>
    </div>
  );
}
