import PathChangeDetect from '@/components/common/PathChangeDetect';

export default function ScrollToTop() {
  return (
    <PathChangeDetect
      callback={() => {
        window.scrollTo(0, 0);
      }}
    />
  );
}
