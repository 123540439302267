import { useEffect, useState } from 'react';
import {
  FileUploadWorkerEventMethod,
  FileUploadWorkerRunMethod,
} from '@/components/content/editor/upload/s3FileUpload.worker';

export default function useS3FileUpload({} = {}) {
  const [worker, setWorker] = useState(null);
  const [workerData, setWorkerData] = useState(null);
  const [uploaded, setUploaded] = useState(null);
  const [state, setState] = useState(FileUploadState.none);
  const [data, setData] = useState(null);
  const [uploadPromise, setUploadPromise] = useState(null);

  useEffect(() => {
    const _worker = new Worker(
      new URL('./s3FileUpload.worker.js', import.meta.url)
    );
    _worker.onmessage = ({ data }) => {
      setWorkerData(data);
    };
    setWorker(_worker);
  }, []);

  useEffect(() => {
    if (!workerData) {
      return;
    }
    const data = workerData;
    const method = data.method;
    if (method === FileUploadWorkerEventMethod.progress) {
      const { loaded, total } = data.data;
      setUploaded(loaded);
      setState(FileUploadState.uploading);
    } else if (method === FileUploadWorkerEventMethod.canceled) {
      setState(FileUploadState.canceled);
      console.log('canceled');
      const e = new UploadAbortError();
      uploadPromise?.reject?.(e);
      setUploadPromise(null);
    } else if (method === FileUploadWorkerEventMethod.error) {
      setState(FileUploadState.error);
      console.error(data.data);
      uploadPromise?.reject?.(data.data);
      setUploadPromise(null);
    } else if (method === FileUploadWorkerEventMethod.uploaded) {
      setState(FileUploadState.uploaded);
      uploadPromise?.resolve?.();
      setUploadPromise(null);
    } else {
      console.warn('unhandled message', data);
    }
  }, [workerData]);

  /**
   *
   * @param fileInfo
   * @param {string} path
   * @param {UploadFileDto} file
   * @returns {Promise<unknown>}
   */
  const upload = (fileInfo, path, file, bucket = 'user') => {
    return new Promise((resolve, reject) => {
      if (!worker) {
        reject(new Error('worker is not ready'));
      } else {
        setUploadPromise({
          resolve,
          reject,
        });
        let data = {
          fileInfo,
          path,
          file,
          bucket,
        };
        worker.postMessage({
          method: FileUploadWorkerRunMethod.upload,
          data: data,
        });
        setData(data);
      }
    });
  };

  const uploadBase64 = (fileInfo, path, body, bucket = 'user') => {
    return new Promise((resolve, reject) => {
      if (!worker) {
        reject(new Error('worker is not ready'));
      } else {
        setUploadPromise({
          resolve,
          reject,
        });
        let data = {
          fileInfo,
          path,
          body,
          bucket,
        };
        worker.postMessage({
          method: FileUploadWorkerRunMethod.uploadBase64,
          data: data,
        });
        setData(data);
      }
    });
  };

  const cancel = () => {
    worker.postMessage({
      method: FileUploadWorkerRunMethod.cancel,
    });
  };

  return {
    data,
    state,
    uploaded,
    upload,
    uploadBase64,
    cancel,
  };
}

export class UploadAbortError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UploadAbortError';
  }
}

export const FileUploadState = {
  none: 'none',
  uploading: 'uploading',
  uploaded: 'uploaded',
  error: 'error',
  canceled: 'canceled',
};
